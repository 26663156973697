
export const en_US = {
  // Options.jsx
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "",
  // Pagination.jsx
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages"
};

export const tr_TR = {
  // Options.jsx
  items_per_page: '/ sayfa',
  jump_to: 'Git',
  jump_to_confirm: 'onayla',
  page: '',
  // Pagination.jsx
  prev_page: 'Önceki Sayfa',
  next_page: 'Sonraki Sayfa',
  prev_5: 'Önceki 5 Sayfa',
  next_5: 'Sonraki 5 Sayfa',
  prev_3: 'Önceki 3 Sayfa',
  next_3: 'Sonraki 3 Sayfa'
};
