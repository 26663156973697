import { LauncherNoticeTs2 } from "domain/launcher/pages/launcher-notice-ts2";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HomeRouter } from "./routers/home-router";
import { useLocation } from "react-router-dom";
import "moment/locale/tr";

function App() {
  const location: any = useLocation();
  //const langSelecter = location.search.split("lang=")[1];
  //console.log(location.pathname);
  return (
    <Router>
        <HomeRouter />
    </Router>
  );
}

export default App;
