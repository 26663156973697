import { gql, useQuery } from "@apollo/client";
import { newsPostGetAllLists, newsPostGetAllListsVariables } from "../__generated__/newsPostGetAllLists";

const NEWS_POST_ALL_QUERY = gql`
  query newsPostGetAllLists($input: GetAllNewsPostInput!) {
   eapi_GetAllNewsPosts(input: $input) {
      ResultCode{
        Code
        Description
        Location
      }
      Pagination{
        totalPages
        totalResults
      }
      newsPostEntities{
       NID
       boardID
       serviceID
       category
       visibility
       thumbnailMain
       thumbnailTitle
       title
       subtitle
       contents
       viewCount
       language
       featured
       postedBy
       regDate
       postDate
      }
    }
  }
`;

export const useNewsPostGetLists = ( page: number, perPage: number, serviceNo:number, categoryNo:number, lang:string) => {
  return useQuery<newsPostGetAllLists, newsPostGetAllListsVariables>(
    NEWS_POST_ALL_QUERY,
    {
      variables: {
        input: { page, perPage, serviceNo, categoryNo, lang },
      },
    }
  );
};
