const Header = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl">END USER LICENSE AGREEMENT</h2>
      <p className="pb-2">
        PLEASE READ THE FOLLOWING END USER LICENSE AGREEMENT (“EULA”) CAREFULLY.
        BY ACCESSING, USING, OR AVAILING YOURSELF OF THE RESOURCES IN ANY
        MANNER, OR USING, REGISTERING, DOWNLOADING, OR INSTALLING THE GAMES, YOU
        ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE
        FOLLOWING TERMS AND CONDITIONS OF THIS LICENSE TO USE THE RESOURCES,
        INCLUDING THE GENIUS PRIVACY POLICY AND THE RULES (AS DEFINED BELOW). IF
        YOU ARE NOT ELIGIBLE OR DO NOT AGREE TO THIS EULA, YOU MAY NOT REGISTER
        FOR OR USE ANY OF THE RESOURCES.
      </p>
    </div>
  );
};
const Content1 = () => {
  return (
    <div>
      <h3 className="mb-4 text-md">1. Scope of this EULA</h3>
      <p className="mb-2 ml-6">
        1.1. This EULA governs the relationship between you and Genius Orc
        Entertainment Inc. 8, Pungdeokcheon-ro 139 beon-gil, Suji-gu, Yongin-si,
        Gyeonggi-do, Republic of Korea, or any one of its affiliated entities
        (“Genius“, “we“, “our” and “us“) in relation to:
      </p>
      <p className="mb-2 ml-12">
        1.1.1. our websites, including without limitation www.geniusorc.com and
        any other websites owned or operated by Genius (the “Site”);
      </p>
      <p className="mb-2 ml-12">
        1.1.2. any online, desktop and mobile games owned, operated or made
        available by Genius including, but not limited to, “Twelve Sky 2” (the
        “Game”);
      </p>
      <p className="mb-2 ml-12">
        1.1.3. any of the forums that we operate for the Games (hereinafter, the
        “Game Forums”) and
      </p>
      <p className="mb-2 ml-12">
        1.1.4. any of our other resources, including without limitation user
        support, mobile apps, and any resources accessible via a third party
        platform, web application, or social media (together the “Ancillary
        Resources”).
      </p>
      <p className="mb-2 ml-6">
        1.2. In this EULA, we refer to the Sites, Games, Games Forums and
        Ancillary Resources as the “Resources”.
      </p>
    </div>
  );
};
const Content2 = () => {
  return (
    <div>
      <h3 className="mb-6 text-md">2. Other Documents You Accept</h3>
      <p className="mb-2 ml-6">
        2.1. This EULA includes the following documents that are incorporated
        herein by reference:
      </p>
      <p className="mb-2 ml-6">
        (a) our Privacy Policy that sets out how we will respect your privacy
        and how we use data collected by the Resources;
      </p>
      <p className="mb-2 ml-6">
        (b) other Genius rules and policies (together with the Game Rules, Forum
        Rules and Clan Rules, the “Rules”).
      </p>
      <p className="mb-2 ml-6">
        2.2. In order to access and use any Game or Game Forum, in addition to
        accepting this EULA, you must also accept the Privacy Policy and the
        Rules applicable to the relevant Game and Game Forum.
      </p>
      <p className="mb-2 ml-6">
        2.4. If you do not want to accept this EULA or the Privacy Policy, then
        you may not use or access any of the Resources. If you do not accept the
        Rules applicable to a Game or Games Forum, then you may not download or
        use that Game or Games Forum.
      </p>
    </div>
  );
};
const Content3 = () => {
  return (
    <div>
      <h3 className="mb-4 text-md">3. Eligibility</h3>
      <p className="mb-2 ml-6">
        3.1 You must be at least 13 years of age to use the Resources. If you
        are under the age of 13 or you do not agree to this EULA, then you are
        not granted a license to use or access the Resources. By agreeing to
        this EULA, you represent and warrant to us: (i) that you are at least
        thirteen years of age; and (ii) that your registration and your use of
        the Resources complies with all applicable laws and regulations.
      </p>
      <p className="mb-2 ml-6">
        3.2 You agree that you will not knowingly allow any individual under the
        age of 13 or other minimum legal age where it differs under local legal
        requirements to use or access the Resources.
      </p>
    </div>
  );
};
export const PolicyEULA = () => {
  return (
    <div className="text-md text-coolGray-700 font-notoserif">
      <Header />
      <Content1 />
      <Content2 />
      <Content3 />
      <h3 className="mb-4 text-md">4. Amendments to this EULA and the Rules</h3>
      <p className="mb-2 ml-6">
        4.1 From time to time, at our sole discretion, we may amend, modify or
        replace this EULA, for example to reflect new products or services or
        because of changes in the law, without prior notice. If we make a
        material change to this EULA, we will make reasonable efforts to notify
        you and, where required by applicable law, we will obtain your consent.
        We may provide notice through a pop-up or banner within the Resources,
        by sending an email to any address you may have used to register for an
        Account, or through other mechanisms. Material changes to this EULA will
        take effect upon the earlier of (a) your actual notice or (b) thirty
        (30) days from posting of such change. If we make a non-material change
        to this EULA which does not reduce your rights, we will post a new
        version of this EULA and the new version of this EULA will take effect
        immediately upon the date of posting. The latest version of this EULA
        will always be available on the Sites, so we recommend that you check
        for updates to this EULA each time you use the Resources. If you
        continue to use the Resources after a new version of this EULA takes
        effect, then you shall be considered to have accepted the latest version
        of this EULA (other than for those material changes where your consent
        is required). Disputes arising under this EULA will be resolved in
        accordance with the version of the EULA in place at the time the dispute
        arose. This section does not apply to changes to the Rules that are
        addressed in Section 4.2.
      </p>
      <p className="mb-2 ml-6">
        4.2 From time to time, at our sole discretion, we may also amend, modify
        or replace the Rules, for example to ensure that the Games and Games
        Forums operate smoothly and to reflect the needs of the community using
        the Games and Games Forums. The latest version of the Rules will always
        be available on the Sites. Any new version of the Rules shall take
        effect immediately upon the date of posting on the Sites. If you
        continue to use a Game or Game Forums after a new version of the
        applicable Rules takes effect, then you shall be considered to have
        accepted the amended Rules.
      </p>
      <h3>5. Your Genius Account</h3>
      <p className="mb-2 ml-6">5.1 Creating an Account</p>
      <p className="mb-2 ml-12">
        5.1.1. In order to use the Resources, you will need to create a Genius
        account. A Genius account is a collection of data associated with a
        particular person that is required for his or her use of the Resources,
        including authentication (authorization), access to settings, statistics
        and other information, and use of rights to Additional Features (the
        “Account”).
      </p>
      <p className="mb-2 ml-12">
        5.1.2. To create an Account, you must have an email address and will be
        required to provide certain information. (The details of the information
        that you may provide are set out in our Privacy Policy). You agree that
        you will provide all information truthfully and accurately, so that we
        have correct details about you and your Account. You will maintain and
        update this information to keep it true, accurate, current, and
        complete.
      </p>
      <p className="mb-2 ml-12">
        5.1.3. When creating an Account you will also be required to provide a
        ‘user name’ and/or ‘persona’ to represent you in connection with the
        Resources. User names and personas are tied to your Account and are
        non-transferable (meaning they cannot be transferred to anyone else).
        You may not use a user name or persona that is used by someone else, is
        vulgar or offensive, imitates any third party, infringes on any
        proprietary right of any third party, or otherwise breaches this EULA or
        the Rules. If you have questions about Account registration, please
        contact us at https://www.geniusorc.com/support.
      </p>
      <p className="mb-2 ml-12">
        5.1.4. In certain cases, including some iOS and Android Games, you may
        also log in through some third party system, if expressly permitted in
        the Game.
      </p>
      <p className="mb-2 ml-12">
        5.1.5. Also, if expressly permitted by Genius, a “demo account” is
        possible in certain Games. In this case, you receive no separate
        username or password. The authorization for the Game is made through the
        specific technical device (mobile phone, tablet, etc.) that you use.
      </p>
      <p className="mb-2 ml-12">
        5.1.6. However, you are strongly advised to set up a standard Account in
        accordance with the above provisions. If a “demo account” is used, we
        are not responsible for keeping your progress in the Game, ensuring
        availability of game items and Additional Features, or maintaining the
        safety of any monetary or valuable assets in the Account. Cases when an
        Account and all connected assets may be lost entirely include the loss
        of the technical device, loss of password or any unauthorized
        modification of the operating system (“jailbreaking”) or of the
        software.
      </p>
      <p>5.2 No Account Sharing</p>
      <p className="mb-2 ml-12">
        5.2.1 You must keep all information relating to your Account
        confidential. At no time should you disclose your account ID or
        password, secret question or answer to anyone. This includes your
        friends, relatives, parents, children, spouses, co-workers, clan members
        and clan leader. In case of such disclosure, clause 5.2.2 will apply.
      </p>
      <p className="mb-2 ml-12">
        5.2.2 You are fully responsible for the conduct and actions using your
        Account and for all breaches of this EULA committed using your Account.
        We shall have no liability to you for any loss or damage arising from
        disclosure of your Account data to anyone, any unauthorized use of your
        Account, or any unauthorized access, use, alteration, modification
        and/or disclosure of your personal information.
      </p>
      <p className="mb-2 ml-12">
        5.2.3 Any sale, purchase, lease, rent, exchange, other commercial
        exploitation of Accounts is prohibited.
      </p>
      <p>5.3 Security of Your Account</p>
      <p className="mb-2 ml-12">
        5.3.1 You must ensure that you secure your Account, computer, mobile
        phone or other device from third party access. Please notify us
        immediately at https://www.geniusorc.com/support if you become aware of:
      </p>
      <p className="mb-2 ml-16">
        5.3.1.1 any unauthorized use of your Account or any other breach of
        security; or
      </p>
      <p className="mb-2 ml-16">
        5.3.1.2 any hacking tools being used or that might be used in relation
        to the Resources.
      </p>
      <p className="mb-2 ml-12">
        5.3.2 We may employ certain physical, electronic and managerial
        procedures designed to help safeguard and prevent unauthorized access,
        use, alteration, modification and/or disclosure of your personal
        information. Although we use procedures reasonably designed to safeguard
        the security of your personal information, transmissions made on or
        through the Internet and personal information stored on our servers or
        the servers of third parties that we use are vulnerable to attack and
        cannot be guaranteed to be secure. In addition, submissions made by
        email are not protected by encryption and are vulnerable to disclosure
        to third parties, including due to interception during transmission.
      </p>
      <p className="mb-2 ml-6">
        5.4 Ownership of Your Account. NOTWITHSTANDING ANYTHING TO THE CONTRARY
        HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR
        OTHER PROPERTY INTEREST IN YOUR ACCOUNT, AND THAT ALL RIGHTS IN AND TO
        YOUR ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT
        OF GENIUS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOU HAVE NO CLAIM,
        RIGHT, TITLE, OWNERSHIP, OR OTHER PROPRIETARY INTEREST IN THE ADDITIONAL
        FEATURES (AS DEFINED BELOW) THAT YOU UNLOCK OR ACCUMULATE, REGARDLESS OF
        ANY CONSIDERATION OFFERED OR PAID IN EXCHANGE FOR SUCH ADDITIONAL
        FEATURES. FURTHERMORE, GENIUS SHALL NOT BE LIABLE IN ANY MANNER FOR THE
        DESTRUCTION, DELETION, MODIFICATION, IMPAIRMENT, HACKING, OR ANY OTHER
        DAMAGE OR LOSS OF ANY KIND CAUSED TO THE GAME CONTENT OR ADDITIONAL
        FEATURES, INCLUDING THE DELETION OF GAME CONTENT OR ADDITIONAL FEATURES
        UPON THE TERMINATION OR EXPIRATION OF YOUR ACCOUNT.
      </p>
      <h3>6. Resources and Content Licenses</h3>
      <p className="mb-2 ml-6">
        6.1 The software, technology, text, forum posts, chat posts, profiles,
        widgets, messages, links, emails, music, sound, graphics, pictures,
        video, data, and all other elements of the Resources, as well as the
        design and appearance of our Sites and the Games (collectively, the
        “Content”), provided by Genius are protected by all relevant
        intellectual property and proprietary rights and applicable laws.
      </p>
      <p className="mb-2 ml-6">
        6.2 As between you and Genius, Genius and its licensors own the Content
        and all of the intellectual property rights in it. Except as expressly
        authorized by Genius, you may not make use of the Content or the
        Resources. Genius and its licensors reserve all rights in and to the
        Content and the Resources not expressly granted in this EULA. Any
        unauthorized use of the Content or Resources for any purpose is
        prohibited.
      </p>
      <p className="mb-2 ml-6">
        6.3 Subject to your compliance with the terms and conditions herein,
        Genius grants you a personal, non-exclusive, revocable,
        non-transferable, limited right to access the Content in connection with
        your access and use of the Resources. Unless and to the extent that we
        have expressly authorized you in writing, you must not:
      </p>
      <p className="mb-2 ml-12">
        6.3.1 copy or download any Content from a Resource (except as part of
        the proper use or operation of that Resource);
      </p>
      <p className="mb-2 ml-12">
        6.3.2 reproduce, distribute, publicly perform or display, lease, sell,
        transmit, transfer, publish, edit, copy, create derivative works from,
        rent, sub-license, distribute, decompile, disassemble, reverse engineer
        or otherwise make unauthorized use of Content;
      </p>
      <p className="mb-2 ml-12">
        6.3.3 make any commercial use (i.e., for profit) of the Content;
      </p>
      <p className="mb-2 ml-12">
        6.3.4 harvest any information from the Resources or Content;
      </p>
      <p className="mb-2 ml-12">
        6.3.5 reverse engineer or modify the Resources or Content;
      </p>
      <p className="mb-2 ml-12">
        6.3.6 interfere with the proper operation of or any security measure
        used by the Resources or Content;
      </p>
      <p className="mb-2 ml-12">
        6.3.7 infringe any intellectual property or other right of any third
        party;
      </p>
      <p className="mb-2 ml-12">
        6.3.8 use the Resources or Content in a manner that suggests an
        unauthorized association or is beyond the scope of the limited license
        granted to you; or
      </p>
      <p className="mb-2 ml-12">
        6.3.9 remove, obscure, or alter copyright, patent, trademark, or other
        proprietary rights notices affixed to Content.
      </p>
      <p className="mb-2 ml-6">
        6.4 Genius makes the Content available to you subject to the following
        conditions:
      </p>
      <p className="mb-2 ml-12">
        6.4.1 we can only make Content available to you if it is legal for you
        to have access to that Content in your home country;
      </p>
      <p className="mb-2 ml-12">
        6.4.2 you may only obtain Content from us (or from any person or third
        party that we authorize for this purpose) and you must not obtain
        Content from any other person or attempt to do so;
      </p>
      <p className="mb-2 ml-12">
        6.4.3 we reserve the right to refuse your request(s) to acquire Content,
        and we also reserve the right to limit or block any request to acquire
        or obtain Content for any reason;
      </p>
      <p className="mb-2 ml-12">
        6.4.4 WE DO NOT GUARANTEE THAT ANY RESOURCES OR CONTENT WILL BE
        AVAILABLE AT ALL TIMES, IN ALL COUNTRIES AND/OR ALL GEOGRAPHIC
        LOCATIONS, OR AT ANY GIVEN TIME OR THAT WE WILL CONTINUE TO OFFER
        PARTICULAR CONTENT FOR ANY PARTICULAR LENGTH OF TIME (UNLESS WE
        EXPRESSLY SAY OTHERWISE AS PART OF THE RESOURCES);
      </p>
      <p className="mb-2 ml-12">
        6.4.5 once you have redeemed or activated Content, including without
        limitation any Additional Feature, it is not returnable, exchangeable,
        or refundable for other Content, cash, goods or services;
      </p>
      <p className="mb-2 ml-12">
        6.4.6 we may change, replace, remove access to or update the Content at
        any time at our sole discretion; and
      </p>
      <p className="mb-2 ml-12">
        6.4.7 we may monitor use of the Resources for a wide variety of
        different purposes, including preventing cheating and hacking, reducing
        toxic player behavior and improving the Resources.
      </p>
      <h3>7. Charges and Billing</h3>
      <p className="mb-2 ml-6">
        7.1 You do not have to pay any registration or subscription fees to
        create an Account. However, some of the Resources or parts thereof may
        require you to pay a fee. If you decide to purchase or to subscribe to
        any such Resources, you must ensure that:
      </p>
      <p className="mb-2 ml-12">7.1.1 you are 13 years of age or older;</p>
      <p className="mb-2 ml-12">
        7.1.2 you are the authorized Account holder for the Account from which
        you are subscribing to the Resources;
      </p>
      <p className="mb-2 ml-12">
        7.1.3 you are authorized to use the particular credit card or other
        accepted payment method;
      </p>
      <p className="mb-2 ml-12">
        7.1.4 all information that you submit at the time of purchase is true
        and accurate; and
      </p>
      <p className="mb-2 ml-12">
        7.1.5 you agree to pay all the fees that you incur, including all
        recurring subscription fees, unless and until you cancel your Account or
        any particular subscription to any of the Resources in accordance with
        this EULA.
      </p>
      <p className="mb-2 ml-6">
        7.2 Any applicable fees and other charges on your Account are payable in
        advance and are not refundable. We may, from time to time, without prior
        notice modify, amend, or supplement payment methods offered within the
        Resources. We will post those changes in the corresponding section of
        the relevant Resource(s).
      </p>
      <p className="mb-2 ml-12">
        7.3 The final price of the purchase and/or subscription may vary
        depending on the selected payment method. To see the final price, click
        the button for the selected payment method. Please check currency
        exchange fees and charges in case payment currency is different from the
        currency of the selected payment method.
      </p>
      <p className="mb-2 ml-6">
        7.4 Prices for the purchases of the Resources are published in the
        corresponding section of the relevant Resource(s). Genius reserves the
        right to change the price at any time by making a respective change on
        the Resource(s). By making a purchase you are considered to demonstrate
        consent with the price. Price changes for any subscriptions will take
        effect at the start of the next subscription period following the date
        of the price change. As permitted by local law, you accept the new price
        by continuing to use the Resources after the price change takes effect.
        If you do not agree with the price changes, you have the right to reject
        the change by unsubscribing from the service prior to the price change
        going into effect. Please therefore make sure you read any such
        notification of price changes carefully.
      </p>
      <p className="mb-2 ml-6">
        7.5 We do our best to describe our services, Resources and prices
        thereof as accurately as possible. However, we are human, and therefore
        we do not warrant that product specifications, pricing, or other content
        on the Resources is complete, accurate, reliable, current, or
        error-free. In the event of any errors relating to the pricing or
        specifications, Genius shall have the right to refuse or cancel any
        purchase or subscription in its sole discretion. If we charged your
        credit card or other account prior to our cancellation, we will issue a
        refund in the amount of the charge. If you have already used your
        purchase or subscription has already expired, Genius reserves the right
        to block access to your Account until you reimburse the full price of
        the corresponding Resource(s). If a subscription or service you
        purchased from Genius is not as described, your sole remedy is to cancel
        the purchase and receive a refund in the amount of the purchase price.
      </p>
      <p className="mb-2 ml-6">
        7.6 Please remember that your Account is personal to you and cannot be
        transferred or traded with any other user.
      </p>
      <p className="mb-2 ml-6">
        7.7 If your issuing bank offers automatic account updater services (Visa
        Account Updater, Mastercard Automatic Billing Updater or similar), these
        services may automatically update your payment card details in our
        acquirers’ systems when they change without any action on your part. If
        you do not want to have your card details automatically updated, please
        contact your issuing bank.
      </p>
      <h3 className="mb-4 ml-4 text-md">8. Additional Features</h3>
      <p>
        8.1 Additional Features. The Resources may permit you to acquire and
        accumulate rights to certain additional virtual game content, features
        and functionalities (including rights to in-game assets and points) as
        made available by Genius (“Additional Features”) which you are granted a
        license to use pursuant to the terms of this EULA. Additional Features
        constitute a limited, non-transferable, revocable right to use features
        of the Resources when, as, and if allowed by Genius and solely as
        governed by and permitted under this EULA. Subject to this EULA,
        Additional Features may be exchanged for access to upgraded features,
        may be exchanged for other Additional Features, or used in connection
        with other features made available by Genius through the Resources.
        Additional Features are not real-world currency, have no monetary value,
        and cannot be used, exchanged, or redeemed except as provided in this
        EULA, and cannot be transferred, in any case. For avoidance of doubt,
        Additional Features are not redeemable or refundable for any sum of
        money or monetary value from Genius or any third party at any time;
        provided, however, if and to the extent that any Additional Features are
        determined by applicable law to constitute real world currency or
        property, then they are the property of Genius.
        <br />
        <br />
      </p>
      <p>
        8.2 Acquiring Additional Features. You may accrue Additional Features in
        a variety of ways. Genius may distribute or provide access to Additional
        Features in exchange for taking certain actions (either directly within
        the Resources, or in connection with a third party service), for a fee,
        or without any fee or required action, in its sole discretion. Genius
        may charge fees for the right to exercise rights associated with
        Additional Features. You agree that you do not “own” the Additional
        Features and that Genius has the absolute right to manage, regulate,
        control, modify and/or eliminate such Additional Features in its sole
        discretion, in any general or specific case, and that Genius will have
        no liability to you based on its exercise of such right. Genius allows
        you to accumulate and manage your Additional Features, and may use terms
        such as “buy” and “sell” to refer to the grant or transfer of rights to
        use the Resources. Use of terms such as “buy” or “sell” does not
        indicate any ownership right. The total amount of a purchase may be
        adjusted by adding a fee, if any, applicable to the payment method you
        selected and for mandatory tax payments, if any, required by law. If
        adjusted, the total amount of the purchase will be recalculated
        automatically and displayed before you confirm the purchase, provided,
        however, that such functionality is supported by a chosen payment
        method. If your payment is in a currency other than that of the purchase
        amount shown on the Site, the exchange rate applied will depend on the
        selected payment method.
        <br />
        <br />
      </p>
      <p>
        8.3 Additional Terms and Conditions. Without limiting the foregoing, the
        following rules apply to Additional Features:
        <br />
        <br />
      </p>
      <p>
        8.3.1 Additional Features may only be redeemed for other Additional
        Features where permitted in the Resources;
        <br />
        <br />
      </p>
      <p>
        8.3.2 Once you have purchased Additional Features, those Additional
        Features are non-refundable and non-exchangeable (whether or not you use
        them);
        <br />
        <br />
      </p>
      <p>
        8.3.3 Additional Features cannot be sold or transferred to anyone, but
        you may buy Additional Features for another user of a Game through the
        gift shop applicable to that Game;
        <br />
        <br />
      </p>
      <p>
        8.3.4 Additional Features cannot be exchanged for cash or any goods or
        services (except other Additional Features as permitted in the
        Resources);
        <br />
        <br />
      </p>
      <p>
        8.3.5 To acquire Additional Features, you need to follow the
        instructions provided in the Resources; this can include making a
        payment and providing personal and financial details (which you
        represent shall be complete and accurate).
        <br />
        <br />
        8.3.6 The price payable for the Additional Features (including any value
        added tax or other applicable taxes of duties) will be as set out on our
        Sites or as part of the Resources (as applicable), but we reserve the
        right to change the price of Additional Features at any time at our
        discretion;
        <br />
        <br />
        8.3.7 We do not make any promises about how or when Additional Features
        may be available and can update or change Additional Features at any
        time;
        <br />
        <br />
        8.3.8 If you receive Additional Features or achievements free of charge,
        at a lower price or without performing the actions that are normally
        required for receipt (e.g., experience points without playing the Game)
        (i) due to a technical error (a bug), you must report this to Genius
        immediately; or (ii) due to hacking, fraud or other illegal actions, you
        must report this to Genius immediately and pay for such Additional
        Features to the extent already consumed. In each case, Genius may delete
        such Additional Features and achievement without any notice to you and,
        if you have already consumed the Additional Features, to block access to
        your Account until you reimburse the full price of the corresponding
        Additional Feature(s). <br />
        <br />
        8.3.9 You may only acquire Additional Features from us (or from any
        person that we authorize for this purpose), or from another authorized
        user of a Game through the use of a feature included in the Game by
        Genius expressly for such purpose (such as a gift through the gift shop
        applicable to that Game), and you must not obtain Additional Features
        from any other person or in any other way or attempt to do so; and{" "}
        <br />
        <br />
        8.3.10 We may limit or block a request to acquire Additional Features
        for any reason. <br />
        <br />
        8.4 Non-Refundable Purchase. In the event you purchase Additional
        Features from Genius, we will transfer the Additional Features to you
        only once that payment has been processed, and you may start using
        Additional Features as soon as you have completed the purchase process.
        You therefore have no right to cancel any transaction to purchase
        Additional Features after completion of the purchase process and being
        entitled to download the Additional Features. <p></p>8.5 Restrictions.
        You agree and acknowledge that Genius may deny or place limitations or
        restrictions on any purchase, issue, or redemption of Additional
        Features, individually or with respect to general volume, at any time
        and for any reason. Genius may halt, suspend, discontinue, or reverse
        any Additional Features transaction (whether proposed, pending or past)
        in cases of actual or suspected fraud, violations of other laws or
        regulations, or deliberate disruptions to or interference with the
        Resources, or the service of any affiliated or related third party.
        <br />
        <br />
      </p>
      <p>
        9. Fan Websites
        <br />
        <br />
      </p>
      <p>
        9.1 This section relates to any fan web site that you may create or
        operate regarding any of our Games or Resources (collectively,
        “Fansites,” and each a “Fansite“).
        <br />
        <br />
      </p>
      <p>
        9.2 At some of our Sites we expressly designate certain Content, such as
        Genius game-related images, graphics or artwork and trademarks, as being
        “for fansite use”. In this EULA we refer to this specifically designated
        Content as “Fansite Content“.
        <br />
        <br />
      </p>
      <p>
        9.3 Subject to the terms and conditions herein, Genius grants you a
        non-exclusive, revocable, personal, non-transferable and limited license
        to reproduce and display Fansite Content on Fansites owned and operated
        by you and solely for non-commercial purposes. This license is further
        conditional upon you complying with the following provisions:
        <br />
        <br />
      </p>
      <p>
        9.3.1 you acknowledge and agree that Genius retains ownership of the
        Fansite Content, and any and all derivative works thereof, and has the
        right to amend, delete, add to or otherwise modify, or to revoke the
        foregoing license with respect to, any items of Fansite Content at any
        time;
        <br />
        <br />
      </p>
      <p>
        9.3.2 you agree to include Genius’s trademark, copyright or other
        proprietary rights notices when displaying Fansite Content if we request
        you to do so and in the manner that we request you to do so;
        <br />
        <br />
      </p>
      <p>
        9.3.3 you agree to comply with any usage guidelines that we may provide
        to you from time to time;
        <br />
        <br />
      </p>
      <p>
        9.3.4 you shall not remove or alter any identifying information or
        copyright management information conveyed in connection with copies of
        Fansite Content, including in digital form, nor challenge Genius’s
        ownership (or the ownership of any third party) of the Fansite Content;
        <br />
        <br />
      </p>
      <p>
        9.3.5 you shall not use or adopt any trademarks that might be
        confusingly similar to any Fansite Content;
        <br />
        <br />
      </p>
      <p>
        9.3.6 the Fansite will not post material that is disparaging, illegal or
        infringes on the rights of any third party or that damages (or that
        might damage) the reputation of Genius or of any of the Games;
        <br />
        <br />
      </p>
      <p>
        9.3.7 except as expressly permitted in this EULA, you shall not rent,
        lease, reproduce, modify, translate the Fansite Content, or make an
        adaptation of (including without limitation fiction or visual art), or
        in any way exploit, any of the Content without our express written
        permission; and
        <br />
        <br />
      </p>
      <p>
        9.3.8 you must not make, or seek to make, any commercial use or profit
        out of the Fansite Content (including for example by selling
        subscriptions to your Fansite) without our prior written consent.
        <br />
        <br />
      </p>
      <p>
        9.4 If you fail to comply with any of the terms set out in this section,
        we reserve the right to terminate your license over the Fansite Content
        and also to close your Account.
        <br />
        <br />
      </p>
      <p>
        9.5 All goodwill arising from your use of Fansite Content, including
        from use of any trademarks owned by Genius, shall inure solely to the
        benefit of Genius.
        <br />
        <br />
      </p>
      <p>
        10. User Generated Content
        <br />
        <br />
      </p>
      <p>
        10.1 General. Some Resources permit you to create or upload content
        which you own, have created or otherwise have appropriate rights in
        (which we refer to in this EULA as “User Generated Content” or “UGC”).
        UGC includes, for example: Account personas, forum posts, chat posts,
        voice chat, messenger type features, profile content and any other
        content or materials contributed by users to, on, or through the
        Resources. If Genius believes that your use or uploading of UGC breaches
        any of the terms and conditions set forth herein, then Genius may
        remove, block, edit, move or disable such UGC in its sole discretion. If
        you contravene any of the terms and conditions herein, Genius reserves
        the right to suspend or permanently remove availability of your UGC and
        to take any other steps that we consider appropriate.
        <br />
        <br />
      </p>
      <p>
        10.2 License Grant to Genius. By posting or publishing UGC, you grant
        Genius a worldwide, non-exclusive, royalty-free right and license (with
        the right to sublicense) to host, store, transfer, display, perform,
        reproduce, modify, and distribute your UGC, in whole or in part, in any
        media formats and through any media channels (now known or hereafter
        developed). Any such use of your UGC by Genius may be without any
        compensation paid to you.
        <br />
        <br />
      </p>
      <p>
        10.3 License Grant to Other Users. By posting and sharing UGC on the
        Resources or otherwise with another user of the Resources, you hereby
        grant that user a non-exclusive license to access and use such UGC as
        permitted by this EULA and the functionality of the Resources.
        <br />
        <br />
      </p>
      <p>
        10.4 You are solely responsible for your UGC and the consequences of
        posting or publishing UGC. By posting or publishing UGC on or through
        the Resources, you affirm, represent, and warrant that:
        <br />
        <br />
      </p>
      <p>
        10.4.1 any part of the UGC which comprises or incorporates any of our
        intellectual property rights remains our property and, as between us and
        you, we own the UGC which you create that is a derivative work of our
        intellectual property rights;
        <br />
        <br />
      </p>
      <p>
        10.4.2 you are the creator of or otherwise own the rights in the UGC
        that you make available to or through the Resources, or, for any UGC
        that is owned by a third party, you have the express authorization of
        such third party to upload such UGC to or through the Resources;
        <br />
        <br />
      </p>
      <p>
        10.4.3 no item of UGC that you upload infringes the intellectual
        property rights or privacy or any other rights of anyone else or is
        illegal or breaches this EULA;
        <br />
        <br />
      </p>
      <p>
        10.4.4 you waive and agree not to assert any moral rights or similar
        rights you may have in UGC;
        <br />
        <br />
      </p>
      <p>
        10.4.5 you are solely responsible for your UGC, and acknowledge that
        Genius does not pre-screen any UGC and does not endorse, approve, or
        pre-screen any UGC that you and other users may contribute to Resources;
        <br />
        <br />
      </p>
      <p>
        10.4.6 you must not in any way claim or suggest that any UGC is
        endorsed, supported by, or affiliated with us; and
        <br />
        <br />
      </p>
      <p>
        10.4.7 Your use of the Resources or Content, including the UGC you
        upload, complies with all applicable laws and legislation and is not
        harmful, offensive, defamatory, sexually explicit, obscene, violent,
        threatening, harassing, abusive, falsely representative of your persona,
        invasive of someone else’s privacy, illegal or likely to cause any
        reputational loss or embarrassment to Genius or its affiliates.
        <br />
        <br />
      </p>
      <p>
        10.5 USERS OF THE RESOURCES CREATE, DOWNLOAD AND USE UGC AT THEIR OWN
        RISK. WE ARE UNDER NO OBLIGATION TO EDIT OR CONTROL UGC THAT YOU OR
        OTHER USERS POST OR PUBLISH, AND WILL NOT BE IN ANY WAY RESPONSIBLE OR
        LIABLE FOR UGC. GENIUS MAY, HOWEVER, AT ANY TIME AND WITHOUT PRIOR
        NOTICE, SCREEN, REMOVE, EDIT, OR BLOCK ANY UGC THAT AT OUR SOLE JUDGMENT
        VIOLATES THIS EULA OR IS OTHERWISE OBJECTIONABLE. YOU UNDERSTAND THAT
        WHEN USING THE RESOURCES YOU WILL BE EXPOSED TO UGC FROM A VARIETY OF
        SOURCES AND ACKNOWLEDGE THAT UGC MAY BE INACCURATE, OFFENSIVE, INDECENT
        OR OBJECTIONABLE. YOU AGREE TO WAIVE, AND DO HEREBY WAIVE, ANY LEGAL OR
        EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST GENIUS WITH
        RESPECT TO UGC. WE EXPRESSLY DISCLAIM ANY AND ALL LIABILITY IN
        CONNECTION WITH UGC. IF NOTIFIED BY A USER OR CONTENT OWNER THAT UGC
        ALLEGEDLY DOES NOT CONFORM TO THIS EULA, WE MAY INVESTIGATE THE
        ALLEGATION AND DETERMINE AT OUR SOLE DISCRETION WHETHER TO REMOVE THE
        UGC, WHICH WE RESERVE THE RIGHT TO DO AT ANY TIME AND WITHOUT NOTICE.
        FOR CLARITY, GENIUS DOES NOT PERMIT COPYRIGHT-INFRINGING ACTIVITIES ON
        THE RESOURCES.
        <br />
        <br />
      </p>
      <p>
        11. Feedback and User Submissions
        <br />
        <br />
      </p>
      <p>
        11.1 We are always pleased to hear from our users and welcome specific
        comments about our Resources. Unfortunately, however, our long-standing
        company policy does not allow us to accept or consider creative ideas,
        suggestions or materials other than those we have specifically
        requested. The aim of this policy is to avoid the possibility of future
        misunderstandings when projects that we develop might seem to others to
        be similar to their own creative work. Accordingly, we must,
        regretfully, ask that you do not send us any original creative
        suggestions, ideas, notes, drawings, concepts or other information such
        as game ideas or original artwork (“Submissions“).
        <br />
        <br />
      </p>
      <p>
        11.2 Any and all Submissions that you send to us, whether at our
        specific request or notwithstanding our request that you do not do so,
        shall be deemed, and shall remain, our property from the time of
        uploading or transmission.
        <br />
        <br />
      </p>
      <p>
        11.3 Accordingly, you hereby assign to Genius (including as a present
        assignment of future rights) all intellectual property rights in
        Submissions that you send to us to the extent owned by you. If for any
        reason this assignment is not effective, then you agree that Genius
        shall have a worldwide, perpetual, irrevocable and royalty-free license
        to host, store, use, display, reproduce, modify, adapt, edit, combine
        with other materials, publish, distribute, create derivative works from,
        promote, exhibit, broadcast, syndicate, sublicense (including, without
        limitation, to third party media channels, platforms, and distributors),
        publicly perform, publicly display, and otherwise use and exploit in any
        manner whatsoever, or grant third parties the right to do any of the
        foregoing, all or any portion of your Submissions, for any purpose
        whatsoever in all formats, on or through any means or medium now known
        or hereafter developed, and with any technology or devices now known or
        hereafter developed, and to advertise, market, and promote the same. You
        further irrevocably grant us the right, but not the obligation, to use
        your name in connection with your Submissions. You also agree to waive
        any right of approval for our use of the rights granted herein and agree
        to waive any moral rights that you may have in any Submissions, even if
        it is altered or changed in a manner not agreeable to you. To the extent
        not waivable, you irrevocably agree not to exercise such rights in a
        manner that interferes with any exercise of the granted rights. To the
        extent permitted by applicable laws, you also waive any moral rights or
        rights of a like nature that you may have in such Submissions.
        <br />
        <br />
      </p>
      <p>
        11.4 You understand that you will not receive any fees, sums,
        consideration, or remuneration for any of the rights granted in this
        section. Our receipt of your Submissions is not an admission of their
        novelty, priority, or originality, and it does not impair our right to
        existing or future intellectual property rights relating to your
        Submissions. You represent and warrant that you own or have the
        necessary rights, licenses, consents, and permissions to grant us the
        rights granted in this section.
        <br />
        <br />
      </p>
      <p>
        12. Rules
        <br />
        <br />
      </p>
      <p>
        12.1 The Rules set out how we expect you to behave when using any of the
        Games or Games Forums. Please review the Rules carefully before using
        any of the Games or Games Forums. We may take appropriate disciplinary
        measures, including account termination and deletion, for inappropriate
        behavior including, but not limited to:
        <br />
        <br />
      </p>
      <p>
        12.1.1 impersonating any person, business or entity, including an
        employee of Genius, or communicating in any way that makes it appear
        that the communication originates from Genius;
        <br />
        <br />
      </p>
      <p>
        12.1.2 posting identifying information about yourself or other users to
        the Sites or within the Games;
        <br />
        <br />
      </p>
      <p>
        12.1.3 harassing, stalking or threatening other users in the Game;
        <br />
        <br />
      </p>
      <p>
        12.1.4 removing, altering or concealing any copyright, trademark, patent
        or other proprietary rights notice of Genius contained in the Sites, the
        Game and/or the Resources.
        <br />
        <br />
      </p>
      <p>
        12.1.5 transmitting content that violates or infringes the rights of
        others, including patent, trademark, trade secret, copyright, publicity,
        personal rights or other rights;
        <br />
        <br />
      </p>
      <p>
        12.1.6 transmitting or communicating any content which, at the sole and
        exclusive discretion of Genius, is deemed offensive, including language
        that is unlawful, harmful, threatening, abusive, harassing, defamatory,
        vulgar, obscene, sexually explicit, discriminatory, or otherwise
        objectionable, or that constitutes cyberbullying;
        <br />
        <br />
      </p>
      <p>
        12.1.7 transmitting or facilitating the transmission of any content that
        contains a virus, corrupted data, trojan horse, bot keystroke logger,
        worm, time bomb, cancelbot or other computer programming routines that
        are intended to and/or actually damage, detrimentally interfere with,
        surreptitiously intercept or mine, scrape or expropriate any system,
        data or personal information;
        <br />
        <br />
      </p>
      <p>
        12.1.8 spamming chat, whether for personal or commercial purposes, by
        disrupting the flow of conversation with repeated postings;
        <br />
        <br />
      </p>
      <p>
        12.1.9 participating in any action which, in the sole and exclusive
        judgment of Genius, defrauds any other user of the Game, including by
        scamming or social engineering;
        <br />
        <br />
      </p>
      <p>
        12.1.10 using any unauthorized third party programs, including hacks,
        cheats, scripts, bots, trainers and automation programs that interact
        with the Resources in any way, for any purpose, including any
        unauthorized third party programs that intercept, emulate, or redirect
        any communication between the software and Genius and any unauthorized
        third party programs that collect information about the Game by reading
        areas of memory used by the software to store information;
        <br />
        <br />
      </p>
      <p>
        12.1.11 developing and using any software designed to modify the Game
        client and change gaming experience (mods) unless expressly permitted by
        Genius;
        <br />
        <br />
      </p>
      <p>
        12.1.12 accessing or attempting to access areas of the Game or Game
        servers that have not been made available to the public;
        <br />
        <br />
      </p>
      <p>
        12.1.13 selecting a user name that is falsely indicative of an
        association with Genius, contains personally identifying information, or
        that is offensive, defamatory, vulgar, obscene, sexually explicit,
        discriminatory or otherwise objectionable. You may not use a misspelling
        or an alternative spelling to circumvent this restriction on user name
        choices. Genius may modify any name which, in the sole and exclusive
        judgment of Genius, violates this provision, without notification to
        you, and may take further disciplinary measures, including account
        termination for repeated violations;
        <br />
        <br />
      </p>
      <p>
        12.1.14 playing on another person’s account to “boost” that account’s
        status or rank; or
        <br />
        <br />
      </p>
      <p>
        12.1.15 performing, soliciting, or assisting with a distributed denial
        of service (DDoS) attack against the Resources or any user.
        <br />
        <br />
      </p>
      <p>
        12.2 If you contravene the Forum Rules, we may, at our sole discretion,
        take some or all of the following actions without any notice to you:
        <br />
        <br />
      </p>
      <p>
        12.2.1 we may restrict your access to the Games Forums to read-only
        status for a short period of time;
        <br />
        <br />
      </p>
      <p>
        12.2.2 we may restrict your access to the Games Forums to read-only
        status for a longer period of time;
        <br />
        <br />
      </p>
      <p>
        12.2.3 we may restrict your access to the Games Forums to read-only
        status permanently; and/or
        <br />
        <br />
      </p>
      <p>
        12.2.4 we may suspend or terminate your Account as provided in section
        13
        <br />
        <br />
      </p>
      <p>
        12.3 If you contravene the Game Rules, we may, at our sole discretion,
        take some or all of the following actions without any notice to you:
        <br />
        <br />
      </p>
      <p>
        12.3.1 we may suspend or terminate your access to certain Game
        functionality, including the “game chat” functionality in the Game;
        <br />
        <br />
      </p>
      <p>
        12.3.2 we may suspend or terminate your Account as provided in section
        13.
        <br />
        <br />
      </p>
      <p>
        12.4 If you encounter another user who is contravening any of the Rules
        or other terms of this EULA, please report this activity to Genius using
        the “Help” or “Report Abuse” functions in the relevant Game or Games
        Forum if available, or otherwise contact Customer Support at
        https://www.geniusorc.com/support.
        <br />
        <br />
      </p>
      <p>
        13. Suspension and Termination of Use, Account or Resources
        <br />
        <br />
      </p>
      <p>
        13.1 If you violate any provision of this EULA, then we may, at our sole
        discretion depending on the seriousness of the breach and without any
        notice to you, take some or all of the following actions: suspend your
        Account for a short period of time; suspend your Account for a longer
        period of time; and may terminate your Account, and in each case the
        level of seriousness of the breach shall be determined exclusively by
        Genius and you agree not to appeal against such Genius decisions.
        <br />
        <br />
      </p>
      <p>
        13.2 If we suspend your Account, then during the period of that
        suspension you will not be able to access your Account or use any of the
        Resources. If we close your Account, then you will never be able to
        access your Account and we may also prohibit you from accessing or using
        the Resources in future.
        <br />
        <br />
      </p>
      <p>
        13.3 We will terminate your Account in very serious circumstances where
        we consider that a suspension is not sufficient. For example, this might
        include a very serious contravention of this EULA, the Rules or the
        Privacy Policy, rules of third party platforms or where you have
        contravened the abovementioned rules or any of them on numerous
        occasions.
        <br />
        <br />
      </p>
      <p>
        13.4 We also reserve the right to modify or discontinue any or all of
        the Resources at any time (including, without limitation, by limiting or
        discontinuing certain features of the Resources) without notice to you.
        We will have no liability whatsoever on account of any change to the
        Resources or any suspension or termination of your access to or use of
        the Resources.
        <br />
        <br />
      </p>
      <p>
        14. Interruptions to the Resources
        <br />
        <br />
      </p>
      <p>
        14.1 From time to time we may need to update, reset, temporarily
        interrupt or shut down some or all of the Resources. Any of these
        actions may cause you to lose access to the Resources temporarily and/or
        cause you setbacks within a Game or other aspects of your use of the
        Resources. Please bear in mind that these activities are sometimes
        required to enable us to continue to provide the Resources.
        <br />
        <br />
      </p>
      <p>
        14.2 We shall have no liability to you if the Resources or any aspect of
        them (including any Additional Features, accounts, statistics, user
        ranks or profile information) are interrupted or unavailable for any
        reason. We may suspend or terminate the availability of the Resources
        and Content, in whole or in part, to any individual user or all users,
        for any reason, at Genius’s sole discretion, and without any advance
        notice or liability.
        <br />
        <br />
      </p>
      <p>
        15. Links to Third Party Sites The Resources may include content from
        and hyperlinks to web sites, locations, platforms and services operated
        and owned by third parties including advertisers and other content
        providers (“Third Party Services”). We may also integrate third party
        technologies into our Resources and host our content on Third Party
        Services. Those Third Party Services may collect data or solicit
        personal information from you. We do not own, control or operate such
        Third Party Services, and are not responsible for their information,
        content, privacy policies, or for the collection, use or disclosure of
        any information those Third Party Services may collect. If you choose to
        access, transact with, or otherwise interact with any such Third Party
        Services, you do so at your own risk. For more information on Third
        Party Services, see our Privacy Policy.
        <br />
        <br />
      </p>
      <p>
        16. Technical Requirements By using the Resources you agree that you
        have the necessary hardware, software and capability (including a
        suitable connection to the Internet) required for the use of the
        Resources. We accept no responsibility or liability for any failure of
        your system to meet the technical requirements of our Resources. 17.
        Patches, Updates and Changes
        <br />
        <br />
      </p>
      <p>
        17.1 From time to time, we may need to:
        <br />
        <br />
      </p>
      <p>
        17.1.1 deploy or provide patches, updates, additional content or other
        modifications to the Resources (for example to enhance online gameplay,
        to add new features or to resolve software bugs); and
        <br />
        <br />
      </p>
      <p>
        17.1.2 remove or suspend access to particular features, content or other
        parts of the Resources.
        <br />
        <br />
      </p>
      <p>
        17.2 We need to take the actions referred to in this section
        automatically in order to keep the Resources running efficiently. It is
        therefore not practicable to ask for your approval or even to notify you
        before we take these actions and you confirm that you consent to us
        taking these actions without your prior approval and without any prior
        notice.
        <br />
        <br />
      </p>
      <p>
        18. Alpha and Beta Tests 18.1 We may give you the opportunity to alpha
        or beta test new games and features of the Resources. Your participation
        as an alpha/beta tester is subject to the following terms and
        conditions.
        <br />
        <br />
      </p>
      <p>
        18.2 Unless expressly indicated otherwise, all alpha/beta tests are
        confidential. The alpha/beta games, including information about features
        and functionality to be offered as part of the games, are confidential.
        If you participate in an alpha/beta test, you must safeguard and prevent
        unauthorized access to, copying, disclosure, and unauthorized use of the
        alpha/beta games. You will carry out the testing personally and not
        provide access to alpha/beta games to any other person. Your obligation
        to keep the alpha/beta games confidential will continue until we
        publicly distribute, or otherwise disclose to the public through no
        fault of yours, each of the games and the content that you are testing.
        <br />
        <br />
      </p>
      <p>
        18.3 If Genius notifies you that you have been selected as an alpha/beta
        tester for an applicable alpha/beta game, you are invited to play the
        alpha/beta game for the sole purpose of evaluating the alpha/beta game
        and identifying errors. Nothing in this EULA or the Sites shall be
        construed as granting you any rights or privileges of any kind with
        respect to the alpha/beta games. THE ALPHA/BETA GAMES ARE PROVIDED FOR
        TESTING ON AN “AS IS” BASIS AND WE MAKE NO WARRANTY TO YOU OF ANY KIND,
        EXPRESS OR IMPLIED.
        <br />
        <br />
      </p>
      <p>
        18.4 When playing certain alpha/beta games, you may accumulate or have
        access to Additional Features or other features referred to as treasure,
        experience points, equipment, or other value or status indicators. We
        may reset this data when the relevant game completes this testing phase
        or at any time during the testing process. In this case, all player
        history and data will be erased and each player will return to novice
        status.
        <br />
        <br />
      </p>
      <p>
        18.5 By starting an alpha/beta game, you agree that:
        <br />
        <br />
      </p>
      <p>
        18.5.1 playing alpha/beta Games is at your own risk and that you know
        that the games may include known or unknown bugs;
        <br />
        <br />
      </p>
      <p>
        18.5.2 any value or status indicators that you achieve through game play
        may be erased at any time;
        <br />
        <br />
      </p>
      <p>
        18.5.3 we have no obligation to make these Games available for play
        without charge for any period of time, nor to make them available at
        all;
        <br />
        <br />
      </p>
      <p>
        18.5.4 these Games may be available only by subscription once the
        testing process is complete or at any time in the future;
        <br />
        <br />
      </p>
      <p>
        18.5.5 this EULA applies to your use of the games during the testing
        phase; and
        <br />
        <br />
      </p>
      <p>
        18.5.6 unless otherwise expressly indicated by Genius, you will keep all
        information about the alpha/beta games confidential and not disclose
        such information to any other person.
        <br />
        <br />
      </p>
      <p>
        19. Epilepsy Warning Certain people are susceptible to epileptic
        seizures or loss of consciousness when exposed to certain flashing
        lights or light patterns in everyday life. Such people may have a
        seizure while watching certain monitor images or playing certain video
        games. This may happen even if the person has no medical history of
        epilepsy or has never had any epileptic seizures. If you or anyone in
        your family has ever had symptoms related to epilepsy (seizures or loss
        of consciousness) when exposed to flashing lights, consult your doctor
        prior to playing. We advise that parents should monitor the use of video
        games by their children. If you or your child experience any of the
        following symptoms: dizziness, blurred vision, eye or muscle twitches,
        loss of consciousness, disorientation, any involuntary movement or
        convulsion, while playing a video game, IMMEDIATELY discontinue use of
        the video game and consult your doctor. Please also note that when using
        a video game you should take certain standard health and safety
        precautions, including avoiding playing the game when tired or fatigued,
        taking 10 to 15 minute breaks every hour, sitting a reasonable distance
        from the screen, and playing the game in a well-lit environment.
        <br />
        <br />
      </p>
      <p>
        20. Copyright Infringement
        <br />
        <br />
      </p>
      <p>
        20.1 DMCA Notification. Genius responds to copyright notifications
        submitted under the Digital Millennium Copyright Act, 17 U.S.C. § 512
        (“DMCA”). To submit a notice of claimed copyright infringement under
        U.S. law, provide our designated agent with the following written
        information:
        <br />
        <br />
      </p>
      <p>
        20.1.1 A physical or electronic signature of the copyright owner or a
        person authorized to act on his or her behalf;
        <br />
        <br />
      </p>
      <p>
        20.1.2 Identification of the copyrighted work claimed to have been
        infringed;
        <br />
        <br />
      </p>
      <p>
        20.1.3 Identification of the infringing material and information
        reasonably sufficient to permit us to locate that material;
        <br />
        <br />
      </p>
      <p>
        20.1.4 Your contact information, including your address, telephone
        number, and an e-mail address;
        <br />
        <br />
      </p>
      <p>
        20.1.5 A statement that you have a good faith belief that the use of the
        material in the manner asserted is not authorized by the copyright
        owner, its agent, or the law; and
        <br />
        <br />
      </p>
      <p>
        20.1.6 A statement that the information in the notification is accurate,
        and, under penalty of perjury, that you are authorized to act on behalf
        of the copyright owner. Our designated agent is: Genius Orc
        Entertainment Inc. 8, Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si,
        Gyeonggi-do, Republic of Korea 16837 Email: dmca@geniusorc.com You can
        obtain further information from the Copyright Office’s online directory
        at www.dmca.copyright.gov/osp . We will respond to notifications of
        claimed copyright infringement in accordance with the DMCA.
        <br />
        <br />
      </p>
      <p>
        20.2 Counter Notification. If you believe that your material has been
        removed in error in response to a copyright notification, you may submit
        a counter notification to our designated agent with the following
        written information:
        <br />
        <br />
      </p>
      <p>
        20.2.1 A physical or electronic signature;
        <br />
        <br />
      </p>
      <p>
        20.2.2 Identification of the material that has been removed or to which
        access has been disabled and the location at which the material appeared
        before it was removed or access to it was disabled;
        <br />
        <br />
      </p>
      <p>
        20.2.3 A statement under penalty of perjury that you have a good faith
        belief that the material was removed or disabled as a result of mistake
        or misidentification of the material to be removed or disabled; and
        <br />
        <br />
      </p>
      <p>
        20.2.4 Your name, address, and telephone number, and a statement that
        you consent to the jurisdiction of the Federal District Court for the
        judicial district in which the address is located, or if your address is
        outside of the U.S., for any judicial district in which Genius may be
        found, and that you will accept service of process from the person who
        provided notification under subsection (c)(1)(C) or an agent of such
        person. We will respond to counter notifications in accordance with the
        DMCA.
        <br />
        <br />
      </p>
      <p>
        21. Indemnity You agree that you are responsible for your use of the
        Resources, and you agree to defend, indemnify, and hold harmless Genius
        and its affiliates, and their officers, directors, employees,
        consultants, and agents (collectively, the “Genius Entities”) from and
        against any and all claims, liabilities, damages, losses, and expenses,
        including reasonable attorneys’ fees and costs, arising out of or in any
        way connected with (i) your access to, use of, or alleged use of the
        Resources; (ii) your violation of this EULA or any representation,
        warranty, or agreements referenced herein, or any applicable law or
        regulation; (iii) your violation of any third-party right, including
        without limitation any intellectual property right, publicity,
        confidentiality, property or privacy right; (iv) your Submissions; (v)
        your use of a Third Party Service; (vi) any misrepresentation by you or
        (vii) any disputes or issues between you and any third party. We reserve
        the right, at our own expense, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by you (and
        without limiting your indemnification obligations with respect to such
        matter), and in such case, you agree to cooperate with our defense of
        such claim. You will not in any event settle any claim without our prior
        written consent. This provision does not require you to indemnify us for
        any unconscionable commercial practice by us or for our fraud,
        deception, false promise, misrepresentation or concealment, suppression
        or omission of any material fact in connection with the Resources.
        <br />
        <br />
      </p>
      <p>
        22. Disclaimers; No Warranties
        <br />
        <br />
      </p>
      <p>
        22.1 EXCEPT AS OTHERWISE SET FORTH HEREIN, THE RESOURCES, GAMES,
        ANCILLARY SERVICES AND ALL CONTENT AVAILABLE THROUGH THE RESOURCES ARE
        PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR
        CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE GENIUS ENTITIES
        SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIM ALL WARRANTIES OF ANY
        KIND, WHETHER STATUTORY, EXPRESS, IMPLIED OR OTHERWISE, RELATING TO THE
        RESOURCES AND ALL CONTENT AVAILABLE THROUGH THE RESOURCES, INCLUDING BUT
        NOT LIMITED TO (i) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT;
        AND (ii) ANY WARRANTIES ARISING OUT OF THE COURSE OF DEALING, USAGE, OR
        TRADE. THE GENIUS ENTITIES DO NOT WARRANT THAT THE RESOURCES OR ANY PART
        THEREOF, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE RESOURCES, WILL
        BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL
        COMPONENTS, AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL BE
        CORRECTED. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES
        AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
        JURISDICTION.
        <br />
        <br />
        22.2 YOU UNDERSTAND AND AGREE THAT YOU USE THE RESOURCES AND USE,
        ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN MATERIALS OR CONTENT THROUGH THE
        RESOURCES AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER
        SYSTEM USED IN CONNECTION WITH THE RESOURCES) OR LOSS OF DATA THAT
        RESULTS FROM THE USE OF THE RESOURCES OR THE DOWNLOAD OR USE OF ANY
        GAMES, MATERIALS OR CONTENT.
        <br />
        <br />
      </p>
      <p>
        23. Limitation of Liability
        <br />
        <br />
      </p>
      <p>
        23.1 IN NO EVENT WILL THE GENIUS ENTITIES BE LIABLE TO YOU FOR ANY
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
        (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, GOODWILL,
        USE, DATA, OR OTHER INTANGIBLE LOSSES OR COST OF PROCURING SUBSTITUTE
        GOODS OR RESOURCES) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE
        OF, OR YOUR INABILITY TO ACCESS OR USE, THE RESOURCES OR ANY GAMES,
        MATERIALS OR CONTENT ON OR THROUGH THE RESOURCES, WHETHER BASED ON
        WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER
        LEGAL THEORY AND WHETHER OR NOT THE GENIUS ENTITIES HAVE BEEN INFORMED
        OF THE POSSIBILITY OF SUCH DAMAGE.
        <br />
        <br />
      </p>
      <p>
        23.2 YOU AGREE THAT THE AGGREGATE LIABILITY OF THE GENIUS ENTITIES TO
        YOU FOR ANY AND ALL CLAIMS ARISING OUT OF RELATING TO THE USE OF OR ANY
        INABILITY TO USE THE RESOURCES (INCLUDING ANY GAMES, MATERIALS OR
        CONTENT AVAILABLE THROUGH THE RESOURCES) OR OTHERWISE UNDER THIS EULA,
        WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF
        (i) THE AMOUNTS YOU HAVE PAID TO GENIUS IN THE 12 MONTHS PRIOR TO THE
        CLAIM FOR ACCESS TO AND USE OF THE SPECIFIC RESOURCES OR GAME FROM WHICH
        THE CLAIM AROSE OR (ii) $100. 23.3 SOME JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
        DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
      </p>
      <p>
        23.4 EACH PROVISION OF THIS EULA THAT PROVIDES FOR A LIMITATION OF
        LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO
        ALLOCATE THE RISKS UNDER THIS EULA BETWEEN THE PARTIES. THIS ALLOCATION
        IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
        EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER
        PROVISIONS OF THIS EULA. THE LIMITATIONS IN THIS SECTION 21 WILL APPLY
        EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
        <br />
        <br />
      </p>
      <p>
        24. Governing Law and Jurisdiction This EULA and any dispute, claim or
        obligation (whether contractual or non-contractual) arising out of or in
        connection with it or its subject matter or formation shall be governed
        by laws of the State of California, USA, without regard to the conflict
        of laws principles thereof. To the extent that any lawsuit or court
        proceeding is permitted hereunder, you and Genius agree to submit to the
        personal and exclusive jurisdiction of the state courts and federal
        courts located within Alameda County, California, USA for the purpose of
        litigating all such disputes. 25. Dispute Resolution and Arbitration.
        <br />
        <br />
      </p>
      <p>
        25.1 Generally. In the interest of resolving disputes between you and
        Genius in the most expedient and cost effective manner, you and Genius
        agree that any and all disputes arising in connection with this EULA
        shall be resolved by binding arbitration. Arbitration is more informal
        than a lawsuit in court. Arbitration uses a neutral arbitrator instead
        of a judge or jury, may allow for more limited discovery than in court,
        and can be subject to very limited review by courts. Arbitrators can
        award the same damages and relief that a court can award. Our agreement
        to arbitrate disputes includes, but is not limited to all claims arising
        out of or relating to any aspect of this EULA, whether based in
        contract, tort, statute, fraud, misrepresentation or any other legal
        theory, and regardless of whether the claims arise during or after the
        termination of this EULA. YOU UNDERSTAND AND AGREE THAT, BY ENTERING
        INTO THIS EULA, YOU AND GENIUS ARE EACH WAIVING THE RIGHT TO A TRIAL BY
        JURY OR TO PARTICIPATE IN A CLASS ACTION.
        <br />
        <br />
      </p>
      <p>
        25.2 Exceptions. Notwithstanding subsection 25.1, we both agree that
        nothing herein will be deemed to waive, preclude, or otherwise limit
        either of our right to (i) bring an individual action in small claims
        court, (ii) pursue enforcement actions through applicable federal,
        state, or local agencies where such actions are available, (iii) seek
        injunctive relief in a court of law, or (iv) to file suit in a court of
        law to address intellectual property infringement claims.
        <br />
        <br />
      </p>
      <p>
        25.3 Arbitrator. Any arbitration between you and Genius will be governed
        by the Commercial Dispute Resolution Procedures and the Supplementary
        Procedures for Consumer Related Disputes (collectively, “AAA Rules“) of
        the American Arbitration Association (“AAA“), as modified by this EULA,
        and will be administered by the AAA. The AAA Rules and filing forms are
        available online at www.adr.org, by calling the AAA at 1-800-778-7879,
        or by contacting Genius.
        <br />
        <br />
      </p>
      <p>
        25.4 Notice; Process. A party who intends to seek arbitration must first
        send a written notice of the dispute to the other, by certified mail or
        Federal Express (signature required), or in the event that we do not
        have a physical address on file for you, by electronic mail (“Notice“).
        Genius’s address for Notice is: Genius Orc Entertainment Inc. 8,
        Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Republic
        of Korea 16837. The Notice must (i) describe the nature and basis of the
        claim or dispute; and (ii) set forth the specific relief sought
        (“Demand“). We agree to use good faith efforts to resolve the claim
        directly, but if we do not reach an agreement to do so within 30 days
        after the Notice is received, you or Genius may commence an arbitration
        proceeding. During the arbitration, the amount of any settlement offer
        made by you or Genius shall not be disclosed to the arbitrator until
        after the arbitrator makes a final decision and award, if any. In the
        event our dispute is finally resolved through arbitration in your favor,
        Genius shall pay you (i) the amount awarded by the arbitrator, if any,
        (ii) the last written settlement amount offered by Genius in settlement
        of the dispute prior to the arbitrator’s award; or (iii) $1,000.00,
        whichever is greater. 25.5 Fees. In the event that you commence
        arbitration in accordance with this EULA, Genius will reimburse you for
        your payment of the filing fee, unless your claim is for greater than
        $10,000, in which case the payment of any fees shall be decided by the
        AAA Rules. Any arbitration hearings will take place at a location to be
        agreed upon in Alameda County, California, provided that if the claim is
        for $10,000 or less, you may choose whether the arbitration will be
        conducted (i) solely on the basis of documents submitted to the
        arbitrator; (ii) through a non-appearance based telephonic hearing; or
        (iii) by an in-person hearing as established by the AAA Rules in the
        county (or parish) of your billing address. If the arbitrator finds that
        either the substance of your claim or the relief sought in the Demand is
        frivolous or brought for an improper purpose (as measured by the
        standards set forth in Federal Rule of Civil Procedure 11(b)), then the
        payment of all fees will be governed by the AAA Rules. In such case, you
        agree to reimburse Genius for all monies previously disbursed by it that
        are otherwise your obligation to pay under the AAA Rules. Regardless of
        the manner in which the arbitration is conducted, the arbitrator shall
        issue a reasoned written decision sufficient to explain the essential
        findings and conclusions on which the decision and award, if any, are
        based. The arbitrator may make rulings and resolve disputes as to the
        payment and reimbursement of fees or expenses at any time during the
        proceeding and upon request from either party made within 14 days of the
        arbitrator’s ruling on the merits.
        <br />
        <br />
      </p>
      <p>
        25.6 No Class Actions. YOU AND GENIUS AGREE THAT EACH MAY BRING CLAIMS
        AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A
        PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        PROCEEDING. Further, unless both you and Genius agree otherwise, the
        arbitrator may not consolidate more than one person’s claims, and may
        not otherwise preside over any form of a representative or class
        proceeding.
        <br />
        <br />
      </p>
      <p>
        25.7 Modifications. In the event that Genius makes any future change to
        this arbitration provision (other than a change to Genius’s address for
        Notice), you may reject any such change by sending us written notice
        within 30 days of the change to Genius’s address for Notice, in which
        case your account with Genius shall be immediately terminated and this
        arbitration provision, as in effect immediately prior to the amendments
        you reject shall survive.
        <br />
        <br />
      </p>
      <p>
        25.8 Enforceability. If Subsection 25.6 is found to be unenforceable or
        if the entirety of this Section 25 is found to be unenforceable, then
        the entirety of this Section 25 shall be null and void and, in such
        case, the parties agree that the exclusive jurisdiction and venue
        described in Section 25 shall govern any action arising out of or
        related to this EULA.
        <br />
        <br />
      </p>
      <p>
        26. Copyright and Trademark Notices “Genius Orc”, “Geniusorc.com” and
        their respective logos are trademarks or registered trademarks of
        Genius. You may not use or display such trademarks in any manner, except
        as expressly set out in this EULA. All third party trademarks and
        service marks that appear in the Games are the property of their
        respective owners and all rights in them are reserved.
        <br />
        <br />
        <p>27. Term and Termination</p>
        <br />
        <br />
        <p>
          27.1 This EULA shall come into effect upon its acceptance by you and
          remain in force until you or Genius terminate(s) your Account.
          Termination of your Account shall constitute termination of this EULA.
          <br />
          <br />
        </p>
        <p>
          27.2 You may terminate your account at any time by contacting customer
          service at
          <a href="https://www.geniusorc.com/support">
            https://www.geniusorc.com/support
          </a>
          . If you terminate your Account, you will remain obligated to pay all
          outstanding fees, if any, relating to your use of the Resources
          incurred prior to termination. Upon any termination of your Account or
          the Resources, we may remove all Additional Features from that Account
          and you will not be entitled to any refunds or compensation.
          <br />
          <br />
        </p>
        <p>
          27.3 Genius may terminate your Account for the reasons and according
          to the process set out in this EULA, including in section 13
          “Suspension and Termination of Use, Account or Resources”, or for any
          other legitimate reason and in accordance with any other legitimate
          process.
          <br />
          <br />
        </p>
        <p>
          27.4 The provisions of this EULA and any applicable Rules, which by
          their nature should survive termination of your use of the Resources,
          Account and this EULA, will survive.
          <br />
          <br />
        </p>
        <p>28. General Provisions</p>
        <p>
          28.1 Consent or Approval. No consent or approval of Genius may be
          deemed to have been granted by Genius without being in writing and
          signed by an officer of Genius.
          <br />
          <br />
        </p>
        <p>
          28.2 Severability; Interpretation; Assignment. If any provision of
          this EULA, or any applicable Rules, is for any reason deemed invalid,
          unlawful, void, or unenforceable, then that provision will be deemed
          severable from this EULA or the Rules, and the invalidity of the
          provision will not affect the validity or enforceability of the
          remainder of this EULA or the Rules. You hereby waive any applicable
          statutory and common law that may permit a contract to be construed
          against its drafter. The summaries of provisions and section headings
          are provided for convenience only and shall not limit the full Terms.
          Genius may assign its rights and obligations under this EULA and any
          applicable Rules, in whole or in part, to any party at any time
          without any notice. This EULA and any applicable Rules may not be
          assigned by you, and you may not delegate your duties under them,
          without the prior written consent of an officer of Genius.
          <br />
          <br />
        </p>
        <p>
          28.3 Complete Agreement; No Waiver. This EULA, and any applicable
          Rules, reflect our complete agreement regarding the Resources and
          supersede any prior agreements, representations, warranties,
          assurances or discussion related to the Resources. Except as expressly
          set forth in this EULA or any applicable Rules, (i) no failure or
          delay by you or Genius in exercising any of rights, powers, or
          remedies under will operate as a waiver of that or any other right,
          power, or remedy, and (ii) no waiver or modification of any term of
          this EULA or any applicable Rules will be effective unless in writing
          and signed by the party against whom the waiver or modification is
          sought to be enforced.
          <br />
          <br />
        </p>
        <p>
          28.4 Investigations; Cooperation with Law Enforcement. Genius reserves
          the right to investigate and prosecute any suspected breaches of this
          EULA or the Resources. Genius may disclose any information as
          necessary to satisfy any law, regulation, legal process or
          governmental request.
          <br />
          <br />
        </p>
        <p>
          28.5 California Consumer Rights and Notices. Residents of California
          are entitled to the following specific consumer rights information:
          you may contact the Complaint Assistance Unit of the Division of
          Consumer Services of the Department of Consumer Affairs by mail at:
          400 R St., Suite 1080, Sacramento, California, 95814, or by telephone
          at (916) 445-1254. Their website is located at:{" "}
          <a href="http://www.dca.ca.gov">http://www.dca.ca.gov</a>
          <br />
          <br />
        </p>
        <p>
          29. Contact Us If you have any questions, complaints, or comments
          regarding this EULA, please contact us at
          https://www.geniusorc.com/support. If you are a California resident,
          you may have this EULA mailed to you electronically by sending a
          letter to the address above with your electronic mail address and a
          request for this EULA. You acknowledge that the provision of support
          is at Genius’s sole discretion and that we have no obligation to
          provide you with customer support of any kind. When you communicate
          with us electronically, you consent to receive communications from us
          electronically. You agree that all agreements, notices, disclosures,
          and other communications that we provide to you electronically satisfy
          any legal requirement that such communications be in writing.
        </p>
      </p>
    </div>
  );
};
