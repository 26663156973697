import mainBg from "assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "assets/game/ts2/images/bg/ts_main_char.png";
import com01 from "assets/game/ts2/images/main/banner-1.jpg";
import com02 from "assets/game/ts2/images/main/banner-2.jpg";
import com03 from "assets/game/ts2/images/main/banner-3.jpg";
import com04 from "assets/game/ts2/images/main/banner-4.jpg";
import comBt01 from "assets/game/ts2/images/main/f-1.jpg";
import comBt02 from "assets/game/ts2/images/main/f-2.jpg";
import comBt03 from "assets/game/ts2/images/main/f-3.jpg";
import comBt04 from "assets/game/ts2/images/main/f-4.jpg";
import comBtShadow from "assets/game/ts2/images/main/com_bt_shadow.png";
import slBg from "assets/game/ts2/images/news-images/main_news_shadow.png";
import biLogoEn from "assets/portal/images/logo.png";
import biLogoTr from "assets/portal/images/karahan_logo.png";
import clanIcon1 from "assets/game/ts2/images/main/server-icon_1.png";
import clanIcon2 from "assets/game/ts2/images/main/server-icon_2.png";
import clanIcon3 from "assets/game/ts2/images/main/server-icon_3.png";
import winIcon from "assets/game/ts2/images/main/windows-icon.png";
import vidBg from "assets/game/ts2/images/main/intro-img.jpg";
import heroChar from "assets/game/ts2/images/main/hero.png";
import fbChar from "assets/game/ts2/images/main/twich-hero.png";
import frChar from "assets/game/ts2/images/main/youtube-hero.png";
import discordText from "assets/game/ts2/images/main/discord.png";
import downloadImg from "assets/game/ts2/images/main/download-bg.jpg";
import playBtn from "assets/game/ts2/images/main/play-button.png";
import topTopBtn from "assets/game/ts2/images/bg/totop.png";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import smokeImg from "assets/game/ts2/images/bg/smoke.png";
import { Dialog, Transition } from "@headlessui/react";
import mainMovie from "assets/game/ts2/movies/12sky2_main.mp4";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../../../apollo";
import { useNewsPostGetLists } from "../../../../hooks/use-news-post-list";
import { ClockIcon, EyeIcon } from "@heroicons/react/outline";
import Moment from "react-moment";
import NumberFormat from "react-number-format";

interface IHoverProps {
  id: number;
  selected: boolean;
}

export const Ts2Main = () => {
  const [isConCode, setIsConCode] = useState("");
  const [userIP, setUserIP] = useState("");
  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    const userIP: string = localStorage.getItem("userIP") || "";
    setIsConCode(conCode);
    setUserIP(userIP);
  }, []);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { t, i18n } = useTranslation();

  const [isMouseOver, setIsMouseOver] = useState<IHoverProps>({
    id: 0,
    selected: false
  });

  const [newsOver, setNewsOver] = useState<IHoverProps>({
    id: 0,
    selected: false
  });

  const [shopOver, setShopOver] = useState<IHoverProps>({
    id: 0,
    selected: false
  });

  const btBanners = [
    {
      id: 0,
      title: `${t("main_banners.about_us")}`,
      desc: `${t("banner_0_context.desc")}`,
      btnLink: "/policy/about-us",
      img: `${comBt01}`
    },
    {
      id: 1,
      title: `${t("main_banners.privacy_policy")}`,
      desc: `${t("banner_1_context.desc")}`,
      btnLink: "/policy/privacy",
      img: `${comBt02}`
    },
    {
      id: 2,
      title: "EULA",
      desc: `${t("banner_2_context.desc")}`,
      btnLink: "/policy/eula",
      img: `${comBt03}`
    },
    {
      id: 3,
      title: `${t("common.support")}`,
      desc: `${t("banner_3_context.desc")}`,
      btnLink: "https://goe.freshdesk.com/support/home",
      img: `${comBt04}`
    }
  ];

  let refDiv = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasData, setHasData] = useState({
    title: "null",
    imgLink: "null"
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setHasData({
      title: "The official video of TwelveSky2 ",
      imgLink: `${mainMovie}`
    });
    setIsOpen(true);
  }

  function onScrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const urlList = [
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/en/support/home"
    },
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/tr/support/home"
    }
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [headerCategory, setHeaderCategory] = useState(0);

  const {data:newsPostLists, loading:newsPostLoading}=useNewsPostGetLists(
    page,
    rowsPerPage,
    1,
    Number(headerCategory),
    i18n.language,
  )

  return (
    <>
      <section
        className="relative flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-opensans"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: 'rgb(28,29,29)',
          overflow: "hidden"
        }}
      >
        {/* wrapper */}
        <div
          style={{
            backgroundImage: `url(${smokeImg})`,
            height: "480px",
            top: "602px"
          }}
          className="absolute z-10 w-full smoke_wrapper"
        />
        {/* wrapper */}

        <div
          className="absolute"
          style={{ marginLeft: "50px", top: "620px", zIndex: 40 }}
        >
          <div className="sparks">
            <div className="spark_1"/>
            <div className="spark_2"/>
            <div className="spark_3"/>
            <div className="spark_4 spark-big"/>
            <div className="spark_5 spark-big"/>
          </div>
        </div>

        {/*캐릭터 height: "736px"*/}
        <section
          className="relative z-20 flex flex-col items-center justify-center w-full max-w-screen-2lg lg:flex-row lg:items-end lg:justify-start"
          style={{ height: "736px"}}
        >
          <div className="z-30 flex flex-col items-center justify-center lg:items-start">
            {isConCode === "TR" ? (
              <img
                src={biLogoTr}
                alt="Karahan logo"
                style={{ height: "110px" }}
                className="ml-2 md:-ml-8"
              />
            ) : i18n.language === "tr" ? (
              <img
                src={biLogoTr}
                alt="Karahan logo"
                style={{ height: "110px" }}
                className="ml-2 md:-ml-8"
              />
            ) : (
              <img
                src={biLogoEn}
                alt="ts2 logo"
                style={{ height: "100px" }}
                className="bright"
              />
            )}
            <ul className="flex items-center justify-center my-20 space-x-6 capitalize sm:space-x-16">
              <li>
                <img
                  src={clanIcon1}
                  alt="ts2 logo"
                  className=""
                  style={{
                    filter: `drop-shadow(0px 0px 20px #ff283d)`
                  }}
                />
                <p className="mt-4 text-coolGray-100 text-shadow">
                  {t("main_clan.white_dragon")}
                </p>
              </li>
              <li>
                <img
                  src={clanIcon2}
                  alt="ts2 logo"
                  className=""
                  style={{
                    filter: `drop-shadow(0px 0px 20px #289cff)`
                  }}
                />
                <p className="mt-4 text-coolGray-100 text-shadow">
                  {t("main_clan.black_snake")}
                </p>
              </li>
              <li>
                <img
                  src={clanIcon3}
                  alt="ts2 logo"
                  className=""
                  style={{
                    filter: `drop-shadow(0px 0px 20px #28ffb6)`
                  }}
                />
                <p className="mt-4 text-coolGray-100 text-shadow">
                  {t("main_clan.golden_tiger")}
                </p>
              </li>
            </ul>
            <div className="flex items-center justify-start">
              <a
                href={process.env.REACT_APP_DOWNLOAD_LINK}
                className="flex items-center justify-center h-20 px-2 text-lg uppercase rounded-xs w-72 text-coolGray-100 bright_wrapper hover:bright hover:text-amber-200 hover:text-shadow"
                style={{
                  backgroundImage: `url(${downloadImg})`,
                  boxShadow: `0px 0px 25px 0px rgb(185 32 46 / 40%), 0px 20px 20px -10px rgb(0 0 0 / 40%)`,
                  zIndex:1000
                }}
              >
                {t("main_banners.download_now")}
              </a>
              <div className="items-center justify-start ml-8 hidden lg:flex">
                <img src={winIcon} alt="" className="h-4 mr-1" />
                <p className="text-sm text-coolGray-300">Windows</p>
              </div>
            </div>
          </div>
          <div className="">
            <img
              src={charBg}
              alt=""
              className="absolute bottom-40 -right-6 sm:-bottom-28 md:-bottom-48 lg:-bottom-32 lg:-right-12"
            />
          </div>
        </section>

        {/* 뉴스 */}
        <section
          className="relative flex flex-col items-center justify-center -mt-16 sm:mt-10 w-11/12 lg:w-full lg:max-w-screen-2lg lg:h-80 lg:flex-row lg:items-center"
          style={{
            backgroundColor: `rgba(8, 13, 18, 0.9)`,
            zIndex: 21
          }}
        >
          <div
            className="relative w-full bg-top bg-no-repeat bg-cover cursor-pointer group h-80 flex items-center justify-center"
            style={{
              backgroundImage: `url(${vidBg})`
            }}
            onClick={() => openModal()}
          >
            <button className="absolute flex items-center justify-center text-3xl text-coolGray-200 lg:left-0 lg:bottom-6">
              <div className={`relative flex items-center justify-center ml-0 mr-4 lg:ml-6`}>
                <img src={playBtn} alt="" className="absolute z-20 left-4" />
                <div className="w-10 h-10 transition duration-500 ease-in-out transform rotate-45 bg-warmGray-900 group-hover:bg-rose-400"/>
              </div>
              <p className="capitalize transition duration-500 ease-in-out group-hover:text-rose-700 text-shadow">
                {t("main_banners.official_video")}
              </p>
            </button>
          </div>
          <div className="relative flex flex-col items-center justify-start h-72 w-full px-0 mt-0 text-coolGray-50 lg:px-6 lg:mt-4">
            <div className="relative z-10 flex items-center justify-between w-full px-4 lg:px-10">
              <div className={`capitalize ${i18n.language==='tr' ? ('text-sm sm:text-2xl') : ('text-2xl')}`}>
                {t("main_banners.latest_news")}
              </div>
              <ul className="relative flex items-center justify-center text-xs space-x-0 sm:space-x-2 capitalize md:space-x-4">
                <li className={`px-2 pb-3 mt-5  transition duration-300 ease-in-out border-b-4 border-transparent ${headerCategory===0?(`text-red-600 border-red-700`):(`hover:border-red-700 hover:text-red-600`)} cursor-pointer`} onClick={()=>setHeaderCategory(0)}>
                  {t("common.all")}
                </li>
                <li className={`px-2 pb-3 mt-5 transition duration-300 ease-in-out border-b-4 border-transparent ${headerCategory === 1 ? (`text-red-600 border-red-700`) : (`hover:border-red-700 hover:text-red-600`)} cursor-pointer`} onClick={()=>setHeaderCategory(1)}>
                  {t("common.news")}
                </li>
                <li className={`px-2 pb-3 mt-5 transition duration-300 ease-in-out border-b-4 border-transparent ${headerCategory === 2 ? (`text-red-600 border-red-700`) : (`hover:border-red-700 hover:text-red-600`)} cursor-pointer`} onClick={()=>setHeaderCategory(2)}>
                  {t("common.update")}
                </li>
              </ul>
            </div>
            <hr className="relative z-0 w-full my-2 border-coolGray-700 -top-2" />
            <div
              className="relative z-10 flex items-center justify-center w-full px-6 lg:px-10"
              onMouseLeave={() => {
                setNewsOver({ id: 0, selected: false });
                setNewsOver({ id: 1, selected: false });
                setNewsOver({ id: 2, selected: false });
                setNewsOver({ id: 3, selected: false });
              }}
            >
              <ul className="w-full mt-2 space-y-6">
                {newsPostLoading ? ('Loading...'):(
                  newsPostLists?.eapi_GetAllNewsPosts.newsPostEntities!.map((item, index) => (
                    <li
                      className=""
                      onMouseEnter={() => setNewsOver({ id: index, selected: true })}
                      key={item.NID}
                    >
                      <Link to={`/game/ts2/details?id=${item.NID}`} className={`flex justify-between items-start w-full`}>
                        <div>
                          <p className="transition duration-300 ease-in-out
                          hover:underline hover:text-red-600
                          flex justify-start items-center mb-1">
                            <p
                              style={{fontSize:'10px', paddingBottom:'1px'}}
                              className={`px-2 rounded-lg text-shadow mr-2 ${
                                item.category === 2
                                  ? `text-amber-100 bg-amber-600 ring-1 ring-amber-800`
                                  : item.category === 1 &&
                                  `text-red-100 bg-red-600 ring-1 ring-red-800`
                              } uppercase`}
                            >{item.category === 1 ? (t('common.news')) : (t('common.update'))}
                            </p>
                            <p className={`text-md md:text-lg`} style={{textOverflow:'ellipsis',
                              overflow:'hidden', whiteSpace:'nowrap', width:'290px' }}>
                              {item.title}
                            </p>
                          </p>
                          <div className={`flex justify-start items-center`}>
                            <p className="text-xs text-coolGray-500 flex justify-start items-center"><ClockIcon className="w-4 h-4 mr-1 text-coolGray-500" /><Moment local locale={i18n.language} format="ll">
                              {item.regDate}
                            </Moment></p>
                            <p className="ml-4 text-xs text-coolGray-500 flex justify-start items-center"><EyeIcon className="w-4 h-4 mr-1 text-coolGray-500"/><NumberFormat
                              value={
                                item.viewCount
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            /></p>
                          </div>
                        </div>
                        <div
                          style={{fontSize:'11px'}}
                          className={`hidden md:flex items-center justify-center h-8 px-4 uppercase transition ease-in-out duration-400 bg-coolGray-800 ring-2 ring-coolGray-800 hover:bg-gray-300 hover:text-gray-700
                    ${
                            newsOver.id === index && newsOver.selected
                              ? `visible`
                              : `invisible`
                          }`}
                        >
                          {t("common.read_info")}
                        </div>
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <img
              src={slBg}
              alt=""
              className="absolute -bottom-0 left-0 z-0 object-fill w-full lg:-bottom-2"
            />
          </div>
        </section>
        {/*커뮤니티 채널 상단 */}
        <hr className="w-full mt-24 mb-12 border-gray-900 max-w-screen-2lg" />
        <section className="relative flex flex-col items-center justify-center w-full
        lg:flex-row lg:items-start lg:justify-between lg:max-w-screen-2lg">

          <div className="z-10 w-11/12 lg:w-5/12">
            <h4 className="mb-6 text-4xl capitalize text-coolGray-900 lg:mb-10">
              {t("main_banners.community")}
            </h4>
            <ul className="flex flex-col items-center justify-center space-y-6 sm:space-y-0 sm:space-x-8 sm:flex-row lg:items-start lg:justify-start lg:flex-col lg:space-y-8 lg:space-x-0">
              {/* 페이스북 배너 */}
              <li className={``}>
                <a
                  href="https://www.facebook.com/12sky2.official/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative flex items-center justify-center transition duration-300 ease-in-out transform scale-100 hover:scale-110"
                  style={{
                    width: "335px",
                    height: "122px",
                    backgroundColor: "#1778F2"
                  }}
                >
                  <p className="flex items-center justify-center text-xl font-medium text-white font-montserrat ">
                    {t("common.find_us_on")}
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="ml-2 mr-24 text-2xl"
                    />
                  </p>
                  <img
                    src={fbChar}
                    alt=""
                    className="absolute right-0 -top-5"
                  />
                </a>
              </li>
              {/* 페이스북 배너 */}
              <li className={``}>
                <a
                  href={process.env.REACT_APP_DISCORD_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative flex items-center justify-center transition duration-300 ease-in-out transform scale-100 hover:scale-110"
                  style={{
                    width: "335px",
                    height: "122px",
                    backgroundColor: "#ED4245"
                  }}
                >
                  <img
                    src={discordText}
                    alt="ts2 discord"
                    className="h-10 mr-24"
                  />
                  <img
                    src={frChar}
                    alt=""
                    className="absolute right-0 -top-5"
                  />
                </a>
              </li>
            </ul>
          </div>
          {/* 뉴스 */}
          <div className="z-10 mt-10 w-11/12 lg:w-7/12 lg:mt-0">
            <h4 className="flex items-center justify-start w-full mb-6 text-4xl capitalize text-coolGray-900 lg:justify-end lg:mb-10">
              {t("main_banners.shops")}
            </h4>
            <div className="relative flex items-center justify-center w-full capitalize flex-col lg:justify-end md:flex-row">
              {/* */}
              <div className="">
                <Link
                  to="/"
                  className="relative
                  flex items-end justify-center text-white transition duration-300 ease-in-out transform scale-100 hover:scale-110"
                  style={{
                    width: "340px",
                    height: "208px",
                    backgroundImage: `url(${com01})`,
                    zIndex: +`${
                      shopOver.id === 0 && shopOver.selected ? `100` : `0`
                    }`
                  }}
                  onMouseEnter={() => setShopOver({ id: 0, selected: true })}
                  onMouseLeave={() => setShopOver({ id: 0, selected: false })}
                >
                  <span
                    className="absolute z-10 px-1 uppercase rounded-xl bg-rose-800 bottom-12"
                    style={{ fontSize: "8px" }}
                  >
                    {t("common.opening_soon")}
                  </span>
                  <span className="absolute z-20 flex items-center justify-center w-full mb-5 text-2xl">
                    {t("main_banners.web_item_shop")}{" "}
                    <ChevronRightIcon className="w-8 h-8" />
                  </span>
                  <div
                    className="absolute bottom-0 left-0"
                    style={{
                      background:
                        "linear-gradient(to top, #131418, rgba(19, 20, 24, 0))",
                      width: `100%`,
                      height: `90px`
                    }}
                 />
                </Link>
                <div className="relative flex items-center justify-center">
                  <Link
                    to="/"
                    className="relative flex items-end justify-center text-white duration-300 ease-in-out transform scale-100 bg-coolGray-500 hover:scale-110"
                    style={{
                      width: "170px",
                      height: "182px",
                      backgroundImage: `url(${com02})`,
                      zIndex: +`${
                        shopOver.id === 1 && shopOver.selected ? `100` : `0`
                      }`
                    }}
                    onMouseEnter={() => setShopOver({ id: 1, selected: true })}
                    onMouseLeave={() => setShopOver({ id: 1, selected: false })}
                  >
                    <span
                      className="absolute z-10 px-1 uppercase rounded-xl bg-rose-800 bottom-12"
                      style={{ fontSize: "8px" }}
                    >
                      {t("common.opening_soon")}
                    </span>
                    <span className="absolute z-20 flex items-center justify-center w-full mb-5 text-2xl">
                      {t("main_banners.gacha")}{" "}
                      <ChevronRightIcon className="w-8 h-8" />
                    </span>
                    <div
                      className="absolute bottom-0 left-0"
                      style={{
                        background:
                          "linear-gradient(to top, #131418, rgba(19, 20, 24, 0))",
                        width: `100%`,
                        height: `90px`
                      }}
                    />
                  </Link>
                  <Link
                    to="/"
                    className="relative z-0 flex items-end justify-center text-white duration-300 ease-in-out transform scale-100 bg-coolGray-200 hover:scale-110 hover:z-30"
                    style={{
                      width: "170px",
                      height: "182px",
                      backgroundImage: `url(${com03})`,
                      zIndex: +`${
                        shopOver.id === 2 && shopOver.selected ? `100` : `0`
                      }`
                    }}
                    onMouseEnter={() => setShopOver({ id: 2, selected: true })}
                    onMouseLeave={() => setShopOver({ id: 2, selected: false })}
                  >
                    <span
                      className="absolute z-10 px-1 uppercase rounded-xl bg-rose-800 bottom-12"
                      style={{ fontSize: "8px" }}
                    >
                      {t("common.opening_soon")}
                    </span>
                    <span className="absolute z-20 flex items-center justify-center w-full mb-5 text-2xl">
                      {t("main_banners.packages")}{" "}
                      <ChevronRightIcon className="w-8 h-8" />
                    </span>
                    <div
                      className="absolute bottom-0 left-0"
                      style={{
                        background:
                          "linear-gradient(to top, #131418, rgba(19, 20, 24, 0))",
                        width: `100%`,
                        height: `90px`
                      }}
                    />
                  </Link>
                </div>
              </div>
              <Link
                to="/shop"
                className="relative z-20 flex items-end bg-cover justify-center text-white duration-300 ease-in-out transform
                scale-100 bg-coolGray-600 hover:scale-110"
                style={{
                  width: window.innerWidth> 1024 ? "260px" : "338px",
                  height: "390px",
                  backgroundImage: `url(${com04})`,
                  zIndex: +`${
                    shopOver.id === 3 && shopOver.selected ? `100` : `0`
                  }`
                }}
                onMouseEnter={() => setShopOver({ id: 3, selected: true })}
                onMouseLeave={() => setShopOver({ id: 3, selected: false })}
              >
                <span className="absolute z-20 flex items-center justify-center w-full mb-5 text-2xl">
                  {t("main_banners.g-coin_shop")}{" "}
                  <ChevronRightIcon className="w-8 h-8" />
                </span>
                <div
                  className="absolute bottom-0 left-0"
                  style={{
                    background:
                      "linear-gradient(to top, #131418, rgba(19, 20, 24, 0))",
                    width: `100%`,
                    height: `90px`
                  }}
                />
              </Link>
            </div>
          </div>
          <img src={heroChar} alt="" className="absolute z-0 -left-8 -top-14 hidden lg:block" />
        </section>
        {/* 하단 */}
        <section className="relative flex flex-col items-center justify-start w-full mt-10 lg:mt-48 pb-20">
          <h4 className="ml-10 w-full text-4xl capitalize md:max-w-screen-2lg mb-10 md:text-coolGray-900 text-coolGray-500">
            {t("main_banners.information")}
          </h4>
          <div
            className="relative flex items-center justify-center w-full flex-wrap lg:space-y-0 lg:flex-nowrap"
            style={{height: window.innerWidth > 1024 ? '408px':''}}
          >
            {/* 컨텐츠 */}
            {btBanners.map((item, index) =>
              item.id < 3 ? (
                <Link
                  key={item.id}
                  to={item.btnLink}
                  className={`relative flex mx-3 my-2
                flex-col items-center justify-start transition-all duration-300
                ease-in-out bg-top bg-no-repeat bg-black text-coolGray-50`}
                  onMouseLeave={() =>
                    setIsMouseOver({ id: item.id, selected: false })
                  }
                  onMouseEnter={() =>
                    setIsMouseOver({ id: item.id, selected: true })
                  }
                  style={{
                    backgroundImage: `url(${item.img})`,
                    width: "280px",
                    height: `${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? "540px"
                        : "390px"
                    }`,
                    boxShadow: `${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `0px 30px 30px -15px rgb(0 0 0 / 50%)`
                        : `0px 0px 0px 0px rgb(0 0 0 )`
                    }`
                  }}
                >
                  <p
                    className={`capitalize absolute z-10 text-xl text-center transition duration-500 ease-in-out bottom-28 ${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `bottom-44`
                        : `bottom-28`
                    }`}
                  >
                    {item.title.split("\n").map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                  <p
                    className={`absolute z-10 px-6 text-sm text-center text-coolGray-500 transition-all duration-500 ease-in-out ${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `opacity-100 top-96`
                        : `opacity-0 top-72`
                    }`}
                  >
                    {item.desc}
                  </p>
                  <div className="absolute z-10 px-4 py-2 text-xs uppercase transition ease-in-out duration-400 bottom-8 bg-coolGray-800 ring-2 ring-coolGray-800 hover:bg-gray-300 hover:text-gray-700">
                    {t("common.more_info")}
                  </div>
                  <img
                    src={comBtShadow}
                    alt="shadow"
                    className="absolute bottom-0 z-0"
                    style={{
                      width: "285px"
                    }}
                  />
                </Link>
              ) : (
                <a
                  key={item.id}
                  href={
                    isLoggedIn
                      ? urlList[0].login
                      : i18n.language === "en"
                      ? urlList[0].logout
                      : urlList[1].logout
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`relative flex mx-3 my-2
                flex-col items-center justify-start transition-all duration-300
                ease-in-out bg-top bg-no-repeat bg-black text-coolGray-50`}
                  onMouseLeave={() =>
                    setIsMouseOver({ id: item.id, selected: false })
                  }
                  onMouseEnter={() =>
                    setIsMouseOver({ id: item.id, selected: true })
                  }
                  style={{
                    backgroundImage: `url(${item.img})`,
                    width: "280px",
                    height: `${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? "540px"
                        : "390px"
                    }`,
                    boxShadow: `${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `0px 30px 30px -15px rgb(0 0 0 / 50%)`
                        : `0px 0px 0px 0px rgb(0 0 0 )`
                    }`
                  }}
                >
                  <p
                    className={`capitalize absolute z-10 text-xl text-center transition duration-500 ease-in-out bottom-28 ${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `bottom-44`
                        : `bottom-28`
                    }`}
                  >
                    {item.title.split("\n").map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                  <p
                    className={`absolute z-10 px-6 text-sm text-center text-coolGray-500 transition-all duration-500 ease-in-out ${
                      isMouseOver.id === item.id && isMouseOver.selected
                        ? `opacity-100 top-96`
                        : `opacity-0 top-72`
                    }`}
                  >
                    {item.desc}
                  </p>
                  <div className="absolute z-10 px-4 py-2 text-xs uppercase transition ease-in-out duration-400 bottom-8 bg-coolGray-800 ring-2 ring-coolGray-800 hover:bg-gray-300 hover:text-gray-700">
                    {t("common.more_info")}
                  </div>
                  <img
                    src={comBtShadow}
                    alt="shadow"
                    className="absolute bottom-0 z-0"
                    style={{
                      width: "285px"
                    }}
                  />
                </a>
              )
            )}
          </div>
        </section>
        <div
          className="fixed z-50 text-xs font-bold text-white uppercase cursor-pointer bottom-10 right-10"
          style={{
            background: `url(${topTopBtn}) center top 18px no-repeat`,
            backgroundColor: `#0d1114`,
            padding: `40px 20px 20px 20px`
          }}
          onClick={() => onScrollTop()}
        >
          {t("common.go_top")}
        </div>
      </section>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          initialFocus={refDiv}
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-80"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-block w-full max-w-4xl p-6 overflow-hidden align-middle transition-all transform bg-black border-2 border-gray-900 shadow-xl">
                <Dialog.Title
                  as="h3"
                  className="mb-2 text-xl font-bold text-gray-400"
                >
                  {hasData.title}
                </Dialog.Title>

                <div className="mt-2">
                  <video
                    autoPlay
                    controls
                    className="w-full border-2 border-gray-700"
                  >
                    <source src={hasData.imgLink} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div
                  className="absolute cursor-pointer top-2 right-2"
                  ref={refDiv}
                  onClick={closeModal}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-2xl text-gray-400 transition-all duration-500 ease-in-out group-hover:text-red-700"
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
