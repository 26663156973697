import { useParams, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { NotFound } from "../../error/404";
import { Ts2Main } from "./pages/ts2-main";
import { Ts2About } from "./pages/ts2-about";
import { Ts2Guide } from "./pages/ts2-guide";
import { Ts2News } from "./pages/ts2-news";
import { Ts2Media } from "./pages/ts2-media";
import { Ts2Shop } from "./pages/ts2-shop";
import { Ts2Downloads } from "./pages/ts2-downloads";
import { Ts2NewsDetail } from "./pages/ts2-news-detail";
import { Ts2Header } from "./common/ts2-header";
import { PortalChildren } from "../../../components/portal-children";
interface IParamTypes {
  id: string;
}

export const Ts2HomeRouter = () => {
  let { id } = useParams<IParamTypes>();

  const returnParamValue = (param: string) => {
    if (param === undefined) {
      return (
        <>
          <Ts2Main />
        </>
      );
    } else if (param === "main") {
      return (
        <>
          <Ts2Main />
        </>
      );
    } else if (param === "about") {
      return (
        <>
          <Ts2About />
        </>
      );
    } else if (param === "guide") {
      return (
        <>
          <Ts2Guide />
        </>
      );
    } else if (param === "news") {
      return (
        <>
          <Ts2News />
        </>
      );
    } else if (param === "details") {
      return (
        <>
          <Ts2NewsDetail />
        </>
      );
    } else if (param === "media") {
      return (
        <>
          <Ts2Media />
        </>
      );
    } else if (param === "shop") {
      return (
        <>
          <Ts2Shop />
        </>
      );
    } else if (param === "downloads") {
      return (
        <>
          <Ts2Downloads />
        </>
      );
    } else {
      return <NotFound />;
    }
  };

  return <PortalChildren classes="game">{returnParamValue(id)}</PortalChildren>;
};
