import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import sc01 from "../../../../assets/game/ts2/images/media/sc_02.jpg";
import sc02 from "../../../../assets/game/ts2/images/media/sc_02.jpg";
import sc03 from "../../../../assets/game/ts2/images/media/sc_03.jpg";
import sc04 from "../../../../assets/game/ts2/images/media/sc_04.jpg";
import sc05 from "../../../../assets/game/ts2/images/media/sc_05.jpg";
import sc06 from "../../../../assets/game/ts2/images/media/sc_06.jpg";
import art01 from "../../../../assets/game/ts2/images/media/art_01.jpg";
import art02 from "../../../../assets/game/ts2/images/media/art_02.jpg";
import art03 from "../../../../assets/game/ts2/images/media/art_03.jpg";
import wp01 from "../../../../assets/game/ts2/images/media/wallpaper_thumb_01.jpg";
import wp02 from "../../../../assets/game/ts2/images/media/wallpaper_thumb_02.jpg";
import wp03 from "../../../../assets/game/ts2/images/media/wallpaper_thumb_03.jpg";

interface IMediaProps {
  id: number;
  imgUrl: string;
}
export const Ts2Media = () => {
  const scList: IMediaProps[] = [
    {
      id: 0,
      imgUrl: `${sc01}`
    },
    {
      id: 1,
      imgUrl: `${sc02}`
    },
    {
      id: 2,
      imgUrl: `${sc03}`
    },
    {
      id: 3,
      imgUrl: `${sc04}`
    },
    {
      id: 4,
      imgUrl: `${sc05}`
    },
    {
      id: 5,
      imgUrl: `${sc06}`
    }
  ];
  const artList: IMediaProps[] = [
    {
      id: 0,
      imgUrl: `${art01}`
    },
    {
      id: 1,
      imgUrl: `${art02}`
    },
    {
      id: 2,
      imgUrl: `${art03}`
    }
  ];
  const wpList: IMediaProps[] = [
    {
      id: 0,
      imgUrl: `${wp01}`
    },
    {
      id: 1,
      imgUrl: `${wp02}`
    },
    {
      id: 2,
      imgUrl: `${wp03}`
    }
  ];
  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/* 상단 */}
        <section
          className="relative z-0 flex items-end justify-center w-full max-w-screen-xl"
          style={{ height: "45rem" }}
        >
          <div className="absolute bottom-0 left-0 z-20 flex items-end justify-start"></div>
          <div className="absolute z-30 w-7/12 top-1/2 left-14">
            <p className="pb-5 text-6xl border-b border-coolGray-200 text-coolGray-100 font-oswald text-shadow">
              MEDIA
            </p>
            <p className="pt-3 text-md text-coolGray-300 text-shadow">
              Twelve Sky has been known for oriental art work and beautiful
              wanderers on the fields. Here we presents some of the biggest
              images we want to share with all.
            </p>
          </div>
          <div className="absolute z-10 -right-10 -bottom-20">
            <img src={charBg} alt="" className="object-fill w-full h-full" />
          </div>
        </section>
        {/* Wallpaper */}
        <section className="w-full max-w-screen-xl mt-10 mb-10">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Wallpaper
          </h4>
          <ul className="grid grid-cols-3 gap-6">
            {wpList.map((item) => (
              <li
                key={item.id}
                className="h-56 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${item.imgUrl})` }}
              ></li>
            ))}
          </ul>
        </section>
        {/* Screenshots */}
        <section className="w-full max-w-screen-xl mt-10 mb-10">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Screenshots
          </h4>
          <ul className="grid grid-cols-3 grid-rows-2 gap-6">
            {scList.map((item) => (
              <li
                key={item.id}
                className="h-56 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${item.imgUrl})` }}
              ></li>
            ))}
          </ul>
        </section>
        {/* Artwork */}
        <section className="w-full max-w-screen-xl mt-10 mb-32">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Artwork
          </h4>
          <ul className="grid grid-cols-3 gap-6">
            {artList.map((item) => (
              <li
                key={item.id}
                className="h-56 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${item.imgUrl})` }}
              ></li>
            ))}
          </ul>
        </section>
      </section>
    </>
  );
};
