import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import { ExternalLinkIcon, BadgeCheckIcon } from "@heroicons/react/solid";
import ts2Logo from "../../../../assets/portal/images/bi_logo.png";

interface IHoverProps {
  id: number;
  selected: boolean;
}
export const Ts2Downloads = () => {
  const clickDownload = () => {
    document.location.href = `${process.env.REACT_APP_DOWNLOAD_LINK}`;
  }

  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/* 상단 */}
        <section
          className="relative z-0 flex items-end justify-center w-full max-w-screen-xl"
          style={{ height: "45rem" }}
        >
          <div className="absolute bottom-0 left-0 z-20 flex items-end justify-start"></div>
          <div className="absolute z-30 w-7/12 top-1/2 left-14">
            <p className="pb-5 text-6xl border-b border-coolGray-200 text-coolGray-100 font-oswald text-shadow">
              DOWNLOAD
            </p>
            <p className="pt-3 text-md text-coolGray-300 text-shadow">
              Free-to-download!
              <br />
              <br />
              The full client will be ready at the launch day. We’ll announce
              all the possible steps until the launch since pre-registration.
            </p>
          </div>
          <div className="absolute z-10 -right-10 -bottom-20">
            <img src={charBg} alt="" className="object-fill w-full h-full" />
          </div>
        </section>
        {/* Media */}
        <section className="flex flex-col items-center justify-center w-full max-w-screen-xl mt-10 mb-10 text-coolGray-200">
          <img src={ts2Logo} alt="" className="" />
          <p className="mt-5 text-5xl uppercase font-oswald">Start!</p>
          <p className="mt-2 text-4xl uppercase font-oswald">
            Tweleve Sky2 : Reborn for FREE
          </p>
          <div className="flex items-center justify-center w-4/5 mt-8">
            <hr className="w-4/12 border-b border-white" />{" "}
            <span className="w-4/12 text-xl text-center">DOWNLOAD LINK</span>
            <hr className="w-4/12 border-b border-white border-1" />
          </div>
          <ul className="flex items-center justify-center mt-6 space-x-10 font-oswald">
            <li>
              <button className="flex items-center justify-center py-2 font-bold uppercase rounded-lg shadow-2xl w-80 ring-4 ring-red-400 ring-offset-4 ring-offset-coolGray-800 bg-gradient-to-t from-red-800 to-red-600 group hover:from-red-600 hover:to-red-800"
                      onClick={clickDownload}
              >
                <span className="transition-all duration-500 ease-in-out group-hover:text-red-200">
                  DOWNLOAD NOW!
                </span>
              </button>
            </li>
          </ul>
        </section>
        {/* Wallpaper */}
        <section className="flex flex-col items-center justify-center w-full max-w-screen-xl mt-10 mb-10 ">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Getting Start
          </h4>
          <div className="w-4/5">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-end w-1/5 mr-2">
                <div className="flex items-center justify-center w-8 h-8 text-xl font-bold border-2 rounded-full border-coolGray-900 font-oswald bg-coolGray-300">
                  1
                </div>
              </div>
              <div className="flex items-center justify-start w-4/5 text-xl pt-7 text-coolGray-100">
                Once you click the Download button, you'll see the file download on the bottom status bar. After it's completed, unzip the file and run "setup.exe" to install the game.
              </div>
            </div>
            <div className="flex items-start justify-end mt-6">
              <div className="flex items-center justify-end w-1/5 mr-2">
                <div className="flex items-center justify-center w-8 h-8 text-xl font-bold border-2 rounded-full border-coolGray-900 font-oswald bg-coolGray-300">
                  2
                </div>
              </div>
              <div className="flex items-center justify-start w-4/5 mt-1 text-xl text-coolGray-100">
                Done!, Log in to the game and start
              </div>
            </div>
          </div>
        </section>
        {/* Screenshots */}
        <section className="w-full max-w-screen-xl mt-10 mb-10">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            System Requirments
          </h4>
          <div className="flex items-center justify-center mt-10 mb-5">
            <table className="w-10/12 border border-collapse table-fixed border-1 border-coolGray-600 text-warmGray-300">
              <thead>
                <tr className="h-12">
                  <th className="w-2/12 border border-coolGray-600">
                    OS & Hardware
                  </th>
                  <th className="w-5/12 border border-coolGray-600">
                    Minimum Requirements
                  </th>
                  <th className="w-5/12 border border-coolGray-600">
                    Recommended Requirements
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-12 bg-opacity-40 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">CPU</td>
                  <td className="pl-5 border border-coolGray-600">
                    INTEL Pentium III 800MHz
                  </td>
                  <td className="pl-5 border border-coolGray-600">
                    INTEL Pentium VI 2.4GHz
                  </td>
                </tr>
                <tr className="h-12 bg-opacity-80 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">RAM</td>
                  <td className="pl-5 border border-coolGray-600">256MB</td>
                  <td className="pl-5 border border-coolGray-600">512MB</td>
                </tr>
                <tr className="h-12 bg-opacity-40 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">VGA</td>
                  <td className="pl-5 border border-coolGray-600">
                    GeForce FX 5200 / Radeon 7500 64MB
                  </td>
                  <td className="pl-5 border border-coolGray-600">
                    GeForce FX 5600 / Radeon 9550, 128MB
                  </td>
                </tr>
                <tr className="h-12 bg-opacity-80 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">DISK SIZE</td>
                  <td
                    colSpan={2}
                    className="text-center border border-coolGray-600"
                  >
                    3GB
                  </td>
                </tr>
                <tr className="h-12 bg-opacity-40 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">OS</td>
                  <td
                    colSpan={2}
                    className="text-center border-1 border-coolGray-600"
                  >
                    Windows 98 / XP / 2000 / Vista / 8 / 10 / 11
                  </td>
                </tr>
                <tr className="h-12 bg-opacity-80 bg-coolGray-900">
                  <td className="pl-5 border border-coolGray-600">DIRECT X</td>
                  <td
                    colSpan={2}
                    className="text-center border border-coolGray-600"
                  >
                    9.0C
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </>
  );
};
