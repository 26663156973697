import React from "react";
import {Link} from "react-router-dom";

interface IAddBtnProps {
  msg: string | null;
  url: string | null;
  size: string | null;
}

export const MyProfileFormBtn: React.FC<IAddBtnProps> = ({
  msg,
  url,
  size
}) => {
  return (
    <Link
      to={`${url}`}
      className={`${size} flex justify-center items-center border border-coolGray-400 
                           bg-gradient-to-b from-coolGray-100 to-coolGray-300 text-sm text-coolGray-800 hover:from-coolGray-400
                           hover:to-coolGray-200 hover:text-coolGray-600 focus:outline-none focus:rounded-none`}
    >
      <div className={`text-center`}>{msg}</div>
    </Link>
  );
};
