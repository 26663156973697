import React, { ChangeEvent, useEffect, useState } from "react";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { MyProfileFormBtn } from "../../../components/general/my-profile-form-btn";
import { CommonLargeBtn } from "../../../components/general/common-large-btn";
import { useMyBalance } from "../../../hooks/use-my-balance";
import { useMyTranLog } from "../../../hooks/use-my-transaction-log";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { client } from "../../../apollo";
import { useTranslation } from "react-i18next";
import { en_US, tr_TR } from "../../../locales/files/page_locales";
import moment from "moment";

export const MyProfilePaymentHistory = () => {
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(3, "months").format()
  );
  const [endDate, setEndDate] = useState(moment.utc().format());
  const [sendStartDate, setSendStartDate] = useState(
    moment.utc().subtract(3, "months").format()
  );
  const [sendEndDate, setSendEndDate] = useState(moment.utc().format());
  const onChangeHandler = (page: number) => {
    //console.log(page);
    setCurPage(page);
    setPageSize(10);
  };
  const {
    data: userBalance,
    loading: userBalanceLoading,
    refetch: balanceRefetch
  } = useMyBalance(1);
  const {
    data: tranLog,
    loading: tranLogLoading,
    refetch: transLogRefetch
  } = useMyTranLog(curPage, pageSize, sendStartDate, sendEndDate);

  useEffect(() => {
    transLogRefetch();
    balanceRefetch();
  }, []);

  const handleStartDate = (e: React.FormEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setStartDate(moment(e.currentTarget.value).format());
  };
  const handleEndDate = (e: React.FormEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setEndDate(moment(e.currentTarget.value).format());
  };

  const onDateChangeHandler = () => {
    if (startDate < endDate) {
      setSendStartDate(startDate);
      setSendEndDate(endDate);
    }
    //setCurPage(1);
    //setPageSize(10);
  };

  const { t, i18n } = useTranslation();
  return (
    <section className="w-full lg:w-9/12">
      <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
        {t("account_info.side.tra_his")}
      </h1>
      <hr className="border border-gray-500" />
      {/* */}
      <section className="flex flex-col items-center justify-center w-full">
        <article className="flex-col items-center justify-center w-9/12 text-center">
          <h1 className="mt-10 mb-4 text-3xl text-purple-900">
            {t("trans_his.tra_ex01")}
          </h1>
          <p className="mb-6 text-sm text-center">
            {t("trans_his.tra_ex02")}
            <a
              href="https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback"
              target="_blank"
              rel="noopener noreferrer "
              className="ml-2 text-purple-900 underline capitalize"
            >
              {t("common.support")}.
            </a>
          </p>
        </article>
        {userBalanceLoading ? (
          <p>{t("common.loading")}</p>
        ) : (
          <>
            <article className="flex items-center justify-center w-full
            px-10 py-3 border border-coolGray-300 flex-col
            sm:justify-between sm:flex-row">
              <div className="flex items-center justify-start w-full sm:w-1/2">
                <div className="flex items-center justify-center w-16 h-16 rounded-full bg-coolGray-200 sm:w-20 sm:h-20">
                  <CurrencyDollarIcon className="text-gray-500 w-12 h-12 sm:w-14 sm:h-14" />
                </div>
                <div className="ml-6">
                  <p className="mb-1 text-gray-900 capitalize text-xs sm:text-md">
                    {t("account_info.main_cont.cur_bal")}
                  </p>
                  <p className="text-4xl font-bold text-gray-600">
                    {userBalance?.eapi_BalanceGet.balanceEntity ? (
                      <NumberFormat
                        value={
                          userBalance?.eapi_BalanceGet.balanceEntity?.amount
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-end w-64 mt-4 sm:w-1/2 sm:mt-0">
                <CommonLargeBtn
                  msg={`${t("common.chg_coin")}`}
                  url={"/shop"}
                  size={"w-64 max-w-3xl capitalize"}
                />
              </div>
            </article>
            <hr className="w-full border-coolGray-300 my-14" />
          </>
        )}
        {tranLogLoading ? (
          <p>{t("common.loading")}</p>
        ) : (
          <>
            <article className="flex items-center justify-between w-full flex-col md:flex-row ">
              <div className="flex items-center justify-center flex-col sm:justify-around sm:flex-row">
                <input
                  id="startDateId"
                  type="date"
                  className="h-12 form-input w-64 sm:w-44"
                  value={moment(startDate).format("YYYY-MM-DD")}
                  onChange={handleStartDate}
                />
                <span className={`mx-1`}>-</span>
                <input
                  id="endDateId"
                  type="date"
                  className="h-12 form-input w-64 sm:w-44"
                  value={moment(endDate).format("YYYY-MM-DD")}
                  onChange={handleEndDate}
                />
                <span className={`mx-1`} />
                <button
                  onClick={onDateChangeHandler}
                  className={`${
                    startDate > endDate
                      ? `pointer-events-none bg-white text-coolGray-300`
                      : `bg-gradient-to-b from-coolGray-100 to-coolGray-300 text-coolGray-800 `
                  } w-64 h-12 mt-2 mb-4 sm:mb-0 sm:mt-0 sm:w-28 capitalize flex justify-center items-center border border-coolGray-400 text-sm
                            hover:from-coolGray-400
                           hover:to-coolGray-200 hover:text-coolGray-600 focus:outline-none focus:rounded-none`}
                >
                  <div className={`text-center`}>{`${t("common.search")}`}</div>
                </button>
              </div>

              <div className="flex items-center justify-center w-full mt-2 md:mt-0 md:w-2/5 md:justify-end">
                <a
                  href="https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback"
                  className={`w-64 h-12 sm:w-44 capitalize flex justify-center items-center border border-coolGray-400 
                           bg-gradient-to-b from-coolGray-100 to-coolGray-300 text-sm text-coolGray-800 hover:from-coolGray-400
                           hover:to-coolGray-200 hover:text-coolGray-600 focus:outline-none focus:rounded-none`}
                >
                  <div className={`text-center`}>
                    {`${t("common.tran_tickets")}`}
                  </div>
                </a>
              </div>
            </article>
            <table className="w-full my-5">
              <thead>
                <tr className="text-sm text-center capitalize bg-coolGray-200">
                  <td className="py-4">{t("common.date")}</td>
                  <td className="py-4 hidden sm:block">{t("common.location")}</td>
                  <td className="py-4">{t("common.access_type")}</td>
                  <td className="py-4">{t("common.product")}</td>
                  <td className="py-4">{t("common.amount")}</td>
                  <td className="py-4">{t("common.total")}</td>
                </tr>
              </thead>
              <tbody>
                {tranLog?.eapi_UsedTrackGetByID.Pagination?.totalResults ===
                0 ? (
                  <tr className="text-sm text-center border-b">
                    <td colSpan={5} className="h-12 text-gray-600">
                      {t("back_end_errors.no_records")}
                    </td>
                  </tr>
                ) : (
                  !tranLogLoading &&
                  tranLog?.eapi_UsedTrackGetByID.userTrackLogs?.map((item) => (
                    <tr
                      key={item.regDate}
                      className="text-sm text-center border-b"
                    >
                      <td className="py-4">
                        <Moment local locale={i18n.language} format="lll">
                          {item.regDate}
                        </Moment>
                      </td>
                      <td className={`hidden sm:table-cell`}>
                        {item.city},{item.countryName}
                      </td>
                      <td>
                        {item.accessType === 1
                          ? "Web"
                          : item.accessType === 2
                          ? "Launcher"
                          : item.accessType === 3
                          ? "Steam"
                          : item.accessType === 4
                          ? "In-game"
                          : "Unknown"}
                      </td>
                      <td
                        className={`${
                          item.deductTxID ? `text-red-600 ` : `text-cyan-800`
                        } font-semibold`}
                      >
                        {t(`${item.itemName}`)}
                      </td>
                      <td
                        className={`${
                          item.deductTxID ? `text-red-600` : `text-cyan-800`
                        } font-bold`}
                      >
                        {item.deductTxID ? `-` : ``}
                        <NumberFormat
                          value={item.usedAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={item.totalBalance}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
        <article>
          <div className="flex items-center justify-center w-full">
            <Pagination
              defaultPageSize={10}
              onChange={onChangeHandler}
              current={curPage}
              total={tranLog?.eapi_UsedTrackGetByID.Pagination?.totalResults!}
              locale={i18n.language === "en" ? en_US : tr_TR}
            />
          </div>
        </article>
      </section>
    </section>
  );
};
