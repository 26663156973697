interface IFormErrorProps{
    errorMessage:string | null | undefined;
}
export const FormErrors:React.FC<IFormErrorProps>=({errorMessage})=> {
    return (
        <ul className="ml-5 text-xs list-disc list-outside">
            <li className="font-medium text-red-500">{errorMessage}</li>
        </ul>
    )
}

