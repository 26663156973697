import {FormErrors} from "../../../components/general/form-errors";
import {ButtonCommon} from "../../../components/general/btn-common";
import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import {
  createAccountMutation,
  createAccountMutationVariables,
} from "../../../__generated__/createAccountMutation";
import { Helmet } from "react-helmet";
import {
  CheckCircleIcon,
  ClipboardCheckIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

interface ICreateAccountForm {
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
  nickName: string | null;
}

interface ILocationProps {
  state: {
    policyTerm: boolean | null;
    policyPrivacy: boolean | null;
    policyPromotion: boolean | null;
  };
}

const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccountMutation($input: CreateAccountInput!) {
    eapi_CreateAccount(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      userInfoEntity {
        email
        nickName
      }
    }
  }
`;

export const JoinForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location: ILocationProps = useLocation();
  const reSK = process.env.REACT_APP_RECAPTCHA;
  const [isCaptcha, setIsCaptcha] = useState(false);
  function onReCaptchaChange(value: any) {
    //console.log("Captcha value:", value);
    setIsCaptcha(true);
  }
  function onReCaptchaError() {
    setIsCaptcha(false);
  }
  function onReCaptchaExpire() {
    setIsCaptcha(false);
  }

  //console.log(location);
  /*
  if (!location.state.policyTerm || !location.state.policyPrivacy) {
    history.push("/join/before-policy");
  }
  */
  if (location.state === undefined) {
    history.goBack();
  }
  const [isEmailError, setIsEmailError] = useState(false);
  const [isNickError, setIsNickError] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Email is required")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/,
        `${t("sign_in.error.err_email_01")}`
      )
      .email(`${t("sign_in.error.err_email_02")}`),
    password: Yup.string()
      .trim()
      .min(8, `${t("sign_in.error.err_password_01")}`)
      .max(16, `${t("sign_in.error.err_password_01_1")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+=~_-])(?=.*[0-9])(?!(?=.*[ışğüşöçİĞÜŞÖÇ])).{8,16}$/,
        `${t("sign_in.error.err_password_02")}`
      )
      .required(`${t("sign_in.error.err_password_03")}`),
    confirmPassword: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref("password"), null],
        `${t("sign_in.error.err_password_04")}`
      )
      .required(`${t("sign_in.error.err_nickname_01")}`),
    nickName: Yup.string()
      .trim()
      .min(3, `${t("sign_in.error.err_nickname_01")}`)
      .max(32, `${t("sign_in.error.err_nickname_02")}`)
      .matches(
        /^([a-zA-Z0-9!@#$%^&*()+=~_-]).{3,32}$/,
        `${t("sign_in.error.err_nickname_03")}`
      )
      .required(`${t("sign_in.error.err_nickname_04")}`)
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ICreateAccountForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });

  const onCompleted = (data: createAccountMutation) => {
    //console.log("oncompleted: ", data);
    const code = data.eapi_CreateAccount.ResultCode?.Code;
    const userInfo = data.eapi_CreateAccount.userInfoEntity;
    if (code === "91030" || code === "91031" || code === "91032") {
      setIsEmailError(true);
      setIsNickError(false);
    } else if (code === "91010" || code === "91011" || code === "91012") {
      setIsEmailError(false);
      setIsNickError(true);
    } else if (code === "10000") {
      setIsEmailError(false);
      setIsNickError(false);
      //계정생성 알림
      history.push("/join/join-confirm", userInfo);
    } else {
      //기타 에러
      setIsEmailError(false);
      setIsNickError(false);
      //history.push("/join/join-confirm", userInfo);
    }
  };

  const [
    createAccountMutation,
    { loading, data: createAccountMutationResult }
  ] = useMutation<createAccountMutation, createAccountMutationVariables>(
    CREATE_ACCOUNT_MUTATION,
    {
      onCompleted
    }
  );

  const onSubmit: SubmitHandler<ICreateAccountForm> = (data) => {
    if (!loading) {
      console.log("onsubmit : ", data);
      createAccountMutation({
        variables: {
          input: {
            email: data.email?.trim().toLowerCase(),
            nickName: data.nickName?.trim(),
            password: data.password?.trim(),
            newsletter: location.state.policyPromotion
          }
        }
      });
    }
  };

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return (
    <div className="flex items-center justify-center w-full pt-12 md:pt-20">
      <Helmet>
        <title>Create Account :: Genius Orc</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-11/12 md:w-3/5">
        <h1 className="text-4xl text-center font-oswald md:text-5xl">
          {t("join_in.gate.title")}
        </h1>
        <hr className="w-full my-10 border border-gray-500" />
        <ul className="flex items-center justify-center mb-10">
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <ClipboardCheckIcon className="w-6 h-6 text-coolGray-100" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-300" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-600">
            <PencilIcon className="w-6 h-6 text-white" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-600" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <CheckCircleIcon className="w-6 h-6 text-coolGray-100" />
          </li>
        </ul>
        <h1 className="text-3xl text-purple-800 font-oswald">
          {t("join_form.title")}
        </h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid w-full gap-3 mt-5 mb-16 md:w-3/5"
          onKeyDown={onKeyDown}
        >
          <label className="block">
            <span className="text-sm text-coolGray-900 font-oswald">
              * {t("common.email")}
            </span>
            <input
              type="email"
              placeholder={t("common.email")}
              className={`block w-full outline-none inputStyle ${
                errors.email
                  ? `border-red-600`
                  : isEmailError
                  ? `border-red-600`
                  : `border-coolGray-300`
              }`}
              {...register("email")}
            />
          </label>
          {errors.email && <FormErrors errorMessage={errors.email?.message} />}
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91030" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91031" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91032" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          <ul className="ml-5 text-xs list-disc list-outside">
            <li>{t("join_form.email_ex")}</li>
          </ul>
          <ul className="ml-5 text-xs list-disc list-outside">
            <li>Only lowercase letters are allowed.</li>
          </ul>
          <label className="block">
            <span className="text-sm text-coolGray-900 font-oswald">
              * {t("common.nickname")}
            </span>
            <input
              maxLength={32}
              type="text"
              placeholder={t("common.nickname")}
              className={`block w-full outline-none inputStyle 
              ${
                errors.nickName
                  ? `border-red-600`
                  : isNickError
                  ? `border-red-600`
                  : `border-coolGray-300`
              }`}
              {...register("nickName")}
            />
          </label>
          {errors.nickName && (
            <FormErrors errorMessage={errors.nickName?.message} />
          )}
          <label className="block">
            <span className="text-sm text-coolGray-900 font-oswald">
              * {t("common.password")}
            </span>
            <input
              maxLength={16}
              aria-label="Enter your password"
              aria-required="true"
              type="password"
              placeholder={t("common.password")}
              className={`block w-full outline-none inputStyle ${
                errors.password ? `border-red-600` : `border-coolGray-300`
              }`}
              {...register("password")}
            />
          </label>
          {errors.password && (
            <FormErrors errorMessage={errors.password?.message} />
          )}

          <label className="block">
            <span className="text-sm text-coolGray-900 font-oswald">
              * {t("common.confirmpw")}
            </span>
            <input
              maxLength={16}
              aria-label="Enter your password to confirm"
              aria-required="true"
              type="password"
              placeholder={t("common.confirmpw")}
              className={`block w-full outline-none inputStyle ${
                errors.confirmPassword
                  ? `border-red-600`
                  : `border-coolGray-300`
              }`}
              {...register("confirmPassword")}
            />
          </label>
          {errors.confirmPassword && (
            <FormErrors errorMessage={errors.confirmPassword?.message} />
          )}
          <ul className="ml-5 text-xs list-disc list-outside">
            <li>{t("join_form.password_ex0")}</li>
            <li>{t("join_form.password_ex1")}</li>
          </ul>
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91010" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91011" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          {createAccountMutationResult?.eapi_CreateAccount.ResultCode?.Code ===
            "91012" && (
            <FormErrors
              errorMessage={
                createAccountMutationResult?.eapi_CreateAccount.ResultCode
                  ?.Description
              }
            />
          )}
          <div className="flex justify-center mt-5 item-center">
            <ReCAPTCHA
              sitekey={reSK!}
              onChange={onReCaptchaChange}
              onExpired={onReCaptchaError}
              onErrored={onReCaptchaExpire}
            />
          </div>
          <div className="flex items-center justify-center w-full">
            <ButtonCommon
              clickEnable={isValid && isCaptcha}
              loading={loading}
              actionText={`${t("gnb.create_account")}`}
              size={`capitalize w-full text-lg ml-2 md:w-1/2 
                    hover:bg-purple-800 hover:text-coolGray-100`}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
