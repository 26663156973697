import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

export const NavSignBar = () => {
  const { t } = useTranslation();
  return (
    <ul className="flex space-x-4 font-opensans text-coolGray-50">
      <li>
        <NavLink
          activeClassName="text-rose-700"
          className="px-1 capitalize transition duration-500 ease-in-out hover:text-rose-700"
          to="/join"
        >
          {t("gnb.create_account")}
        </NavLink>
      </li>
      <li className="text-coolGray-500">{t("common.or")}</li>
      <li>
        <NavLink
          activeClassName="shadow-inner bg-coolGray-400 text-rose-700"
          className="px-8 py-2 uppercase transition duration-500 ease-in-out rounded-sm bg-coolGray-700 text-coolGray-100 hover:shadow-inner hover:bg-gray-300 hover:text-rose-700"
          to="/sign-in"
        >
          {t("gnb.sign_in")}
        </NavLink>
      </li>
    </ul>
  );
};
