import React from "react";

interface IBtnCommonProps {
  clickEnable: boolean;
  loading: boolean;
  actionText: string;
  size: string | null;
  onClick?: () => void;
}

export const ButtonCommon: React.FC<IBtnCommonProps> = ({
  clickEnable,
  loading,
  actionText,
  size,
  onClick
}) => (
  <button
    className={`${size} border border-coolGray-400
    duration-300 ease-in-out
    flex justify-center items-center
    focus:outline-none text-white px-6 transition-colors ${
      clickEnable
        ? "bg-coolGray-700 text-coolGray-200 cursor-pointer"
        : "bg-coolGray-200 text-coolGray-700 opacity-40 pointer-events-none"
    } ${loading && `pointer-events-none`}`}
    onClick={onClick}
    style={{ height: "62px" }}
  >
    {loading ? (
      <>
        <svg
          className="w-6 h-6 mr-2 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span>Loading...</span>
      </>
    ) : (
      actionText
    )}
  </button>
);


