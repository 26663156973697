import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormErrors } from "../../components/general/form-errors";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import {
  signInMutation,
  signInMutationVariables
} from "../../__generated__/signInMutation";
import { ButtonCommon } from "../../components/general/btn-common";
import { Helmet } from "react-helmet";
import { authTokenVar, isLoggedInVar, freshDeskId } from "../../apollo";
import { LOCALSTORAGE_TOKEN } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { PortalChildren } from "../../components/portal-children";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const SIGNIN_MUTATION = gql`
  mutation signInMutation($loginInput: LoginInput!) {
    eapi_UserSignIn(input: $loginInput) {
      TokenInfo {
        Status
        Email
        UserIP
        Token
      }
      ResultCode {
        Code
        Description
      }
    }
  }
`;

interface ILoginForm {
  email: string;
  password: string;
}

interface ILocationProps {
  search: string;
  state: {
    referrer: string | null;
  };
}

export const SignIn = () => {
  const reSK = process.env.REACT_APP_RECAPTCHA;
  const location: ILocationProps = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const clientURL = location.search.split("?")[1];
  const [email, setEmail] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookies, removeCookie] = useCookies(["rememberEmail"]);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  //토큰관련 수정
  const makeFdToken = (clientURL: string, email: string) => {
    console.log("ClientUrl", clientURL);
    let apiURL = "";
    let hostSp = window.location.hostname.split(".");
    if (hostSp[2] === undefined) {
      apiURL = `http://localhost:4000/`;
    } else {
      apiURL = `https://www.geniusorc.${hostSp[2]}/`;
    }
    const fdURL = `${apiURL}freshdesk/sso/jwt/login?${clientURL}&email=${email}`;
    console.log(fdURL);
    axios.get(fdURL).then((res) => {
      console.log("fdURL :", res.data);

      //ks.yoon modify
      //redirect_url을 임의로 변경해서 특정 사이트로 이동하게 하는것 금지
      if(res.data) {
        let domain = (new URL(res.data));
        if(domain) {
          let afterDomain = domain.hostname.toLowerCase();
          if(afterDomain == "genius-orc.myfreshworks.com") {
            window.location.href = res.data;
          }
        }
      }

      //
      // if (res.data.includes("genius-orc")) {
      //   window.location.href = `${res.data}`;
      // }
    });
  };

  if (isLoggedIn) {
    if (clientURL) {
      makeFdToken(clientURL, localStorage.getItem("go-email")!);
    } else {
      history.push("/");
    }
  }

  useEffect(() => {
    const checkCookie = () => {
      if (cookies.rememberEmail !== undefined) {
        setEmail(cookies.rememberEmail);
        setIsRemember(true);
      }
    };
    checkCookie();
  }, [cookies]);

  const onHandleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRemember(e.target.checked);
    if (e.target.checked) {
      setCookies("rememberEmail", email, { maxAge: 2000, path: "/" });
    } else {
      removeCookie("rememberEmail");
    }
  };

  const accountLinks = [
    {
      id: 0,
      name: `${t("gnb.find_password")}`,
      url: "/find/find-password"
    },
    { id: 1, name: `${t("gnb.create_account")}`, url: "/join" }
  ];

  const validateSignIn = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(`${t("sign_in.error.err_email_01")}`)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        `${t("sign_in.error.err_email_03")}`
      )
      .email(`${t("sign_in.error.err_email_02")}`),
    password: Yup.string()
      .trim()
      .min(8, `${t("sign_in.error.err_password_01")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+=~_-])(?=.*[0-9]).{8,16}$/,
        `${t("sign_in.error.err_password_02")}`
      )
      .required("sign_in.error.err_password_03")
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ILoginForm>({
    mode: "onChange",
    defaultValues: {
      email: cookies.rememberEmail
    },
    resolver: yupResolver(validateSignIn)
  });

  const onCompleted = (data: signInMutation) => {
    //console.log("data Call:", data);
    const token = data.eapi_UserSignIn.TokenInfo?.Token;
    const code = data.eapi_UserSignIn.ResultCode?.Code;
    if (code === "10000" && token) {
      //console.log(token);
      localStorage.setItem(LOCALSTORAGE_TOKEN, token);
      localStorage.setItem("go-email", data.eapi_UserSignIn.TokenInfo?.Email!);
      //setCookies(LOCALSTORAGE_TOKEN, token, { path: "/" });
      //redirect
      if (clientURL) {
        makeFdToken(clientURL, data.eapi_UserSignIn.TokenInfo?.Email!);
      }
      authTokenVar(token);
      isLoggedInVar(true);
    }
  };

  const [signInMutation, { data: signInMutationResult, loading }] = useMutation<
    signInMutation,
    signInMutationVariables
  >(SIGNIN_MUTATION, {
    onCompleted
  });

  const onSubmit: SubmitHandler<ILoginForm> = (data) => {
    //console.log(data);

    if (isRemember) {
      setEmail(data.email);
      setCookies("rememberEmail", data.email, { maxAge: 86400, path: "/" });
    } else {
      setEmail("");
      removeCookie("rememberEmail", { path: "/" });
    }
    if (!loading) {
      signInMutation({
        variables: {
          loginInput: { email: data.email, password: data.password }
        }
      });
    }
  };

  function onReCaptchaChange(value: any) {
    //console.log("Captcha value:", value);
    setIsCaptcha(true);
  }
  function onReCaptchaError() {
    setIsCaptcha(false);
  }
  function onReCaptchaExpire() {
    setIsCaptcha(false);
  }

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  return !isLoggedIn ? (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center w-full pt-24 md:pt-28">
        <Helmet>
          <title>Sign in :: Genius Orc</title>
        </Helmet>
        <div className="flex flex-col items-center justify-center w-11/12 sm:w-4/5">
          <h1 className="pb-5 text-3xl text-center capitalize font-oswald sm:text-4xl sm:pb-10">
            {t("gnb.sign_in")}
          </h1>
          <hr className="w-full my-2 border border-gray-500" />
          <div className="w-full pb-4 m-2 bg-white border border-gray-400 md:max-w-xl sm:m-0 sm:p-5 sm:my-10 sm:w-11/12">
            <h1 className="my-5 text-xl text-center font-oswald md:text-3xl sm:my-10">
              {t("sign_in.gate.title")}
            </h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="grid w-full gap-3 px-2 mt-5 mb-5"
              onKeyDown={onKeyDown}
            >
              <input
                type="email"
                placeholder={t("common.email")}
                className={`form-input w-full outline-none inputStyle ${
                  errors.email ? `border-red-600` : `border-coolGray-300`
                }`}
                {...register("email")}
              />
              {errors.email && (
                <FormErrors errorMessage={errors.email?.message} />
              )}
              <input
                type="password"
                placeholder={t("common.password")}
                className={`form-input w-full outline-none inputStyle ${
                  errors.password ? `border-red-600` : `border-coolGray-300`
                }`}
                {...register("password")}
              />
              {errors.password && (
                <FormErrors errorMessage={errors.password?.message} />
              )}
              <div className="flex justify-center item-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={reSK!}
                  onChange={onReCaptchaChange}
                  onExpired={onReCaptchaError}
                  onErrored={onReCaptchaExpire}
                />
              </div>
              <ButtonCommon
                clickEnable={isValid && isCaptcha}
                loading={loading}
                actionText={`${t("gnb.sign_in")}`}
                size={`text-lg hover:bg-purple-800 hover:text-coolGray-100 capitalize`}
              />
              {signInMutationResult?.eapi_UserSignIn.ResultCode?.Code && (
                <FormErrors
                  errorMessage={t(
                    `${signInMutationResult?.eapi_UserSignIn.ResultCode?.Description}`
                  )}
                />
              )}
              <label className="inline-flex items-center">
                <input
                  className="form-checkbox"
                  type="checkbox"
                  checked={isRemember}
                  onChange={onHandleCheckbox}
                />
                <span className="ml-2">{t("sign_in.gate.remember_btn")}</span>
              </label>
            </form>

            <div className={`w-full flex justify-center items-center`}>
              <ul className="grid w-11/12 grid-flow-row space-y-2 text-sm uppercase divide-y divide-gray-400 md:grid-flow-col md:divide-x md:space-x-1 md:divide-y-0 md:space-y-0">
                {accountLinks.map((item) => {
                  return (
                    <li
                      className="flex items-center justify-center pt-2 md:pt-0"
                      key={item.id}
                    >
                      <Link
                        to={item.url}
                        className="flex items-center justify-center h-6 text-coolGray-600 hover:text-purple-600 hover:underline"
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </PortalChildren>
  ) : (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center w-full pt-12 md:pt-28 h-72">
        <Helmet>
          <title>Redirect Support page :: Genius Orc</title>
        </Helmet>
      </div>
    </PortalChildren>
  );
};
