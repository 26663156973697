import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import borderImg from "../../../../assets/game/ts2/images/box/frame_01.png";
import bgImg from "../../../../assets/game/ts2/images/box/p1_box_bg.png";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";

interface IHoverProps {
  id: number;
  selected: boolean;
}
export const Ts2Guide = () => {
  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/*캐릭터 */}
        <section
          className="relative z-0 flex items-end justify-center w-full max-w-screen-xl"
          style={{ height: "45rem" }}
        >
          <div className="absolute bottom-0 left-0 z-20 flex items-end justify-start"></div>
          <div className="absolute z-30 w-7/12 top-1/2 left-14">
            <p className="pb-5 border-b border-coolGray-300 text-coolGray-100 text-7xl font-oswald text-shadow">
              The Story of Twelve Sky 2
            </p>
            <p className="pt-3 text-md text-coolGray-400 text-shadow">
              Many years ago the country was ruled by the Twelve Legions. Their
              reign was brutal and harsh. Many lived impoverished and as slaves.
              Only off to the far Northern region was there a warrior who openly
              opposed the cruelty inflicted upon the people, and that leader was
              Saga Swordsman Lord Chen. His clan was that of the Sky Clan; one
              of the most prominent clans in the Realm.
            </p>
          </div>
          <div className="absolute z-10 -right-10 -bottom-20">
            <img src={charBg} alt="" className="object-fill w-full h-full" />
          </div>
        </section>
        <section className="relative z-20 flex flex-col items-center justify-center w-full max-w-screen-xl mt-10">
          <div className="flex items-center justify-center w-full h-1 shadow-lg bg-gradient-to-r from-transparent via-coolGray-200 to-transparent">
            <div className="w-2/5 h-1 border-t border-b border-coolGray-900"></div>
          </div>
          <div className="flex flex-col items-center justify-center w-full bg-red-800 bg-opacity-20">
            <p className="mt-10 text-3xl text-coolGray-100 text-shadow">
              Extreme Warfare
            </p>
            <p className="text-xl tracking-wider text-coolGray-100 text-shadow ">
              Exhilaration of Victory!
            </p>
            <p className="mt-10 text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-b from-coolGray-100 via-coolGray-200 to-coolGray-600 font-oswald">
              Who will take the only throne?
            </p>
            <p className="mt-2 mb-10 text-center text-md text-coolGray-100 text-shadow">
              Twelve Sky 2 rewrites the legendary history of
              <br />
              the twelve clans in the Wuxia, the Eastern Fantasy.
            </p>
          </div>
          <div className="flex items-center justify-center w-full h-1 shadow-lg bg-gradient-to-r from-transparent via-coolGray-200 to-transparent">
            <div className="w-2/5 h-1 border-t border-b border-coolGray-900"></div>
          </div>
        </section>
        {/* Headline */}
        <section className="w-full max-w-screen-xl mt-10 mb-10">
          <h4 className="w-full pb-3 mb-6 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Story
          </h4>
          <div className="flex items-stretch justify-center w-full">
            <div className="w-3/12 space-y-6">
              <div className="h-2/5 card-zoom group">
                <div
                  className="card-zoom-image"
                  style={{
                    backgroundImage: `url()`
                  }}
                ></div>
                <img
                  src=""
                  alt=""
                  className="absolute bottom-0 object-contain w-full"
                />
              </div>
              <div className="h-2/5 card-zoom group">
                <div
                  className="card-zoom-image"
                  style={{
                    backgroundImage: `url()`
                  }}
                ></div>
                <img
                  src=""
                  alt=""
                  className="absolute bottom-0 object-contain w-full"
                />
              </div>
            </div>
            <div className="w-9/12 h-full ml-6">
              <div
                className="w-full bg-left-top shadow-2xl text-coolGray-100"
                style={{
                  border: `30px solid #333`,
                  borderImage: `url(${borderImg}) 45 42 round`,
                  backgroundImage: `url(${bgImg})`
                }}
              >
                Many years ago the country was ruled by the Twelve Legions.
                Their reign was brutal and harsh. Many lived impoverished and as
                slaves. Only off to the far Northern region was there a warrior
                who openly opposed the cruelty inflicted upon the people, and
                that leader was Saga Swordsman Lord Chen. His clan was that of
                the Sky Clan; one of the most prominent clans in the Realm.
                There had been many legends about the incredible abilities of
                the twelve High Masters, and about the sacred stones that were
                shared with the Twelve Legions. These stones, it was said, gave
                the Legions their powerful hold over the people.
                <br />
                <br />
                It was during a fierce uprising, when one of the Southern tribes
                began fighting back against the tyranny of the Legion Lord
                controlling their territory, that Lord Chen decided to put an
                end to the evil grip of the Twelve Legions. He devised a plan to
                take back control of the sacred shards and combine them once
                again to give the Sky Clan the power they would need to finally
                stop to the inhuman suffering of the people. <br />
                <br />
                Lord Yudi didn't have to wonder why MauLi had not returned home.
                It was impossible for her to return now. She had brought shame
                upon her family as well as herself by her actions, even now, his
                family name had lost honor because of it. He looked at the maps
                under his hands, but didn't really see them. So, he had been
                right about Qing. That had to mean MauLi had been leaking
                information to him all this time. The fact that she was now with
                the rogue clan, made it clear she had been an unwitting
                participant in all of it. It had been Qing all along who had
                been supplying Lord Reni the information that had lead to the
                Dragon Clan's constant losses.
                <br />
                <br />
                The battle that was feared would last decades, ended in mere
                days. The power of Chen's sword was too great, even for the
                joint effort of the other Legions, and peace finally came to a
                nation that had for so long only knew pain and anguish.
                <br />
                <br />
                In the years that followed, the nation flourished under the
                watchful eyes of the Sky Clan. Then one catastrophic morning, it
                all ended. The Mountain Changbaishan, had heaved a great plume
                of smoke and commenced to cover the entire region of the Sky
                Clan.
              </div>
            </div>
          </div>
        </section>
        {/*스토리 */}
        <section className="w-full max-w-screen-xl pb-20 mt-10">
          <div className="w-full ">
            <h4 className="w-full pb-2 mb-6 ml-1 text-3xl border-b border-coolGray-600 text-coolGray-100 filter drop-shadow-lg text-shadow">
              Getting Started
            </h4>
            <div
              className="w-full p-5 font-thin bg-left-top shadow-2xl text-coolGray-100"
              style={{
                border: `30px solid #333`,
                borderImage: `url(${borderImg}) 45 42 round`,
                backgroundImage: `url(${bgImg})`
              }}
            >
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                1) Creating an Account
              </span>
              Before you can play, you will need a Genius Orc account which you
              can set up in our portal. After creating your account, you will
              receive a verification e-mail in your registered e-mail address.
              You can also charge your TSC premium currencies after login.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                2) Download
              </span>
              You can find the Twelve Sky 2 client in the Download page. As soon
              as the download is complete, you can initiate the installation of
              the game by executing the setup file. The program starts
              automatically and leads you through the individual installation
              steps.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                3) Patch
              </span>
              With every start up,the patcher will check whether your copy of
              the game is the latest version or not. It will automatically load
              and install the patch files if it is outdated. An active internet
              connection is required for this process.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                4) Server Selection
              </span>
              Once you get into the game, enter your username and password on
              the login screen. On the server selection screen, you can see how
              busy the servers are and also how many characters you currently
              have on the server. If you want to explore the world of Twelve Sky
              2 with friends, ask them which server they play on and join the
              same one. You can freely select between the channels within a
              server. As soon as you have decided on a channel, click on Start.
              The game will automatically assign you a channel if you do not
              select one.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                5) Character Creation
              </span>
              After the server selection you will come to the character
              selection screen. If you do not currently have a character on the
              server, click on Create. Once you have created a character on a
              server, it is no longer possible to switch to a different server
              with that character. Then you can select your gender, hairstyle,
              hair colour, race and class.
              <br />
              <br />
              If further problems arise during the installation or registration
              process and you are unable to find a solution, please contact our
              Support Team
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
