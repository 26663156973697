import {
    ApolloClient,
    createHttpLink,
    defaultDataIdFromObject,
    InMemoryCache, makeVar
} from "@apollo/client";
import { LOCALSTORAGE_TOKEN } from "./constants";
import { setContext } from "@apollo/client/link/context";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from 'crypto-hash';
import { useGetMyIp } from "./hooks/use-web-ip";

let apiURL = "";
let hostSp = window.location.hostname.split('.');

if (hostSp[2] === undefined) {
    apiURL = `http://localhost:4000/${process.env.REACT_APP_ENDPOINT}`
} else {
    apiURL = `https://www.geniusorc.${hostSp[2]}/${process.env.REACT_APP_ENDPOINT}`;
}

//console.log(document.cookie.split(`; `).find(row => row.startsWith('go-jwt'))?.split('=')[1])
//const token = document.cookie.split(`; `).find(row => row.startsWith('go-jwt'))?.split('=')[1];
const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const fdId = localStorage.getItem("client_id");
let conCode = localStorage.getItem("country_code");

export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
export const freshDeskId = makeVar(fdId);
export const externalConCode = makeVar(conCode);

const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
            "go-jwt": authTokenVar() || "",
        },
    }
})

const httpLink = createHttpLink({
    uri: apiURL, fetch,
    credentials: 'same-origin'
})

const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(httpLink);

export const client = new ApolloClient({
    link: authLink.concat(persistedQueriesLink),
    cache: new InMemoryCache({
        dataIdFromObject(res){
            switch (res.__typename){
                case 'BalanceEntity':
                    return `BalanceEntity:${res.email}`
                case 'UserInfoEntity':
                    return `UserInfoEntity:${res.email}`
                default:
                    return defaultDataIdFromObject(res);
            }
          },
        typePolicies: {
            Query: {
                fields: {
                    isLoggedIn() {
                        return isLoggedInVar();
                    },
                    token() {
                        return authTokenVar();
                    },
                    freshDeskId() {
                        return freshDeskId();
                    },
                    externalConCode() {
                        return externalConCode();
                    }
                }
            }
        }
    }),
});
