import {useMyProfile} from "../../../../hooks/use-my-profile";
import {ArrowCircleRightIcon, MailIcon} from "@heroicons/react/solid";
import React, {useState} from "react";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {resendSecondCodeQuery} from "../../../../__generated__/resendSecondCodeQuery";
import {useBoolean, useInterval} from 'react-use';
import { useTranslation } from "react-i18next";

const RESEND_SECOND_VERIFY_QUERY = gql`
  query resendSecondCodeQuery {
    eapi_ResendSecondVerifyEmail {
      ResultCode {
        Code
        Description
      }
      RegDate
    }
  }
`;

export const ResendCode = () => {
  const { t, i18n } = useTranslation();
  const [isClick, setIsClick] = useState(true);
  const [seconds, setSeconds] = useState(Date.now());
  const [isRunning, toggleIsRunning] = useBoolean(false);
  const { data: userProfile } = useMyProfile();
  const userData = userProfile?.eapi_MyProfile;
  let startDate;
  let nowTime;
  useInterval(
    () => {
      setSeconds(Date.now());
    },
    isRunning ? 1000 : null
  );

  const onCompleted = (data: resendSecondCodeQuery) => {
    //console.log("data call : ", data)
  };
  const [resendCode, { called, loading, data }] =
    useLazyQuery<resendSecondCodeQuery>(RESEND_SECOND_VERIFY_QUERY, {
      onCompleted
    });

  return (
    <section className="w-full lg:w-9/12">
      <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
        {t("account_detail.resend_code")}
      </h1>
      <hr className="border border-gray-500" />
      {/* */}
      <article className="flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center justify-center my-8">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
            <MailIcon className="w-14 h-14 text-coolGray-500" />
          </div>
          <div className="absolute top-11 -right-2">
            <ArrowCircleRightIcon className="w-10 h-10 text-warmGray-400" />
          </div>
        </div>
        <p className="mb-5 text-3xl text-purple-900 font-oswald">
          {t("resend_email.subtitle")}
        </p>
      </article>
      <form
        className={
          "w-full border border-gray-300 flex justify-center items-center flex-col py-10"
        }
      >
        <p className={"text-sm text-center"} style={{ whiteSpace: "pre-line" }}>
          {t("resend_email.ex_02")}
        </p>
        <div className="w-3/5 py-4 my-8 text-center text-purple-900 bg-gray-200">
          <p className="font-medium">{userData?.recoverEmail}</p>
        </div>
        <p className="mb-4 text-sm text-gray-600">
          ({t("join_complete.ex_01")})
        </p>
        <div className="flex items-center justify-center w-8/12">
          <button
            onClick={() => {
              if (!called) {
                resendCode();
              }
            }}
            className={`w-1/2 h-16 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100 border border-coolGray-400 py-6 my-5 transition duration-300 ease-in-out
    flex justify-center items-center
    focus:outline-none text-white py-4 transition-colors ${
      !called
        ? "bg-coolGray-700 text-coolGray-200 cursor-pointer"
        : "bg-coolGray-200 text-coolGray-700 opacity-40 pointer-events-none"
    }`}
          >
            {loading ? (
              <>
                <svg
                  className="w-6 h-6 mr-2 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span>{t("common.loading")}...</span>
              </>
            ) : (
              `${t("join_complete.btn_01")}`
            )}
          </button>
        </div>
      </form>
      {/* */}
    </section>
  );
};
