import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  CalculatorIcon,
  LogoutIcon,
} from "@heroicons/react/solid";
import { ClockIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { useMyProfile } from "../../../hooks/use-my-profile";
import { useMyBalance } from "../../../hooks/use-my-balance";
import Moment from "react-moment";
import "moment-timezone";
import { useTranslation } from "react-i18next";

function userCircleIcon() {
  return (
    <UserCircleIcon className="text-gray-400 myProfileIconStyle group-hover:text-rose-700" />
  );
}

function calculatorIcon() {
  return (
    <CalculatorIcon className="text-gray-400 myProfileIconStyle group-hover:text-rose-700" />
  );
}

function logOutIcon() {
  return (
    <LogoutIcon className="text-gray-400 myProfileIconStyle group-hover:text-rose-700" />
  );
}

export const NavSignProfile = () => {
  const { t, i18n } = useTranslation();
  const { data, loading } = useMyProfile();
  const { data: userBalance, loading: userBalanceLoading } = useMyBalance(1);
  //console.log(data?.eapi_MyProfile.userType);
  const myProfile = [
    {
      name: `${t("gnb.my_account")}`,
      href: "/my-profile/info",
      icon: userCircleIcon
    },
    {
      name: `${t("gnb.transaction_history")}`,
      href: "/my-profile/payment",
      icon: calculatorIcon
    },
    {
      name: `${t("gnb.sign_out")}`,
      href: "/sign-out",
      icon: logOutIcon
    }
  ];
  return (
    <>
      {loading && <span>{t("common.loading")}...</span>}
      <div className="mr-3 font-opensans" style={{ zIndex: 100 }}>
        <Popover className="list-inline-text">
          {({ open }) => (
            <>
              <Popover.Button
                className={`${open ? "" : ""}
              group flex items-center focus:outline-none text-coolGray-300`}
              >
                <span className="flex items-center justify-center w-12 h-12 bg-gray-400 border rounded-full"></span>
                <span className="ml-2">{data?.eapi_MyProfile.nickName}</span>
                <ChevronDownIcon
                  className={`${open ? "transform rotate-180" : ""}
                h-5 w-5 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute w-screen max-w-xs px-4 mt-1 transform -translate-x-44 sm:px-0"
                >
                  <div className="overflow-hidden text-gray-500 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 font-opensans">
                    <div className="relative grid gap-8 bg-white divide-y divide-gray-200 p-7">
                      {/* 사진 프로파일 */}
                      <div className="flex flex-col items-center justify-center">
                        <div className="mb-2 bg-gray-600 border rounded-full w-28 h-28">
                          <img src="" alt="" />
                        </div>
                        <span
                          className={`inline-block rounded-full px-3 py-1 text-xs font-semibold 
                      ${
                        data?.eapi_MyProfile.userType === "NonAuthUser" &&
                        "bg-red-100 text-red-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "AuthUser" &&
                        "bg-purple-100 text-purple-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "VIPUser" &&
                        "bg-purple-100 text-purple-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "GameProvider" &&
                        "bg-indigo-100 text-indigo-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "Manager" &&
                        "bg-amber-100 text-amber-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "Admin" &&
                        "bg-yellow-100 text-yellow-700"
                      }
                      ${
                        data?.eapi_MyProfile.userType === "TestAccount" &&
                        "bg-cyan-100 text-cyan-700"
                      }
                      `}
                        >
                          {data?.eapi_MyProfile.userType === "NonAuthUser" ||
                          data?.eapi_MyProfile.userType === "AuthUser" ||
                          data?.eapi_MyProfile.userType === "VIPUser"
                            ? data?.eapi_MyProfile.userType === "NonAuthUser"
                              ? "Need Verification"
                              : data?.eapi_MyProfile.userType === "AuthUser"
                              ? "Verified User"
                              : data?.eapi_MyProfile.userType === "VIPUser" && (
                                  <span className="font-bold text-purple-500 font-skranji">
                                    VIP
                                  </span>
                                )
                            : data?.eapi_MyProfile.userType}
                        </span>
                        <span className="flex items-center justify-center inline-block my-2 mb-2 font-semibold text-gray-600 text-md">
                          {data?.eapi_MyProfile.nickName}
                        </span>
                        <div className="flex items-center justify-center mb-0 text-sm text-gray-600">
                          <span>{data?.eapi_MyProfile.email}</span>
                        </div>
                        <div className="flex items-center justify-center mt-1 mb-0 text-xs text-gray-400">
                          <ClockIcon className="w-3 h-3" aria-hidden="true" />
                          <span className="ml-1">
                            <Moment local locale={i18n.language} format="lll">
                              {data?.eapi_MyProfile.lastCheckInDate}
                            </Moment>
                          </span>
                        </div>
                      </div>
                      {myProfile.map((item, index) => (
                        <NavLink
                          exact
                          key={item.name}
                          to={
                            data?.eapi_MyProfile.isVerifyEmail
                              ? item.href
                              : index === 2
                              ? item.href
                              : ""
                          }
                          activeClassName="myProfileActiveMenus"
                          className="p-2 -m-4 group myProfileMenus"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 myProfileMenusItemIcon sm:h-12 sm:w-12">
                            <item.icon aria-hidden="true" />
                          </div>
                          <div className="ml-2">
                            <p className="capitalize myProfileMenusItemTxt">
                              {item.name}
                            </p>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </>
  );
};
