import { useParams } from "react-router-dom";
import React from "react";
import { NotFound } from "../error/404";
import { PortalChildren } from "../../components/portal-children";
import { AboutUs } from "./pages/about-us";
import { ContactUs } from "./pages/contact-us";
import { Eula } from "./pages/eula";
import { Privacy } from "./pages/privacy";
import { UGC } from "./pages/ugc";
import { Terms } from "./pages/termsof";
interface IParamTypes {
  id: string;
}

export const PolicyHomeRouter = () => {
  let { id } = useParams<IParamTypes>();

  const returnParamValue = (param: string) => {
    if (param === "about-us") {
      return (
        <>
          <AboutUs />
        </>
      );
    } else if (param === "contact-us") {
      return (
        <>
          <ContactUs />
        </>
      );
    } else if (param === "eula") {
      return (
        <>
          <Eula />
        </>
      );
    } else if (param === "privacy") {
      return (
        <>
          <Privacy />
        </>
      );
    } else if (param === "terms") {
      return (
        <>
          <Terms />
        </>
      );
    } else if (param === "ugc") {
      return (
        <>
          <UGC />
        </>
      );
    } else {
      return <NotFound />;
    }
  };

  return (
    <PortalChildren classes="portal">{returnParamValue(id)}</PortalChildren>
  );
};
