import { useParams } from "react-router-dom";
import React from "react";
import { NotFound } from "../error/404";
import { ShopHome } from "./shop/shop-home";
import { ShopPaymentMethod } from "./shop/shop-payment-method";
import { ShopPurchasedOk } from "./shop/shop-purchased-ok";
import { ShopPurchasedFail } from "./shop/shop-purchased-fail";
import { PortalChildren } from "../../components/portal-children";
interface IParamTypes {
  id: string;
}
/* 
else if (param === "paymentMethod") {
      return (
        <>
          <ShopPaymentMethod />
        </>
      );
    }
*/
export const ShopPage = () => {
  let { id } = useParams<IParamTypes>();
  const returnParamValue = (param: string) => {
    if (param === undefined) {
      return (
        <>
          <ShopHome />
        </>
      );
    } else if (param === "paymentSuccess") {
      return (
        <>
          <ShopPurchasedOk />
        </>
      );
    } else if (param === "paymentFail") {
      return (
        <>
          <ShopPurchasedFail />
        </>
      );
    } else {
      return <NotFound />;
    }
  };

  return (
    <>
      <PortalChildren classes="portal">{returnParamValue(id)}</PortalChildren>
    </>
  );
};
