const Header = () => {
  return (
    <div>
      <h2 className="mb-6 text-3xl font-bold">User Generated Content Policy</h2>
    </div>
  );
};
const Content = () => {
  return (
    <div className="mb-10">
      <p className="mb-4">
        Various features of the interactive Services provided through Genius Orc
        Entertainment Inc. (Genius Orc) may allow Users to create, upload and
        distribute User Generated Content ("UGC"). Genius Orc does not review or
        screen UGC that may be posted or made available through the Services or
        any related platform by Users, and therefore does not assume any
        responsibility or liability for content that is generated by Users in
        connection with any of our interactive Services or related platforms.
      </p>
      <p className="mb-4">
        Users must have the legal right to create, upload and distribute UGC in
        connection with our interactive Services. Users may not upload or post
        any UGC that infringes the copyright, trademark or other intellectual
        property rights of a third party. Users may not upload UGC that violates
        the law, the Genius Orc EULA or Terms of Service, and/or any third
        party's right of privacy or right of publicity.
      </p>
      <p className="mb-4">
        Genius Orc does not assume any liability for any failure to remove, or
        any delay in removing, content. Genius Orc may, however, without prior
        notice to Users and in its sole judgment, remove UGC that may infringe
        the intellectual property or other rights of a third party. If Users are
        repeat infringers of Genius Orc’s or any third party's intellectual
        property or other rights, Genius Orc may suspend or terminate User
        access to our interactive Services and related platforms without notice.
        If User access to Genius Orc’s interactive Services is suspended or
        terminated, Users are not entitled to a refund for any virtual currency
        or virtual items they have obtained. Genius Orc’s determinations
        regarding suspensions or terminations are made in the Company’s sole
        discretion and are final and binding for every User.
      </p>
      <p className="mb-4">
        Users acknowledge and agree that they have no expectation of privacy
        concerning the transmission of gameplay or any User Generated Content,
        including without limitation chat text, voice communications or any
        social media content. Genius Orc RESERVES THE RIGHT, AT ALL TIMES AND IN
        OUR SOLE DISCRETION, TO DISCLOSE ANY USER GENERATED CONTENT AND OTHER
        INFORMATION (INCLUDING WITHOUT LIMITATION CHAT TEXT, VOICE
        COMMUNICATIONS, GAMEPLAY FOOTAGE, AND SOCIAL MEDIA CONTENT) FOR ANY
        REASON, including without limitation (a) to satisfy any applicable law,
        regulation, legal process or governmental request; (b) to enforce the
        terms of Genius Orc’s Terms of Service or other applicable agreements
        and policies; (c) to protect Genius Orc’s legal rights and remedies; (d)
        to protect the health or safety of anyone believed to be threatened be
        any individual or entity; or (e) to report a crime or other offensive
        behavior.
      </p>
      <p className="mb-4">
        Users agree that they will be personally responsible for the creation of
        any associated User generated content, and for all communications and
        activities done in connection with Genius Orc’s interactive Services,
        including any miscellaneous contributed content. Users will indemnify
        and hold harmless Genius Orc, its parent, affiliates, and their
        respective employees, officers, and directors from any liability or
        damages arising from their conduct in the connection with Genius Orc’s
        interactive Services and related platforms.
      </p>
      <p className="mb-4">
        In exchange for use of Genius Orc’s interactive Services and related
        platforms, and to the extent that User contributions through use of
        these interactive Services give rise to any copyright interest, Users
        hereby grant Genius Orc an exclusive, perpetual, irrevocable, fully
        transferable and sub-licensable worldwide right and license to use their
        contributions in any way and for any purpose in connection with the
        Software and related goods and Services, including the rights to
        reproduce, copy, adapt, modify, perform, display, publish, broadcast,
        transmit, or otherwise communicate to the public by any means whether
        now known or unknown and distribute their contributions without any
        further notice or compensation to them of any kind for the whole
        duration of protection granted to intellectual property rights by
        applicable laws and international conventions. To the extent permitted
        by applicable law, Users hereby waive any moral rights of paternity,
        publication, reputation, or attribution with respect to Genius Orc’s and
        other players’ use and enjoyment of such assets in connection with the
        Game and related goods and Services under applicable law. The license
        granted to Genius Orc, and the above waiver of any applicable moral
        rights, survives any termination or revocation of this Agreement.
      </p>
    </div>
  );
};
export const PolicyUGC = () => {
  return (
    <div className="text-md text-coolGray-700 font-notoserif">
      <Header />
      <Content />
    </div>
  );
};
