import {Helmet} from "react-helmet";
import React from "react";
import { PortalChildren } from "../../components/portal-children";

export const SupportPage = () => {
  return (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center bg-gray-100 h-96">
        <Helmet>
          <title>Method Purchase GOC :: Genius Orc</title>
        </Helmet>
        <h1>Support Page</h1>
      </div>
    </PortalChildren>
  );
};
