import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ButtonCommon } from "../../components/general/btn-common";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import {
  unsubscribePortal,
  unsubscribePortalVariables,
} from "../../__generated__/unsubscribePortal";
import { SubmitHandler } from "react-hook-form";
import { FormErrors } from "../../components/general/form-errors";
import { PortalChildren } from "../../components/portal-children";
import { useTranslation } from "react-i18next";

interface IUnsubscribeForm {
  isAgreement: boolean | null;
}
const UNSUBSCRIBE_MUTAION = gql`
  mutation unsubscribePortal($input: UpdateUnsubscribeInput!) {
    eapi_UnSubscribe(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;
export const UnscribeEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let [isDataLoading, setIsDataLoading] = useState(false);

  const validateUnsubscribe = Yup.object().shape({
    isAgreement: Yup.boolean()
      .oneOf([true], "This checkbox need to select for unscribe newsletter.")
      .required("The checkbox is required.")
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IUnsubscribeForm>({
    mode: "onChange",
    defaultValues: {
      isAgreement: true
    },
    resolver: yupResolver(validateUnsubscribe)
  });

  const onCompleted = (data: unsubscribePortal) => {
    //console.log("data call", data);
    const {
      eapi_UnSubscribe: { ResultCode }
    } = data;
    if (ResultCode?.Code === "10000") {
      //
    } else {
      //history.push("/")
    }
    setIsDataLoading(true);
  };

  const [unsubscribePortal, { data: unsubscribePortalResult, loading }] =
    useMutation<unsubscribePortal, unsubscribePortalVariables>(
      UNSUBSCRIBE_MUTAION,
      {
        onCompleted
      }
    );
  //eslint-disable-next-line
  const [_, vid] = window.location.href.split(`vid=`);
  const getParamID: string = vid;
  const onSubmit: SubmitHandler<IUnsubscribeForm> = (data) => {
    if (!loading) {
      unsubscribePortal({
        variables: {
          input: { UNID: getParamID }
        }
      });
    } else {
      history.push("/");
    }
  };
  return (
    <PortalChildren classes="portal">
      <div className="flex flex-col items-center justify-start pt-20 bg-gray-100">
        <Helmet>
          <title>Unsubscribe newsletter :: Genius Orc</title>
        </Helmet>
        <article className="flex items-center justify-center w-full h-56 text-white bg-coolGray-800">
          <div className="-mt-16 text-center">
            <p className="mt-12 mb-6 text-5xl capitalize font-oswald">
              {t("unsub_news.title")}
            </p>
          </div>
        </article>
        <div className="relative flex flex-col items-center justify-center w-4/5 -mt-10 bg-white shadow-xl">
          <div className="flex items-center justify-center h-32 mr-10">
            <p className="text-4xl capitalize font-opensans">
              {t("unsub_news.subtitle")}
            </p>
          </div>
          <hr className="w-4/5 border-1" />
          {isDataLoading ? (
            <div className="flex flex-col items-center justify-center w-full p-5 mt-5">
              <p className="mb-5 text-sm text-center md:text-lg">
                {unsubscribePortalResult?.eapi_UnSubscribe.ResultCode?.Code ===
                "10000"
                  ? "Successfully updated mailing subscriptions."
                  : unsubscribePortalResult?.eapi_UnSubscribe.ResultCode
                      ?.Description}
              </p>

              <button
                className={`text-md hover:bg-blue-800 
              hover:text-gray-100 
              border border-gray-400 duration-300 ease-in-out
              flex justify-center items-center
              focus:outline-none px-6 py-4 transition-colors 
              bg-gray-700 text-gray-200 cursor-pointer mb-10`}
                onClick={() => {
                  history.push("/");
                }}
              >
                {t("common.go_back")}
              </button>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center justify-center w-full p-5 mt-5 md:w-4/5"
            >
              <p className="mb-5 text-sm text-center md:text-lg">
                {t("unsub_news.exp_02")}
              </p>

              <fieldset className="">
                <div className="space-y-4">
                  <div className="flex justify-start">
                    <div className="flex items-center h-5">
                      <input
                        id="isAgree"
                        type="checkbox"
                        className="w-4 h-4 text-indigo-900 border-gray-300 focus:ring-indigo-900 md:w-6 md:h-6"
                        {...register("isAgreement", { required: true })}
                      />
                    </div>
                    <div className="mb-5 ml-3 text-xs md:text-sm">
                      <label htmlFor="isAgree" className="text-gray-800">
                        {t("unsub_news.exp_01")}
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              {errors.isAgreement && (
                <FormErrors errorMessage={errors.isAgreement?.message} />
              )}
              <ButtonCommon
                clickEnable={isValid}
                loading={loading}
                actionText={`${t("common.unsub")}`}
                size={`my-5 text-md hover:bg-purple-800 hover:text-coolGray-100 capitalize`}
              />

              <div className="py-20"></div>
              <div></div>
            </form>
          )}
        </div>
      </div>
    </PortalChildren>
  );
};
