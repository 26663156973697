import { gql, useQuery, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import {
  shopItemsQuery,
  shopItemsQueryVariables
} from "../../../__generated__/shopItemsQuery";
import { useMyBalance } from "../../../hooks/use-my-balance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormErrors } from "../../../components/general/form-errors";
import { useForm } from "react-hook-form";
import { useMyProfile } from "../../../hooks/use-my-profile";
import { Link, useHistory } from "react-router-dom";
import { CurrencyDollarIcon, CurrencyEuroIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  pwWidgetMutation,
  pwWidgetMutationVariables
} from "../../../__generated__/pwWidgetMutation";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
interface ICheckBoxForm {
  isPurchaseAgree: boolean | null;
}

//아이템 리스트 가져오기
const SHOPITEMS_QUERY = gql`
  query shopItemsQuery($input: AllItemInfoInput!) {
    eapi_ItemAllGet(input: $input) {
      itemEntities {
        itemID
        itemName
        currencyType
        price
        amount
        bonus
        visibility
      }
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;
//바로 구매
const PW_WIDGET_MUTATION = gql`
  mutation pwWidgetMutation($input: CreateWidgetInput!) {
    pingback_widget(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      ReturnWidget
    }
  }
`;

export const ShopHomeNormal = () => {
  const { t } = useTranslation();
  const productInfo = [
    {
      name: "info0",
      desc: `${t("coin_shop.home.ex_01")}`
    }
    // ,
    // {
    //   name: "info1",
    //   desc: `${t("coin_shop.home.ex_02")}`
    // },
    // {
    //   name: "info2",
    //   desc: `${t("coin_shop.home.ex_03")}`
    // }
  ];

  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  const history = useHistory();
  if (!userProfileLoading && !userProfile?.eapi_MyProfile.isVerifyEmail) {
    history.push("/");
  }
  const {
    loading: userBalanceLoading,
    data: userBalance,
    refetch: balanceRefetch
  } = useMyBalance(1);

  const { data: shopItemResult, loading: shopItemLoading } = useQuery<
    shopItemsQuery,
    shopItemsQueryVariables
  >(SHOPITEMS_QUERY, {
    variables: {
      input: {
        page: 1
      }
    }
  });

  const validatePurchase = Yup.object().shape({
    isPurchaseAgree: Yup.string()
      .oneOf(["true"], `${t("coin_shop.home.err_chk_01")}`)
      .required(`${t("coin_shop.home.err_chk_01")}`)
  });

  const {
    register,
    formState: { errors, isValid }
  } = useForm<ICheckBoxForm>({
    mode: "onChange",
    defaultValues: {
      isPurchaseAgree: true
    },
    resolver: yupResolver(validatePurchase)
  });

  let [isOpen, setIsOpen] = useState(false);

  const onPWwidgetCompleted = (data: pwWidgetMutation) => {
    console.log(data);
    if (data.pingback_widget.ResultCode?.Code === "10000") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };
  //pw 위젯가져오기
  const [
    pwWidgetMutation,
    { data: pwWidgetDataResult, loading: pwWidgetLoading }
  ] = useMutation<pwWidgetMutation, pwWidgetMutationVariables>(
    PW_WIDGET_MUTATION,
    {
      onCompleted: onPWwidgetCompleted
    }
  );

  //페이먼트월 위젯 가져오기
  const onPWhandler = (
    itemID: string,
    serviceID: number | null | undefined,
    currencyType: string | null | undefined,
    psOpt: string | null | undefined
  ) => {
    pwWidgetMutation({
      variables: {
        input: {
          itemID,
          serviceID,
          currencyType,
          psOpt
        }
      }
    });
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [isCurrency, setIsCurrency] = useState("USD");

  useEffect(() => {
    balanceRefetch();

    if (userProfile?.eapi_MyProfile.countryName === "Austria")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Andorra")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Belgium")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Finland")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "France")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Germany")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Ireland")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Italy")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Luxembourg")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Monaco")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Netherlands")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Portugal")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "San Marino")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Spain")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Vatican City")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Slovenia")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Cyprus")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Malta")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Slovakia")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Estonia")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Latvia")
      setIsCurrency("EUR");
    if (userProfile?.eapi_MyProfile.countryName === "Lithuania")
      setIsCurrency("EUR");
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-start pt-20 bg-gray-100">
        <Helmet>
          <title>Purchase GOC :: Genius Orc</title>
        </Helmet>
        <article className="flex items-center justify-center w-full h-56 text-white bg-coolGray-800">
          <div className="-mt-10 text-center">
            <p className="mb-4 text-5xl capitalize font-oswald">
              {t("common.purchase")} G-Coin
            </p>
            <p className="text-sm text-gray-300 font-opensans">
              {t("coin_shop.home.subtitle")}
            </p>
          </div>
        </article>
        {userProfileLoading ? (
          <p>{t("common.loading")} ...</p>
        ) : (
          <div className="relative w-4/5 -mt-10 bg-white shadow-2xl">
            <div className="flex items-center justify-center h-32 mr-2 w-full md:justify-end">
              {isCurrency === "EUR" ? (
                <CurrencyEuroIcon className="w-8 h-8 mr-1 text-coolGray-500" />
              ) : (
                <CurrencyDollarIcon className="w-8 h-8 mr-1 text-coolGray-500" />
              )}
              <p className="capitalize hidden lg:block">G-Coin {t("common.balance")}:</p>
              {userBalanceLoading ? (
                <p className="mx-4 text-sm">{t("common.loading")} ...</p>
              ) : (
                <>
                  <p className="mr-2 text-4xl font-black md:mx-4">
                    {userBalance?.eapi_BalanceGet.balanceEntity ? (
                      <NumberFormat
                        value={
                          userBalance?.eapi_BalanceGet.balanceEntity?.amount
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </p>
                  <Link to="/my-profile/payment">
                    <button className="mr-1 px-4 py-2 text-sm font-bold
                    text-gray-200 capitalize transition duration-300
                    ease-in-out bg-gray-800 border border-gray-400
                    hover:text-gray-800 hover:bg-gray-50 md:text-md md:py-4 md:px-8 md:mr-4">
                     {t("common.usage_his")}
                    </button>
                  </Link>
                </>
              )}
            </div>
            <hr className="w-full border-1" />
            <div className="flex flex-col items-center justify-center p-5 mt-2">
              <p className="text-center">{t("coin_shop.home.desc_01")}</p>
              <p className="text-center">{t("coin_shop.home.desc_02")}</p>
              {shopItemLoading ? (
                <div className="py-10">{t("common.loading")} ...</div>
              ) : (
                <div className="py-1">
                  <ul className="flex flex-wrap items-center justify-center w-full max-w-4xl">
                    {shopItemResult?.eapi_ItemAllGet.itemEntities?.map((item) =>
                      item.currencyType === isCurrency ? (
                        <li
                          key={item.itemID}
                          className={`${
                            item.price > 49 && item.price < 51
                              ? "transition duration-300 ease-in-out bg-gradient-to-tl from-lime-600 to-lime-300 hover:from-lime-300 hover:to-lime-600"
                              : item.price > 99 && item.price < 101
                              ? "transition duration-300 ease-in-out bg-gradient-to-tl from-purple-600 to-purple-300 hover:from-purple-300 hover:to-purple-600"
                              : "transition duration-300 ease-in-out bg-gray-200 hover:bg-gray-300"
                          } w-56 h-48 flex justify-center items-center flex-col m-4 relative border border-gray-400`}
                        >
                          {item.bonus !== 0 && (
                            <>
                              <div
                                className={`${
                                  item.price > 49 && item.price < 51
                                    ? "bg-pink-600"
                                    : item.price > 99 && item.price < 101
                                    ? "bg-cyan-600"
                                    : null
                                } rounded-2xl px-5 text-sm text-gray-50 absolute -top-2 uppercase z-10`}
                              >
                                {item.price > 49 && item.price < 51 && (
                                  <span>{t("common.recommended")}</span>
                                )}
                                {item.price > 99 && item.price < 101 && (
                                  <span>{t("common.special_promo")}</span>
                                )}
                              </div>
                              <div className="absolute top-0 left-0">
                                <img
                                  src={`${process.env.REACT_APP_IMG_URL}shop/bonus.svg`}
                                  alt=""
                                />
                              </div>
                            </>
                          )}
                          <div className="flex items-center justify-center">
                            <div
                              className="w-16 h-16 m-2 bg-no-repeat bg-cover border border-gray-300 rounded-full bg-gray-50"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_IMG_URL}shop/coin${item.itemID}.png)`
                              }}
                            ></div>
                            <div className="font-oswald">
                              <p className="text-2xl font-bold filter drop-shadow-sm">
                                <NumberFormat
                                  value={item.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={" "}
                                />{" "}
                                <span className="text-sm">G-Coin</span>
                              </p>
                              {item.bonus !== 0 && (
                                <p className="text-xs capitalize filter drop-shadow-sm">
                                  + {t("common.bonus")}{" "}
                                  <NumberFormat
                                    value={item.bonus}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  ={" "}
                                  <span className="font-bold text-md">
                                    <NumberFormat
                                      value={
                                        Number(item.amount) + Number(item.bonus)
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                          <p className="mb-2"></p>
                          {/*
                        to={{
                              pathname: "/shop/paymentMethod",
                              state: {
                                itemID: item.itemID,
                                itemName: item.itemName,
                                price: item.price,
                                amount: item.amount,
                                bonus: item.bonus,
                                serviceID: 1,
                                currencyType: isCurrency,
                                email: userProfile?.eapi_MyProfile.email
                              }
                            }}
                         */}
                          {isValid ? (
                            <button
                              onClick={() =>
                                onPWhandler(item.itemID, 1, isCurrency, "all")
                              }
                              className={`flex items-center justify-center w-44 py-2 font-bold text-gray-200 capitalize transition duration-300 ease-in-out bg-purple-900 border border-gray-400 outline-none hover:bg-coolGray-200 hover:text-purple-900
                            ${pwWidgetLoading && "pointer-events-none"}`}
                            >
                              {pwWidgetLoading && (
                                <svg
                                  className="w-6 h-6 mr-2 text-coolGray-200 animate-spin"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              )}
                              {t("common.buy")}{" "}
                              {item.currencyType === "EUR" ? "€ " : "$ "}
                              <NumberFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </button>
                          ) : (
                            <button
                              className={`px-10 py-2 font-bold 
                      text-gray-800 transition duration-300 ease-in-out 
                      bg-gray-300 border border-gray-600 outline-none pointer-events-none`}
                            >
                              {t("common.buy")}{" "}
                              {item.currencyType === "EUR" ? "€ " : "$ "}
                              <NumberFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </button>
                          )}
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              )}
              <div className="w-full min-h-full bg-white border mb-28">
                <div className="pt-3 pb-1 pl-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="w-6 h-6 text-indigo-800 capitalize bg-gray-200 border-gray-300 form-checkbox focus:bg-indigo-200 focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
                      {...register("isPurchaseAgree")}
                    />
                    <span className="ml-2 capitalize font-oswald text-md">
                      {t("common.pur_agree")}
                    </span>
                  </label>
                </div>
                {errors.isPurchaseAgree && (
                  <FormErrors errorMessage={errors.isPurchaseAgree?.message} />
                )}
                <hr />
                <div className="p-5">
                  <ul className="ml-5 list-disc">
                    {productInfo.map((info) => (
                      <li
                        className="text-sm font-semibold font-opensans"
                        key={info.name}
                      >
                        {info.desc}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Transition appear show={isOpen}>
        <Dialog
          open={isOpen}
          onClose={closeModal}
          className="fixed inset-0 z-50 bg-black bg-opacity-60"
        >
          <div className="flex items-center justify-center mt-28">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative w-10/12 max-w-5xl bg-black shadow-2xl max-h-5xl ring-4 ring-gray-800 ring-offset-2 ring-offset-gray-400">
                {pwWidgetLoading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="w-full">
                    <iframe
                      id="pw"
                      title="Payment Wall"
                      style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                        minHeight: "620px"
                      }}
                      src={`${pwWidgetDataResult?.pingback_widget.ReturnWidget}`}
                      loading="lazy"
                    ></iframe>
                  </div>
                )}

                <div className="absolute -top-3 -right-3">
                  <button
                    type="button"
                    className={`px-1 py-1 font-bold text-sm focus:outline-none group bg-coolGray-200 bg-opacity-80 rounded-md ring-2 ring-coolGray-500 transition duration-300 ease-in-out hover:bg-coolGray-700 hover:text-coolGray-200`}
                    onClick={closeModal}
                  >
                    <XIcon className="w-4 h-4 text-coolGray-700 group-hover:text-coolGray-50" />
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
