import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import borderImg from "../../../../assets/game/ts2/images/box/frame_01.png";
import bgImg from "../../../../assets/game/ts2/images/box/p1_box_bg.png";
import smokeImg from "../../../../assets/game/ts2/images/bg/smoke.png";
import newsBanner from "../../../../assets/game/ts2/images/about/page-img.jpg";
import biLogo from "../../../../assets/portal/images/bi_logo.png";
interface IHoverProps {
  id: number;
  selected: boolean;
}
export const Ts2About = () => {
  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/* wrapper */}
        <div
          style={{
            backgroundImage: `url(${smokeImg})`,
            height: "480px",
            top: "440px"
          }}
          className="absolute z-10 w-full smoke_wrapper"
        ></div>

        <div className="absolute z-0 -top-14" style={{ marginLeft: "580px" }}>
          <div className="absolute w-full h-full bottom-56 right-44">
            <div className="sparks">
              <div className="spark_1"></div>
              <div className="spark_2"></div>
              <div className="spark_3"></div>
              <div className="spark_4 spark-big"></div>
              <div className="spark_5 spark-big"></div>
            </div>
          </div>
          <img src={charBg} alt="" className="" />
        </div>
        {/*캐릭터 */}
        <section
          className="relative z-0 flex items-end justify-start w-full max-w-screen-xl"
          style={{ height: "34rem" }}
        >
          <div className="absolute z-30 w-6/12 top-1/2">
            <div className="flex items-center justify-start mb-4 border-b">
              <p className="text-6xl border-coolGray-200 text-coolGray-100 font-oswald text-shadow">
                About
              </p>
              <img src={biLogo} alt="ts2 logo" className="mb-6 ml-4 h-28" />
            </div>

            <p className="pt-3 text-md text-coolGray-300 text-shadow">
              The Story of Twelve Sky 2 is the story after the Sky Clan’s era
              that Lord Chen brought with his sword taking down the evil
              alliance of the rulers. The land flourished until the mountain
              Changbaishan erupted.
            </p>
          </div>
        </section>

        {/* Headline */}
        <section className="relative z-10 w-full max-w-screen-xl mt-10 mb-10">
          <h4 className="w-full pb-4 mb-10 ml-1 text-3xl border-b border-coolGray-400 text-coolGray-100 filter drop-shadow-lg text-shadow">
            Story
          </h4>
          <div className="flex items-stretch justify-center w-full">
            <div
              className="w-full leading-8 bg-left-top shadow-2xl text-coolGray-100"
              style={{
                border: `30px solid #333`,
                borderImage: `url(${borderImg}) 45 42 round`,
                backgroundImage: `url(${bgImg})`
              }}
            >
              <img
                src={newsBanner}
                alt=""
                className="mb-4 ring-2 ring-warmGray-700"
              />
              The Twelve Legions, the rulers once dominated the country
              ruthlessly many years ago faced a warrior who was also called as a
              hero by many living as impoverished and slaves. He is Saga
              Swordsman Lord Chen from the north region. He set up the Sky Clan,
              the most noticeable clan in the realm at that time. He was with
              other legends who possessed the incredible abilities, and their
              power was known to be from the sacred stones that were shared with
              the Twelve Legions.
              <br />
              <br />
              After more furious uprisings against the Twelve Legions, one of
              the south region’s tribes successfully made their attempts in
              their territory. That was the time Lord Chen saw the timing to end
              the era of evil rulers. He needed the power from the combined
              sacred shards to do so.
              <br />
              <br />
              The fierce battles shaken the whole country and lasted decades.
              But the great power of Lord Chen’s sword finally broke down the
              evil alliance of the rulers. The peace finally came to the country
              that had been covered by pain and anguish for so long.
              <br />
              <br />
              Years after that, the country flourished under the new rules of
              the Sky Clan. But then one day, it all suddenly stopped and became
              void like a daydream. Everyone had to look at the mountain
              Changbaishan where emits an inundant dark smoke commencing to
              cover the entire region of the Sky Clan.
            </div>
          </div>
        </section>
        {/*스토리 */}
        <section className="w-full max-w-screen-xl pb-20 mt-10">
          <div className="w-full ">
            <h4 className="w-full pb-5 mb-10 ml-1 text-3xl border-b border-coolGray-600 text-coolGray-100 filter drop-shadow-lg text-shadow">
              Getting Started
            </h4>
            <div
              className="w-full p-5 font-thin bg-left-top shadow-2xl text-coolGray-100"
              style={{
                border: `30px solid #333`,
                borderImage: `url(${borderImg}) 45 42 round`,
                backgroundImage: `url(${bgImg})`
              }}
            >
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                1) Creating an Account
              </span>
              Before you can play, you will need a Genius Orc account which you
              can set up in our portal. After creating your account, you will
              receive a verification e-mail in your registered e-mail address.
              You can also charge your TSC premium currencies after login.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                2) Download
              </span>
              You can find the Twelve Sky 2 client in the Download page. As soon
              as the download is complete, you can initiate the installation of
              the game by executing the setup file. The program starts
              automatically and leads you through the individual installation
              steps.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                3) Patch
              </span>
              With every start up,the patcher will check whether your copy of
              the game is the latest version or not. It will automatically load
              and install the patch files if it is outdated. An active internet
              connection is required for this process.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                4) Server Selection
              </span>
              Once you get into the game, enter your username and password on
              the login screen. On the server selection screen, you can see how
              busy the servers are and also how many characters you currently
              have on the server. If you want to explore the world of Twelve Sky
              2 with friends, ask them which server they play on and join the
              same one. You can freely select between the channels within a
              server. As soon as you have decided on a channel, click on Start.
              The game will automatically assign you a channel if you do not
              select one.
              <br />
              <br />
              <span className="block pb-2 mb-2 text-xl font-bold text-yellow-100 border-b border-coolGray-500">
                5) Character Creation
              </span>
              After the server selection you will come to the character
              selection screen. If you do not currently have a character on the
              server, click on Create. Once you have created a character on a
              server, it is no longer possible to switch to a different server
              with that character. Then you can select your gender, hairstyle,
              hair colour, race and class.
              <br />
              <br />
              If further problems arise during the installation or registration
              process and you are unable to find a solution, please contact our
              Support Team
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
