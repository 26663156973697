import React from "react";
import {Link} from "react-router-dom";

interface ICommonLargeBtnProps {
  msg: string | null;
  url: any | null;
  size: string | null;
}

export const CommonLargeBtn: React.FC<ICommonLargeBtnProps> = ({
  msg,
  url,
  size
}) => {
  return (
    <Link
      to={url}
      className={`${size} border 
        border-coolGray-400 px-6 py-4
        transition duration-300 ease-in-out text-center hover:bg-purple-700 hover:text-coolGray-200`}
    >
      {msg}
    </Link>
  );
};
