import React, {useState} from "react";
import { RadioGroup } from "@headlessui/react";
import {MyProfileFormBtn} from "../../../components/general/my-profile-form-btn";
import { useMySignInLog } from "../../../hooks/use-my-signin-log";
import Moment from "react-moment";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useTranslation } from "react-i18next";
import { en_US, tr_TR } from "../../../locales/files/page_locales";
import moment from "moment";

export const MyProfileHistory = () => {
  const { t, i18n } = useTranslation();
  const tabBtns = [
    {
      name: `1 ${t("common.week")}`
    },
    {
      name: `1 ${t("common.month")}`
    },
    {
      name: `6 ${t("common.months")}`
    }
  ];
  const [selected, setSelected] = useState(tabBtns[0]);
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const onChangeHandler = (page: number) => {
    //console.log(page);
    setCurPage(page);
    setPageSize(10);
  };
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(1, "week").format()
  );
  const [endDate, setEndDate] = useState(moment.utc().format());
  const [sendStartDate, setSendStartDate] = useState(
    moment.utc().subtract(1, "week").format()
  );
  const [sendEndDate, setSendEndDate] = useState(moment.utc().format());

  const handleStartDate = (e: React.FormEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setStartDate(moment(e.currentTarget.value).format());
  };
  const handleEndDate = (e: React.FormEvent<HTMLInputElement>) => {
    console.log(e.currentTarget.value);
    setEndDate(moment(e.currentTarget.value).format());
  };

  const onDateChangeHandler = () => {
    if (startDate < endDate) {
      setSendStartDate(startDate);
      setSendEndDate(endDate);
    }
    //setCurPage(1);
    //setPageSize(10);
  };

  const { data: signInLog, loading: signInLogLoading } = useMySignInLog(
    curPage,
    pageSize
  );

  return (
    <section className="w-full lg:w-9/12">
      <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
        {t("login_history.title")}
      </h1>
      <hr className="border border-gray-500" />
      {/* */}
      <section className="w-full">
        <article>
          <div>
            <h2 className="mt-6 mb-2 text-lg capitalize">
              {t("account_info.side.log_his")}
            </h2>
            <p className="mb-3 text-sm">- {t("login_history.log_ex03")}</p>
          </div>
          <div className="flex items-center justify-center flex-col w-full md:justify-between md:flex-row">
            <RadioGroup
              value={selected}
              onChange={setSelected}
              className="flex items-center
              justify-start my-5 border
              divide-x cursor-pointer w-max border-coolGray-400 divide-coolGray-400"
            >
              <RadioGroup.Label className="sr-only">
               week
              </RadioGroup.Label>
              {tabBtns.map((item) => (
                <RadioGroup.Option value={item} key={item.name}>
                  {({ active, checked }) => (
                    <div
                      className={`${
                        checked
                          ? "from-coolGray-400 to-coolGray-200 text-coolGray-800"
                          : "from-coolGray-100 to-coolGray-300 text-coolGray-500"
                      }
                           flex justify-center items-center
                           bg-gradient-to-b text-sm text-coolGray-800 px-2 h-12
                           hover:from-coolGray-400 hover:to-coolGray-200 hover:text-coolGray-900`}
                    >
                      {item.name}
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div className="flex items-center justify-start flex-col sm:justify-center sm:flex-row">
              <input
                id="startDateId"
                type="date"
                className="h-12 form-input w-52 sm:w-44"
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={handleStartDate}
              />
              <span className={`mx-1`}>-</span>
              <input
                id="endDateId"
                type="date"
                className="h-12 form-input w-52 sm:w-44"
                value={moment(endDate).format("YYYY-MM-DD")}
                onChange={handleEndDate}
              />
              <span className={`mx-1`} />
              <button
                onClick={onDateChangeHandler}
                className={`${
                  startDate > endDate
                    ? `pointer-events-none bg-white text-coolGray-300`
                    : `bg-gradient-to-b from-coolGray-100 to-coolGray-300 text-coolGray-800 `
                } w-52 h-12 mt-2 sm:mt-0 sm:w-20 capitalize flex justify-center items-center border border-coolGray-400 text-sm
                            hover:from-coolGray-400
                           hover:to-coolGray-200 hover:text-coolGray-600 focus:outline-none focus:rounded-none`}
              >
                <div className={`text-center`}>{`${t("common.search")}`}</div>
              </button>
            </div>
          </div>
          <table className="w-full my-5">
            {signInLogLoading ? (
              t("common.loading") + "..."
            ) : (
              <>
                <thead>
                  <tr className="text-sm text-center capitalize bg-coolGray-200">
                    <td className="py-4">{t("common.connection")}</td>
                    <td>{t("common.access_type")}</td>
                    <td>{t("common.region")}</td>
                    <td className="">
                      {t("common.login")} {t("common.date")} {t("common.and")}{" "}
                      {t("common.time")}
                    </td>
                  </tr>
                </thead>
                {signInLog?.eapi_SignInLogGetByID.Pagination?.totalResults ===
                0 ? (
                  <tbody>
                    <tr className="text-sm text-center border-b">
                      <td colSpan={4}>There is no data</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {!signInLogLoading &&
                      signInLog?.eapi_SignInLogGetByID.signInLogEntities?.map(
                        (item) => (
                          <tr
                            className="text-sm text-center border-b"
                            key={item.regDate}
                          >
                            <td className="py-4">{item.ISP}</td>
                            <td className="py-4">
                              {item.accessType === 1
                                ? "Web"
                                : item.accessType === 2
                                ? "Launcher"
                                : item.accessType === 3
                                ? "Steam"
                                : item.accessType === 4
                                ? "In-game"
                                : item.accessType === 5
                                ? "Support"
                                : "Unknown"}
                            </td>
                            <td className="py-4">
                              {item.accessType === 5 ? (
                                "Support page"
                              ) : (
                                <>
                                  {item.city === "unknown"
                                    ? ""
                                    : item.city + ", "}
                                  {item.countryName}
                                </>
                              )}
                            </td>
                            <td className="py-4">
                              <Moment local locale={i18n.language} format="lll">
                                {item.regDate}
                              </Moment>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                )}
              </>
            )}
          </table>
        </article>
        <article>
          <div className="flex items-center justify-center w-full">
            <Pagination
              defaultPageSize={10}
              onChange={onChangeHandler}
              current={curPage}
              total={signInLog?.eapi_SignInLogGetByID.Pagination?.totalResults!}
              locale={i18n.language === "en" ? en_US : tr_TR}
            />
          </div>
        </article>
      </section>
    </section>
  );
};
