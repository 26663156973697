import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  MailOpenIcon,
  PlusCircleIcon,
  XCircleIcon,
  CheckCircleIcon
} from "@heroicons/react/solid";
import { FormErrors } from "../../components/general/form-errors";
import { ButtonCommon } from "../../components/general/btn-common";
import React, { useEffect, useState } from "react";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PortalChildren } from "../../components/portal-children";
import {
  chkValidteToken,
  chkValidteTokenVariables
} from "__generated__/chkValidteToken";
import { passwordChg, passwordChgVariables } from "__generated__/passwordChg";
import { isLoggedInVar } from "../../apollo";

const CHK_VALIDATE_TOKEN = gql`
  mutation chkValidteToken($input: FindPassValidInput!) {
    eapi_FindPassVaild(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      Email
    }
  }
`;

const PASSWORD_CHG = gql`
  mutation passwordChg($input: FindPassChgInput!) {
    eapi_FindPassChgInput(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

interface IChgPasswordProps {
  chgPassword: string;
  confirmPassword: string;
}

export const ConfirmRecoverPassword = () => {
  const { t } = useTranslation();
  const [tokenEmail, setTokenEmail] = useState("");
  const history = useHistory();
  const location = useLocation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  useEffect(() => {
    const chkToken = location.search.split("code=")[1];
    if (isLoggedIn) {
      history.push("/sign-in");
    } else {
      if (chkToken) {
        chkValidteToken({
          variables: {
            input: {
              token: chkToken
            }
          }
        });
      } else {
        history.push("/sign-in");
      }
    }
  }, [location]);

  const validChgPassword = Yup.object().shape({
    chgPassword: Yup.string()
      .trim()
      .min(8, `${t("sign_in.error.err_password_01")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+=~_-])(?=.*[0-9])(?!(?=.*[ışğüşöçİĞÜŞÖÇ])).{8,16}$/,
        `${t("sign_in.error.err_password_02")}`
      )
      .required(`${t("sign_in.error.err_password_03")}`),
    confirmPassword: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref("chgPassword"), null],
        `${t("sign_in.error.err_password_04")}`
      )
      .required(`${t("sign_in.error.err_password_05")}`)
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IChgPasswordProps>({
    resolver: yupResolver(validChgPassword),
    mode: "onChange"
  });

  const onFindPassComp = (data: chkValidteToken) => {
    const {
      eapi_FindPassVaild: { ResultCode }
    } = data;
    console.log(data);
    if (ResultCode?.Code === "10000") {
      setTokenEmail(data.eapi_FindPassVaild.Email!);
    }
  };
  const [chkValidteToken, { loading: chkValLoading, data: chkValidteResult }] =
    useMutation<chkValidteToken, chkValidteTokenVariables>(CHK_VALIDATE_TOKEN, {
      onCompleted: onFindPassComp
    });

  const onPassChgComp = (data: passwordChg) => {
    console.log(data);
    const {
      eapi_FindPassChgInput: { ResultCode }
    } = data;
    if (ResultCode?.Code === "10000") {
    } else {
    }
  };
  const [passwordChg, { loading: passChgLoading, data: passChgResult }] =
    useMutation<passwordChg, passwordChgVariables>(PASSWORD_CHG, {
      onCompleted: onPassChgComp
    });

  const onSubmit: SubmitHandler<IChgPasswordProps> = (data) => {
    console.log(data, tokenEmail);
    if (!passChgLoading) {
      passwordChg({
        variables: {
          input: {
            email: tokenEmail,
            chgPassword: data.confirmPassword
          }
        }
      });
    }
  };
  const onGoBack = () => {
    history.push("/sign-in");
  };
  return (
    <PortalChildren classes="portal">
      <section className="flex flex-col items-center justify-center w-full py-10 bg-gray-100">
        {chkValLoading ? (
          <p>Loading...</p>
        ) : chkValidteResult?.eapi_FindPassVaild.ResultCode?.Code ===
          "10000" ? (
          passChgResult?.eapi_FindPassChgInput.ResultCode?.Code === "10000" ? (
            <>
              <article className="flex flex-col items-center justify-center w-full pt-24">
                <div className="relative flex items-center justify-center my-8">
                  <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
                    <MailOpenIcon className="w-14 h-14 text-coolGray-500" />
                  </div>
                  <div className="absolute top-11 -right-2">
                    <CheckCircleIcon className="w-10 h-10 text-warmGray-400" />
                  </div>
                </div>
                <p className="mb-5 text-3xl text-purple-900 font-oswald">
                  Password Reset Success!
                </p>
              </article>
              <div
                className={
                  "w-full border border-gray-300 flex justify-center items-center flex-col py-10 max-w-4xl"
                }
              >
                <div className="mb-6 text-center">
                  Go to the sign-in page by clicking button below and try
                  signing in again.
                  <br />
                  <br />
                  Thank you!
                  <br />
                  Genius Orc Support Team
                </div>
                <ButtonCommon
                  clickEnable={true}
                  loading={passChgLoading}
                  actionText={"Go to sign in"}
                  size={`w-1/3 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100`}
                  onClick={onGoBack}
                />
              </div>
            </>
          ) : (
            <>
              <h1 className="pt-24 pb-10 text-4xl text-center font-oswald">
                Reset Password
              </h1>
              <hr className="w-4/5 border border-gray-500" />
              {/* */}
              <article className="flex flex-col items-center justify-center w-full">
                <div className="relative flex items-center justify-center my-8">
                  <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
                    <MailOpenIcon className="w-14 h-14 text-coolGray-500" />
                  </div>
                  <div className="absolute top-11 -right-2">
                    <PlusCircleIcon className="w-10 h-10 text-warmGray-400" />
                  </div>
                </div>
                <p className="mb-5 text-3xl text-purple-900 font-oswald">
                  {t("chg_password.subtitle")}
                </p>
              </article>
              <div
                className={
                  "w-full border border-gray-300 flex justify-center items-center flex-col py-10 max-w-4xl"
                }
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* */}
                  <label className="block mt-4">
                    <span className="text-sm text-gray-700 font-oswald">
                      {t("chg_password.new_pass")}
                    </span>
                    <input
                      aria-label="Enter your password"
                      aria-required="true"
                      type="password"
                      placeholder={t("chg_password.new_pass")}
                      className={`block w-full outline-none inputStyle ${
                        errors.chgPassword
                          ? `border-red-600`
                          : `border-coolGray-300`
                      }`}
                      {...register("chgPassword")}
                    />
                  </label>
                  {errors.chgPassword && (
                    <FormErrors errorMessage={errors.chgPassword?.message} />
                  )}
                  <label className="block mt-6">
                    <span className="text-sm text-gray-700 font-oswald">
                      {t("chg_password.con_pass")}
                    </span>
                    <input
                      aria-label="Enter your password to confirm"
                      aria-required="true"
                      type="password"
                      placeholder={t("chg_password.con_pass")}
                      className={`block w-full outline-none inputStyle ${
                        errors.confirmPassword
                          ? `border-red-600`
                          : `border-coolGray-300`
                      }`}
                      {...register("confirmPassword")}
                    />
                  </label>
                  {errors.confirmPassword && (
                    <FormErrors
                      errorMessage={errors.confirmPassword?.message}
                    />
                  )}
                  <ul className="mt-2 mb-6 ml-5 text-xs list-disc list-outside">
                    <li className="my-1">{t("join_form.password_ex0")}</li>
                    <li className="my-1">{t("join_form.password_ex1")}</li>
                  </ul>
                  {/* */}
                  <div className="flex items-center justify-center w-full">
                    <ButtonCommon
                      clickEnable={isValid}
                      loading={passChgLoading}
                      actionText={t("chg_password.title")}
                      size={`w-1/2 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100`}
                    />
                  </div>
                </form>
              </div>
            </>
          )
        ) : (
          <>
            <h1 className="pt-24 pb-10 text-4xl text-center font-oswald">
              Error Found
            </h1>
            <hr className="w-4/5 border border-gray-500" />
            {/* */}
            <article className="flex flex-col items-center justify-center w-full">
              <div className="relative flex items-center justify-center my-8">
                <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
                  <MailOpenIcon className="w-14 h-14 text-coolGray-500" />
                </div>
                <div className="absolute top-11 -right-2">
                  <XCircleIcon className="w-10 h-10 text-warmGray-400" />
                </div>
              </div>
              <p className="mb-5 text-3xl text-purple-900 font-oswald">
                {chkValidteResult?.eapi_FindPassVaild.ResultCode?.Description}
              </p>
            </article>
            <div
              className={
                "w-full border border-gray-300 flex justify-center items-center flex-col py-10 max-w-4xl mb-24"
              }
            >
              <div className="w-3/5 pt-5 pb-10 text-center">
                We can no longer proceed with the password reset using the
                current token/URL. Please type your email address again in the
                "Find Password" section to receive the new password reset link
                to your email.
              </div>
              <ButtonCommon
                clickEnable={true}
                loading={false}
                actionText={`Go Back`}
                size={`w-1/3 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100`}
                onClick={onGoBack}
              />
            </div>
          </>
        )}
      </section>
    </PortalChildren>
  );
};
