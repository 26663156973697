import {Helmet} from "react-helmet";
import React, { useState } from "react";
import { CommonSocialLinks } from "../../../components/general/common-social-links";
import { CommonLargeBtn } from "../../../components/general/common-large-btn";
import {
  faFacebookF,
  faGoogle,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

export const JoinSocial = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center w-full pt-20">
      <Helmet>
        <title>Sign up :: Genius Orc</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-11/12 md:w-3/5">
        <h1 className="text-4xl text-center font-oswald md:text-5xl">
          {t("join_in.gate.title")}
        </h1>
        <hr className="w-full my-6 border border-gray-500 md:my-10" />
        <h1 className="text-2xl text-purple-800 font-oswald md:text-3xl">
          {t("join_in.gate.sub_title")}
        </h1>
        <p className="my-4 text-sm md:my-8">{t("join_in.gate.desc")}</p>
        <CommonLargeBtn
          msg={`${t("join_in.gate.sign_up_btn")}`}
          url={`/join/before-policy`}
          size={`w-11/12 text-lg md:text-xl md:w-3/5 md:max-w-2xl  outline-none 
          focus:outline-none 
          bg-coolGray-700 text-coolGray-100
          hover:bg-coolGray-100 hover:text-coolGray-700 `}
        />
        {/*
          <p className="mt-10 mb-3 text-sm text-gray-500">
            Use an Open ID for easier logins.
          </p>
          <ul className="w-3/5 mb-10 space-y-4">
            <CommonSocialLinks
              msg="Log in with Facebook"
              url="/"
              iconName={faFacebookF}
              heightSize={"h-20"}
            />
            <CommonSocialLinks
              msg="Log in with Google"
              url="/"
              iconName={faGoogle}
              heightSize={"h-20"}
            />
          </ul>
           */}
      </div>
    </div>
  );
};
