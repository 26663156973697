import { gql, useQuery } from "@apollo/client";
import {
  mySignInLogQuery,
  mySignInLogQueryVariables,
} from "../__generated__/mySignInLogQuery";
const SIGNIN_LOG_QUERY = gql`
  query mySignInLogQuery($input: AllSignInLogInput!) {
    eapi_SignInLogGetByID(input: $input) {
      signInLogEntities {
        email
        isVerifyEmail
        userIP
        countryCode
        countryName
        city
        latitude
        longitude
        ISP
        accessType
        serviceID
        deviceID
        blockNID
        status
        note
        regDate
      }
      Pagination {
        totalPages
        totalResults
      }
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

export const useMySignInLog = (page: number, perPage: number) => {
  return useQuery<mySignInLogQuery, mySignInLogQueryVariables>(
    SIGNIN_LOG_QUERY,
    {
      variables: {
        input: { page, perPage },
      },
    }
  );
};
