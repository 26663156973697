import {gql, useQuery} from "@apollo/client";
import {
  myBalanceQuery,
  myBalanceQueryVariables,
} from "../__generated__/myBalanceQuery";

const BALANCE_QUERY = gql`
  query myBalanceQuery($balanceInfoInput: BalanceInfoInput!) {
    eapi_BalanceGet(input: $balanceInfoInput) {
      Code
      Location
      balanceEntity {
        email
        serviceID
        status
        amount
        updatedDate
        regDate
      }
    }
  }
`;

export const useMyBalance=(serviceID:number)=>{
    return useQuery<myBalanceQuery, myBalanceQueryVariables>(BALANCE_QUERY,{
        variables:{
            balanceInfoInput:{serviceID}
        }
    });
}
