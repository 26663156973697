import React from "react";
import {Redirect, useParams} from "react-router-dom";
import {AddEmail} from "./second-email/add-email";
import {ResendCode} from "./second-email/resend-code";
import {ChangePassword} from "./change-password/change-password";

export const ChgDetailProfile = () => {
    let {id}: any = useParams()
    const returnParamValue = (param: any) => {
        if (param === 'add-email') {
            return (
                <AddEmail/>
            )
        } else if (param === 'resend-code') {
            return (
                <ResendCode/>
            )
        }else if (param === 'change-password') {
            return (
                <ChangePassword/>
            )
        }  else {
            return (
                <Redirect to="/my-profile/detail"/>
            );
        }
    }
    return (
        <>
            {returnParamValue(id)}
        </>
    );
};
