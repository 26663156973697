const Header = () => {
  return (
    <div>
      <h2 className="mb-4 text-3xl font-bold">Terms Of Service</h2>
      <p className="mb-10">
        Genius Orc Entertainment Inc. ("GENIUS") would like to thank you for
        visiting our website. This document contains the terms of use for your
        visit and stay at www.GeniusOrc.com (or any subsequent URL which may
        replace it) and all officially associated websites and micro-sites
        operated by GENIUS (collectively, the "Website").
      </p>
    </div>
  );
};

const Content1 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">1. LEGAL AGREEMENT</h2>
      <p className="mb-10">
        By using this Website or accessing any of our games, you agree to accept
        and be bound by the terms and conditions appearing in this document, and
        agree to comply with any Rules of Conduct, the Privacy Policy
        (http://www.GeniusOrc.com) and any end-user license agreement applicable
        to our games, as they may be amended from time to time. In this
        Agreement, the terms "We/us/our" mean Genius Orc Entertainment Inc.
        "You/your" means you as a user of the Website or any of our games.
        <br />
        <br />
        You hereby represent that you have the legal capacity to enter into this
        Agreement and you are not barred from receiving services offered by the
        Website under the laws of Republic of Korea or other applicable
        jurisdictions. You understand that the services being offered may
        include certain communications from GENIUS, such as service
        announcement, administrative messages, or other information, and that
        these communications constitute the services being offered by the
        Website and you may not have the right to opt out of receiving them.
        <br />
        <br />
        GENIUS may amend this Agreement, any Rules of Conduct, the Privacy
        Policy, and any end-user license agreement at any time in its sole
        discretion. Amendments and modifications will be effective immediately
        after publishing them on the Website. You agree to and are responsible
        for checking this Agreement, the Rules of Conduct, the Privacy Policy,
        and any end-user license agreement periodically so you will be familiar
        with their contents as they may be amended or modified from time to
        time. By continuing to use the Website and its services, you are
        signifying your acceptance of any revised or updated terms.
        <br />
        <br />
        GENIUS is the owner of the Website. Your use of the Website is subject
        at all times to this Agreement, any Rules of Conduct, the Privacy Policy
        and any end-user license agreement applicable to our games. All user
        identities created on the Website are governed by these terms. This
        includes, but is not limited to, proper in-game and out-of-game conduct.
        This Agreement is in addition to, and does not in any way replace or
        supplant, any end-user license agreement that may accompanied the GENIUS
        software (the "EULA") and to which the GENIUS software is subject. You
        also may be subject to additional terms and conditions that may apply
        when you use or purchase certain other GENIUS services, affiliate
        services, third-party content or third-party software.
        <br />
        <br />
        All rights and titles in and to the Website and in any of our published
        games (including without limitation any user accounts, titles, computer
        code, themes, objects, characters, character names, stories, dialogue,
        catch phrases, locations, concepts, artwork, animation, sounds, musical,
        compositions, audio-visual effects, methods of operation, moral rights,
        any related documentation, "applets" incorporated into the game(s),
        transcripts of the chat rooms, character profile information, recordings
        of game play, and the game(s) client and server software) are owned by
        GENIUS or have been licensed to GENIUS.
      </p>
    </div>
  );
};
const Content2 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">2. DESCRIPTION OF SERVICE</h2>
      <p className="mb-10">
        Genius Orc Entertainment Inc. provides free-to-play, high quality games
        to our members. Additional contents are also available on our Website.
        To access our games, you may be required to download and install certain
        client software. This entails the use of hardware, software, and
        Internet access. By using the Website, you acknowledge that hardware,
        software, and Internet access play a crucial role in your user
        experience. You agree that GENIUS is not responsible for any hardware,
        software or Internet access or unavailability issues and you agree to
        hold us harmless for any such issues. GENIUS does not provide Internet
        access, and you are responsible for all fees relating to telephone and
        Internet access charges along with all necessary equipment, servicing,
        repair, or correction incurred in maintaining connectivity to the
        servers.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content3 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">3. ACCOUNT</h2>
      <p className="mb-10">
        You may be required to create an account (an "Account") to access our
        Website and our games. Accounts are available only to individuals 13
        years or older. If you are over 13 years of age but are still considered
        a minor, we ask that you review these terms with your parents or
        guardian to ensure they understand them and agree to them. By accepting
        the terms of use in the Agreement, you represent that you are 13 years
        of age or older. Only one person may use an Account. You are liable for
        all activities conducted through your Account.
        <br />
        <br />
        Notwithstanding the above, certain portions of our Website and some of
        our games may contain mature contents not suitable for anyone younger
        than 18 years old. We will require that you confirm that you are at
        least 18 years old to access such content. If you enter any portion of
        the Website which is intended for or is marked for mature audiences
        only, you are certifying that you are at least 18 years old or in the
        jurisdiction in which you reside have the legal right to access such
        content. We shall not be responsible in any way for your failure to
        accurately confirm your age per the terms hereunder.
        <br />
        <br />
        When creating a user identity ("User ID") you agree to (i) provide true,
        accurate, current, and complete information as requested in the required
        fields and (ii) promptly maintain and update such User ID to keep it
        true, accurate, current and complete. The information you provided shall
        be subject to our Privacy Policy.
        <br />
        <br />
        You, as creator of your User ID, are completely responsible for your
        identity. GENIUS will not tolerate offensive or obscene user identities.
        If a User ID violates any part of these terms, we may immediately,
        temporarily, or permanently ban such a User ID.
        <br />
        <br />
        GENIUS is not responsible for any misuse of your User ID, you agree to
        hold GENIUS and its affiliates harmless for any improper use of such
        identity including, but not limited to, improper use by someone to whom
        you revealed your password.
        <br />
        <br />
        Your User ID may be deactivated if you do not use it within six months
        after the date that it was created or for any continuous period of six
        months after creation. If you do not use your User ID for six or more
        months, it may be removed and deleted by the Website administrator at
        their sole discretion. We will use reasonable efforts to notify you by
        e-mail before we delete your User ID. If you advise us within five days
        of the notice that you want to keep your User ID active, we will not
        delete it. If you do not so notify us, your User ID will be permanently
        deleted, along with your User ID records, ranks and service information.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content4 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">4. PRIVACY</h2>
      <p className="mb-10">
        Our Privacy Policy, which covers the usage and protection of your
        personal information, is published on the Website, currently at
        https://www.geniusorc.com, and is incorporated herein by this reference.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content5 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">5. LICENSE TO USE</h2>
      <p className="mb-10">
        Subject to the terms of this Agreement and any applicable EULA, GENIUS
        grants to you, for your personal use only, a non-exclusive, revocable,
        nontransferable license to use the services offered by our Website,
        including access to our games (the "Services"), and a non-exclusive,
        revocable, nontransferable license to use any client software (the
        "Software") in connection with the Services, without charge.
        <br />
        <br />
        You may not (a) sublicense, rent, lease, loan, sell or otherwise
        transfer the Software or the Services (or any part thereof) (b) modify,
        adapt, reverse engineer or decompile the Software, or otherwise attempt
        to derive source code from the Software; (c) create any derivative works
        in respect of the Software or the Service; or (d) otherwise use the
        Software or the Service except as expressly provided in this Agreement.
        Title to the Software, and all rights with respect to the Software and
        Service not specifically granted under this Agreement, including without
        limitation all rights of reproduction, modification, distribution,
        display, disassembly and de-compilation and all copyright, patent,
        trademark, trade secret and other proprietary rights and interests are
        reserved to GENIUS or its licensor(s).
        <br />
        <br />
        NEITHER GENIUS NOR ITS LICENSORS HAVE ANY LIABILITY OF ANY KIND OR
        NATURE IN CONNECTION WITH YOUR USE OF THE SOFTWARE (INCLUDING LIABILITY
        FOR ANY CONSEQUENTIAL OR INCIDENTAL DAMAGES OR DAMAGE TO YOUR COMPUTER
        HARDWARE OR SOFTWARE), AND THE ENTIRE RISK OF USE (INCLUDING, WITHOUT
        LIMITATION, ANY DAMAGE TO YOUR COMPUTER HARDWARE OR SOFTWARE) RESIDES
        WITH YOU.
        <br />
        <br />
        As more particularly described in each applicable EULA, GENIUS and/or
        its licensors retain exclusive right, title and interest (including all
        intellectual property rights) in and to the Software, copies thereof,
        and all error corrections, bug-fixes, patches, updates, derivative
        works, improvements, modifications thereto (whether made by GENIUS, its
        licensors, you, or otherwise), including, but not limited to, any
        titles, computer code, themes, objects, characters, character names,
        stories, dialog, catch phrases, locations, concepts, artwork, character
        inventories, structural or landscape designs, animations, sounds,
        musical compositions,
        <br />
        <br />
        audio-visual effects, storylines, character likenesses, methods of
        operation, moral rights, any related documentation, and "applets"
        incorporated into the Software. The rights described in the foregoing
        sentence are the copyrighted work of GENIUS and/or its licensors and are
        protected by the copyright laws of the Singapore, USA, Japan,
        international copyright treaties and conventions, and/or other
        applicable laws. All rights are reserved. The Software may contain
        certain licensed materials, and the licensors of those materials may
        enforce their rights in the event of any violation of the EULA.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content6 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">6. USER SUBMISSIONS</h2>
      <p className="mb-10">
        "Submissions" means all data, text, music, sound, photographs, comments,
        feedback, suggestions, postings on forums, chat rooms and similar venues
        on the Website, e-mails and similar information or materials that you
        submit to GENIUS regarding the games and services of GENIUS or the use
        or play of those games and services. User IDs and Submissions are not
        confidential and will become the property of GENIUS upon submission. You
        agree to assign and grant to GENIUS an irrevocable, transferable,
        royalty-free, and perpetual right and license to all worldwide,
        transferable license and the right to quote, re-post, use, reproduce,
        modify, distribute, transmit, broadcast, and otherwise communicate, and
        publicly display the User IDs and Submissions in any form, anywhere,
        with or without attribution to your User ID, and without notice or
        compensation to you.
        <br />
        <br />
        You acknowledge that you are solely responsible for the Submissions that
        you provide and for managing your User ID. You, and not GENIUS, have
        full responsibility for your Submissions and User ID, including their
        legality, reliability, appropriateness, originality, and copyright. You
        understand that by using the Website you may be exposed to Submissions
        that is offensive, indecent, or objectionable. GENIUS shall not be
        liable in any way for any Submission, including, but not limited to, any
        errors or omissions in any Submission, or any loss or damages of any
        kind incurred as a result of the use of any Submission posted, e-mailed,
        transmitted or otherwise made available via the Website.
        <br />
        <br />
        All materials published in forums, chat rooms and similar venues on the
        Website are considered public. Therefore, GENIUS strongly recommends
        that you not publish any personal information about yourself or others
        on or through the Website. As a matter of policy, GENIUS does not
        pre-screen or monitor any Submissions. However, GENIUS reserves the
        right to screen, refuse or move any Submission that is available via the
        Website. GENIUS has the right in its discretion to remove any Submission
        deemed objectionable or violates the terms hereof.
        <br />
        <br />
        Except as expressly stated and agreed upon in advance by GENIUS, no
        confidential relationship will be established in the event any user of
        this Website should make any oral, written or electronic communication
        to GENIUS (such as feedback, questions, comments, suggestions, ideas,
        etc.). If any GENIUS Website requires or requests that such information
        be provided, and that such information contains personal identifying
        information (e.g., name, address, phone number), GENIUS shall obtain,
        use and maintain it in a manner consistent with its Privacy Policy.
        Otherwise, such communication and any information submitted therewith
        will be considered non-confidential, and GENIUS will be free to
        reproduce, publish or otherwise use such information for any purposes
        including, without limitation, the research, development, use or sale of
        games or services incorporating such information. You are fully
        responsible for the content of your communications, including its
        truthfulness and accuracy and its non-infringement of any other person's
        proprietary or privacy rights.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content7 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">7. USER CONDUCT</h2>
      <p className="mb-10">
        You must observe these terms, all applicable laws and also basic rules
        of etiquette and common courtesy when using the Website. Any conduct
        that violates the law in an offline, real world community is also a
        violation of these terms. GENIUS will not tolerate any illegal or
        offensive conduct.
        <br />
        <br />
        You agree not to take any of the following actions:
        <br />
        <br />
        (a) Upload, post, email, transmit or otherwise make available any
        Submission (as defined below) that is unlawful, harmful, threatening,
        abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous,
        invasive of another's privacy, hateful, or racially, ethnically or
        otherwise objectionable; (b) Harm minors in any way; (c) Impersonate any
        person or entity, including, but not limited to, a GENIUS official,
        forum leader, guide or host, or falsely state or otherwise misrepresent
        your affiliation with a person or entity; (d) Forge headers or otherwise
        manipulate identifiers in order to disguise the origin of any Submission
        transmitted through the Website; (e) Upload, post, e-mail, transmit or
        otherwise make available any Submission that you do not have a right to
        make available under any law or under contractual or fiduciary
        relationships; (f) Upload, post, e-mail, transmit or otherwise make
        available any Submission that infringes any patent, trademark, trade
        secret, copyright or other proprietary rights of any party; (g) Upload,
        post, e-mail, transmit or otherwise make available any unsolicited or
        unauthorized advertising, promotional materials, "junk mail", "spam",
        "chain letters", "pyramid schemes", or any other form of solicitation;
        (h) Upload, post, e-mail, transmit or otherwise make available any
        material that contains software viruses or any other computer code,
        files or programs designed to interrupt, destroy or limit the
        functionality of any computer software or hardware or telecommunications
        equipment; (i) Interfere with or disrupt the Website or servers or
        networks connected to the Website, or disobey any requirements,
        procedures, policies or regulations of networks connected to the
        Website; (j) "Stalk" or otherwise harass another; (k) Collect or store
        personal data about other users in connection with the prohibited
        conduct and activities set forth in the paragraphs above; (l) Impede the
        flow of chat in game, in forums, or in chat rooms with vulgar language,
        abusiveness, use of excessive shouting (ALL CAPS) "spamming" or any
        other disruptive or detrimental methods in an attempt to disturb other
        users or GENIUS employees; (m) Engage in, encourage, or promote any
        illegal activity, or any activity that violates the Terms of Service,
        the Rules of Conduct, or the Privacy Policy; and/or (n) Take any action
        that may prohibit other users from enjoying the services of the Website
        or games. GENIUS may take any actions and impose any penalties we deem
        necessary to discourage and punish any violation of these terms or any
        other illegal or inappropriate conduct, all without prior notice or
        warning. The determination as to whether a violation has occurred and
        who is responsible for such act is solely within GENIUS's discretion,
        and is based on what we deem best for the community and the Website. By
        using the Website, you agree you will be bound by GENIUS's determination
        as to whether a violation has occurred and any penalty we choose to
        implement.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content8 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">8. THIRD PARTY SITES</h2>
      <p className="mb-10">
        Clicking a hyperlink may direct you away from the Website. GENIUS does
        not endorse or control any third-party linked sites, is not responsible
        for their content and has no association with the owners or operators of
        such sites. Also, other sites may have different terms of service and
        different privacy policies. GENIUS therefore strongly recommends that
        you inform yourself regarding the practices of third party websites.
        <br />
        <br />
        Your correspondence or business dealings with, or participation in
        promotions of, advertisers found on or through the Website, including
        payment and delivery of related goods or services, and any other terms,
        conditions, warranties or representations associated with such dealings,
        are solely between you and such advertiser. You agree that GENIUS shall
        not be responsible or liable for any loss or damages of any sort
        incurred as the result of any such dealings or as the result of the
        presence of such advertisers on the Website.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content9 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">
        9. OFFICIAL SERVICE AND BETA TESTING
      </h2>
      <p className="mb-10">
        The games are designed for play only as offered through our Services at
        the Website. You agree not to access, create or provide any other means
        through which the game may be played by others, such as through server
        emulators. You agree not to use any hardware or software, including but
        not limited to third party tools, or any other method of support which
        may in any way influence or give you an advantage in the use of the
        Services which is not authorized by GENIUS, including but not limited to
        the use of "bots" and/or any other method by which the Service may be
        played automatically without human input. You acknowledge that you do
        not have the right to create, publish, distribute, create derivative
        works from or use any software programs, utilities, applications,
        emulators or tools derived from or created for the games, except that
        you may use the Software to the extent expressly permitted by this
        Agreement and the applicable EULA. You may not take any action which
        imposes an unreasonable or disproportionately large load on our
        infrastructure. You may not sell or auction any game accounts,
        characters, items, coin or copyrighted material, nor may you assist
        others in doing so.
        <br />
        <br />
        You may be given the opportunity to Beta test new games and Website
        features. Your participation as a Beta tester is subject to the terms
        and conditions below.
        <br />
        <br />
        Closed Beta tests are confidential. The Beta games, including
        information about features and functionality to be offered as part of
        the games, are confidential. If you participate in a closed Beta test,
        you must safeguard and prevent unauthorized access to, copying,
        disclosure, and unauthorized use of the Beta games. You will carry out
        the testing personally and not provide access to Beta games to any other
        person. You agree that breach of the above confidentiality obligations
        will cause irreparable harm to GENIUS, and GENIUS is entitled to (in
        addition to any other remedies available to it) ex parte injunctive
        relief without bond to prevent the breach or threatened breach of your
        obligations. Your obligation to keep the Beta games confidential will
        continue until GENIUS publicly distributes, or has otherwise disclosed
        to the public through no fault of yours, each of the games and the
        content that you are testing.
        <br />
        <br />
        As a Beta tester, you are invited to play Beta games for the sole
        purpose of evaluating the games and identifying errors. Nothing in this
        Agreement, or on this Website, shall be construed as granting you any
        rights or privileges of any kind with respect to the Beta games or
        content that you find here. The Beta games are provided for testing on
        an "as is", "as available" basis and we make no warranty to you of any
        kind, express or implied.
        <br />
        <br />
        When playing some Beta games, you may accumulate treasure, experience
        points, equipment, or other value or status indicators within the Beta
        test. This data may be reset at any time during the testing process, and
        it may be reset when the particular game completes this testing phase.
        In this case, all player history and data will be erased and each player
        will return to novice status.
        <br />
        <br />
        By playing a Beta game, you agree that: (i) playing Beta games is at
        your own risk and that you know that the games may include known or
        unknown bugs, (i) any value or status indicators that you achieve
        through game play may be erased at any time, (iii) GENIUS has no
        obligation to make these games available for play without charge for any
        period of time, nor to make them available at all, (iv) these games may
        be available only by subscription once the testing process is complete
        or at any time in the future; (v) these terms apply to your use of the
        games during the testing phase, and (vi) if it is a closed Beta test,
        you will keep all information (including but not limited to functions,
        features, graphics, and screenshots) about the Beta games confidential
        as stated above and not disclose such information to any other person.
        Beta test accounts are non-transferable under any circumstances.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content10 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">10. FEES</h2>
      <p className="mb-10">
        Certain areas of the Website may charge fees to access and acquire
        certain game items or participate in game activities on the Website. ANY
        APPLICABLE FEES AND OTHER CHARGES ARE PAYABLE IN ADVANCE AND ARE NOT
        REFUNDABLE IN WHOLE OR IN PART. GENIUS may, from time to time, modify,
        amend, or supplement its fee and billing methods, and post those changes
        in this Agreement or elsewhere on the Website. Such modifications,
        amendments or supplements shall be effective immediately upon posting on
        the Website. If any change is unacceptable to you, you may cancel your
        account at any time, but GENIUS will not refund any fees that may have
        accrued to your account before such cancellation, and we will not
        pro-rate fees for any charges.
        <br />
        <br />
        You may pay for any applicable fees and charges by major credit card,
        PayPal or other such methods authorized by GENIUS.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content11 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">11. INTERRUPTION OF SERVICE</h2>
      <p className="mb-10">
        (a) GENIUS reserves the right to interrupt the Services from time to
        time on a regularly scheduled basis or otherwise with or without prior
        notice in order to perform maintenance. You agree that GENIUS will not
        be liable for any interruption of the Services, delay or failure to
        perform resulting from any causes whatsoever. (b) You acknowledge that
        the Services may be interrupted for reasons beyond the control of
        GENIUS, and GENIUS cannot guarantee that you will be able to access the
        Services or your Account whenever you may wish to do so. GENIUS shall
        not be liable for any interruption of the Services, delay or failure to
        perform resulting from any causes whatsoever. (c) GENIUS has the right
        at any time with or without reason to change and/or eliminate any
        aspect(s) of the Services as it sees fit in its sole discretion. (d)
        GENIUS is not obligated to refund all or any portion of any Account fee
        (if any), by reason of any interruption of the Services by reason of any
        of the circumstances described in paragraph (a), (b), or (c).
        <br />
        <br />
      </p>
    </div>
  );
};
const Content12 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">
        12. DISCIPLINARY ACTION/ACCOUNT TERMINATION
      </h2>
      <p className="mb-10">
        (GENIUS MAY SUSPEND, TERMINATE, MODIFY, OR DELETE ANY ACCOUNT AT ANY
        TIME WITH OR WITHOUT REASON, WITH OR WITHOUT NOTICE.
        <br />
        <br />
        GENIUS can and does issue warnings and temporary suspensions and
        permanent terminations of user identities for user violations. We retain
        the sole discretion as to when and how to impose warnings, penalties
        and/or disciplinary actions. We consider the severity of the violation
        and the number of infractions in making our determination; however, any
        determination shall be under the absolute discretion of GENIUS.
        <br />
        <br />
        For the avoidance of doubt, you hereby acknowledge that GENIUS has sole
        discretion with respect to penalties and disciplinary actions to
        terminate your Account, even if there are credits remaining on your
        Account. If you feel you or your User ID has been unfairly warned or
        disciplined, please contact us with a full detailed explanation. We will
        reasonably consider your explanation.
        <br />
        <br />
        You have the right to terminate or cancel your Account at any time by
        delivering notice to GENIUS. You understand and agree that the
        cancellation of your User Id is your sole right and remedy with respect
        to any dispute, including, but not limited to, any related to, or
        arising out of: (i) any term of this Agreement or GENIUS's enforcement
        or application of this Agreement; (ii) the Submissions; (iii) your
        ability to access and/or use the Website; or (iv) the amount or type of
        fees, surcharges, applicable taxes, billing methods, or any change to
        the fees, applicable taxes, surcharges or billing methods.
        <br />
        <br />
        You may cancel your User ID by delivering notice to GENIUS. GENIUS
        reserves the right to collect accrued fees and charges and costs
        incurred by GENIUS before your cancellation. In addition, you are
        responsible for any fees, charges and costs incurred to third-party
        vendors or content providers before your cancellation. In the event your
        User ID is terminated or canceled for any reason no refund will be
        granted, no online time or other credits (e.g., points in an online
        game) will be credited to you or converted to cash or other form of
        reimbursement, and you will have no further access to your User ID. Any
        delinquent or unpaid accounts or accounts with unresolved disputes must
        be settled before GENIUS may allow you to register again.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content13 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">13. INDEMNIFICATION</h2>
      <p className="mb-10">
        At GENIUS's request, you agree to defend, indemnify and hold harmless
        (a) GENIUS, (b) its shareholders, partners, affiliates, directors,
        officers, subsidiaries, employees, agents or suppliers, (c) its
        licensees, distributors, content providers, and (d) other members of the
        Services, from all damages, liabilities, claims and expenses, including
        without limitation attorneys' fees and costs, arising from or related,
        directly or indirectly, to any misuse of the Services or breach of this
        Agreement by you.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content14 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">14. ACKNOWLEDGEMENTS</h2>
      <p className="mb-10">
        You hereby acknowledge and agree that:
        <br />
        <br />
        (a) WHEN USING THE SERVICES, THE SOFTWARE MAY MONITOR YOUR COMPUTER'S
        RANDOM ACCESS MEMORY (RAM) AND/OR CPU PROCESSES FOR UNAUTHORIZED THIRD
        PARTY PROGRAMS RUNNING CONCURRENTLY WITH THE SOFTWARE. AN "UNAUTHORIZED
        THIRD PARTY PROGRAM" AS USED HEREIN SHALL BE DEFINED AS ANY THIRD PARTY
        SOFTWARE, INCLUDING WITHOUT LIMITATION ANY "ADD-ON" OR "MOD", THAT IN
        GENIUS'S SOLE DETERMINATION: (i) ENABLES OR FACILITATES CHEATING OF ANY
        TYPE; (ii) ALLOWS USERS TO MODIFY OR HACK THE SOFTWARE INTERFACE,
        ENVIRONMENT, AND/OR EXPERIENCE IN ANY WAY NOT EXPRESSLY AUTHORIZED BY
        GENIUS; OR (iii) INTERCEPTS, "MINES", OR OTHERWISE COLLECTS INFORMATION
        FROM OR THROUGH THE SOFTWARE. IN THE EVENT THAT THE SOFTWARE DETECTS AN
        UNAUTHORIZED THIRD PARTY PROGRAM, IT MAY COMMUNICATE INFORMATION BACK TO
        GENIUS, INCLUDING WITHOUT LIMITATION YOUR ACCOUNT NAME, DETAILS ABOUT
        THE UNAUTHORIZED THIRD PARTY PROGRAM DETECTED, AND THE TIME AND DATE THE
        UNAUTHORIZED THIRD PARTY PROGRAM WAS DETECTED; AND/OR AND GENIUS MAY
        EXERCISE ANY OR ALL OF ITS RIGHTS UNDER THIS SECTION OF THE AGREEMENT,
        WITH OR WITHOUT PRIOR NOTICE TO THE USER. (b) WHEN THE SOFTWARE IS
        RUNNING, GENIUS MAY OBTAIN CERTAIN IDENTIFICATION INFORMATION ABOUT YOUR
        COMPUTER AND ITS OPERATING SYSTEM, INCLUDING WITHOUT LIMITATION YOUR
        HARD DRIVES, CENTRAL PROCESSING UNIT, IP ADDRESS(ES) AND OPERATING
        SYSTEM(S), FOR PURPOSES OF IMPROVING THE PROGRAM AND/OR THE SERVICE, AND
        TO POLICE AND ENFORCE THE PROVISIONS OF THIS AGREEMENT AND THE EULA. (c)
        GENIUS may, with or without notice to you, disclose your Internet
        Protocol (IP) address(es), personal information, and information about
        you and your activities in response to a written request by law
        enforcement, a court order or other legal process. GENIUS may use or
        disclose your personal information if they believe that doing so may
        protect your safety or the safety of others. (d) GENIUS MAY RECORD YOUR
        CHAT SESSIONS AND OTHER ELECTRONIC COMMUNICATION TRANSMITTED OR RECEIVED
        THROUGH THE GAME AND YOU CONSENT TO SUCH MONITORING OR LOGGING. (e) You
        may not hold GENIUS responsible for any loss resulting from in-game
        trading activities. You may not hold GENIUS responsible for any
        information that is passed between players. GENIUS does not guarantee
        the safe keeping of information that is passed between players and will
        not be held responsible for any resulting losses or consequences arising
        from the improper use of said information. You may not hold GENIUS
        responsible for any in-game feature or item loss. GENIUS does not
        guarantee the availability of any in-game items, any Services or any
        levels to players.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content15 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">15. DISCLAIMER OF WARRANTIES</h2>
      <p className="mb-10">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
        <br />
        <br />
        (a) YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE WEBSITE IS
        PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. GENIUS AND ITS
        SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
        LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        (b) GENIUS AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
        AGENTs, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE WEBSITE
        WILL MEET YOUR REQUIREMENTS; (ii) THE WEBSITE WILL BE UNINTERRUPTED,
        TIMELY, SECURE OR ERROR-FREE; (iii) THE QUALITY OF ANY PRODUCTS,
        SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
        THROUGH THE WEBSITE WILL MEET YOUR EXPECTATIONS; AND (iv) ANY ERRORS IN
        THE SOFTWARE WILL BE CORRECTED. (c) ANY MATERIAL DOWNLOADED OR OTHERWISE
        OBTAINED THROUGH THE USE OF THE WEBSITE IS ACCESSED AT YOUR OWN
        DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
        TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD
        OF ANY SUCH MATERIAL. (d) NO ADVICE OR INFORMATION, WHETHER ORAL OR
        WRITTEN, OBTAINED BY YOU FROM GENIUS OR THROUGH OR FROM THE WEBSITE
        SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF USE. (e)
        A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN
        EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER SCREEN OR
        WHILE USING THE WEBSITE. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY
        UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR
        SEIZURES OR EPILEPSY. IF YOU, OR ANYONE IN YOUR FAMILY, HAVE AN
        EPILEPTIC CONDITION, CONSULT YOUR PHYSICIAN PRIOR TO USING THE SERVICE.
        IMMEDIATELY DISCONTINUE USE OF THE SERVICE AND CONSULT YOUR PHYSICIAN IF
        YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING THE SERVICE:
        DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
        DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content16 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">16. LIMITATION OF LIABILITY</h2>
      <p className="mb-10">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT GENIUS AND ITS SUBSIDIARIES,
        AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL
        NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
        LOSSES (EVEN IF GENIUS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE
        WEBSITE; (ii) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE;
        OR (iii) ANY OTHER MATTER RELATING TO THE WEBSITE. Some states do not
        allow the foregoing limitations of liability, so they may not apply to
        you and in such a case you agree that GENIUS's (or any such other
        released parties) liability to you shall be limited to the maximum
        extent permitted by law. You agree that GENIUS cannot be held
        responsible or liable for anything that occurs or results from accessing
        or subscribing to the Services.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content17 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">17. DISPUTE RESOLUTION</h2>
      <p className="mb-10">
        If a dispute arises between you and GENIUS, our goal is to provide you
        with a neutral and cost-effective means of resolving the dispute
        quickly. Accordingly, you and GENIUS agree to resolve any claim or
        controversy at law or in equity that arises from or relates to this
        Agreement or our Service (a "Claim") in accordance with one of the
        subsections below.
        <br />
        <br />
        (a) This Agreement and the relationship between you and GENIUS shall be
        governed in all respects by the laws of the State of California without
        regard to conflict of law principles or the United Nations Convention on
        the International Sale of Goods. (b) You and GENIUS agree to submit to
        the exclusive jurisdiction and venue of the courts located in Santa
        Clara County, California, except as provided in subsection 17(c) below
        regarding optional arbitration. Notwithstanding this, you agree that
        GENIUS shall still be allowed to apply for injunctive or other equitable
        relief in any court of competent jurisdiction. (c) For any Claim,
        excluding Claims for injunctive or other equitable relief, where the
        total amount of the award sought is less than ten thousand U.S. Dollars
        ($10,000.00 USD), the party requesting relief may elect to resolve the
        Claim in a cost-effective manner through binding non-appearance-based
        arbitration. A party electing arbitration shall initiate it through an
        established alternative dispute resolution ("ADR") provider mutually
        agreed upon by the parties. The ADR provider and the parties must comply
        with the following rules: (a) the arbitration shall be conducted, at the
        option of the party seeking relief, by telephone, online, or based
        solely on written submissions; (b) the arbitration shall not involve any
        personal appearance by the parties or witnesses unless otherwise
        mutually agreed by the parties; and (c) any judgment on the award
        rendered by the arbitrator may be entered in any court of competent
        jurisdiction. (d) All Claims you bring against GENIUS must be resolved
        in accordance with this Dispute Resolution Section. All Claims filed or
        brought contrary to this Dispute Resolution Section shall be considered
        improperly filed. Should you file a Claim contrary to this Dispute
        Resolution Section, GENIUS may recover attorneys' fees and costs up to
        one thousand U.S. Dollars ($1,000.00 USD), provided that GENIUS has
        notified you in writing of the improperly filed Claim, and you have
        failed to promptly withdraw the Claim.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content18 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">18. GENERAL</h2>
      <p className="mb-10">
        You agree to comply fully with all relevant export laws and regulations
        of Republic of Korea ("Export Laws") to assure that neither the Software
        nor the Services are exported, directly or indirectly, in violation of
        Export Laws; or is intended to be used for any purposes prohibited by
        the Export Laws. Our failure to act with respect to a breach by you or
        others does not waive our right to act with respect to subsequent or
        similar breaches. Except as otherwise permitted herein, you may not
        assign or transfer this Agreement or your rights hereunder, and any
        attempt to the contrary is void. This Agreement sets forth the entire
        understanding and agreement between us and you with respect to the
        subject matter hereof. Notwithstanding anything else in this Agreement,
        no default, delay or failure to perform on the part of GENIUS shall be
        considered a breach of this Agreement if such default, delay or failure
        to perform is shown to be due to causes beyond the reasonable control of
        GENIUS.
        <br />
        <br />
        If any provision of this Agreement shall be unlawful, void, or for any
        reason unenforceable, then that provision shall be deemed severable from
        this Agreement and shall not affect the validity and enforceability of
        any remaining provisions. This Agreement is the complete and exclusive
        statement of the agreement between you and Genius Orc Entertainment Inc.
        concerning the Service, and this Agreement supersedes any prior or
        contemporaneous agreement, either oral or written, and any other
        communications with regard thereto between you and GENIUS; provided,
        however that this Agreement is in addition to, and does not replace or
        supplant, the applicable EULA. This Agreement may only be modified as
        set forth herein. The section headings used herein are for reference
        only and shall not be read to have any legal effect.
        <br />
        <br />
      </p>
    </div>
  );
};
const Content19 = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">19. CLOSING</h2>
      <p className="mb-10">
        Thank you again for visiting our Website. We hope you enjoy your stay
        and take full advantage of the online community we are developing.
        Please remember that you are a guest at the Website and act with the
        same courtesy and respect you expect from other guests. GENIUS always
        reserves the right to refuse or terminate service.
        <br />
        <br />
      </p>
    </div>
  );
};
export const PolicyTermsOfService = () => {
  return (
    <div className="text-md text-coolGray-700 font-notoserif">
      <Header />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />
      <Content5 />
      <Content6 />
      <Content7 />
      <Content8 />
      <Content9 />
      <Content10 />
      <Content11 />
      <Content12 />
      <Content13 />
      <Content14 />
      <Content15 />
      <Content16 />
      <Content17 />
      <Content18 />
      <Content19 />
    </div>
  );
};
