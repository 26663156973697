import {
  gql,
  useReactiveVar,
  useMutation,
  useApolloClient
} from "@apollo/client";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { useMyBalance } from "../../../hooks/use-my-balance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormErrors } from "../../../components/general/form-errors";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMyProfile } from "../../../hooks/use-my-profile";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faLiraSign,
  faSimCard,
  faExternalLinkAlt,
  faUnlockAlt,
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import koparBiLogo from "../../../assets/portal/images/bi_kopazar.png";
import ReCAPTCHA from "react-google-recaptcha";
import { isLoggedInVar } from "../../../apollo";
import { ButtonCommon } from "components/general/btn-common";
import Moment from "react-moment";
import {
  epinVaildate,
  epinVaildateVariables,
  epinVaildate_eapi_EpinGetValid_OrderDetail
} from "../../../__generated__/epinVaildate";
import { Dialog, Transition } from "@headlessui/react";

interface ICheckBoxForm {
  isPurchaseAgree: boolean | null;
}

interface ILoginForm {
  epinNumber: string;
  epinPass: string;
}

interface IShopReturnProps {
  onClick: (data: string) => void;
  resultData: epinVaildate_eapi_EpinGetValid_OrderDetail;
}

//발리데이션 체크
const EPIN_VALIDATE = gql`
  mutation epinVaildate($input: EpinValidInput!) {
    eapi_EpinGetValid(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      OrderDetail {
        Price
        Amount
        TotalBalance
        TransactionID
        TranactionDate
        Error
      }
    }
  }
`;

const ShopEpinOk: React.FC<IShopReturnProps> = ({ onClick, resultData }) => {
  return (
    <>
      <article className="flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center justify-center mt-6 mb-4">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
            <FontAwesomeIcon
              icon={faSimCard}
              className="text-5xl text-coolGray-500"
            />
          </div>
          <div className="absolute top-11 -right-2">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-4xl text-blue-400"
            />
          </div>
        </div>
        <p className="mb-2 text-4xl text-purple-900 font-oswald">
          Yükleme Başarılı
        </p>
        <p className="mb-5 text-lg text-coolGray-500 font-opensans">
          Lütfen satın alma geçmişinizi kontrol edin
        </p>
      </article>
      <div
        className={"flex justify-center items-center flex-col w-3/5 mt-5 mb-16"}
      >
        {resultData ? (
          <table className="w-11/12 border border-gray-300 ">
            <tbody>
              <tr>
                <td className="w-4/12 py-4 pl-4 bg-gray-300 border border-gray-400">
                  İşlem numarası
                </td>
                <td className="w-8/12 py-4 my-4 text-center text-purple-900 border-gray-300">
                  <NumberFormat
                    value={resultData.Price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />{" "}
                </td>
              </tr>
              <tr>
                <td className="w-4/12 py-4 pl-4 bg-gray-300 border border-gray-400">
                  Satın Alınan G-Para
                </td>
                <td className="w-8/12 py-4 my-4 text-center text-purple-900 border border-gray-300">
                  <NumberFormat
                    value={resultData.Amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-4/12 py-4 pl-4 bg-gray-300 border border-gray-400">
                  Toplam G-Para
                </td>
                <td className="w-8/12 py-4 my-4 text-center text-purple-900 border border-gray-300">
                  <NumberFormat
                    value={resultData.TotalBalance}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="w-4/12 py-4 pl-4 bg-gray-300 border border-gray-400">
                  İşlem miktarı
                </td>
                <td className="w-8/12 py-4 my-4 text-center text-purple-900 border border-gray-300">
                  {resultData.TransactionID}
                </td>
              </tr>
              <tr>
                <td className="w-4/12 py-4 pl-4 bg-gray-300 border border-gray-400">
                  Ödeme tarihi
                </td>
                <td className="w-8/12 py-4 my-4 text-center text-purple-900 border border-gray-300">
                  <Moment local locale={"tr"} format="lll">
                    {resultData.TranactionDate}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}

        <button
          onClick={() => onClick("input")}
          className={`w-1/3 mt-6 max-w-2xl 
          text-xl bg-coolGray-800 text-coolGray-100 
          hover:bg-coolGray-200 hover:text-coolGray-600 
          border border-coolGray-400 px-6 py-4
        transition duration-300 ease-in-out text-center`}
        >
          {`Devam et`}
        </button>
      </div>
    </>
  );
};

const ShopEpinFail: React.FC<IShopReturnProps> = ({ onClick, resultData }) => {
  return (
    <>
      <article className="flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center justify-center mt-6 mb-4">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
            <FontAwesomeIcon
              icon={faSimCard}
              className="text-5xl text-coolGray-500"
            />
          </div>
          <div className="absolute top-11 -right-2">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-4xl text-red-700"
            />
          </div>
        </div>
        <p className="mb-2 text-4xl text-red-700 font-oswald">Yükleme Hatası</p>
        <p className="mb-5 text-lg text-coolGray-500 font-opensans">
          Lütfen aşağıdaki hata ayrıntılarını kontrol edin
        </p>
      </article>
      <div
        className={"flex justify-center items-center flex-col w-3/5 mt-5 mb-16"}
      >
        {resultData ? (
          <p className="p-6 mb-6 text-2xl text-center text-red-700">
            {resultData.Error}
          </p>
        ) : null}
        <button
          onClick={() => onClick("input")}
          className={`w-1/3 mt-6 max-w-2xl 
          text-xl bg-coolGray-800 text-coolGray-100 
          hover:bg-coolGray-200 hover:text-coolGray-600 
          border border-coolGray-400 px-6 py-4
        transition duration-300 ease-in-out text-center`}
        >
          {`Geri gitmek`}
        </button>
      </div>
    </>
  );
};

export const ShopHomeEpin = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  if (!userProfileLoading && !userProfile?.eapi_MyProfile.isVerifyEmail) {
    history.push("/");
  }
  const {
    loading: userBalanceLoading,
    data: userBalance,
    refetch: balanceRefetch
  } = useMyBalance(1);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [isEpinReturnVal, setEpinReturnVal] = useState("input");
  let [isOpen, setIsOpen] = useState(false);
  const reSK = process.env.REACT_APP_RECAPTCHA;
  const [isCaptcha, setIsCaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const client = useApolloClient();

  const closeModal = () => {
    setIsOpen(false);
    history.push("/");
  };
  const handleConfirm = (value: string) => {
    setEpinReturnVal(value);
  };

  useEffect(() => {
    balanceRefetch();

    if (location.pathname === "/shop") {
      if (isEpinReturnVal !== "input") {
        setEpinReturnVal("input");
      }
    }
  }, [location]);

  const productInfo = [
    {
      name: "info0",
      desc: `Satın alma sözleşmesini onaylayarak ödemenin yapılmasına ve hizmeti kullanmaya onay veriyor ve SKLA doğrultusunda iptal/iade talebinde bulunmayacağımı taahhüt ediyorum.`
    }
    // ,
    // {
    //   name: "info1",
    //   desc: `Satın alma işleminizi tamamlamadan önce doğru hesaba giriş yaptığınızı ve alım yapacağınız tutarı lütfen kontrol ediniz.`
    // },
    // {
    //   name: "info2",
    //   desc: `G-Para satın alındıktan hemen sonra kullanılabilir`
    // }
  ];

  const validatePurchase = Yup.object().shape({
    isPurchaseAgree: Yup.string()
      .oneOf(["true"], `${t("coin_shop.home.err_chk_01")}`)
      .required(`${t("coin_shop.home.err_chk_01")}`)
  });

  const {
    register: registerPurchase,
    formState: { errors: purchaseErr, isValid: purchaseValid }
  } = useForm<ICheckBoxForm>({
    mode: "onChange",
    defaultValues: {
      isPurchaseAgree: true
    },
    resolver: yupResolver(validatePurchase)
  });

  const validateEpin = Yup.object().shape({
    epinNumber: Yup.string()
      .trim()
      .matches(
        /^[A-NP-Z0-9/-]*$/,
        `İngilizce büyük harfler, sayılar ve - gereklidir.`
      )
      .min(16, `E-Pin 16 karakter uzunluğunda olmalıdır.`)
      .required(`E-Pin numarası gerekli.`),
    epinPass: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9]*$/, `Lütfen seri numarasını doğru girin.`)
      .min(8, `Seri numarası 9-10 karakter uzunluğunda olmalıdır.`)
      .required(`Seri numaranızı girmelisiniz.`)
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<ILoginForm>({
    mode: "onChange",
    resolver: yupResolver(validateEpin)
  });

  function onReCaptchaChange(value: any) {
    //console.log("Captcha value:", value);
    setIsCaptcha(true);
  }
  function onReCaptchaError() {
    setIsCaptcha(false);
  }
  function onReCaptchaExpire() {
    setIsCaptcha(false);
  }

  const onCompleted = (data: epinVaildate) => {
    console.log("oncompleted: ", data.eapi_EpinGetValid.ResultCode?.Code);
    if (data.eapi_EpinGetValid.ResultCode?.Code === "60003"||data.eapi_EpinGetValid.ResultCode?.Code === "60004" ||data.eapi_EpinGetValid.ResultCode?.Code === "97799") {
      setIsOpen(true)
    }

    if (data.eapi_EpinGetValid.ResultCode?.Code === "99001") {
      setIsCaptcha(false);
      setEpinReturnVal("fail");
    }
    if (data.eapi_EpinGetValid.ResultCode?.Code === "10000") {
      //성공
      setIsCaptcha(false);
      setEpinReturnVal("ok");
      client.writeFragment({
        id: `BalanceEntity:${userProfile?.eapi_MyProfile.email}`,
        fragment: gql`
          fragment TotalBalance on BalanceEntity {
            amount
          }
        `,
        data: {
          amount: data.eapi_EpinGetValid.OrderDetail?.TotalBalance
        }
      });
    }
    reset();
    balanceRefetch();

  };
  const [epinVaildate, { loading: epinValidLoading, data: epinValidResult }] =
    useMutation<epinVaildate, epinVaildateVariables>(EPIN_VALIDATE, {
      onCompleted
    });

  const onSubmit: SubmitHandler<ILoginForm> = async (data) => {
    epinVaildate({
      variables: {
        input: {
          epin: data.epinNumber?.trim().toUpperCase(),
          serial: data.epinPass?.trim()
        }
      }
    });
    reset();
  };

  return (
    <div className="flex flex-col items-center justify-start pt-20 bg-gray-100">
      <Helmet>
        <title>Purchase GOC :: Genius Orc</title>
      </Helmet>
      <article className="flex items-center justify-center w-full h-56 text-white bg-coolGray-800">
        <div className="-mt-10 text-center">
          <p className="mb-4 text-5xl capitalize font-oswald">
            E-PİN İLE G-PARA YÜKLEME
          </p>
          <p className="text-sm text-gray-300 font-opensans">
            Hesabınıza G-Para yükleyerek oyun içerisinde kullanabilirsiniz.
          </p>
        </div>
      </article>
      {userProfileLoading ? (
        <p>Yükleniyor ...</p>
      ) : (
        <div className="relative w-full max-w-screen-xl -mt-10 bg-white shadow-2xl">
          <div className="flex items-center justify-end mr-10 h-28">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center mr-1 rounded-full w-7 h-7 bg-coolGray-700">
                <FontAwesomeIcon
                  icon={faLiraSign}
                  className="w-6 h-6 text-coolGray-200"
                />
              </div>
              <p className="text-sm capitalize lg:text-md">G-Para Bakiye:</p>
              {userBalanceLoading ? (
                <p className="mx-4 text-sm">Yükleniyor ...</p>
              ) : (
                <>
                  <p className="mx-4 text-xl font-bold lg:text-4xl">
                    {userBalance?.eapi_BalanceGet.balanceEntity ? (
                      <NumberFormat
                        value={
                          userBalance?.eapi_BalanceGet.balanceEntity?.amount
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    ) : (
                      0
                    )}
                  </p>
                  <Link to="/my-profile/payment">
                    <button className="block px-4 py-2 text-sm font-bold text-gray-200 capitalize transition duration-300 ease-in-out bg-gray-800 border border-gray-400 lg:hidden lg:text-sm lg:font-bold hover:text-gray-800 hover:bg-gray-50">
                      Satın Alınan G-Para
                    </button>
                    <button className="hidden px-5 py-3 text-xs font-bold text-gray-200 capitalize transition duration-300 ease-in-out bg-gray-800 border border-gray-400 lg:block lg:text-sm lg:font-bold hover:text-gray-800 hover:bg-gray-50">
                      Satın Alınan G-Para / Kullanım Geçmişi
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
          <hr className="w-full border-1" />
          <div className="flex flex-col items-center justify-center mt-6 font-opensans">
            {/* */}
            {isEpinReturnVal === "input" ? (
              <>
                <div className="flex flex-col items-center justify-center w-full max-w-screen-xl mb-6 border lg:w-11/12 lg:flex-row border-coolGray-300 bg-coolGray-50">
                  <a
                    href="https://www.kopazar.com/karahan-online"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative flex flex-col items-center justify-center w-full lg:w-2/5 "
                  >
                    <img src={koparBiLogo} alt="" className="h-52 lg:-mt-12" />
                    <p className="absolute w-4/5 max-w-sm font-semibold leading-4 text-center mt-44 lg:mt-36 text-coolGray-600">
                      Epin satın almak için Kopazar'ı ziyaret edin{" "}
                      <span className="transition duration-300 ease-in-out text-cyan-600 hover:text-blue-800 hover:underline">
                        www.kopazar.com/karahan-online
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          className="w-6 h-6 pt-2 ml-1"
                        />
                      </span>
                    </p>
                  </a>
                  <div className="hidden border-l h-72 border-coolGray-300 lg:block">
                    <div className="block "></div>
                  </div>
                  <div className="flex flex-col items-center justify-center w-full lg:w-3/5">
                    <div className="mt-6 mb-1" style={{ width: "475px" }}>
                      <h3 className="text-2xl font-bold">Epin Yükleme</h3>
                      <p className="mt-2 text-sm">
                        Yetkili satıcılarımızdan aldığınız E-PİN'leri bu ekrandan yükleyebilirsiniz
                      </p>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="grid gap-3 px-2 mt-3 mb-2"
                    >
                      <div className="flex items-stretch justify-center">
                        <label
                          htmlFor="kodu"
                          className="flex items-center justify-start font-semibold tracking-tighter border border-r-0 bg-amber-400 border-coolGray-300 text-md text-coolGray-600"
                          style={{ width: "134px" }}
                        >
                          <FontAwesomeIcon
                            icon={faSimCard}
                            className="ml-3 mr-1 text-sm "
                          />
                          E-PİN KODU
                        </label>
                        <input
                          maxLength={19}
                          id="kodu"
                          type="text"
                          placeholder="örnek) XXXX-XXXX-XXXX-XXXX"
                          style={{ width: "346px" }}
                          className={`form-input outline-none placeholder-coolGray-300 inputStyle ${
                            errors.epinNumber
                              ? `border-red-600`
                              : `border-coolGray-300`
                          }`}
                          {...register("epinNumber")}
                        />
                      </div>
                      {errors.epinNumber && (
                        <FormErrors errorMessage={errors.epinNumber?.message} />
                      )}
                      <div className="flex items-stretch justify-center">
                        <label
                          htmlFor="seri"
                          className="flex items-center justify-start font-semibold tracking-tighter border border-r-0 bg-amber-400 border-coolGray-300 text-md text-coolGray-600"
                          style={{ width: "134px" }}
                        >
                          <FontAwesomeIcon
                            icon={faUnlockAlt}
                            className="ml-3 mr-1 text-sm"
                          />
                          SERİ NO
                        </label>
                        <input
                          maxLength={10}
                          id="seri"
                          type="text"
                          placeholder="örnek) 12a1b2xx91"
                          style={{ width: "346px" }}
                          className={`form-input outline-none placeholder-coolGray-300 inputStyle ${
                            errors.epinPass
                              ? `border-red-600`
                              : `border-coolGray-300`
                          }`}
                          {...register("epinPass")}
                        />
                      </div>
                      {errors.epinPass && (
                        <FormErrors errorMessage={errors.epinPass?.message} />
                      )}
                      {epinValidResult?.eapi_EpinGetValid.ResultCode?.Code ===
                        "97701" && (
                        <FormErrors
                          errorMessage={
                            epinValidResult?.eapi_EpinGetValid.ResultCode
                              ?.Description
                          }
                        />
                      )}
                      <div className="flex justify-center w-full item-center">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={reSK!}
                          onChange={onReCaptchaChange}
                          onExpired={onReCaptchaError}
                          onErrored={onReCaptchaExpire}
                        />
                        {/*&& isCaptcha*/}
                        <button
                          className={`ml-4 text-lg hover:bg-purple-800 hover:text-coolGray-100 capitalize w-40 border border-coolGray-400
    duration-300 ease-in-out
    flex justify-center items-center
    focus:outline-none text-white px-6 transition-colors ${
      purchaseValid && isValid && isCaptcha
        ? "bg-coolGray-700 text-coolGray-200 cursor-pointer"
        : "bg-coolGray-200 text-coolGray-700 opacity-40 pointer-events-none"
    } ${epinValidLoading && `pointer-events-none`}`}
                          style={{ height: "75px" }}
                        >
                          {epinValidLoading ? (
                            <>
                              <svg
                                className="w-6 h-6 mr-2 text-white animate-spin"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                />
                              </svg>
                              <span>Loading...</span>
                            </>
                          ) : (
                            `YÜKLE`
                          )}
                        </button>
                      </div>
                    </form>
                    <p className="w-4/5 mb-8 text-sm text-center">
                      Epin yükleme ve satın alma ile ilgili herhangi bir
                      sorununuz varsa, lütfen{" "}
                      <a
                        href="https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback"
                        rel="noopener noreferrer"
                        className="font-semibold text-blue-600 transition duration-300 ease-in-out hover:text-blue-800 hover:underline"
                      >
                        destek
                      </a>{" "}
                      bölümünden bize bilet gönderin.
                    </p>
                  </div>
                </div>
              </>
            ) : isEpinReturnVal === "ok" ? (
              <ShopEpinOk
                onClick={handleConfirm}
                resultData={epinValidResult?.eapi_EpinGetValid.OrderDetail!}
              />
            ) : isEpinReturnVal === "fail" ? (
              <ShopEpinFail
                onClick={handleConfirm}
                resultData={epinValidResult?.eapi_EpinGetValid.OrderDetail!}
              />
            ) : null}
            {isEpinReturnVal === "input" && (
              <div className="w-full min-h-full bg-white border mb-28">
                <div className="pt-3 pb-1 pl-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="w-6 h-6 text-indigo-800 capitalize bg-gray-200 border-gray-300 form-checkbox focus:bg-indigo-200 focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
                      {...registerPurchase("isPurchaseAgree")}
                    />
                    <span className="ml-2 capitalize font-oswald text-md">
                      Satın Alma Sözleşmesi
                    </span>
                  </label>
                </div>
                {purchaseErr.isPurchaseAgree && (
                  <FormErrors
                    errorMessage={purchaseErr.isPurchaseAgree?.message}
                  />
                )}
                <hr />
                <div className="p-5">
                  <ul className="ml-5 list-disc">
                    {productInfo.map((info) => (
                      <li className="text-sm font-opensans" key={info.name}>
                        {info.desc}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Transition appear show={isOpen}>
        <Dialog
          open={isOpen}
          onClose={closeModal}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="min-h-screen px-4 text-center bg-opacity-50 bg-coolGray-700">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle h-96" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-sm shadow-xl ring-2 ring-coolGray-600">
                <Dialog.Title className="flex items-center justify-start h-12 pl-4 text-2xl font-medium bg-red-900 text-coolGray-200">
                  Uyarı
                </Dialog.Title>
                <div className="flex flex-col items-center justify-center p-6">
                  <p className="mt-6 mb-10 text-2xl font-semibold text-coolGray-500 font-opensans">
                    {epinValidResult?.eapi_EpinGetValid.ResultCode?.Description}
                  </p>

                  <button
                    type="button"
                    className="inline-flex justify-center px-6 py-2 ml-2 text-lg font-medium transition duration-300 ease-in-out bg-red-700 border border-transparent rounded-sm text-coolGray-200 hover:bg-coolGray-50 hover:text-red-700 hover:border-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={closeModal}
                  >
                    Geri
                  </button>
                  <p className="w-full mt-6 mb-1 text-sm text-center">
                    Epin yükleme ve satın alma ile ilgili herhangi bir sorununuz
                    varsa, lütfen{" "}
                    <a
                      href="https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback"
                      rel="noopener noreferrer"
                      className="font-semibold text-blue-600 transition duration-300 ease-in-out hover:text-blue-800 hover:underline"
                    >
                      destek
                    </a>{" "}
                    bölümünden bize bilet gönderin.
                  </p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
