import { ChevronDownIcon, ClockIcon, EyeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNewsPostGetLists } from "../../../../hooks/use-news-post-list";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Ts2NewsHeader } from "../common/ts2-news-header";

export const Ts2News = () => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [headerCategory, setHeaderCategory] = useState(0);
  const {data:newsPostLists, loading:newsPostLoading}=useNewsPostGetLists(
    page,
    rowsPerPage,
    1,
    Number(headerCategory),
    i18n.language,
  )

  const handleNextPage=()=>{
    setRowsPerPage(rowsPerPage+rowsPerPage)
    console.log(page)
    console.log(newsPostLists?.eapi_GetAllNewsPosts.Pagination?.totalPages)
  }

  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif pb-16 h-full"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: 'rgb(28,29,29)',
          overflow: "hidden"
        }}
      >
        {/* wrapper */}
        <Ts2NewsHeader/>
        {/* 뉴스 리스트  */}
        <section className="relative z-20 grid w-11/12 grid-flow-row grid-cols-1 gap-6 xl:max-w-screen-2lg lg:grid-cols-3 md:grid-cols-2 xl:w-full">
          {newsPostLoading
            ? ('Loading ...')
            : (
              newsPostLists?.eapi_GetAllNewsPosts.newsPostEntities?.map((item,index) =>(
                <div
                  key={item.regDate}
                  className={`relative transition-all duration-500 ease-in-out bg-cover bg-no-repeat border-4 bg-center
                  outline border-coolGray-800 h-56 text-md text-coolGray-100 group ${
                    item.category === 1
                      ? `hover:border-red-600`
                      : item.category === 2 && `hover:border-amber-600`
                  }`}
                  style={{ backgroundImage: `url('${item.thumbnailMain}')` }}
                >
                  <Link to={`/game/ts2/details?id=${item.NID}`}>
                    <p className="absolute right-2 top-2">
                  <span
                    className={`px-3 text-xs rounded-lg text-shadow ${
                      item.category === 2
                        ? `text-amber-100 bg-amber-600 ring-1 ring-amber-800`
                        : item.category === 1 &&
                        `text-red-100 bg-red-600 ring-1 ring-red-800`
                    } uppercase`}
                  >
                    {item.category === 1 ? (t('common.news')) : (t('common.update'))}
                  </span>
                    </p>
                    <div className="absolute bottom-0 left-0 w-full p-3 transition-all duration-500 ease-in-out bg-black bg-opacity-50 group-hover:bg-opacity-80">
                      <p className="font-bold text-md text-coolGray-200"
                         style={{textOverflow:'ellipsis',overflow:'hidden', whiteSpace:'nowrap', width:'298px' }}
                      >
                        {item.title}
                      </p>
                      <p className={`my-2 text-xs text-coolGray-200 ${item.subtitle !=='' ? `block` : `hidden`}`}
                         style={{textOverflow:'ellipsis',overflow:'hidden',
                           whiteSpace:'normal', width:'98%', lineHeight:'1.4',
                           height:'2.4em', wordWrap:'break-word', textAlign:'left' }}
                      >
                        {item.subtitle}
                      </p>
                      <p className="flex items-center justify-start">
                        <ClockIcon className="w-4 h-4 mr-1 text-coolGray-400" />
                        <span className="text-xs text-coolGray-400">
                          <Moment local locale={i18n.language} format="ll">{item.regDate}</Moment>
                        </span>
                        <EyeIcon className="w-4 h-4 ml-2 text-coolGray-400 mr-1"/><NumberFormat
                        value={
                          item.viewCount
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        className={`text-xs text-coolGray-400`}
                      />
                      </p>
                    </div>
                  </Link>
                </div>
              ))
            )}
        </section>
        {Number(newsPostLists?.eapi_GetAllNewsPosts.Pagination?.totalPages!) > Number(page+1)
          ? (
          <button className={`mt-6 mb-6 z-50`} onClick={handleNextPage}>
            <div className={`ring-2 px-6 py-2 flex items-center justify-center h-16 
                font-bold text-xl ring-coolGray-500 shadow-md bg-coolGray-900 text-coolGray-300 transition duration-100 ease-in-out group hover:bg-coolGray-300 hover:text-coolGray-900`}>
              SEE MORE <ChevronDownIcon className="w-6 h-6 mx-2 text-coolGray-300 group-hover:text-coolGray-900" />
            </div>
        </button>)
          : null
        }
      </section>
    </>
  );
};
