import { useEffect } from "react";
import { useLocation } from "react-router";

interface IPortalProps {
  classes: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const PortalChildren: React.FC<IPortalProps> = ({
  classes,
  children
}) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <main
      className={`flex justify-center w-full font-opensans ${
        classes === "portal"
          ? `bg-coolGray-200 portal-main-bg`
          : `bg-main-color`
      }`}
    >
      <section
        className={` ${
          classes === "portal"
            ? `w-full bg-coolGray-100 border-l border-r border-gray-300 max-w-screen-2xl rl:shadow-xl`
            : `flex justify-start items-center flex-col w-full max-w-screen-5xl`
        }`}
        style={{ minHeight: "calc(100vh - 13rem - 24px)" }}
      >
        {children}
      </section>
    </main>
  );
};
