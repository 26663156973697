import React, {useState} from "react";
import { MyProfileFormBtn } from "../../../components/general/my-profile-form-btn";
import { MyProfileFormErrorTxt } from "../../../components/general/my-profile-form-error-txt";
import { CommonLargeBtn } from "../../../components/general/common-large-btn";
import { useMyProfile } from "../../../hooks/use-my-profile";
import Moment from "react-moment";
import { TrashIcon } from "@heroicons/react/solid";
import { Transition, Dialog } from "@headlessui/react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  updateProfileDeleteRecoverEmail,
  updateProfileDeleteRecoverEmailVariables,
} from "../../../__generated__/updateProfileDeleteRecoverEmail";
import { useTranslation } from "react-i18next";

const UPDATE_PROFILE_DELETE_RECOVER_EMAIL_MUTATION = gql`
  mutation updateProfileDeleteRecoverEmail(
    $input: UpdateProfileRecoverEmailInput!
  ) {
    eapi_UpdateProfileRecoverEmail(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

export const MyProfileDetail = () => {
  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  const userData = userProfile?.eapi_MyProfile;
  const client = useApolloClient();
  const { t, i18n } = useTranslation();
  const onCompleted = (data: updateProfileDeleteRecoverEmail) => {
    const {
      eapi_UpdateProfileRecoverEmail: { ResultCode }
    } = data;
    //console.log(ResultCode?.Description);
    if (ResultCode?.Code === "10000" && userProfile?.eapi_MyProfile.email) {
      client.writeFragment({
        id: `UserInfoEntity:${userProfile.eapi_MyProfile.email}`,
        fragment: gql`
          fragment DeleteSecondEmail on UserInfoEntity {
            isVerifySecondEmail
            recoverEmail
          }
        `,
        data: {
          isVerifySecondEmail: false,
          recoverEmail: ""
        }
      });
    }
    //실패
    else {
      //
      //console.log(ResultCode?.Description);
    }
  };
  const [updateProfileDeleteRecoverEmail] = useMutation<
    updateProfileDeleteRecoverEmail,
    updateProfileDeleteRecoverEmailVariables
  >(UPDATE_PROFILE_DELETE_RECOVER_EMAIL_MUTATION, {
    onCompleted
  });
  let [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const deleteModal = () => {
    if (userProfile?.eapi_MyProfile.recoverEmail) {
      updateProfileDeleteRecoverEmail({
        variables: {
          input: {
            recoverEmail: ""
          }
        }
      });
    }
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <section className="relative w-full lg:w-9/12">
        <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
          {t("account_info.side.acc_detail")}
        </h1>
        <hr className="border border-gray-500" />
        {/* */}
        <section className="flex flex-col items-center justify-start w-full">
          <table className="w-full my-6 border-collapse">
            <tbody>
              <tr className="border-t border-b border-coolGray-300">
                <td className="w-3/12 text-center bg-coolGray-200 text-sm md:text-md">
                  {t("common.email")}
                </td>
                <td className="w-9/12">
                  <div className="flex items-start justify-center flex-col p-6 md:flex-row md:justify-between">
                    <div className="w-full mb-2 md:w-3/5 md:mb-0">
                      <p>{userData?.email}</p>
                    </div>
                    <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                      {userData?.isVerifyEmail ? (
                        <MyProfileFormErrorTxt
                          textColor="text-coolGray-500"
                          errorMsg="Verified"
                        />
                      ) : (
                        <Link
                          to={{
                            pathname: "/resend-code",
                            state: { referrer: "/my-profile/detail" }
                          }}
                        >
                          <MyProfileFormErrorTxt
                            textColor="text-red-600"
                            errorMsg="Need Verification"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-b border-coolGray-300">
                <td className="w-3/12 text-center capitalize bg-coolGray-200 text-sm md:text-md">
                  {t("account_detail.reg_email")}
                </td>
                <td className="w-9/12">
                  <div className="flex items-start justify-center flex-col p-6 md:flex-row md:justify-between">
                    {userProfileLoading && "Loading ..."}
                    {(!userProfileLoading &&
                      !userProfile?.eapi_MyProfile?.recoverEmail) ||
                    userProfile?.eapi_MyProfile?.recoverEmail === "" ? (
                      !userProfile?.eapi_MyProfile?.isVerifySecondEmail && (
                        <>
                          {/*보조이메일이 없고 인증도 안됐을 경우*/}
                          <div className="w-full mb-2 md:w-3/5 md:mb-0">
                            <p>{t("account_detail.reg_exp01")}</p>
                            <p className="text-xs text-coolGray-500">
                              * {t("account_detail.reg_exp02")}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                            <MyProfileFormBtn
                              msg={`${t("account_detail.add_email")}`}
                              url={`/my-profile/detail/add-email`}
                              size={"w-44 max-w-2xl py-2"}
                            />
                          </div>
                        </>
                      )
                    ) : !userProfile?.eapi_MyProfile?.isVerifySecondEmail ? (
                      <>
                        {/*보조이메일이 등록됐지만 인증되지 않았을때*/}
                        <div className="w-full mb-2 md:w-3/5 md:mb-0">
                          <p className="flex items-center justify-start w-full">
                            {userProfile?.eapi_MyProfile?.recoverEmail}
                            <TrashIcon
                              className="w-5 h-5 transition duration-300 ease-in-out cursor-pointer text-coolGray-400 hover:text-coolGray-700"
                              onClick={openModal}
                            />
                          </p>
                          <p className="text-xs text-coolGray-500">
                            * {t("account_detail.reg_exp03")}
                          </p>
                        </div>
                        <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                          <MyProfileFormBtn
                            msg={`${t("account_detail.resend_code")}`}
                            url={`/my-profile/detail/resend-code`}
                            size={"w-44 max-w-2xl py-2 capitalize"}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {/*보조이메일이 등록 되었을때*/}
                        <div className="w-full mb-2 md:w-3/5 md:mb-0">
                          <p className="flex items-center justify-start w-full">
                            {userData?.recoverEmail}
                            <TrashIcon
                              className="w-5 h-5 transition duration-300 ease-in-out cursor-pointer text-coolGray-400 hover:text-coolGray-700"
                              onClick={openModal}
                            />
                          </p>
                        </div>
                        <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                          <MyProfileFormErrorTxt
                            textColor="text-coolGray-500 capitalize"
                            errorMsg={t("common.verified")}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </td>
              </tr>
              <tr className="border-b border-coolGray-300">
                <td className="w-3/12 text-center
                capitalize bg-coolGray-200 text-sm md:text-md">
                  {t("account_detail.forum_name")}
                </td>
                <td className="w-9/12">
                  <div className="flex items-start justify-center flex-col p-6 md:flex-row md:justify-between">
                    <div className="w-full md:w-1/2">{userData?.nickName}</div>
                    <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                      <MyProfileFormErrorTxt
                        textColor="text-coolGray-500"
                        errorMsg={t("common.can_chg")}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="border-b border-coolGray-300">
                <td className="w-3/12 text-center bg-coolGray-200 text-sm md:text-md">
                  {t("common.password")}
                </td>
                <td className="w-9/12">
                  <div className="flex items-start justify-center flex-col p-6 md:flex-row md:justify-between">
                    <div className="w-full text-sm capitalize text-coolGray-500 mb-2 md:mb-0 md:w-1/2 md:mt-2">
                      {t("account_detail.lat_chg")}:
                      <span className="ml-2">
                        <Moment format="lll" locale={i18n.language}>
                          {userData?.pwChgDate}
                        </Moment>
                      </span>
                    </div>
                    <div className="w-full flex items-center justify-start md:w-2/5 md:justify-end">
                      <MyProfileFormBtn
                        msg={t("account_info.main_cont.chg_pass")}
                        url={"/my-profile/detail/change-password"}
                        size={"w-44 max-w-2xl py-2 capitalize"}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <CommonLargeBtn
            msg={t("common.go_back")}
            url={"/my-profile/info"}
            size={`w-64 max-w-3xl text-coolGray-100 text-coolGray-700 capitalize`}
          />
        </section>
        {/* */}
        <Transition appear show={isOpen}>
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="fixed inset-0 z-50 overflow-y-auto"
          >
            <div className="min-h-screen px-4 text-center bg-opacity-50 bg-coolGray-700">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block align-middle h-96"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-11/12 max-w-sm p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
                  <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                    {t("add_email.pop_title")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t("add_email.pop_desc")}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center w-24 px-4 py-2 text-sm font-medium border border-transparent rounded-md text-coolGray-900 bg-coolGray-200 hover:bg-coolGray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-coolGray-500"
                      onClick={closeModal}
                    >
                      {t("common.cancel")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-24 px-4 py-2 ml-2 text-sm font-medium text-red-900 bg-red-200 border border-transparent rounded-md hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      onClick={deleteModal}
                    >
                      {t("common.delete")}
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </section>
    </>
  );
};
