import {useHistory} from "react-router-dom";
import {authTokenVar, client, isLoggedInVar} from "../../apollo";
import {LOCALSTORAGE_TOKEN} from "../../constants";
import { gql, useMutation } from "@apollo/client";
import {
  removeToken,
  removeTokenVariables,
} from "../../__generated__/removeToken";
import { useMyProfile } from "../../hooks/use-my-profile";

const REMOVE_REFRESH_TOKEN = gql`
  mutation removeToken($input: RemoveTokenInput!) {
    eapi_RemoveToken(input: $input) {
      ActionInfo {
        ActionName
        Version
        Mode
      }
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;
export const SignOut = () => {
  const { data: profileData, loading: profileLoading } = useMyProfile();
  const history = useHistory();
  const signOutHandler = () => {
    authTokenVar("");
    isLoggedInVar(false);
    client.cache.gc();
    //redirect
    history.push("/");
    window.location.reload();
  };
  if (!profileLoading && !profileData?.eapi_MyProfile.email) {
    signOutHandler();
  }
  const onRemoveTokenCompleted = (data: removeToken) => {
    //console.log("SignOut ", data);
    signOutHandler();
  };
  const [removeToken, { data: removeTokenData, loading: removeTokenLoading }] =
    useMutation<removeToken, removeTokenVariables>(REMOVE_REFRESH_TOKEN, {
      onCompleted: onRemoveTokenCompleted
    });

  const delCookie = (name: string) => {
    localStorage.removeItem(name);
  };

  removeToken({
    variables: {
      input: {
        email: profileData?.eapi_MyProfile.email!
      }
    }
  });

  delCookie(LOCALSTORAGE_TOKEN);
  delCookie("go-email");

  return (
    <div className="pt-4 pl-6 text-coolGray-500">
      {profileLoading
        ? "Profile data Loading ..."
        : removeTokenLoading
        ? "Token data Loading ..."
        : "Redirect main page"}
    </div>
  );
};
