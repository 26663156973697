import React from "react";
import { useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import { NotFound } from "../error/404";
import { FindPassword } from "./join/find-password";
import { PortalChildren } from "../../components/portal-children";

export const FindAccount = () => {
  let { id }: any = useParams();
  const returnParamValue = (param: any) => {
    //console.log(param);
    if (param === "find-password") {
      return (
        <>
          <FindPassword />
        </>
      );
    } else {
      return <NotFound />;
    }
  };

  return (
    <PortalChildren classes="portal">
      <div className="flex items-start justify-center w-full py-10 bg-gray-100">
        <Helmet>
          <title>Create Account :: Genius Orc</title>
        </Helmet>
        {returnParamValue(id)}
      </div>
    </PortalChildren>
  );
};

