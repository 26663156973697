import smokeImg from "../../../../assets/game/ts2/images/bg/smoke.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import krLogo from "../../../../assets/portal/images/karahan_logo.png";
import biLogo from "../../../../assets/portal/images/bi_logo.png";
import { ChevronDownIcon, ClockIcon, EyeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNewsPostGetLists } from "../../../../hooks/use-news-post-list";
import Moment from "react-moment";
import NumberFormat from "react-number-format";

export const Ts2NewsHeader:React.FC=()=>{
  const [isConCode, setIsConCode] = useState("");
  const [userIP, setUserIP] = useState("");
  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    const userIP: string = localStorage.getItem("userIP") || "";
    setIsConCode(conCode);
    setUserIP(userIP);
  }, []);
  const { t, i18n } = useTranslation();
  return(
    <>
    {/* wrapper */}
  <div
  style={{
    backgroundImage: `url(${smokeImg})`,
    height: "480px",
    top: "440px"
  }}
  className="absolute z-10 w-full smoke_wrapper"
  />

  <div className="absolute z-0 top-6 md:-top-10 lg:-top-14" style={{ marginLeft: window.innerWidth > 1196 ? "580px" :"0", overflow:"hidden" }}>
    <div className="absolute w-full h-full bottom-0 right-0 lg:right-44 lg:bottom-56">
      <div className="sparks">
        <div className="spark_1"/>
        <div className="spark_2"/>
        <div className="spark_3"/>
        <div className="spark_4 spark-big"/>
        <div className="spark_5 spark-big"/>
      </div>
    </div>
    <img src={charBg} alt="" className="" />
  </div>
  {/*캐릭터 */}
  <section
    className="relative z-0 flex justify-center items-end w-full xl:max-w-screen-2lg xl:justify-start"
    style={{ height: window.innerWidth > 617 ? "36rem" : "24rem" }}
  >
    <div className="absolute z-30 w-11/12 lg:w-6/12" style={{ bottom: "40px" }}>
      <div className="flex items-center justify-center mb-4 border-b lg:justify-start">
        <p className="text-5xl capitalize border-coolGray-200 text-coolGray-100 font-oswald text-shadow md:text-6xl">
          {t("common.news")}
        </p>
        {isConCode === "TR" ? (
          <img
            src={krLogo}
            alt="Karahan logo"
            className="h-20 -ml-8 md:h-28 md:-ml-6 md:mb-6"
          />
        ) : i18n.language === "tr" ? (
          <img
            src={krLogo}
            alt="Karahan logo"
            className="h-20 -ml-8 md:h-28 md:-ml-6 md:mb-6"
          />
        ) : (
          <img src={biLogo} alt="ts2 logo" className="mb-1 h-16 md:h-28 md:ml-4 md:mb-6" />
        )}
      </div>
      <p className="pt-3 text-md text-coolGray-100 text-shadow text-center lg:text-left">
        {t("news_page.desc")}
      </p>
    </div>
  </section>
    </>
  )
}
