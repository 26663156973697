import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../apollo";
import { myProfileQuery } from "../__generated__/myProfileQuery";
const MYPROFILE_QUERY = gql`
  query myProfileQuery {
    eapi_MyProfile {
      email
      recoverEmail
      nickName
      userIP
      countryCode
      countryName
      city
      birthDate
      newsletter
      isVerifyEmail
      profilePhoto
      language
      userType
      lastCheckInDate
      updatedDate
      pwChgDate
      regDate
      isVerifySecondEmail
    }
  }
`;

export const useMyProfile = () => {
  const isLoggedIn: boolean = useReactiveVar(isLoggedInVar);
  //console.log("isLoggedIn : ", isLoggedIn);
  return useQuery<myProfileQuery>(MYPROFILE_QUERY, { skip: !isLoggedIn });
};
