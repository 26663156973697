import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { GlobeAltIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface ILocaleValue {
  id: number;
  lang: string;
  name: string;
}

export const LanguageSelectBar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  let langValue: ILocaleValue;

  const lang = [
    { id: 1, name: t("language_en"), lang: "en" },
    { id: 2, name: t("language_tr"), lang: "tr" }
  ];

  const [isConCode, setIsConCode] = useState("");
  const [langSelect, setLangSelect] = useState(lang[0]);

  const saveData = (e: ILocaleValue) => {
    const userObj = { e };
    window.localStorage.setItem("language", JSON.stringify(userObj));
  };

  const handleChangeLanguage = (e: ILocaleValue) => {
    //console.log(e);
    i18n.changeLanguage(e.lang);
    setLangSelect(e);
    saveData(e);
  };

  useEffect(() => {
    const langSel = location.search.split("lang=")[1];
    const initLs = window.localStorage.getItem("language");
    const initIp = window.localStorage.getItem("country_code");

    //
    if (initLs) {
      setLangSelect(JSON.parse(initLs).e);
      i18n.changeLanguage(JSON.parse(initLs).e.lang);
      saveData(JSON.parse(initLs).e);
    } else {
      //없을경우
      //아이피 체크 후 언어설정
      if (initIp === "TR") {
        setLangSelect(lang[1]);
        i18n.changeLanguage("tr");
        saveData(lang[1]);
      } else {
        setLangSelect(lang[0]);
        i18n.changeLanguage("en");
        saveData(lang[0]);
      }
    }
    //
    if (langSel === "en") {
      setLangSelect(lang[0]);
      i18n.changeLanguage("en");
      saveData(lang[0]);
    } else if (langSel === "tr") {
      setLangSelect(lang[1]);
      i18n.changeLanguage("tr");
      saveData(lang[1]);
    }
  }, []);

  return (
    <Listbox value={langSelect} onChange={handleChangeLanguage}>
      <div className="relative w-full">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-xs text-left rounded-sm shadow-xl cursor-default bg-coolGray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
          <span className="flex items-center justify-start text-gray-100 truncate">
            <GlobeAltIcon className="w-5 h-5 mr-1" />
            {langSelect.name}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-300"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-xs rounded-sm shadow-xl bg-coolGray-900 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
            {lang.map((language) => (
              <Listbox.Option
                key={language.id}
                className={({ active }) =>
                  `${
                    active
                      ? "text-rose-800 bg-coolGray-300"
                      : "text-coolGray-100"
                  }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={language}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } block truncate`}
                    >
                      {language.name}
                    </span>
                    {language.id === langSelect.id ? (
                      <span
                        className={`text-rose-400 absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
