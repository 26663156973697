import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { FormErrors } from "components/general/form-errors";
import { gql, useMutation } from "@apollo/client";
import { ButtonCommon } from "components/general/btn-common";
import { Helmet } from "react-helmet";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { PortalChildren } from "../../../components/portal-children";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  findPassMutation,
  findPassMutationVariables
} from "__generated__/findPassMutation";
interface ILoginForm {
  email: string;
}

interface ILocationProps {
  search: string;
  state: {
    referrer: string | null;
  };
}

const FIND_PASS_MUTATION = gql`
  mutation findPassMutation($input: FindPassEmailInput!) {
    eapi_FindPass(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

export const FindPassword = () => {
  const reSK = process.env.REACT_APP_RECAPTCHA;
  const location: ILocationProps = useLocation();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isCaptcha, setIsCaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const history = useHistory();
  const valFindPass = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please check your email address."
      )
      .email("Email is invalid")
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ILoginForm>({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(valFindPass)
  });

  const onCompleted = (data: findPassMutation) => {
    const {
      eapi_FindPass: { ResultCode }
    } = data;
    console.log(data);
    if (ResultCode?.Code === "10000") {
    }
  };
  const [findPassMutation, { loading, data: findPassResult }] = useMutation<
    findPassMutation,
    findPassMutationVariables
  >(FIND_PASS_MUTATION, {
    onCompleted
  });

  const onSubmit: SubmitHandler<ILoginForm> = (data) => {
    console.log(data);
    if (!loading) {
      findPassMutation({
        variables: {
          input: {
            email: data.email
          }
        }
      });
    }
  };

  function onReCaptchaChange(value: any) {
    console.log("Captcha value:", value);
    setIsCaptcha(true);
  }
  function onReCaptchaError() {
    setIsCaptcha(false);
  }
  function onReCaptchaExpire() {
    setIsCaptcha(false);
  }
  const onGoBack = () => {
    history.push("/");
  };
  return (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center w-full pt-12 md:pt-28">
        <Helmet>
          <title>Find Password :: Genius Orc</title>
        </Helmet>
        {loading ? (
          <p>Loading...</p>
        ) : findPassResult?.eapi_FindPass?.ResultCode?.Code !== "10000" ? (
          <div className="flex flex-col items-center justify-center w-11/12 sm:w-4/5">
            <h1 className="pb-4 text-3xl text-center capitalize font-oswald sm:pb-10 sm:text-4xl">
              {t("find_pass.title")}
            </h1>
            <hr className="w-full my-2 border border-gray-500" />

            <div className="flex items-center justify-center w-20 h-20 my-5 rounded-full bg-coolGray-200">
              <FontAwesomeIcon
                icon={faPaperPlane}
                className="mr-1 text-5xl text-coolGray-500"
              />
            </div>
            <h1 className="text-3xl font-oswald  text-center sm:text-4xl">{t("find_pass.subtitle")}</h1>
            <p className="mt-4 text-sm">{t("find_pass.desc")}</p>
            <div className="w-full p-1 my-6 bg-white border border-gray-400 md:max-w-xl sm:w-11/12 sm:p-5 sm:my-10">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid w-full gap-3 px-2 mt-5 mb-5"
              >
                <input
                  type="email"
                  placeholder={`${t("common.email")}`}
                  className={`mt-4 form-input w-full outline-none inputStyle ${
                    errors.email ? `border-red-600` : `border-coolGray-300`
                  }`}
                  {...register("email")}
                />
                {errors.email && (
                  <FormErrors errorMessage={errors.email?.message} />
                )}
                <FormErrors
                  errorMessage={
                    "This password reset link is valid for 30 minutes"
                  }
                />
                <div className="flex justify-center my-5 item-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={reSK!}
                    onChange={onReCaptchaChange}
                    onExpired={onReCaptchaError}
                    onErrored={onReCaptchaExpire}
                  />
                </div>
                <ButtonCommon
                  clickEnable={isValid && isCaptcha}
                  loading={false}
                  actionText={`${t("find_pass.snd_email")}`}
                  size={`text-lg hover:bg-purple-800 hover:text-coolGray-100`}
                />
              </form>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-4/5">
            <div className="flex items-center justify-center w-20 h-20 my-8 rounded-full bg-coolGray-200">
              <FontAwesomeIcon
                icon={faPaperPlane}
                className="mr-1 text-5xl text-coolGray-500"
              />
            </div>
            <h1 className="mb-4 text-4xl font-oswald">
              Password Reset Email Sent
            </h1>
            <p className="w-full max-w-lg mt-4 mb-10 text-center text-md">
              We just sent the instructions for completing your password reset
              request. If you don't see it in your Email inbox within the next
              few minutes, please try looking in your spam folder as well.
            </p>
            <ButtonCommon
              clickEnable={true}
              loading={false}
              actionText={`Go to Main`}
              size={`text-lg hover:bg-purple-800 hover:text-coolGray-100`}
              onClick={onGoBack}
            />
          </div>
        )}
      </div>
    </PortalChildren>
  );
};
