import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { JoinAccount } from "../domain/account/join-account";
import { SignIn } from "../domain/account/sign-in";
import { Header } from "../domain/common/header";
import { Footer } from "../domain/common/footer";
import { MyProfile } from "../domain/account/my-profile";
import { ShopPage } from "../domain/payment/shop-page";
import { SupportPage } from "../domain/support/support-page";
import { NewsPage } from "../domain/news/news-page";
import { FindAccount } from "../domain/account/find-account";
import { EmailVerification } from "../domain/email/email-verification";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../apollo";
import { SignOut } from "../domain/account/sign-out";
import { ResendCode } from "../domain/email/resend-code";
import { RecoverEmailVerification } from "../domain/email/recover-email-verification";
import { UnscribeEmail } from "../domain/email/unscribe-email";
import { Ts2HomeRouter } from "../domain/game/ts2/ts2-home-router";
import { MainPage } from "../pages/main-page";
import { PolicyHomeRouter } from "../domain/policy/policy-home-router";
import { ConfirmRecoverPassword } from "../domain/account/confirm-recover-password";
import { useMyProfile } from "hooks/use-my-profile";
import { NotFound } from "domain/error/404";
export const HomeRouter = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { loading: userProfileLoading, data: userProfile } = useMyProfile();

  return (
    <Router>
      <>
        <Helmet>
          <style>{"body { background-color: rgba(63, 63, 70,1); }"}</style>
        </Helmet>
        <Header />
        <Switch>
          <Route
            path="/game/ts2"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Ts2HomeRouter} exact />
                <Route path={`${url}/:id`} children={<Ts2HomeRouter />} />
              </>
            )}
          />
          <Route path="/sign-in" exact>
            <SignIn />
          </Route>
          <Route path="/sign-out" exact>
            <SignOut />
          </Route>
          <Route
            path="/policy"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={PolicyHomeRouter} exact />
                <Route path={`${url}/:id`} children={<PolicyHomeRouter />} />
              </>
            )}
          />
          <Route
            path="/my-profile"
            render={({ match: { url } }) =>
              isLoggedIn ? (
                <>
                  <Route path={`${url}/`} component={MyProfile} exact />
                  <Route path={`${url}/:id`} children={<MyProfile />} />
                </>
              ) : (
                <Redirect
                  to={{
                    pathname: "/sign-in",
                    state: { referrer: "/my-profile" }
                  }}
                />
              )
            }
          />
          <Route
            path="/join"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={JoinAccount} exact />
                <Route path={`${url}/:id`} children={<JoinAccount />} />
              </>
            )}
          />
          <Route
            path="/find"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={FindAccount} exact />
                <Route path={`${url}/:id`} children={<FindAccount />} />
              </>
            )}
          />

          {/*
          <Route
            path="/buyitem"
            render={({ match: { url } }) =>
              isLoggedIn ? (
                <>
                  <Route path={`${url}/`} component={BuyItemPage} exact />
                  <Route path={`${url}/:id`} children={<BuyItemPage />} />
                </>
              ) : (
                <Redirect
                  to={{
                    pathname: "/sign-in",
                    state: { referrer: "/buyitem" }
                  }}
                />
              )
            }
          />


          <Route
            path="/shop"
            render={({ match: { url } }) =>
              isLoggedIn ? (
                <>
                  <Route path={`${url}/`} component={ShopPage} exact />
                  <Route path={`${url}/:id`} children={<ShopPage />} />
                </>
              ) : (
                <Redirect
                  to={{
                    pathname: "/sign-in"
                  }}
                />
              )
            }
          />
  */}
          {userProfileLoading ? (
            "loading..."
          ) : (
            <Route
              path="/shop"
              render={({ match: { url } }) =>
                isLoggedIn ? (
                  <>
                    <Route path={`${url}/`} component={ShopPage} exact />
                    <Route path={`${url}/:id`} children={<ShopPage />} />
                  </>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/sign-in"
                    }}
                  />
                )
              }
            />
          )}
          {/*
          <Route path="/purchase-item" exact>
            {isLoggedIn ? (
              <PurchaseItem />
            ) : (
              <Redirect
                to={{
                  pathname: "/"
                }}
              />
            )}
          </Route>
           */}
          <Route path="/confirm-email">
            <EmailVerification />
          </Route>
          <Route path="/confirm-recover-email" exact>
            <RecoverEmailVerification />
          </Route>
          <Route path="/unsubscribe-email" component={UnscribeEmail} exact />
          <Route path="/resend-code" exact>
            {isLoggedIn ? (
              <ResendCode />
            ) : (
              <Redirect
                to={{
                  pathname: "/sign-in",
                  state: { referrer: "/resend-code" }
                }}
              />
            )}
          </Route>
          <Route
            path="/confirm-recover-password"
            component={ConfirmRecoverPassword}
            exact
          />
          <Route path="/support" component={SupportPage} exact />
          <Route path="/news" component={NewsPage} exact />
          {/*
          <Route path="/chg-pass" component={AccountChgPass} exact />

              <Route path="/games" component={GamesPage} exact />
          */}
          <Route path="/" exact>
            {isLoggedIn ? (
              userProfileLoading ? (
                "Loading ..."
              ) : userProfile?.eapi_MyProfile.isVerifyEmail ? (
                <MainPage />
              ) : (
                <Redirect
                  to={{
                    pathname: "/resend-code"
                  }}
                />
              )
            ) : (
              <MainPage />
            )}
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </>
    </Router>
  );
};
