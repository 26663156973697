import {Helmet} from "react-helmet";
import React, { useState, MouseEvent } from "react";
import { BadgeCheckIcon, CakeIcon } from "@heroicons/react/solid";
import { CommonLargeBtn } from "../../components/general/common-large-btn";
import { useHistory, useLocation } from "react-router-dom";
import { BtnSmallLoading } from "../../components/general/btn-small-loading";
import { gql, useMutation } from "@apollo/client";
import {
  resendMainVerifyEmail,
  resendMainVerifyEmailVariables,
} from "../../__generated__/resendMainVerifyEmail";
import { useMyProfile } from "../../hooks/use-my-profile";
import { PortalChildren } from "../../components/portal-children";
import { useTranslation } from "react-i18next";
interface ILocationProps {
  state: {
    referrer: string | null | undefined;
  };
}

const RESEND_MAIN_VERIFY_MUTATION = gql`
  mutation resendMainVerifyEmail($input: ResendCodeInput!) {
    eapi_ResendVerifyEmail(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      RegDate
    }
  }
`;

export const ResendCode: React.FC = () => {
 
  const { t } = useTranslation();
  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();
  const location: ILocationProps = useLocation();
  if (!userProfileLoading && userProfile?.eapi_MyProfile.isVerifyEmail) {
    history.push("/");
  }
  const onCompleted = (data: resendMainVerifyEmail) => {
    //console.log("oncompleted: ", data);
    const code = data.eapi_ResendVerifyEmail.ResultCode?.Code;
    if (code) {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 180000);
    }
  };
  const [resendMainVerifyEmail, { loading, data: resendVerifyEmailResult }] =
    useMutation<resendMainVerifyEmail, resendMainVerifyEmailVariables>(
      RESEND_MAIN_VERIFY_MUTATION,
      { onCompleted }
    );

  const onClickMainSendEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    //console.log("event: ", event);
    if (!loading) {
      resendMainVerifyEmail({
        variables: {
          input: {
            email: userProfile?.eapi_MyProfile.email!,
            nickName: userProfile?.eapi_MyProfile.nickName
          }
        }
      });
    }
  };
  return (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center bg-gray-100 pt-36">
        <div className="flex flex-col items-center justify-center w-3/5">
          {userProfileLoading ? (
            <p>{t("common.loading")}</p>
          ) : (
            <>
              <h1 className="text-5xl text-center font-oswald">
                {t("join_complete.btn_01")}
              </h1>
              <hr className="w-full my-10 border border-gray-500" />
              <article className="flex flex-col items-center justify-center w-full">
                <div className="relative flex items-center justify-center mb-10">
                  <div className="flex items-center justify-center w-24 h-24 rounded-full bg-coolGray-300">
                    <CakeIcon className="w-20 h-20 text-coolGray-500" />
                  </div>
                  <div className="absolute top-12 -right-3">
                    <BadgeCheckIcon className="w-14 h-14 text-warmGray-400" />
                  </div>
                </div>
                <p className="mb-2 text-3xl text-center text-purple-900 font-oswald">
                  {t("resend_email.ex_01")
                    .split("\n")
                    .map((line) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                </p>
              </article>
              <section
                className={
                  "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
                }
              >
                <p className={"text-sm text-center"}>
                  {t("resend_email.ex_02")
                    .split("\n")
                    .map((line) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                </p>
                <div className="w-3/5 py-6 my-8 text-center text-purple-900 bg-gray-200">
                  <p className="font-semibold">
                    {!userProfileLoading && userProfile?.eapi_MyProfile.email}
                  </p>
                </div>
                <p className="mb-6 text-sm text-gray-600">
                  ({t("join_complete.ex_01")})
                </p>
                <BtnSmallLoading
                  actionText={`${
                    isClicked ? `Take 3 min later` : `Resend Verification Email`
                  }`}
                  loading={loading}
                  enabled={isClicked}
                  size={"w-1/3 max-w-2xl h-10 my-4"}
                  onClick={onClickMainSendEmail}
                />

                {!loading &&
                resendVerifyEmailResult?.eapi_ResendVerifyEmail.ResultCode
                  ?.Code === "10000" ? (
                  <p className="mb-5 -mt-2 text-xs text-red-600">
                    {t("join_complete.ex_02")}
                  </p>
                ) : (
                  <p className="mb-5 -mt-2 text-xs text-red-600">
                    {
                      resendVerifyEmailResult?.eapi_ResendVerifyEmail.ResultCode
                        ?.Description
                    }
                  </p>
                )}
                <CommonLargeBtn
                  msg={`${t("common.go_back")}`}
                  url={"/"}
                  size={
                    "w-1/3 max-w-2xl text-xl bg-coolGray-800 text-coolGray-100 hover:bg-coolGray-200 hover:text-coolGray-600"
                  }
                />
              </section>
            </>
          )}
        </div>
      </div>
    </PortalChildren>
  );
};
