import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  faStore,
  faTicketAlt,
  faNewspaper,
  faIdBadge,
  faSignOutAlt,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLoggedInVar } from "../../../apollo";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";

interface IProps {
  onClick?: () => void;
}

export const NavMobileMenus: React.FC<IProps> = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  const urlList = [
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/en/support/home"
    },
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/tr/support/home"
    }
  ];
  const isLoggedIn: boolean = useReactiveVar(isLoggedInVar);
  return (
    <ul className="flex flex-col items-start w-full py-10 space-y-6 text-white uppercase font-oswald justify-items-center md:max-w-max md:items-center">
      <li className="px-10 text-xl list-inline-text group hover:text-red-600 text-shadow">
        <NavLink to="/shop" onClick={onClick}>
          <FontAwesomeIcon icon={faStore} className="mr-2 text-gray-300" />
          {t("gnb.coin_shop")}
        </NavLink>
      </li>
      <li className="px-10 text-xl list-inline-text hover:text-red-600 text-shadow">
        <NavLink to="/game/ts2/news" onClick={onClick}>
          <FontAwesomeIcon icon={faNewspaper} className="mr-3 text-gray-300" />
          {t("common.news")}
        </NavLink>
      </li>
      <li className="px-10 text-xl list-inline-text hover:text-red-600 text-shadow">
        <a
          href={
            i18n.language === "tr"
              ? `https://goe.freshdesk.com/tr/support/discussions`
              : `https://goe.freshdesk.com/en/support/discussions`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTable} className="mr-3 text-gray-300 " />
          {t("common.forum")}
        </a>
      </li>
      <li className="px-10 text-xl list-inline-text hover:text-red-600 text-shadow">
        {isLoggedIn
          ? (
            i18n.language==="tr" ? (
              <a
                href={urlList[1].login}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("common.support")}
              </a>
            ) : (
              <a
                href={urlList[0].login}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTicketAlt} className="mr-3 text-gray-300 " />
                {t("common.support")}
              </a>
            )
          )
          : i18n.language === "tr" ? (
            <a href={urlList[1].logout} target="_blank" rel="noopener noreferrer">
              {t("common.support")}
            </a>
          )
            : (
              <a href={urlList[0].logout} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTicketAlt} className="mr-3 text-gray-300 " />
                {t("common.support")}
          </a>
            )
        }
      </li>
      {!isLoggedIn ? (
        <>
          <hr className="self-center w-11/12 border-coolGray-900" />
          <li>
            <NavLink
              className="px-10 text-xl list-inline-text hover:text-red-700 text-shadow"
              to="/join"
              onClick={onClick}
            >
              {t("gnb.create_account")}
            </NavLink>
          </li>
          <li className="px-10 text-xs list-inline-text text-coolGray-300 ">
            {t("common.or")}
          </li>
          <li>
            <NavLink
              className="px-10 text-xl list-inline-text hover:text-red-700 text-shadow"
              to="/sign-in"
              onClick={onClick}
            >
              <span className="px-10 py-2 rounded-md bg-coolGray-700 hover:bg-coolGray-600">
                {t("gnb.sign_in")}
              </span>
            </NavLink>
          </li>
        </>
      ) : (
        <>
          <li className="px-10 text-xl list-inline-text hover:text-red-600 text-shadow">
            <NavLink to="/my-profile/info" onClick={onClick}>
              <FontAwesomeIcon
                icon={faIdBadge}
                className="ml-1 mr-4 text-gray-300 "
              />
              My profile
            </NavLink>
          </li>
          <li className="px-10 text-xl list-inline-text hover:text-red-600 text-shadow">
            <NavLink to="/sign-out" onClick={onClick}>
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="ml-1 mr-3 text-gray-300 "
              />
              Sign Out
            </NavLink>
          </li>
        </>
      )}
    </ul>
  );
};
