import React from "react";
import {
  ChevronRightIcon,
  IdentificationIcon,
  UserAddIcon,
  LinkIcon,
  LoginIcon,
  CashIcon,
} from "@heroicons/react/outline";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function userAddIcon({ active }: any) {
  return (
    <UserAddIcon
      className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${
        active ? "text-purple-700" : ""
      }`}
    />
  );
}

function idIcon({ active }: any) {
  return (
    <IdentificationIcon
      className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${
        active ? "text-purple-700" : ""
      }`}
    />
  );
}
function loginIcon({ active }: any) {
  return (
    <LoginIcon
      className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${
        active ? "text-purple-700" : ""
      }`}
    />
  );
}

function linkIcon({ active }: any) {
  return (
    <LinkIcon
      className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${
        active ? "text-purple-700" : ""
      }`}
    />
  );
}
/*
function userRemoveIcon({active}:any) {
    return (
        <UserRemoveIcon className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${active?'text-purple-700':''}`} />
    );
}
*/
function cashIcon({ active }: any) {
  return (
    <CashIcon
      className={`myProfileIconStyle text-gray-600 group-hover:text-purple-700 ${
        active ? "text-purple-700" : ""
      }`}
    />
  );
}
/*{name: "Delete Account",href: "/my-profile/deletion",icon: userRemoveIcon, active: false} */

export const MyProfileSideBar = () => {
  let location = useLocation();
  const { t } = useTranslation();
  //console.log(location.pathname);
  const AccountMenuList = [
    {
      name: `${t("account_info.side.acc_info")}`,
      href: "/my-profile/info",
      icon: userAddIcon,
      active: false
    },
    {
      name: `${t("account_info.side.acc_detail")}`,
      href: "/my-profile/detail",
      icon: idIcon,
      active: false
    },
    {
      name: `${t("account_info.side.log_his")}`,
      href: "/my-profile/history",
      icon: loginIcon,
      active: false
    },
    {
      name: `${t("account_info.side.tra_his")}`,
      href: "/my-profile/payment",
      icon: cashIcon,
      active: false
    }
    /*
  {
    name: "Link Account",
    href: "/my-profile/social",
    icon: linkIcon,
    active: false,
  },
  */
  ];
  return (
    <section className="w-full lg:w-3/12">
      {/* */}
      <div className="mt-8 lg:mt-44">
        <hr className="border border-gray-500" />
        <ul>
          {AccountMenuList.map((item) => (
            <li key={item.name}>
              {location.pathname === item.href
                ? (item.active = true)
                : location.pathname.includes(item.href)
                ? (item.active = true)
                : (item.active = false)}
              <NavLink
                to={`${item.href}`}
                className={`group flex justify-between items-center border-b px-4 py-2 ${
                  item.active ? "bg-purple-200" : ""
                }`}
              >
                <div className={`flex justify-start items-center`}>
                  <item.icon aria-hidden="true" active={item.active} />
                  <p
                    className={`${
                      item.active ? "text-purple-700" : "text-gray-600"
                    } ml-3 text-md font-oswald group-hover:text-purple-700 transition duration-300 ease-in-out capitalize`}
                  >
                    {item.name}
                  </p>
                </div>
                <div className="">
                  <ChevronRightIcon
                    className={`${
                      item.active ? "text-purple-700" : "text-gray-200"
                    } w-6 h-6  transition duration-300 ease-in-out group-hover:text-purple-700`}
                  />
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6">
        <Link to="/sign-out">
          <button className="w-full py-4 text-sm text-center text-gray-600 capitalize transition duration-300 ease-in-out border border-coolGray-300 bg-coolGray-200 hover:text-coolGray-50 hover:bg-coolGray-500">
            {t("gnb.sign_out")}
          </button>
        </Link>
      </div>
    </section>
  );
};
