import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import charBg from "../../../../assets/game/ts2/images/bg/ts_main_char.png";
import tempBg from "../../../../assets/game/ts2/images/shop/shop_temp_img.png";
import tempTitleBg from "../../../../assets/game/ts2/images/shop/shop_temp_title.png";

interface IHoverProps {
  id: number;
  selected: boolean;
}
export const Ts2Shop = () => {
  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/* 상단 */}
        <section
          className="relative z-0 flex items-end justify-center w-full max-w-screen-xl"
          style={{ height: "45rem" }}
        >
          <div className="absolute bottom-0 left-0 z-20 flex items-end justify-start"></div>
          <div className="absolute z-30 w-7/12 top-1/2 left-14">
            <p className="pb-5 text-6xl border-b border-coolGray-200 text-coolGray-100 font-oswald text-shadow">
              SHOP
            </p>
            <p className="pt-3 text-md text-coolGray-300 text-shadow">
              *Under development
              <br />
              <br /> This space intends to introduce premium items we’d like to
              offer you. We’re also developing this space where you can purchase
              the items directly.
            </p>
          </div>
          <div className="absolute z-10 -right-10 -bottom-20">
            <img src={charBg} alt="" className="object-fill w-full h-full" />
          </div>
        </section>
        {/* Media */}
        <section className="w-full max-w-screen-xl mt-24 mb-10">
          <div className="relative flex items-center justify-center w-full">
            <div
              className="absolute flex items-center justify-center w-full mt-20 bg-top bg-no-repeat bg-contain top-1/2 h-28"
              style={{ backgroundImage: `url(${tempTitleBg})` }}
            >
              <p className="px-10 pt-2 pb-3 text-3xl text-center text-white">
                Coming Soon!
              </p>
            </div>
            <img src={tempBg} alt="" className="w-3/5" />
          </div>
        </section>
      </section>
    </>
  );
};
