import {gql, useQuery} from "@apollo/client";
import { webIpQuery } from "../__generated__/webIpQuery";
const WEB_IP_QUERY = gql`
  query webIpQuery{
    web_GetMyIp{
      IPInfo{
        UserIP
        CountryCode
        CountryName
      }
      ResultCode{
        Code
        Location
      }
    }
  }
`;

export const useGetMyIp=()=>{
  return useQuery<webIpQuery>(WEB_IP_QUERY);
};
