import {Helmet} from "react-helmet";
import React from "react";
import {CommonLargeBtn} from "../../../components/general/common-large-btn";
import {ClipboardCheckIcon, PencilIcon, CheckCircleIcon} from "@heroicons/react/solid"
import {PolicyTermsOfService} from "../../../components/general/policy-terms-of-service";
import {PolicyPrivacy} from "../../../components/general/policy-privacy";
import {Disclosure} from '@headlessui/react'
import {ChevronUpIcon} from '@heroicons/react/solid'
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {SubmitHandler, useForm} from "react-hook-form";
import {ButtonCommon} from "../../../components/general/btn-common";
import {FormErrors} from "../../../components/general/form-errors";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IJoinPolicyProps {
  policyTerm: boolean;
  policyPrivacy: boolean;
  policyPromotion: boolean | null;
}

export const JoinPolicy = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const validatePolicy = Yup.object().shape({
    policyTerm: Yup.boolean()
      .oneOf([true], "The terms of service must be accepted.")
      .required("Please Check Terms of Service"),
    policyPrivacy: Yup.boolean()
      .oneOf([true], "The privacy policy must be accepted.")
      .required("Please Check Privacy")
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IJoinPolicyProps>({
    mode: "onChange",
    defaultValues: {
      policyTerm: true,
      policyPrivacy: true,
      policyPromotion: true
    },
    resolver: yupResolver(validatePolicy)
  });
  const onSubmit: SubmitHandler<IJoinPolicyProps> = (data) => {
    history.push("/join/join-account", data);
  };
  return (
    <div className="flex items-center justify-center w-full pt-16 md:pt-36">
      <Helmet>
        <title>Sign up :: Genius Orc</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-11/12 md:w-3/5">
        <h1 className="text-4xl text-center font-oswald md:text-5xl">
          {t("join_in.gate.sign_up_btn")}
        </h1>
        <hr className="w-full my-10 border border-gray-500" />
        <ul className="flex items-center justify-center mb-10">
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-600">
            <ClipboardCheckIcon className="w-6 h-6 text-white" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-600" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <PencilIcon className="w-6 h-6 text-coolGray-100" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-300" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <CheckCircleIcon className="w-6 h-6 text-coolGray-100" />
          </li>
        </ul>
        <h1 className="text-3xl text-purple-800 font-oswald">
          {t("join_in.gate.sub_title")}
        </h1>
        <p className="mt-3 mb-5 text-sm text-center">{t("join_policy.desc")}</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center justify-center w-full"
        >
          {/* */}
          <Disclosure>
            {({ open }) => (
              <div className="w-full my-3 bg-white border border-coolGray-300 md:w-8/12">
                <div className="flex items-center justify-between px-4 py-4">
                  <label className="inline-flex items-center w-3/5">
                    <input
                      className="w-6 h-6 text-purple-900 form-checkbox border-coolGray-300 bg-coolGray-100 hover:border-purple-900 hover:bg-purple-200 focus:ring-purple-800 "
                      type="checkbox"
                      {...register("policyTerm")}
                    />
                    <span className="ml-2 text-xs tracking-tight sm:text-sm sm:tracking-normal">
                      {t("join_policy.ex_01")}
                      <span className="ml-1 text-xs font-bold capitalize">
                        [{t("common.required")}]
                      </span>
                    </span>
                  </label>
                  <Disclosure.Button
                    className={`relative w-2/5 border flex justify-center items-center px-4 py-2 text-sm 
                                hover:bg-coolGray-300 focus:outline-none ${
                                  open
                                    ? "shadow-inner text-coolGray-700 bg-coolGray-100 border-purple-600"
                                    : "shadow-md text-coolGray-900 bg-coolGray-200 border-coolGray-400"
                                }`}
                  >
                    <span className="capitalize">{t("common.details")}</span>
                    <ChevronUpIcon
                      className={`absolute right-2 ${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-coolGray-600`}
                    />
                  </Disclosure.Button>
                </div>
                <hr className="border-coolGray-300" />
                <div>
                  <Disclosure.Panel
                    className="px-4 pt-4 pb-2 text-sm text-gray-800"
                    style={{ height: "260px", overflowY: "auto" }}
                  >
                    <PolicyTermsOfService />
                  </Disclosure.Panel>
                </div>
              </div>
            )}
          </Disclosure>
          {errors.policyTerm?.message && (
            <FormErrors errorMessage={errors.policyTerm?.message} />
          )}
          {/* */}
          <Disclosure>
            {({ open }) => (
              <div className="w-full my-3 bg-white border border-coolGray-300 md:w-8/12">
                <div className="flex items-center justify-between px-4 py-4">
                  <label className="inline-flex items-center w-3/5">
                    <input
                      className="w-6 h-6 text-purple-900 form-checkbox border-coolGray-300 bg-coolGray-100 hover:border-purple-900 hover:bg-purple-200 focus:ring-purple-800"
                      type="checkbox"
                      {...register("policyPrivacy")}
                    />
                    <span className="ml-2 text-xs tracking-tight sm:text-sm sm:tracking-normal">
                      {t("join_policy.ex_02")}
                      <span className="ml-1 text-xs font-bold capitalize">
                        [{t("common.required")}]
                      </span>
                    </span>
                  </label>
                  <Disclosure.Button
                    className={`relative w-2/5 border flex justify-center items-center px-4 py-2 text-sm 
                                hover:bg-coolGray-300 focus:outline-none ${
                                  open
                                    ? "shadow-inner text-coolGray-700 bg-coolGray-100 border-purple-600"
                                    : "shadow-md text-coolGray-900 bg-coolGray-200 border-coolGray-400"
                                }`}
                  >
                    <span className="capitalize">{t("common.details")}</span>
                    <ChevronUpIcon
                      className={`absolute right-2 ${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-coolGray-600`}
                    />
                  </Disclosure.Button>
                </div>
                <hr className="border-coolGray-300" />
                <div>
                  <Disclosure.Panel
                    className="px-4 pt-4 pb-2 text-sm text-gray-800"
                    style={{ height: "260px", overflowY: "auto" }}
                  >
                    <PolicyPrivacy />
                  </Disclosure.Panel>
                </div>
              </div>
            )}
          </Disclosure>
          {errors.policyPrivacy?.message && (
            <FormErrors errorMessage={errors.policyPrivacy?.message} />
          )}
          {/* */}
          <Disclosure>
            {({ open }) => (
              <div className="w-full my-3 bg-white border border-coolGray-300 md:w-8/12">
                <div className="flex items-center justify-between px-4 py-4">
                  <label className="inline-flex items-center w-3/5">
                    <input
                      className="w-6 h-6 text-purple-900 form-checkbox border-coolGray-300 bg-coolGray-100 hover:border-purple-900 hover:bg-purple-200 focus:ring-purple-800"
                      type="checkbox"
                      {...register("policyPromotion")}
                    />
                    <span className="ml-2 text-xs tracking-tight sm:text-sm sm:tracking-normal">
                      {t("join_policy.ex_03")}
                      <span className="ml-1 text-xs font-bold capitalize">
                        [{t("common.optional")}]
                      </span>
                    </span>
                  </label>
                  <Disclosure.Button
                    className={`relative w-2/5 border flex justify-center items-center px-4 py-2 text-sm 
                                hover:bg-coolGray-300 focus:outline-none ${
                                  open
                                    ? "shadow-inner text-coolGray-700 bg-coolGray-100 border-purple-600"
                                    : "shadow-md text-coolGray-900 bg-coolGray-200 border-coolGray-400"
                                }`}
                  >
                    <span className="capitalize">{t("common.details")}</span>
                    <ChevronUpIcon
                      className={`absolute right-2 ${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-coolGray-600`}
                    />
                  </Disclosure.Button>
                </div>
                <hr className="border-coolGray-300" />
                <div>
                  <Disclosure.Panel
                    className="px-4 pt-4 pb-2 text-sm text-gray-800"
                    style={{ height: "260px", overflowY: "auto" }}
                  >
                    {t("join_policy.ex_promo")}
                  </Disclosure.Panel>
                </div>
              </div>
            )}
          </Disclosure>
          <div className="flex items-center justify-center w-full my-3 md:w-8/12">
            <CommonLargeBtn
              msg={t("common.disagree")}
              url={"/"}
              size={`w-1/2 text-lg mr-2 
                  bg-coolGray-200 text-coolGray-700 
                  hover:bg-purple-800 hover:text-coolGray-100 capitalize`}
            />
            <ButtonCommon
              clickEnable={isValid}
              loading={false}
              actionText={t("common.agree")}
              size={`w-1/2 text-lg ml-2 
                    hover:bg-purple-800 hover:text-coolGray-100 capitalize`}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
