import {Helmet} from "react-helmet";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { CommonLargeBtn } from "../../../components/general/common-large-btn";
import { gql, useQuery } from "@apollo/client";
import {
  getOrderFail,
  getOrderFailVariables
} from "../../../__generated__/getOrderFail";
import { useTranslation } from "react-i18next";

const GET_ORDER_FAIL = gql`
  query getOrderFail($input: OrderInfoT3Input!) {
    eapi_OrderGet(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      orderEntity {
        price
        currencyType
        note
        orderID
        approveDate
      }
    }
  }
`;

export const ShopPurchasedFail = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  if (!window.location.href.includes("id=")) {
    history.push("/");
  }

  const sp: any = window.location.href.split(`?`)[1].split("&");
  const idSp: string | undefined = decodeURI(sp[0].split("id=")[1]);
  //const vSp: string | undefined = decodeURI(sp[1].split("v=")[1]);

  //getOrderSuccess
  const { data, loading } = useQuery<getOrderFail, getOrderFailVariables>(
    GET_ORDER_FAIL,
    {
      variables: {
        input: {
          Vender: "t3"
        }
      }
    }
  );

  return (
    <div
      className="flex items-start justify-center w-full pt-28"
      style={{ height: "860px" }}
    >
      <Helmet>
        <title>Fail Buying coin :: Genius Orc</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-3/5">
        <h1 className="text-5xl text-center font-oswald">
          {t("coin_shop.result.title")}
        </h1>
        <hr className="w-full my-10 border border-gray-500" />

        <h1 className="text-4xl text-red-700 font-oswald">
          {t("coin_shop.result.fail")}
        </h1>
        <div
          className={
            "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
          }
        >
          {loading ? (
            <p>{t("common.loading")} ...</p>
          ) : (
            <table className="w-11/12 border border-gray-300 ">
              <tbody>
                <tr>
                  <td className="w-4/12 py-4 pl-4 border border-gray-300">
                    {t("coin_shop.result.table_ex0")}
                  </td>
                  <td className="w-8/12 py-4 my-4 text-center text-purple-900 bg-gray-200 border border-gray-300">
                    {data?.eapi_OrderGet.orderEntity?.orderID}
                  </td>
                </tr>
                <tr>
                  <td className="w-4/12 py-4 pl-4 border border-gray-300">
                    {t("coin_shop.result.table_ex1")}
                  </td>
                  <td className="w-8/12 py-4 my-4 text-center text-purple-900 bg-gray-200 border border-gray-300">
                    <NumberFormat
                      value={data?.eapi_OrderGet.orderEntity?.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />{" "}
                    {data?.eapi_OrderGet.orderEntity?.currencyType}
                  </td>
                </tr>
                <tr>
                  <td className="w-4/12 py-4 pl-4 border border-gray-300">
                    Error
                  </td>
                  <td className="w-8/12 py-4 my-4 text-center text-purple-900 bg-gray-200 border border-gray-300">
                    {data?.eapi_OrderGet.orderEntity?.note}
                  </td>
                </tr>
                <tr>
                  <td className="w-4/12 pl-4 border border-gray-300">
                    {t("coin_shop.result.table_ex2")}
                  </td>
                  <td className="w-8/12 py-4 my-4 text-center text-purple-900 bg-gray-200 border border-gray-300">
                    <Moment locale={i18n.language} format="lll">
                      {data?.eapi_OrderGet.orderEntity?.approveDate}
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <CommonLargeBtn
            msg={`${t("coin_shop.result.btn")}`}
            url={"/shop"}
            size={
              "w-1/3 my-4 max-w-2xl text-xl bg-coolGray-800 text-coolGray-100 hover:bg-coolGray-200 hover:text-coolGray-600"
            }
          />
        </div>
      </div>
    </div>
  );
};
