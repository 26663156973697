import { ClockIcon, EyeIcon, TemplateIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";
import mainBg from "../../../../assets/game/ts2/images/bg/ts_main_bg.png";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNewsPostGetLists } from "../../../../hooks/use-news-post-list";
import { useNewsPostByID } from "../../../../hooks/use-news-post-id";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Ts2NewsHeader } from "../common/ts2-news-header";

export const Ts2NewsDetail = () => {
  const [isConCode, setIsConCode] = useState("");
  const [userIP, setUserIP] = useState("");
  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    const userIP: string = localStorage.getItem("userIP") || "";
    setIsConCode(conCode);
    setUserIP(userIP);
  }, []);
  const { t, i18n } = useTranslation();
  //console.log(i18n);
  let newsId: any;
  const location = useLocation();
  const getUrl = location.search?.split("id=")[1];
  newsId=getUrl.split('&')[0]
  console.log(newsId)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [headerCategory, setHeaderCategory] = useState(0);

  const {data:newsPostLists, loading:newsPostLoading}=useNewsPostGetLists(
    page,
    rowsPerPage,
    1,
    Number(headerCategory),
    i18n.language,
  )
  const [postID, setPostID]=useState(0)
  const {data:newsPostList, loading:newsPostIDLoading}=useNewsPostByID(Number(newsId))

  const handlePrevPage=(index:number)=>{
    console.log(newsPostLists)
  }
  const handleNextPage=(index:number)=>{
    console.log(newsPostLists)

  }
  return (
    <>
      <section
        className="flex flex-col items-center justify-start w-full bg-top bg-no-repeat font-notoserif"
        style={{
          backgroundImage: `url(${mainBg})`,
          backgroundColor: `#161a1e`
        }}
      >
        {/* wrapper */}
        <Ts2NewsHeader/>
        {/* 뉴스 리스트  */}
        <div className="relative z-20 flex items-center justify-center -mt-2 flex-col w-full md:max-w-screen-2lg lg:w-full lg:flex-row lg:items-start">
          <article
            className="relative w-11/12 p-8 text-coolGray-200 lg:w-4/5 lg:min-h-full lg:pb-10 lg:mr-3"
            style={{ backgroundColor: "#0a0d0f" }}
          >
          {newsPostIDLoading
            ? ('Loading')
            : (newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.visibility === 1
              ? (
                <>
                <div className="relative">
              <span
                className={`px-3 text-sm rounded-sm text-shadow text-red-100  uppercase
                ${
                  newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.category === 1
                    ? `bg-red-600 ring-1 ring-red-800`
                    : `bg-amber-600 ring-1 ring-amber-800`
                }`}
              >
                {newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.category === 1 ? (t('common.news')) : (t('common.update'))}
              </span>
                    <h1 className="mt-6 text-2xl">{newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.title}</h1>
                    <p className="absolute flex items-center justify-center text-sm top-3 right-0 text-coolGray-300">
                      <ClockIcon className="w-4 h-4 mr-1 text-coolGray-400" />
                      <span className="text-xs text-coolGray-400">
                          <Moment local locale={i18n.language} format="ll">{newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.regDate}</Moment>
                        </span>
                      <EyeIcon className="w-4 h-4 ml-2 text-coolGray-400 mr-1"/><NumberFormat
                      value={
                        newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.viewCount
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      className={`text-xs text-coolGray-400`}
                    />
                      <div className={`flex items-center justify-center ml-4`}>
                        <Link to={`/game/ts2/news`}>
                          <TemplateIcon className="w-6 h-6 mx-2 text-coolGray-400" />
                        </Link>
                      </div>
                    </p>
                  </div>
                  <div className="mt-4 border-coolGray-500">
                    <div
                      className={`mb-4 relative bg-no-repeat border-2 border-coolGray-800 h-56 bg-cover bg-center ${newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.thumbnailTitle!==''?'block':'hidden'}`}
                      style={{
                           backgroundImage: `url('${newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.thumbnailTitle}')`
                      }}/>
                    <div className="mt-4 text-coolGray-400" dangerouslySetInnerHTML={{__html:`${newsPostList?.eapi_GetNewsPostByID.newsPostEntity?.contents}`}}/>
                  </div>
                </>
              )
              : (null)
            )}
          </article>
          <article className="w-11/12 mt-10 grid w-11/12 grid-flow-row grid0cols-1 sm:grid-cols-2 gap-4 lg:w-1/4 lg:ml-3 lg:mb-4 lg:mt-0 lg:grid-cols-1">
            {newsPostLoading
              ? ('Loading ...')
              : newsPostLists?.eapi_GetAllNewsPosts.newsPostEntities?.map((item,index) =>(
            <div
              key={item.NID}
              className={`relative transition-all duration-500 ease-in-out bg-no-repeat border-4 outline border-coolGray-800 h-48 text-md
                 text-coolGray-100 group bg-cover bg-center ${
                item.category === 1
                  ? `hover:border-red-600`
                  : item.category === 2 && `hover:border-amber-600`
              }`}
              style={{
                backgroundImage: `url('${item.thumbnailMain}')`
              }}
            >
              <Link to={`/game/ts2/details?id=${item.NID}`}>
                <p className="absolute right-2 top-2">
                    <span
                      className={`px-3 text-xs rounded-lg text-shadow ${
                        item.category === 2
                          ? `text-amber-100 bg-amber-600 ring-1 ring-amber-800`
                          : item.category === 1 && `text-red-100 bg-red-600 ring-1 ring-red-800`
                      } uppercase`}
                    >
                      {item.category === 1 ? (t('common.news')) : (t('common.update'))}
                    </span>
                </p>
                <div className="absolute bottom-0 left-0 w-full p-3 transition-all duration-500 ease-in-out bg-black bg-opacity-50 group-hover:bg-opacity-80">
                  <p className="font-bold text-md text-coolGray-200"
                     style={{textOverflow:'ellipsis',overflow:'hidden', whiteSpace:'nowrap', width:'232px' }}
                  >
                    {item.title}
                  </p>
                  <p className={`my-2 text-xs text-coolGray-200 ${item.subtitle !=='' ? `block` : `hidden`}`}
                     style={{textOverflow:'ellipsis',overflow:'hidden',
                       whiteSpace:'normal', width:'98%', lineHeight:'1.4',
                       height:'2.4em', wordWrap:'break-word', textAlign:'left' }}
                  >
                    {item.subtitle}
                  </p>
                  <p className="flex items-center justify-start">
                    <ClockIcon className="w-4 h-4 mr-1 text-coolGray-400" />
                    <span className="text-xs text-coolGray-400">
                          <Moment local locale={i18n.language} format="ll">{item.regDate}</Moment>
                        </span>
                    <EyeIcon className="w-4 h-4 ml-2 text-coolGray-400 mr-1"/><NumberFormat
                    value={
                      item.viewCount
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    className={`text-xs text-coolGray-400`}
                  />
                  </p>
                </div>
              </Link>
            </div>))}
            <Link to={`/game/ts2/news`} className={`mt-0 mb-10 hidden lg:block`}>
              <div className={`uppercase ring-2 px-6 py-4 flex items-center justify-center 
              font-semibold text-lg ring-coolGray-700 shadow-md bg-coolGray-900 text-coolGray-300 transition duration-100 ease-in-out group hover:bg-coolGray-300 hover:text-coolGray-900`}>
                {t("common.load_more")} <ChevronDownIcon className="w-6 h-6 mx-2 text-coolGray-300 group-hover:text-coolGray-900" />
              </div>
            </Link>
          </article>
          <Link to={`/game/ts2/news`} className={`mt-10 mb-10 lg:hidden`}>
            <div className={`uppercase ring-2 px-6 py-4 flex items-center justify-center 
              font-semibold text-lg ring-coolGray-700 shadow-md bg-coolGray-900 text-coolGray-300 transition duration-100 ease-in-out group hover:bg-coolGray-300 hover:text-coolGray-900`}>
              {t("common.load_more")} <ChevronDownIcon className="w-6 h-6 mx-2 text-coolGray-300 group-hover:text-coolGray-900" />
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};;
