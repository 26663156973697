import {gql, useQuery} from "@apollo/client";
import { newsPostById, newsPostByIdVariables } from "../__generated__/newsPostById";

const NEWS_POST_ID_QUERY = gql`
  query newsPostById($input: GetNewsPostInput!) {
    eapi_GetNewsPostByID(input: $input) {
      ResultCode{
        Code
        Description
        Location
      }
      newsPostEntity{
       NID
       boardID
       serviceID
       category
       visibility
       thumbnailMain
       thumbnailTitle
       title
       subtitle
       contents
       viewCount
       language
       featured
       postedBy
       regDate
       postDate
      }
    }
  }
`;

export const useNewsPostByID=(NID:number)=>{
  return useQuery<newsPostById, newsPostByIdVariables>(
    NEWS_POST_ID_QUERY,{
      variables:{
        input:{NID}
      }
    });
}
