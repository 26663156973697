const Header = () => {
  return (
    <div className="my-2">
      <h1 className="mb-2 text-3xl font-bold">Genius Orc Privacy Policy</h1>
      <p className="mb-2 text-xl">Effective: 22 August 2021</p>
      <p className="p-4">
        We are committed to protecting and respecting your privacy. This Privacy
        Policy (“Policy”) describes how we collect, process, use and share your
        personal information when you download and play our games and use our
        websites, applications and other products. By “Personal Data”, we refer
        to data that relates to you as an identified or identifiable natural
        person. The term “Personal Data” may include, for example, your name,
        your postal address, your telephone number, your e-mail address, your
        age, your gender, and your game preferences. Anonymous or de-identified
        information, which we are not able to identify you, does not qualify as
        “Personal Data”.
      </p>
    </div>
  );
};

const Section1 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        1. CONTROLLER’S NAME AND CONTACT DETAILS The data controller in respect
        of our Products (as defined below) is: Genius Orc Entertainment Inc. 8,
        Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Republic
        of Korea 16837 Email: privacy@Genius.com In this Policy, “Genius”, “we”,
        “us” or “our” means Genius Orc Entertainment Inc. and the term
        “Products” means: (i) our PC games that post a link to this Policy and
        are made available through the Sites (the “Games”). (ii) Our websites
        that post a link to this Policy, including those at:{" "}
        <a href="http://Genius.com">http://Genius.com</a> (“Sites”). (v) Genius
        account (“Account“) and any other online game, website, application and
        product operated by us that posts a link to this Policy. This Policy
        also applies to any of our offline activities that make this Policy
        available to you.
      </p>
    </div>
  );
};
const Section2 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        2. CONTACT DETAILS OF THE DATA PROTECTION OFFICER (DPO) For questions
        regarding this Policy, our data practices, or our compliance with
        applicable laws, please contact our Data Protection Officer as follows:
        Genius Data Protection Officer 8, Pungdeokcheon-ro 139beon-gil, Suji-gu,
        Yongin-si, Gyeonggi-do, Republic of Korea 16837 Email:
        <a href="mailto:dpo@Genius.com">dpo@Genius.com</a>
      </p>
    </div>
  );
};

const Section3 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        3. CALIFORNIA AND NEVADA RESIDENTS For California and Nevada residents,
        we have also prepared a supplemental privacy policy in compliance with
        the California Consumer Privacy Act of 2018 (“CCPA“) and Nevada law (NRS
        603A.340). Please review our supplementary privacy policy attached below
        for more information.
      </p>
    </div>
  );
};

const Section4 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        4. GENERAL INFORMATION IN BRIEF We process Personal Data only when
        necessary for the performance of a contract with you, for compliance
        with a legal obligation to which we are subject, or based on our
        legitimate interests, except where such interests are overridden by your
        interests or fundamental rights and freedoms for which require the
        protection of your Personal Data. Our legitimate interests are to render
        and improve our Products in an effective, safe and harmless manner. We
        want to provide everyone with a fair and balanced experience when using
        our Products.
      </p>
      <p className="p-4">
        4.1. YOUR PREFERENCES You can choose how we use your Personal Data using
        the Account Management tool. As we need to verify your identity before
        we follow your instructions on Personal Data use, you need to have a
        registered Account in order to access the Data Protection Officer. For
        example, through the Data Protection Officer you can: opt-out of
        receiving direct marketing, offered via email, within Games and online.
        Read more below in Section 8.1 “Opt-Out of Direct Marketing and Right to
        Object”; access the Personal Data we collect and store about you. Read
        more below in Section 8.2 “Right of Access”; have your Account, and the
        related Personal Data, deleted. Read more below in Section 8.4 “Right to
        erasure”.
      </p>
      <p className="p-4">
        4.2. INFORMATION SECURITY We maintain reasonable and appropriate
        security safeguards designed to protect your information from loss,
        theft, misuse and unauthorised access, disclosure, alteration and
        destruction, taking into due account the risks involved in the
        processing and the nature of the information.
      </p>
      <p className="p-4">
        4.3. PROCESSING INFORMATION BY THIRD PARTIES We may share Personal Data
        with our affiliates, subsidiaries, vendors or agents working on our
        behalf for the purposes described in this Policy. For example, we may
        hire companies to assist with protecting and securing our systems or
        Products. Any vendor or agent that we retain must comply with our data
        privacy and security requirements and are not allowed to use personal
        data they receive from us for any other purpose unless so instructed by
        us. Those companies may be located outside of the European Economic
        Area. Read more in Section 7 “How we share your information”.
      </p>
      <p className="p-4">
        4.4. CHILDREN We recognise that we have a special obligation to protect
        personal information obtained from children. We do not knowingly collect
        Personal Data from any child, or process such information, without
        parental consent. For the purpose of this Policy, a child means any
        individual who is under the age of 18 (or the minimum legal age to
        consent to the collection and processing of Personal Data where this is
        different under applicable law). If you are a parent or guardian and
        believe we have collected your child’s information in violation of
        applicable law, contact us at privacy@Genius.com. We will remove the
        information in accordance with applicable law.
      </p>
      <p className="p-4">
        4.5. DATA RETENTION We keep most of your Personal Data collected and
        processed for the purposes described in this Policy for as long as you
        continue to use our Products, e.g. you have an active Account in one of
        our Games. We will delete your Personal Data after you request your
        Account to be deleted via the Account Management and the grace period of
        45 (forty-five) calendar days, during which we can restore your Account,
        expires. The process of erasing your Personal Data can take up to one
        month from expiry of the grace period and, considering the complexity
        and number of requests, may be extended by a further two months. Read
        more in Section 8.4 “Right to erasure”.
      </p>
    </div>
  );
};

const Section5 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        5. INFORMATION WE COLLECT In relation to your use of our Products, we
        collect information either directly from you (when you provide
        information to us) or indirectly (e.g. through our Products
        technologies).
      </p>
      <p className="p-4">
        5.1. INFORMATION WE COLLECT DIRECTLY We collect Personal Data and other
        information that you provide to us. Account Data When setting up an
        Account, you may be asked to provide Personal Data including, but not
        limited to, your in-game name, in-game password and email address. Once
        you set up your Account, you have the opportunity via the Account
        Management settings to specify your date of birth for receiving gifts at
        your birthday as well as to link your Account with your telephone number
        as a second authentication factor tool or to receive marketing
        communication via SMS. You can also use your credentials from other
        services such as Google, Facebook or Twitch to create an account faster
        with us. In such case we may collect additional information as per
        section 5.3 below. We also generate a unique number / identifier which
        we call “Genius ID” in connection with your Account and your use of our
        Products can be identified via Genius ID. Content Data We may obtain
        your Personal Data when you register for and use our other Products,
        e.g. game forums, chats, or when you provide feedback about our
        Products. These data include: Information that you post, comment, share
        content or like in any of the Games-related forums owned or operated by
        Genius (“Forums”), on the pages of social network groups operated by
        Genius, and on the Sites; Information sent through the “game-chat”
        functionality in the Games, including through in-Game voice chat;
        Information you provide when you request information or support from us
        or purchase a product or service from us, including information
        necessary to process your orders with the relevant payment merchant,
        which may include the amount of any transaction, but will not include
        your financial information, which will only be submitted directly to the
        payment merchant under secure protocols; Information other than the
        Account Data you provide to us when participating in competitions,
        contests, tournaments, loyalty programs, at offline events, responding
        to surveys, e.g. your contact details; Information that you provide to
        us to become or as a participant of alpha-tests, super tests, and UX
        tests. Personal Contacts Data To fulfil a request by you, we collect
        data about your personal contacts, such as their name, nickname, email
        address, and phone number with your consent, such as finding your
        contacts on our Products or inviting your contacts to join our Products
        via our referral programs etc. By using such functionalities, you
        acknowledge and agree that you have your contact’s consent for us to use
        their contact information to fulfil your request. Other data provided by
        you voluntarily You may choose to voluntarily provide other information
        to us that we do not request, and, in such instances, you are solely
        responsible for such information.
      </p>
      <p className="p-4">
        5.2. INFORMATION WE COLLECT INDIRECTLY We indirectly collect a variety
        of information through your interaction with and use of our Games, Sites
        and other Products. This information may include, but is not limited to,
        anonymized browser and device information (both software and hardware),
        data collected through automated electronic interactions, application
        usage data, demographic information, geographic, geo-location
        information, statistical and aggregated information (“Other
        Information”). Statistical or aggregated information does not directly
        identify a specific person, but it may be derived from Personal Data.
        For example, we may aggregate Personal Data to calculate the percentage
        of users in a particular country. If Other Information is combined with
        Personal Data, we will treat the combined information as Personal Data.
      </p>
      <p className="p-4">
        5.2.1. Tracking Data and Cookies We and our partners, such as marketing
        partners and analytics providers, use cookies, which are text files
        placed on your computer, to help us analyse how users use our Products,
        and similar technologies (e.g. web beacons, pixels, tags and device
        identifiers) to recognise you and/or your device(s) on, off and across
        different devices and our Products, as well as to improve the Products
        we are offering, to improve marketing, analytics or website
        functionality. The use of cookies is standard on the internet. You may
        adjust your browser settings to prevent the reception of cookies, or to
        provide notification whenever a cookie is sent to you. You may refuse
        the use of cookies by selecting the appropriate settings on your
        browser. However, please note that if you do this, you may not be able
        to access the full functionality of our Sites. Website traffic volume
        and patterns, such as the number of visitors to a given website or page
        on a daily basis is typically referred to as “Tracking Data”. This type
        of indirectly collected information is gathered through various means,
        such as an IP address, which is a number that is automatically assigned
        to your computer whenever you are surfing the web. Web servers, the
        computers that “serve up” web pages, automatically identify your
        computer by its IP address. When you visit any of our Sites, our servers
        log your computer’s IP address. For more information about the names of
        the cookies used on our Sites, the cookie provider (Genius, third-party
        analytics partner, or third-party advertising partner), the purpose of
        the cookie (necessary for website to function, performance, or
        marketing), and the cookie life cycle, you may click on the Cookie
        Settings link found at the footer of each Site. This link allows you to
        set your cookie preferences and control your cookie settings at any
        time.
      </p>
      <p className="p-4">
        5.2.1.1. How to delete cookies and opt out of targeted advertising and
        analytics Like most online services, we and our partners use cookies and
        similar technologies to provide and personalize the Service, analyse
        use, target advertisements and prevent fraud. You can disable cookies in
        your browser settings, but some parts of the Service may then not
        function properly. If you wish to limit behaviourally targeted
        advertising, you can do so by limiting ad tracking in your device
        settings. Please note that opt-outs are specific to each browser and
        device and it may take a little bit of time before your opt-out choice
        will take effect. For mobile advertising in apps, you can reset your
        Advertising Identifier and depending on your device, select to opt out
        of interest-based ads (Android) or turn on the Limit Ad Tracking setting
        (iOS). For display advertising on the Web, you can also adjust your
        browser settings to limit certain tracking by means of cookies (e.g. for
        Chrome{" "}
        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
          https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en
        </a>
        ), and by visiting the following sites:
        <a href="http://www.aboutads.info/choices">
          http://www.aboutads.info/choices
        </a>{" "}
        and{" "}
        <a href="http://www.youronlinechoices.eu">
          http://www.youronlinechoices.eu
        </a>
        . Do Not Track: Your browser settings may allow you to automatically
        transmit a “Do Not Track” signal to online services you visit. Note,
        however, there is no industry consensus as to what site and app
        operators should do with regard to these signals. Accordingly, unless
        and until the law is interpreted to require us to do so, we do not
        monitor or take action with respect to “Do Not Track” signals. For more
        information on “Do Not Track” visit{" "}
        <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.
      </p>
      <p className="p-4">
        5.2.2. Game-Related Information In order to provide you with efficient
        services, we need to collect, store and use various information about
        your use of our Games. “Game-Related Information” are stored under your
        Genius ID as provided in the Account Data in section 5.1 above as well
        as what is usually referred to as “game statistics”. By “game
        statistics” we mean information about your Games preferences, progress
        in the Games, participation, performance and achievements in clans and
        battles, frequency of playing, etc.
      </p>
      <p className="p-4">
        5.2.3. Device Information and Geo-Location In order to fix crashes and
        other technical issues that may arise while you are installing the Games
        and using our Products, as well as to make our Products more suitable
        for you, we collect, store and use information about the device you are
        using the Products on, including what type of device it is, what
        hardware and operating system you are using, the device settings, unique
        device identifiers, and crash data (if any). Whether we collect some or
        all of this information often depends on what type of device you are
        using and its settings. You may also install applications collecting and
        using additional data required for fixing crashes, errors and other
        technical issues arising during your use of the Products. To make our
        Products more suitable for you and only upon your permission we can also
        collect your precise GPS location from mobile devices. Wi-Fi and IP
        addresses received from your browser or device may be used to determine
        approximate location.
      </p>
      <p className="p-4">
        5.2.4. Information required for fraud and violations detection We may
        also collect certain data (like IP address, username, etc.) that is
        required for our detection, investigation and prevention of cheating in
        the Game and EULA violations. This data, being used only for the
        purposes of detection, investigation and prevention of frauds and
        cheating in the Game are deleted six months after their collection,
        unless the data can be used to prove that cheating has occurred. If the
        data can be used to prove that cheating or another fraud or EULA
        violation has occurred, we will further store the data for the
        establishment, exercise or defence of legal claims during the applicable
        statute of limitations. Please note that the specific data stored for
        this purpose may not be disclosed to you or any third parties, since the
        disclosure will compromise the mechanism through which we detect,
        investigate and prevent frauds and cheating in the Game, as well as
        other EULA violations.
      </p>
      <p className="p-4">
        5.2.5. Information from publicly available online resources We may use
        the information made publicly available, e.g. through YouTube, Facebook,
        Instagram and Twitter, in order to find out your opinion about Games and
        Products, so that we could take them into account while improving our
        Products. Such data collection can be carried out by Genius
        independently, or received from our partners making analysis of public
        sentiments on various issues.
      </p>
      <p className="p-4">
        5.2.6. Information from our partners In some cases, Genius receives your
        personal data as a data processor, which means that the data are
        controlled by our partners and we use them only in accordance with their
        instructions and applicable laws. We use this data in order to fulfil
        our obligation under the EULA, improve the Products, and when processing
        is required in order to comply with a legal obligation to which we are
        subject. Payment partners. When you purchase in-game items, in-game
        currency, or time-limited premium memberships with your credit card, we
        may receive from our payment partners and process a part of your credit
        card number, information about your credit card issuer, as well as your
        full address for billing purposes; Co-Promotion Partners. In order to
        provide you with in-game items and/or in-game currency for participation
        in various loyalty programs, we may also receive information from our
        partners, such as telecommunication companies, banks, cashback partners,
        travel agencies, and others; Social networks. We have registered certain
        Games-related group accounts and pages at Facebook, YouTube, Instagram,
        Discord, Twitter, Reddit, and other social networks to better
        communicate with our players in case they have questions about our
        Products, as well as prefer to learn about news about our Products
        through the social networks. Also, we get information relating to your
        account on social networking sites in order to allow you to integrate or
        link your social network account with your Account or our Products. For
        example, you may link your Account via your YouTube account credentials.
        In such case, we will collect from YouTube your channel ID to link your
        social network account with your Account. If you are a content creator,
        we may access your channel subscribers/members to find our players’
        accounts to provide them with some in-game customization or goods. Any
        personal data collected from such sources will be stored in accordance
        with this policy. We also automatically collect information via our
        Games application technologies that are not browser-based like cookies
        and cannot be controlled by browser settings or cannot be controlled via
        the Account Management tool or while you play our Games. For example,
        our Games applications may include SDKs, which is code that sends
        information about your use to a server, for instance through the
        Facebook SDK or other partner’s SDKs. These SDKs allows us to track our
        conversions, bring you advertising, and provide you with additional
        functionality, such as the ability to connect our Products with other
        services.
      </p>
      <p className="p-4">
        5.3. INFORMATION FROM OTHER SOURCES The categories of sources from which
        we collect information include:
      </p>
      <p className="p-4">
        5.3.1. Game platforms: We collect data (such as the relevant user
        identifier, email, nickname), from partners that make our Games
        available on their platforms, such as Valve (Steam), Apple (iOS), and
        Google (Android). Our partners, and not us, are responsible for your
        accounts on their platforms, and we recommend that you review their
        privacy policies.
      </p>
      <p className="p-4">
        5.3.2. Developers who use our API: We collect data from developers who
        use the Genius Orc API. Use of API is subject to our API Terms of Use .
      </p>
      <p className="p-4">
        5.3.3. Verification partners: We collect data from verification services
        such as ID.me to confirm your eligibility for discounts (e.g., related
        to your military status).
      </p>
    </div>
  );
};

const Section6 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        6. HOW WE USE YOUR INFORMATION We need to process your Personal Data in
        order to perform the contract with you. As a user, we will use your
        Personal Data, unless otherwise prohibited by law, for the following
        purposes: to provide you with the Products you request, i.e., to ;allow
        your installation of the Games, operate the Products and verify and
        confirm your payments; to communicate with you about your Account or
        transactions with us and send you information about features on our
        Sites or changes to our policies; to provide support including, but not
        limited to, product updates, product patches and fixes and other similar
        communications; to arrange your travel, receipt of gifts and awards, to
        which you may be entitled as a result of winning an offline competition,
        etc.
      </p>
      <p className="p-4">
        6.1. COMMUNICATION PURPOSES We will use your Personal Data for our
        legitimate interests to advertise our Products in accordance with this
        Policy. Namely, once you have created an Account we may use your email
        address, nickname, IP address, mobile phone number, in order to send you
        personalized marketing emails, messages, targeted in-game and web offers
        about Games. Also, if you opt-in, we will send you personalized
        marketing emails, messages and in-game and web offers about Genius
        offline events, co-projects of Genius and its partners, as well as about
        Genius games, not using Genius ID.
      </p>
      <p className="p-4">
        6.2. IN-GAME OFFERS We will also use the Game-Related Information to
        send you in-game offers that we believe are most useful to you. To make
        the offer we first collect Game-Related Information from various
        players, identify patterns in their behaviour, and create algorithms
        that identify pools of players following the pattern or particular
        players who follow the pattern. To those players who actually follow the
        pattern – we send the in-game offers, which can be personalized (i.e.,
        we want the in-game offer to reach you or a relatively small number of
        players specifically) or non-personalized (i.e., we want the in-game
        offer to reach a large number of players following a pattern). We always
        make sure that such offers have no significant impact on you or our
        other players. For example, we may notice that you are a player who is
        very good at playing with French vehicles and has registered the account
        more than five years ago: so, we may specifically offer you the new
        French vehicle for rent, but in any case not more expensively than it is
        offered to other users. You can unsubscribe from personalized in-Game
        offers through our Account Management tool.
      </p>
      <p className="p-4">
        6.3. PRODUCTS IMPROVEMENT PURPOSES We analyse your information to
        identify how to improve the Products and our operations to make them
        more suitable and interesting for our players in general. We also may
        ask you to respond to our Products-related surveys or questionnaires,
        and you always have the choice about whether to respond to them and
        which information you provide.
      </p>
      <p className="p-4">
        6.4. FRAUD AND EULA VIOLATIONS DETECTION We use the information required
        for our detection, investigation and prevention of cheating in the Game
        and violations of EULA and Game Rules and policies only for the purposes
        of detection, investigation and prevention. If the data can be used to
        prove that cheating or another fraud or EULA violation has occurred, we
        will further store the data for the establishment, exercise or defence
        of legal claims during the applicable statute of limitations.
      </p>
      <p className="p-4">
        6.5. AUDIENCE MATCHING SERVICES We will also use your information for
        audience matching services to reach people (or people similar to people)
        who have used or visited our Products or are identified in one or more
        of our databases (“Matched Ads“). This is done by us sharing a list of
        hashed emails. For instance, we use Facebook Custom Audiences to serve
        ads to our players on Facebook and Facebook Lookalike Audiences to serve
        ads to users on Facebook similar to our players. Vendors and ad partners
        may act as our processors or in certain contexts, they may act as data
        controllers and independently decide how to process your information. We
        encourage you to familiarize yourself with and consult their privacy
        policies and terms of use. To opt-out of us sharing your hashed email
        address for Matched Ads please contact us at privacy@Genius.com and
        specify that you wish to opt out of Matched Ads. We will remove your
        email address from any subsequent lists shared with ad partners for
        purposes of Matched Ads.
      </p>
    </div>
  );
};
const Section7 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        7. HOW WE SHARE YOUR INFORMATION For the performance of a contract with
        you, compliance with a legal obligation to which we are subject, and
        based on our legitimate interests, we may share your personal data with
        the following categories of third parties: Payment partners: we share
        your email address, IP address, information about in-game purchases
        requested by you at Genius’s web-sites, and other information you
        provide to us for the purpose of fulfilling your purchase and withdrawal
        requests; Advertising and advertising measurement partners: we share
        your email, nickname, IP address and other information you provide to us
        in order to facilitate targeting, delivery, and measurement of online
        advertising on third-party services, facilitate transmittal of
        information that may be useful, relevant, or of interest to you, e.g..
        allowing you to get rewards for viewing in-Games advertisements. These
        parties may act as our processors, or in certain contexts, independently
        decide how to process your information; Data storage partners: we store
        some of your information using third-party data storage services owned
        or operated by other entities, e.g., while some of your Game-related
        information is stored using Google Cloud Platform (BigQuery), as well
        the services of Amazon Web Services, Inc., G-Core Labs S.A.; Software
        providers: proper Products rendering requires us to use software
        solutions of third parties. Research partners: we may provide access to
        some of your data, such as your Game statistics, to provide
        marketing-related and other research. We share anonymous data with our
        partners unless your personal data is necessary for the requested
        services or research; Player Support partners: for some territories and
        products we engage third parties to provide you with appropriate player
        support services. Such companies may get access to your personal data,
        including personal data that you may provide in your player support
        requests.; Developers of applications based on the Game: we make your
        nickname and some of your Game statistics available to other players and
        our partners through Public API. The data can be used for developing and
        support of the applications created by the Game community, mobile
        applications, e.g. Genius Assistant, authorized mods to the Games.
        Public API enables us to improve your gaming experience, fulfil
        potential of our games’ community, and put use of our players’
        statistics under our control by creating rules and requirements for
        their use by other players and community. Use of your information is
        subject to the restrictions set out in our API Terms of Use; Information
        available to other players: making your nickname and game statistics
        available to other players through our Products is an important part of
        making players’ experience more enjoyable. If you are banned in a Game
        based on other players’ complaints, we may inform them about the ban.
        Please note that members of your clan have access to a bigger amount of
        your game statistics than others; Public information: our Products may
        also have message boards, forums and/or chat areas, where users can
        exchange ideas and communicate with each other. When posting a message
        to a board, forum or chat area, please be aware that the information is
        being made publicly available on-line; therefore, you are doing so at
        your own risk and you are solely responsible for any information you
        make public. Your nickname and game statistics may also become available
        within Games replays and other videos, which Genius or other players may
        generate and put online. Please do not include into your nickname any
        data that can identify you personally. To change your nickname please
        contact Player Support; External moderators and testers: some
        information about you (such as your IP address, nickname, content of
        Player Support ticket) may become available to volunteering moderators
        and testers who contribute to providing you with better Products, e.g.
        to quickly respond to your questions about the Games. We ensure that use
        of your information by moderators and testers in this context is subject
        to confidentiality and data protection agreement. Anti-cheat and
        anti-fraud companies: we may share the information required for our
        detection, investigation and prevention of cheating in the Games and
        EULA violations only for the purposes of detection, investigation and
        prevention of cheating in the Game. Such partners include ACI Worldwide
        (EMEA) Ltd., UK. Easy Anti-Cheat software provided by Epic Inc., US;
        Logistics partners: to arrange your trips (e.g., as an eSports player)
        and deliver the prizes you may be entitled to thanks to use of your
        Products, we can share your post address and other required details with
        our logistics partners or ask you to share that data with our logistics
        partners directly; Third-party websites: you can choose to log into some
        websites using Genius OpenID. In this case we pass to the websites your
        nickname and your profile link. Please check our Player Support articles
        on OpenID for more details and note that Genius does not control further
        use of this data by third-party websites, i.e., we cannot request their
        erasure of your data; Game Developers and Change of Publisher: Genius is
        the data controller with regard to certain Games developed by third
        parties and published by Genius. Such developers can process certain
        data required for the Games’ and other Products’ further improvement,
        e.g., to Game-Related Information. Should Genius transfer the right to
        publish such or other Games and other related Products to a third party,
        the new publisher will have the right to continue using your data in the
        manner substantially the same as set out in this Privacy Policy unless
        you are specifically informed otherwise. Public authorities and
        auditors: some personal data shall be disclosed to public authorities,
        e.g., tax and customs authorities, auditors, and other competent
        parties, in accordance with the laws of each local jurisdiction where we
        operate; Genius subsidiaries and affiliates: as a global company, we
        share information with our affiliates and related entities which they
        act as our processors. This information may be processed in our offices
        worldwide. Your information may also be processed outside of the
        jurisdiction in which it was collected (e.g. in the United States,
        Korea, Russian Federation, the Republic of Belarus, or any other country
        in which we, our affiliates or subsidiaries maintain a presence). Change
        of control: we may share your information as part of, or during the
        negotiation/preparation of, our company’s sale, merger, change in
        control, or any other type of acquisition or business combination of all
        or any portion of our assets, or transfer of all or a portion of our
        business to another business. In addition, should we transfer the right
        to publish any Games or other related Products to another entity, the
        new publisher will have the right to continue using your information in
        the manner substantially the same as set out in this Policy unless you
        specifically informed otherwise. Third-party game developers: We also
        publish on our platform games developed solely by third party
        developers. When you choose to play such games, we may share your data
        with the third-party game developer, such as your Genius ID, nickname,
        in-game purchases, in-game bans of our Games, language preference of
        Genius Game Center. In such case we and the third-party game developer
        act as independent data controllers. Such third-parties are responsible
        for your activities on their platforms and we recommend you review their
        privacy policies. Security and compelled disclosure: We share
        information to comply with the law or other legal process (e.g., tax and
        customs authorities, auditors, and where required, in response to lawful
        requests by public authorities, including to meet security or law
        enforcement requirements). We also share information to protect the
        rights, property, life health, security and safety of us, the Products,
        or anyone else; Competitions: Our competitions, contests, and
        tournaments (“Competitions“) may be jointly sponsored or offered by
        other parties. When you voluntarily enter a Competition, we share
        information as set out in the official rules that govern the Competition
        as well as for administrative purposes and as required by law (e.g., on
        a winners list). By entering a Competition, and may, except where
        prohibited by applicable law, allow the sponsor and/or other entities to
        use your name, voice and/or likeness in advertising or marketing
        materials. When your personal data is shared with Genius affiliates and
        subsidiaries, as well as third parties outside the European Union or the
        European Economic Area, Genius guarantees an adequate level of personal
        data protection, including but not limited to, by entering into standard
        data protection clauses adopted by the European Commission with Genius
        affiliates and subsidiaries, as well as such third parties. We will
        provide you with a copy of the standard data protection clauses upon
        your request. Sharing anonymous information. We also may share anonymous
        information, i.e. information which does not directly or indirectly
        disclose your identity, and aggregated information (meaning information
        about groups and categories of users, including game statistics and
        behaviour, but which does not identify and that cannot reasonably be
        used to identify any individual user) with our advertising partners. We
        may also allow our advertisers to collect anonymous information and
        aggregated information within the Products and they may then share that
        information with us. Our advertisers may collect this information
        through the use of tracking technologies like cookies and web beacons.
        This enables our advertisers to develop and deliver targeted advertising
        in the Products and on the websites of third parties, so that they can
        try to serve you with advertisements for products and services that are
        most likely to be of interest to you. Advertisers will also use this
        information to monitor, improve or modify their operations.
      </p>
    </div>
  );
};
const Section8 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        8. YOUR RIGHTS Please visit it to make choices about which direct
        communications you would like to receive, as well as to exercise your
        rights to data access, data erasure and objection to data processing. In
        some cases, for games developed solely by third parties and published by
        Genius on Genius Orc Entertainment (see section 7(18) above), you can
        exercise your rights directly from the third-party developer. You may
        contact us to assist you on how you can exercise your rights for games
        developed solely by third parties. If you have linked your Account with
        linked services (e.g., Google, Microsoft, Facebook, Steam, Twitch etc)
        you may unlink your accounts at any time through the DPO. Please note
        that unlinking your accounts will not affect any information previously
        shared through linking. We are not responsible for the data practices of
        any other parties, and we recommend that you carefully review their
        privacy policies and terms of use.
      </p>
      <p className="p-4">
        8.1. OPT-OUT OF DIRECT MARKETING AND RIGHT TO OBJECT You can manage your
        subscriptions to direct marketing through our DPO. Please note that even
        if you unsubscribe from all our personalized marketing emails, messages,
        in-game and web- offers, we will still send you some notifications (we
        often refer to them as “transactional”), e.g. to confirm purchases or
        changes in your Account, give warranty or security information,
        information about changes to the EULA, Terms of Service and Privacy
        Policy, as well as will show to you non-personalized marketing in-game
        and web offers. We will be able to stop all communications only after
        your Account is deleted. Also, even after you have unsubscribed from our
        in-game and web offers, you may still see them within the Games and on
        some web-sites: that would mean that such offers are shown to everyone
        or to a very broad audience and are not specifically directed to you.
        Where processing of your Personal Data is based on legitimate interests,
        you can use your right to object at any time. If you object we will no
        longer process your Personal Data unless there are compelling and
        prevailing legitimate grounds for the processing or the data are
        necessary for the establishment, exercise or defense of legal claims.
      </p>
      <p className="p-4">
        8.2. RIGHT OF ACCESS You have the right to access your Personal Data
        that we hold about you, i.e. the right to require free of charge (i)
        information whether your Personal Data is retained, (ii) access to
        and/or (iii) duplicates of the Personal Data retained. You can use the
        right to access to your Personal Data through the “Data Export” tool
        available via DPO. Normally, we provide the duplicate of the Personal
        Data retained within 30 (thirty) days upon your request. Once the
        archive with the Personal Data we retain is ready, you will be able to
        download in it from our resources. If the request affects the rights and
        freedoms of others or is manifestly unfounded or excessive, we reserve
        the right to charge a reasonable fee (taking into account the
        administrative costs of providing the information or communication or
        taking the action requested) or refuse to act on the request. We also
        will not be able to provide you with some Personal Data that you may
        post or provide using our Products regardless of our request not to
        provide the Personal Data in this particular field, website, etc.
      </p>
      <p className="p-4">
        8.3. RIGHT TO RECTIFICATION You control which nickname, email, phone
        number, country (only in exceptional circumstances), social networks
        accounts, trusted sites and payment methods we associate with your
        Account. If they change, please modify the data in the Personal Account
        directly. Please inform us if any of the Personal Data we retain about
        you is inaccurate.
      </p>
      <p className="p-4">
        8.4. RIGHT TO ERASURE You have the right to obtain deletion by us of
        Personal Data concerning you by deleting your Account via our DPO. As a
        result of deleting your Account, you will lose access to most Products,
        including the Account and Game-Related Information linked to the
        Account, subscriptions to Genius forums and the possibility to access
        other services you are using the Account for. No payments after Account
        deletion. As a result of deleting your Account, you will lose the
        possibility to make payments in the Premium Shop, including during the
        grace period. Should any payment be made after Account deletion due to a
        technical error or for any other reason, we will refund the amount paid
        by you and will not grant you the rights to the respective Additional
        Features. Complicated Account deletion. In some cases, deletion of your
        Account, and therefore Personal Data deletion, is complicated. Namely,
        if you are a guild leader in the Games, it is highly likely that you
        will only be able to delete your Account after you have transferred the
        guild and placed it under the leadership of another user or dissolve the
        clan. In some cases, considering the complexity and number of the
        requests, the period for Personal Data erasure may be extended, but for
        no longer than two further months. Earlier Personal Data deletion. We
        store most Personal Data we retain about you until you request deletion
        of your Account to us. However, certain Personal Data is deleted even
        while you have a valid Account. For example, we will delete: your
        Contact Data, clothes sizes, colour preferences, etc., you have provided
        for customisation, or for us to send you an award or arrange travel to
        one of our events, within 45 (forty-five) days after us sending the
        award to your address; the data about you that we have collected in the
        course of alpha-tests, super tests, UX tests, surveys, analysis of
        publicly available sentiments in social networks such as YouTube,
        Facebook, Instagram, at onsite events: within no longer than 45
        (forty-five) days after completion of the data analysis, data
        aggregation and generating of the reports for our Products improvement;
        technical logs such as records of communication between Products, access
        logs, errors logs: normally within 45 (forty-five) days after their
        creation. Information we retain. Upon expiry of the suspension period we
        will delete all your Personal Data, except for the data which are
        required for our compliance with the requirements of applicable laws
        (e.g. tax and accounting requirements) or for our detection,
        investigation and prevention of cheating in the Game and EULA violations
        or to resolve disputes and enforce our agreements. We will also retain
        anonymous or de-identified information after your Account has been
        deleted. Information you have shared with others (e.g. through in-Games
        chats, updates or group posts) will remain visible after you have
        deleted your account or deleted the information from your own profile or
        mailbox, and we do not control the data that other members/users copied
        out of our Products. Groups content associated with deleted accounts
        will show an unknown user as the source. Your profile may continue to be
        displayed in the services of others (e.g. search engine results) until
        they refresh their cache. COMPLAINTS If you have any issues with our
        compliance you may contact our Data Protection Officer as set out in
        section 2 above. You also have the right to lodge a complaint with the
        data protection regulator in your jurisdiction.
      </p>
    </div>
  );
};
const Section10 = () => {
  return (
    <div className="my-2">
      <p className="p-4">
        9. WE CAN CHANGE THIS POLICY We may change this Policy from time to time
        for various reasons, such as legal and regulatory changes, changes in
        industry practices and technological developments that need to be
        reflected. If the changes are material, we may provide you additional
        notice to your email address. ADDITIONAL DISCLOSURES FOR CALIFORNIA AND
        NEVADA RESIDENTS Please click here to review additional disclosures
        required under the California Consumer Privacy Act.
      </p>
    </div>
  );
};
export const PolicyPrivacy = () => {
  return (
    <div className="text-md text-coolGray-700 font-notoserif">
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section10 />
    </div>
  );
};
