import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sc05 from "../../../assets/game/ts2/images/about/page-img_about_us.png";

const AboutUsEn = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-24 text-xl font-notoserif text-coolGray-800">
      <img src={sc05} alt="GOE Logo" className="w-full my-10" />
      <article className="my-6">
        <h1 className="pb-4 mb-4 text-5xl font-bold border-b border-coolGray-400">
          About Us
        </h1>
        <p className="p-6">
          We are a group of game masters who are specialized in their areas of
          video online game service, such as business relations, live
          operations, server, web, design, etc. We focus to service our games to
          users with the following main philosophy:
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          Game environment at its{" "}
          <span className="font-bold">best quality</span>
        </h3>
        <p className="p-6">
          As a publisher, we are committed to keep users' game environment at
          its best quality 24/7 and also to solve the problems users face on our
          platform and the games.
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          <span className="font-bold">Honest and swift</span> communication
        </h3>
        <p className="p-6">
          We take many roles in the live operations and expect to encounter
          unexpected issues randomly just like how users face new challenges in
          their games everyday. We may not have instant answers for all
          problems. Some problems would require users cooperations and we admit
          that our answer would not be always the best solution depending on
          issues. But we strive to show our honest communication throughout all
          communications.
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          <span className="font-bold">Value people</span> who work for the game
        </h3>
        <p className="p-6">
          who work for Genius Orc are all around the globe and they’re connected
          via the game. We’ll make our work and game environment enjoyable for
          us and value users’ feedback on games first of all.
        </p>
      </article>
      <article className="my-6">
        <h1 className="pb-4 mb-4 text-5xl font-bold border-b border-coolGray-400">
          Our Latest Project
        </h1>
        <p className="p-6">
          We’re proud to start Genius Orc’s journey with Twelve Sky 2, the most
          noble MMORPG based in Turkey since 2007. We’ll be servicing the game
          in English and Turkish versions with our official partner ‘Bega Games
          (Developers of Twelve Sky Series)’ and we’re preparing the launch in
          September, 2021. The goal of its launch is to bring back the community
          in Europe the classic they used to enjoy and years of missing content.
        </p>
      </article>
    </div>
  );
};

const AboutUsTR = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-24 text-xl font-notoserif text-coolGray-800">
      <img src={sc05} alt="GOE Logo" className="w-full my-10" />
      <article className="my-6">
        <h1 className="pb-4 mb-4 text-5xl font-bold border-b border-coolGray-400">
          About Us
        </h1>
        <p className="p-6">
          Biz iş ilişkileri, oyun yönetimi, sunucu, web, tasarım gibi çevrim-içi
          oyun alanlarında uzmanlaşmış, oyunsever bir grup oyun yöneticisiyiz.
          Oyunlarımızı aşağıdaki ana ilkelere bağlı kalarak sizlere sunmayı
          hedefliyoruz:
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          En iyi durumda <span className="font-bold">bir oyun ortamı</span>
        </h3>
        <p className="p-6">
          Oyuncu odaklı en kaliteli oyun deneyimini 7/24 sunabilmeyi ve
          oyunlarımızda karşılaşabileceğiniz aksaklıkları en kısa sürede
          çözebilmeyi taahhüt ediyoruz.
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          <span className="font-bold">Samimi ve Hızlı</span> iletişim
        </h3>
        <p className="p-6">
          Tecrübelerimiz doğrultusunda, tıpkı oyuncuların oyun içerisinde her
          gün yeni bir meydan okumayla karşılaşması gibi bazı zorluklar ile
          karşılaşacağımızın farkındayız fakat sorunlarınız ile samimi ve hızlı
          bir şekilde ilgileneceğimizi bilmenizi isteriz. Tüm soru ve sorunlara
          anında yanıt veremeyebiliriz. Bazı sorunların çözümünde oyuncu
          işbirliği gerekebileceğinin ve duruma göre cevabımızın her zaman en
          iyi çözüm olmayabileceğini kabul ediyoruz fakat sizinle olan tüm
          iletişimimizde dürüst ve şeffaf olacağımıza söz veriyoruz.
        </p>
      </article>
      <article className="my-6">
        <h3 className="text-2xl underline">
          <span className="font-bold">Oyun için çalışanlara</span> değer
          veriyoruz
        </h3>
        <p className="p-6">
          Yönetim kadromuzda yer alan ve dünyanın çeşitli bölgelerinde yaşayan
          tüm değerli ekip arkadaşlarımız oyun aracılığı le birbirlerine
          bağlıdır. Çalışma alanımızı ve oyun deneyiminizi en eğlenceli hale
          getirmek, geri bildirimlerinize değer vermek önceliklerimiz arasında
          ilk sıralarda yer alıyor.
        </p>
      </article>
      <article className="my-6">
        <h1 className="pb-4 mb-4 text-5xl font-bold border-b border-coolGray-400">
          Son projemiz
        </h1>
        <p className="p-6">
          Genius Orc yolculuğumuza, 2007 yılından beri Türkiye'nin en önemli ve
          klasikleşmiş MMORPG oyunlarından biri olan Karahan ile başlamaktan
          gurur duyuyoruz. Resmi iş ortağımız Bega Games (Karahan geliştiricisi)
          ile birlikte oyunu Eylül 2021 tarihinde Türkçe ve İngilizce olarak
          yayınlamaya başlayacağız. Hedefimiz Türk ve Avrupa oyuncusunun zevk
          alarak oynadığı eski güzel oyun ortamını geri getirerek canlandırmak
          ve yıllardır eksik olan güncel içeriği sizlere sunmaktır..
        </p>
      </article>
    </div>
  );
};

export const AboutUs = () => {
  const [isConCode, setIsConCode] = useState("");
  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    setIsConCode(conCode);
  }, []);
  const { t, i18n } = useTranslation();
  return isConCode === "TR" ? (
    <AboutUsTR />
  ) : i18n.language === "tr" ? (
    <AboutUsTR />
  ) : (
    <AboutUsEn />
  );
};
