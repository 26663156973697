import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../apollo";
import { Link, useHistory } from "react-router-dom";
import { NavSignBar } from "./navbar/nav-sign-bar";
import { NavSignProfile } from "./navbar/nav-sign-profile";
import { NavMainMenus } from "./navbar/nav-main-menus";
import { NavMobileMenus } from "./navbar/nav-mobile-menus";
import { useMyProfile } from "../../hooks/use-my-profile";
import goWhLogo from "../../assets/portal/images/goe_logo_white.svg";
import { useLocation } from "react-use";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetMyIp } from "../../hooks/use-web-ip";

export const Header = () => {
  const location = useLocation();
  const disablePathName = location.pathname?.split("/")[1];
  const { t } = useTranslation();
  const isLoggedIn: boolean = useReactiveVar(isLoggedInVar);
  const { data, loading } = useMyProfile();
  const history = useHistory();

  const { data: myIpResult, loading: myIpLoading } = useGetMyIp();

  const myCon=myIpResult?.web_GetMyIp.IPInfo?.CountryCode!
  const myIp=myIpResult?.web_GetMyIp.IPInfo?.UserIP!

  localStorage.setItem("country_code", myCon)
  localStorage.setItem("userIP", myIp)

  useEffect(() => {
    if (isLoggedIn) {
      //console.log("토큰 만료 확인: ", data?.eapi_MyProfile);
      if (!loading && data?.eapi_MyProfile.email === undefined) {
        console.log("1차 토큰 만료", data);
        history.push("/sign-out");
      }
    }
  }, [loading]);

  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [width, setWidth] = useState(window.innerWidth);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  //const { height, width } = useWindowDimensions();
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth > 1023) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);



  return (
    <>
      {disablePathName !== "launcher" ? (
        disablePathName !== "sign-out" ? (
          <div className="fixed z-50 flex flex-col items-center justify-center w-full bg-black bg-opacity-60 font-opensans">
            {isLoggedIn && !loading && !data?.eapi_MyProfile.isVerifyEmail && (
              <div className="w-full p-3 text-xs text-center bg-red-700 text-coolGray-300 bg-opacity-70">
                <Link
                  to={{
                    pathname: "/resend-code",
                    state: { referrer: "/" }
                  }}
                >
                  <span className="font-bold transition-all duration-300 ease-in-out text-md text-warmGray-100 filter drop-shadow-md hover:text-coolGray-600 hover:underline">
                    {t("common.verify_email")}
                  </span>
                </Link>
              </div>
            )}
            <nav className="items-center justify-between hidden w-full h-20 mx-auto text-gray-300 max-w-screen-2lg lg:flex">
              <div className="flex items-center justify-center">
                <Link to="/" className="group">
                  <img src={goWhLogo} alt="genius orc logo" className="h-14" />
                </Link>
                <NavMainMenus />
              </div>
              {isLoggedIn ? <NavSignProfile /> : <NavSignBar />}
            </nav>
            <nav className="relative flex items-center justify-center w-full h-16 mx-auto text-gray-300 max-w-screen-2lg lg:hidden">
              <Link to="/" className="group">
                <img src={goWhLogo} alt="genius orc logo" className="h-10" />
              </Link>
              <div className="absolute right-4">
                <button
                  type="button"
                  className="block border-0 outline-none appearance-none focus:outline-none active:outline-none"
                  onClick={handleClick}
                >
                  <svg
                    className={`w-8 h-8 text-white fill-current hover:text-red-700`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    {isOpen && (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                    {!isOpen && (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </button>
              </div>
            </nav>
            <div
              className={`border-t border-red-900 w-full justify-center ${
                isOpen ? "flex " : "hidden"
              }`}
            >
              <NavMobileMenus onClick={handleClick} />
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
