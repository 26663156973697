import { CakeIcon, BadgeCheckIcon } from "@heroicons/react/solid";
import { CommonLargeBtn } from "../../components/general/common-large-btn";
import { Helmet } from "react-helmet";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import {
  verifySecondEmail,
  verifySecondEmailVariables
} from "../../__generated__/verifySecondEmail";
import { useLocationParam } from "../../hooks/use-location-param";
import { useMyProfile } from "../../hooks/use-my-profile";
import { useHistory } from "react-router-dom";
import { PortalChildren } from "../../components/portal-children";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const VERIFY_SECOND_EMAIL_MUTATION = gql`
  mutation verifySecondEmail($input: VerifySecondEmailInput!) {
    eapi_VerifySecondEmail(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

export const RecoverEmailVerification = () => {
  const { t, i18n } = useTranslation();
  const { data: userProfile } = useMyProfile();
  const client = useApolloClient();
  const history = useHistory();

  const onCompleted = (data: verifySecondEmail) => {
    const {
      eapi_VerifySecondEmail: { ResultCode }
    } = data;
    //성공
    if (ResultCode?.Code === "10000" && userProfile?.eapi_MyProfile.email) {
      client.writeFragment({
        id: `UserInfoEntity:${userProfile.eapi_MyProfile.email}`,
        fragment: gql`
          fragment VerifyRecoverEmailFrag on UserInfoEntity {
            isVerifySecondEmail
          }
        `,
        data: {
          isVerifySecondEmail: true
        }
      });
    }
    //실패
    else {
      //history.push("/");
      //window.location.reload();
    }
  };
  const [verifySecondEmail, { data: verifyEmailResult, loading }] = useMutation<
    verifySecondEmail,
    verifySecondEmailVariables
  >(VERIFY_SECOND_EMAIL_MUTATION, {
    onCompleted
  });

  const getParamCode: string = useLocationParam("code");

  useEffect(() => {
    //console.log("getParamCode:", getParamCode);

    if (getParamCode !== undefined) {
      verifySecondEmail({
        variables: {
          input: {
            secondCode: getParamCode
          }
        }
      });
    } else {
      setTimeout(() => {
        history.push("/");
      }, 3000);
    }
  }, [verifySecondEmail]);

  return (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center w-full pt-32">
        <Helmet>
          <title>Second Verification Email :: GeniusOrc</title>
        </Helmet>
        <div className="flex flex-col items-center justify-center w-3/5">
          <h1 className="text-5xl text-center font-oswald">
            {t("verify_comp.title1")}
          </h1>
          <hr className="w-full my-10 border border-gray-500" />
          {getParamCode !== undefined ? (
            loading ? (
              <div className="flex flex-col items-center justify-center w-3/5">
                {t("common.loading")} ...
              </div>
            ) : verifyEmailResult?.eapi_VerifySecondEmail.ResultCode?.Code ===
              "10000" ? (
              <>
                <h1 className="text-3xl text-purple-800 font-oswald">
                  `${t("verify_comp.subtitle1")}`
                </h1>
                <div
                  className={
                    "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
                  }
                >
                  <div className="relative flex items-center justify-center mb-10">
                    <div className="flex items-center justify-center w-24 h-24 rounded-full bg-coolGray-300">
                      <CakeIcon className="w-20 h-20 text-coolGray-500" />
                    </div>
                    <div className="absolute top-12 -right-3">
                      <BadgeCheckIcon className="w-14 h-14 text-warmGray-400" />
                    </div>
                  </div>
                  <p className="mb-2 text-sm">Welcome to Genius Orc</p>
                  <p className="text-sm">
                    Embark on an unforgettable adventure presended by Genius Orc
                    Entertainment.
                  </p>
                  <div className="box-border flex items-center justify-center w-2/3 mt-4">
                    <CommonLargeBtn
                      msg={`${t("gnb.sign_in")}`}
                      url={"/sign-in"}
                      size={
                        "w-1/2 max-w-2xl text-lg bg-coolGray-700 text-coolGray-100 hover:bg-purple-900 hover:text-coolGray-100 m-2"
                      }
                    />
                    <CommonLargeBtn
                      msg={`${t("common.go_back")}`}
                      url={"/"}
                      size={
                        "w-1/2 max-w-2xl text-lg bg-coolGray-100 text-coolGray-700 hover:bg-purple-900 hover:text-coolGray-100 m-2 capitalize"
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <h1 className="text-3xl text-red-700 font-oswald">
                  {t("verify_comp.error_04")}
                </h1>
                <div
                  className={
                    "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
                  }
                >
                  <div className="relative flex items-center justify-center mb-10">
                    <div className="flex items-center justify-center w-24 h-24 rounded-full bg-coolGray-300">
                      <CakeIcon className="w-20 h-20 text-coolGray-500" />
                    </div>
                    <div className="absolute top-12 -right-3">
                      <BadgeCheckIcon className="w-14 h-14 text-warmGray-400" />
                    </div>
                  </div>
                  <p className="text-md">
                    {
                      verifyEmailResult?.eapi_VerifySecondEmail.ResultCode
                        ?.Description
                    }
                  </p>
                  <div className="box-border flex items-center justify-center w-2/3 mt-4">
                    <CommonLargeBtn
                      msg={`${t("common.go_back")}`}
                      url={"/"}
                      size={
                        "w-1/2 max-w-2xl text-lg bg-coolGray-100 text-coolGray-700 hover:bg-purple-900 hover:text-coolGray-100 m-2 capitalize"
                      }
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <>
              <h1 className="text-3xl text-red-700 font-oswald">
                {t("verify_comp.error_05")}
              </h1>
              <div
                className={
                  "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
                }
              >
                <div className="relative flex items-center justify-center mb-10">
                  <div className="flex items-center justify-center w-24 h-24 rounded-full bg-coolGray-300">
                    <CakeIcon className="w-20 h-20 text-coolGray-500" />
                  </div>
                  <div className="absolute top-12 -right-3">
                    <BadgeCheckIcon className="w-14 h-14 text-warmGray-400" />
                  </div>
                </div>
                <p className="text-md">{t("verify_comp.error_03")}</p>
                <div className="box-border flex items-center justify-center w-2/3 mt-4">
                  <CommonLargeBtn
                    msg={`${t("common.go_back")}`}
                    url={"/"}
                    size={
                      "w-1/2 max-w-2xl text-lg bg-coolGray-100 text-coolGray-700 hover:bg-purple-900 hover:text-coolGray-100 m-2 capitalize"
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </PortalChildren>
  );
};
