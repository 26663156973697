import React from "react";

interface IAddBtnProps {
  actionText: string | null;
  size: string | null;
  loading: boolean | null;
  enabled: boolean | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const BtnSmallLoading: React.FC<IAddBtnProps> = ({
  actionText,
  size,
  loading,
  enabled,
  onClick,
}) => {
  return (
    <button
      className={`${size} flex justify-center items-center border border-coolGray-400 
        bg-gradient-to-b  text-sm text-coolGray-800 
        hover:from-coolGray-400 hover:to-coolGray-200 hover:text-coolGray-600
        ${
          loading
            ? `from-coolGray-500 to-coolGray-100 pointer-events-none`
            : `from-coolGray-100 to-coolGray-300 cursor-pointer`
        }
        ${enabled && `from-coolGray-700 to-coolGray-200 pointer-events-none`} 
        focus:outline-none focus:rounded-none
        `}
      onClick={onClick}
    >
      {loading ? (
        <>
          <svg
            className="w-6 h-6 mr-2 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>Loading...</span>
        </>
      ) : (
        actionText
      )}
    </button>
  );
};
