import {Helmet} from "react-helmet";
import React, { useEffect, useState } from "react";
import { PortalChildren } from "../components/portal-children";
import { useHistory } from "react-router-dom";

export const MainPage = () => {

  let history = useHistory();
  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    if (conCode === "TR") {
      history.push("/game/ts2/main?lang=tr");
    } else {
      history.push("/game/ts2/main?lang=en");
    }
  }, []);

  return (
    <PortalChildren classes="portal">
      <div className="flex items-center justify-center bg-gray-100 h-96">
        <Helmet>
          <title>Main :: Genius Orc</title>
        </Helmet>
        <h1>Main Page</h1>
      </div>
    </PortalChildren>
  );
};
