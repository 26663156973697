import React from "react";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import { NotFound } from "../error/404";
import { JoinSocial } from "./join/join-social";
import { JoinPolicy } from "./join/join-policy";
import { JoinForm } from "./join/join-form";
import { JoinSignedUp } from "./join/join-signed-up";
import { PortalChildren } from "../../components/portal-children";

export const JoinAccount = () => {
  let { id }: any = useParams();
  const returnParamValue = (param: any) => {
    //console.log(param);
    if (param === undefined) {
      return (
        <>
          <JoinSocial />
        </>
      );
    } else if (param === "before-policy") {
      return (
        <>
          <JoinPolicy />
        </>
      );
    } else if (param === "join-account") {
      return (
        <>
          <JoinForm />
        </>
      );
    } else if (param === "join-confirm") {
      return (
        <>
          <JoinSignedUp />
        </>
      );
    } else {
      return <NotFound />;
    }
  };

  return (
    <PortalChildren classes="portal">
      <div className="flex items-start justify-center w-full py-10 bg-gray-100">
        <Helmet>
          <title>Create Account :: Genius Orc</title>
        </Helmet>
        {returnParamValue(id)}
      </div>
    </PortalChildren>
  );
};

