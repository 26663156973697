import { PolicyEULA } from "../../../components/general/policy-EULA";
import sc05 from "../../../assets/game/ts2/images/about/page-img_about_us.png";
export const Eula = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-24 text-xl font-notoserif text-coolGray-800">
      <img src={sc05} alt="GOE Logo" className="w-full my-10" />
      <PolicyEULA />
    </div>
  );
};
