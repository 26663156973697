import {Helmet} from "react-helmet";
import React, { MouseEvent, useState } from "react";
import {
  CheckCircleIcon,
  ClipboardCheckIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { CommonLargeBtn } from "../../../components/general/common-large-btn";
import { BtnSmallLoading } from "../../../components/general/btn-small-loading";
import { useHistory, useLocation } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import {
  resendVerifyEmail,
  resendVerifyEmailVariables,
} from "../../../__generated__/resendVerifyEmail";
import { useTranslation } from "react-i18next";
interface ILocationProps {
  state: {
    email: string;
    nickName: string;
  };
}

const RESEND_VERIFY_MUTATION = gql`
  mutation resendVerifyEmail($input: ResendCodeInput!) {
    eapi_ResendVerifyEmail(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
      RegDate
    }
  }
`;

export const JoinSignedUp = () => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const history = useHistory();
  const location: ILocationProps = useLocation();
  //console.log("data :", location);
  if (location.state === undefined) {
    history.goBack();
  }

  const onCompleted = (data: resendVerifyEmail) => {
    //console.log("oncompleted: ", data);
    const code = data.eapi_ResendVerifyEmail.ResultCode?.Code;
    if (code) {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 180000);
    }
  };

  const [resendVerifyEmail, { loading, data: resendVerifyEmailResult }] =
    useMutation<resendVerifyEmail, resendVerifyEmailVariables>(
      RESEND_VERIFY_MUTATION,
      { onCompleted }
    );

  const onClickSendEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!loading) {
      resendVerifyEmail({
        variables: {
          input: {
            email: location.state.email,
            nickName: location.state.nickName
          }
        }
      });
    }
    //console.log("event: ", event);
  };
  return (
    <div className="flex items-center justify-center w-full pt-20">
      <Helmet>
        <title>Complete Sign up :: Genius Orc</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center w-3/5">
        <h1 className="text-5xl text-center font-oswald">Join Genius Orc</h1>
        <hr className="w-full my-10 border border-gray-500" />
        <ul className="flex items-center justify-center mb-10">
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <ClipboardCheckIcon className="w-6 h-6 text-coolGray-100" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-300" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-300">
            <PencilIcon className="w-6 h-6 text-coolGray-100" />
          </li>
          <li>
            <hr className="w-5 border-coolGray-300" />
          </li>
          <li className="flex items-center justify-center w-10 h-10 rounded-full bg-coolGray-600">
            <CheckCircleIcon className="w-6 h-6 text-white" />
          </li>
        </ul>
        <h1 className="text-3xl text-purple-800 font-oswald">
          {t("join_complete.title")}
        </h1>
        <div
          className={
            "w-full border border-gray-300 m-10 py-10 px-4 flex justify-center items-center flex-col "
          }
        >
          <p className={"text-sm text-center max-w-lg"}>
            {t("join_complete.desc")
              .split("\n")
              .map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))}
          </p>
          <div className="w-3/5 py-4 my-4 text-center text-purple-900 bg-gray-200">
            <p className="">{location.state.email}</p>
          </div>
          <p className="mb-6 text-sm text-gray-600">
            ({t("join_complete.ex_01")})
          </p>

          <BtnSmallLoading
            actionText={`${
              isClicked
                ? `${t("join_complete.btn_01_1")}`
                : `${t("join_complete.btn_01")}`
            }`}
            loading={loading}
            enabled={isClicked}
            size={"w-1/3 max-w-2xl h-10 my-4"}
            onClick={onClickSendEmail}
          />
          {resendVerifyEmailResult?.eapi_ResendVerifyEmail.ResultCode?.Code ===
          "10000" ? (
            <p className="mb-5 -mt-2 text-xs text-red-600">
              {t("join_complete.ex_02")}
            </p>
          ) : (
            <p className="mb-5 -mt-2 text-xs text-red-600">
              {
                resendVerifyEmailResult?.eapi_ResendVerifyEmail.ResultCode
                  ?.Description
              }
            </p>
          )}
          <CommonLargeBtn
            msg={`${t("join_complete.btn_02")}`}
            url={"/sign-in"}
            size={
              "w-1/3 my-4 max-w-2xl text-xl bg-coolGray-800 text-coolGray-100 hover:bg-coolGray-200 hover:text-coolGray-600"
            }
          />
        </div>
      </div>
    </div>
  );
};
