import { MyProfileSideBar } from "./my-profile/my-profile-side-bar";
import React from "react";
import { Redirect, Route, useParams } from "react-router-dom";
import { MyProfileInfo } from "./my-profile/my-profile-info";
import { MyProfileDetail } from "./my-profile/my-profile-detail";
import { MyProfileSocialLink } from "./my-profile/my-profile-social-link";
import { MyProfileHistory } from "./my-profile/my-profile-history";
import { MyProfilePaymentHistory } from "./my-profile/my-profile-payment-history";
import { NotFound } from "../error/404";
import { ChgDetailProfile } from "./my-profile/chg-detail-profile";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../apollo";
import { PortalChildren } from "../../components/portal-children";

export const MyProfile = () => {
  let { id }: any = useParams();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const returnParamValue = (param: any) => {
    if (param === "info") {
      return (
        <>
          <MyProfileSideBar />
          <MyProfileInfo />
        </>
      );
    } else if (param === "detail") {
      return (
        <>
          <MyProfileSideBar />
          <Route
            path="/my-profile/detail"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={MyProfileDetail} exact />
                <Route path={`${url}/:id`} children={<ChgDetailProfile />} />
              </>
            )}
          />
        </>
      );
    } else if (param === "history") {
      return (
        <>
          <MyProfileSideBar />
          <MyProfileHistory />
        </>
      );
    } /*else if (param === "social") {
      return (
        <>
          <MyProfileSideBar />
          <MyProfileSocialLink />
        </>
      );
    }*/else if (param === "payment") {
      return (
        <>
          <MyProfileSideBar />
          <MyProfilePaymentHistory />
        </>
      );
    } else {
      return <NotFound />;
    }
  };
  return (
    <PortalChildren classes="portal">
      <div className="flex items-start justify-center w-full py-10 bg-gray-100">
        <div className="flex justify-center flex-col w-11/12 xl:w-4/5 lg:justify-start lg:flex-row lg:space-x-10">
          {isLoggedIn ? returnParamValue(id) : <Redirect to="/" />}
        </div>
      </div>
    </PortalChildren>
  );
};
/*else if (param === "deletion") {
      return (
        <>
          <MyProfileDeletion />
        </>
      );
    } */
