import React from "react";
import {ShieldExclamationIcon} from "@heroicons/react/solid";
interface IErrorTextProps {
  textColor: string | null | undefined;
  errorMsg: string | null | undefined;
}
export const MyProfileFormErrorTxt: React.FC<IErrorTextProps> = ({
  textColor,
  errorMsg,
}) => {
  return (
    <div className="flex items-center justify-center group">
      <ShieldExclamationIcon
        className={`w-5 h-5 ${textColor} group-hover:text-cyan-700 group-hover:underline transition-all ease-in-out duration-500`}
      />
      <p
        className={`ml-1 ${textColor} text-sm group-hover:text-cyan-700 group-hover:underline transition-all ease-in-out duration-500`}
      >
        {errorMsg}
      </p>
    </div>
  );
};
