import { useMyProfile } from "../../../hooks/use-my-profile";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShopHomeNormal } from "./shop-home-normal";
import { ShopHomeEpin } from "./shop-home-epin";

export const ShopHome = () => {
  const { t, i18n } = useTranslation();
  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  const history = useHistory();
  if (!userProfileLoading && !userProfile?.eapi_MyProfile.isVerifyEmail) {
    history.push("/");
  }

  return userProfileLoading ? (
    <p>Loading...</p>
  ) : userProfile?.eapi_MyProfile.countryCode === "TR" ? (
    <ShopHomeEpin />
  ) : (
    <ShopHomeNormal />
  );
  
  /*
  return userProfileLoading ? (
    <p>Loading...</p>
  ) : i18n.language === "tr" ? (
    <ShopHomeEpin />
  ) : (
    <ShopHomeNormal />
  );
  */
};
