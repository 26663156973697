import React, { useEffect, useState } from "react";
import {
  ServerIcon,
  KeyIcon,
  LocationMarkerIcon,
  CreditCardIcon,
  CogIcon
} from "@heroicons/react/solid";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { useMyProfile } from "../../../hooks/use-my-profile";
import { useMyBalance } from "../../../hooks/use-my-balance";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { useMyTranLog } from "../../../hooks/use-my-transaction-log";
import { useMySignInLog } from "../../../hooks/use-my-signin-log";
import { Transition, Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import i18n from "locales/i18n";
import biLogoEn from "../../../assets/portal/images/bi_logo_sm.png";
import biLogoTr from "../../../assets/portal/images/small_karahan_logo.png";
import moment from "moment";

export const MyProfileInfo = () => {
  const [isConCode, setIsConCode] = useState("");
  const { data: userProfile, loading: userProfileLoading } = useMyProfile();
  const { data: userBalance, refetch: balanceRefetch } = useMyBalance(1);
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(1, "months").format()
  );
  const [endDate, setEndDate] = useState(moment.utc().format());
  const {
    data: tranLog,
    loading: tranLogLoading,
    refetch: transRefetch
  } = useMyTranLog(1, 9, startDate, endDate);
  const {
    data: signInLog,
    loading: signInLogLoading,
    refetch: signInLogRefetch
  } = useMySignInLog(1, 2);
  const userData = userProfile?.eapi_MyProfile;
  const userBalanceData = userBalance?.eapi_BalanceGet.balanceEntity;
  const { t } = useTranslation();

  let [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    transRefetch();
    signInLogRefetch();
    balanceRefetch();
    const conCode: string = localStorage.getItem("country_code") || "";
    setIsConCode(conCode);
  }, []);

  return (
    <>
      <section className="w-full lg:w-9/12">
        <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
          {t("account_info.side.acc_info")}
        </h1>
        <hr className="border border-gray-500 filter drop-shadow-sm" />
        {/* Header Hero */}
        <section className="w-full my-2">
          <div className="box-border my-4">
            <div className="box-border relative flex items-center justify-start w-full h-40 p-4 rounded-xl bg-coolGray-700">
              <div className="absolute top-3 right-3">
                <CogIcon className="w-6 h-6 text-gray-400 transition duration-300 ease-in-out cursor-pointer hover:text-purple-600" />
              </div>
              {userProfileLoading ? (
                `${t("common.loading")} ...`
              ) : (
                <div className="flex items-center justify-center lg:ml-6">
                  <div className="rounded-full bg-coolGray-200 w-28 h-28"></div>
                  <div className="ml-2 md:ml-4">
                    <p className="text-xl text-white font-oswald">
                      {userData?.nickName === null && "Set Your NickName"}
                    </p>
                    <p className="text-sm text-gray-300 capitalize">
                      {t("account_info.main_cont.member_since")},
                      <Moment
                        local
                        locale={i18n.language}
                        format="ll"
                        className="ml-2 font-semibold text-md lg:ml-4 lg:mr-1"
                      >
                        {userData?.regDate}
                      </Moment>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* End */}
        {/* Main Contents */}
        <div className="flex flex-col items-start justify-center w-full md:flex-row">
          {/*Left side */}
          <section className="box-border w-full space-y-4 md:w-1/2 md:pr-2">
            {/*My Games */}
            <div className="w-full">
              <div className="relative w-full h-full border border-gray-300 shadow-md rounded-xl">
                <div className="flex items-center justify-start py-2 pl-2 bg-gray-200 rounded-t-xl">
                  <ServerIcon className="w-8 h-8 mx-2 text-gray-500 transition duration-300 ease-in-out" />
                  <p className="text-lg capitalize font-oswald">
                    {t("account_info.main_cont.my_games")}
                  </p>
                </div>
                <hr className="border-gray-300" />
                <div className="flex items-center justify-start p-6">
                  <div className="flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full ring-2 ring-red-600 ring-offset-4">
                    {isConCode === "TR" ? (
                      <img
                        src={biLogoTr}
                        alt="Karahan"
                        className="absolute h-6"
                      />
                    ) : i18n.language === "tr" ? (
                      <img
                        src={biLogoTr}
                        alt="Karahan"
                        className="absolute h-6"
                      />
                    ) : (
                      <img src={biLogoEn} alt="TS2" className="absolute h-6" />
                    )}
                  </div>
                  <div className="ml-4">
                    <p className="mb-1 text-lg font-semibold">
                      {t("game_title")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*Account */}
            <div className="w-full">
              <div className="relative w-full h-full border border-gray-300 shadow-md rounded-xl">
                <div className="absolute top-3 right-3">
                  <Link to="/my-profile/detail">
                    <CogIcon className="w-6 h-6 text-gray-400 transition duration-300 ease-in-out cursor-pointer hover:text-purple-600" />
                  </Link>
                </div>
                <div className="flex items-center justify-start py-2 pl-2 bg-gray-200 rounded-t-xl">
                  <KeyIcon className="w-8 h-8 mx-2 text-gray-500 transition duration-300 ease-in-out" />
                  <p className="text-lg capitalize font-oswald">
                    {t("account_info.main_cont.acc")}
                  </p>
                </div>
                <hr className="border-gray-300" />
                <div className="flex flex-col items-start justify-start px-4 py-4">
                  <p className="mb-2 text-gray-900 text-md">
                    <span className="">{userData?.nickName}</span>
                    <span className="ml-2 font-semibold">
                      [ {userData?.email} ]
                    </span>
                  </p>
                  <p className="text-xs text-gray-600">
                    <span>{t("account_info.main_cont.pass_chg_on")}</span>{" "}
                    <span className="font-semibold">
                      <Moment local locale={i18n.language} format="lll">
                        {userData?.pwChgDate}
                      </Moment>
                    </span>
                  </p>
                  <Link
                    to="/my-profile/detail/change-password"
                    className="w-full py-2 mt-4 text-sm text-center text-gray-600 capitalize transition duration-300 ease-in-out border border-gray-300 rounded-lg hover:bg-gray-500 hover:text-white"
                  >
                    {t("account_info.main_cont.chg_pass")}
                  </Link>
                </div>
              </div>
            </div>
            {/*Recent Login */}
            <div className="w-full">
              <div className="relative w-full h-full border border-gray-300 shadow-md rounded-xl">
                <div className="absolute top-3 right-3">
                  <Link to="/my-profile/history">
                    <CogIcon className="w-6 h-6 text-gray-400 transition duration-300 ease-in-out cursor-pointer hover:text-purple-600" />
                  </Link>
                </div>
                <div className="flex items-center justify-start py-2 pl-2 bg-gray-200 rounded-t-xl">
                  <LocationMarkerIcon className="w-8 h-8 mx-2 text-gray-500 transition duration-300 ease-in-out" />
                  <p className="text-lg capitalize font-oswald">
                    {t("account_info.main_cont.rec_log")}
                  </p>
                </div>
                <hr className="border-gray-300" />

                <div className="flex flex-col items-start justify-start px-4 py-4">
                  {signInLogLoading
                    ? `${t("common.loading")} ...`
                    : signInLog?.eapi_SignInLogGetByID.signInLogEntities?.map(
                        (item, index) => (
                          <>
                            {index === 0 && (
                              <div
                                key={item.regDate}
                                className="relative mb-3 pl-9"
                              >
                                <span className="absolute block w-3 h-3 rounded-full bg-cyan-500 top-2 left-2"></span>
                                <span className="absolute block w-1 h-1 bg-gray-300 rounded-full top-6 left-3"></span>
                                <span className="absolute block w-1 h-1 bg-gray-300 rounded-full top-9 left-3"></span>
                                <p className="">
                                  {item.city}, {item.countryName}
                                </p>
                                <p className="text-xs capitalize text-cyan-500">
                                  {t("account_info.main_cont.conn")}
                                </p>
                              </div>
                            )}
                            {index === 1 && (
                              <div key={item.regDate} className="relative pl-9">
                                <span className="w-2 h-2 rounded-full bg-cyan-500 block absolute top-1.5 left-2.5"></span>
                                <p className="text-sm">
                                  {item.city}, {item.countryName}
                                </p>
                                <p className="text-xs text-gray-400">
                                  <Moment
                                    local
                                    locale={i18n.language}
                                    format="lll"
                                  >
                                    {item.regDate}
                                  </Moment>
                                </p>
                              </div>
                            )}
                          </>
                        )
                      )}
                </div>
              </div>
            </div>
          </section>
          {/* End */}
          {/* Right Side */}
          <section className="box-border mt-4 w-full space-y-4 md:w-1/2 md:pl-2 md:mt-0">
            {/* Transaction History */}
            <div className="w-full">
              <div className="relative w-full h-full border border-gray-300 shadow-md rounded-xl">
                <div className="absolute top-3 right-3">
                  <Link to="/my-profile/payment">
                    <CogIcon className="w-6 h-6 text-gray-400 transition duration-300 ease-in-out cursor-pointer hover:text-purple-600" />
                  </Link>
                </div>
                <div className="flex items-center justify-start py-2 pl-2 bg-gray-200 rounded-t-xl">
                  <CreditCardIcon className="w-8 h-8 mx-2 text-gray-500 transition duration-300 ease-in-out" />
                  <p className="text-lg font-oswald">
                    {t("gnb.transaction_history")}
                  </p>
                </div>
                <hr className="border-gray-300" />
                <div className="flex items-center justify-between w-full p-6">
                  <div className="flex items-center justify-start">
                    <div className="flex items-center justify-center rounded-full bg-coolGray-200 w-14 h-14">
                      <CurrencyDollarIcon className="w-10 h-10 text-gray-500" />
                    </div>
                    <div className="ml-4">
                      <p className="text-gray-900 capitalize text-md">
                        {t("account_info.main_cont.cur_bal")}
                      </p>
                      <p className="text-3xl font-bold text-gray-600">
                        {userBalance?.eapi_BalanceGet.balanceEntity ? (
                          <NumberFormat
                            value={
                              userBalance?.eapi_BalanceGet.balanceEntity?.amount
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <Link
                      to="/shop"
                      className="px-2 py-2 my-3 text-sm text-gray-600 capitalize transition duration-300 ease-in-out border border-gray-300 rounded-lg hover:bg-gray-500 hover:text-white"
                    >
                      {t("account_info.main_cont.buy_coin")}
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full">
                  <table className="w-11/12 border-collapse table-auto">
                    {tranLog?.eapi_UsedTrackGetByID.Pagination?.totalPages !==
                    0 ? (
                      <thead>
                        <tr className="h-8 text-sm uppercase bg-gray-200 font-oswald">
                          <th className="">{t("common.date")}</th>
                          <th className="">{t("common.item")}</th>
                          <th className="">{t("common.amount")}</th>
                          <th className="">{t("common.total")}</th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <hr />
                      </thead>
                    )}
                    <tbody>
                      {/* */}
                      {tranLogLoading ? (
                        `${t("common.loading")} ...`
                      ) : tranLog?.eapi_UsedTrackGetByID.Pagination
                          ?.totalPages === 0 ? (
                        <tr>
                          <td
                            colSpan={4}
                            className="py-4 font-semibold text-center text-gray-500"
                          >
                            {t("back_end_errors.no_records")}
                          </td>
                        </tr>
                      ) : (
                        tranLog?.eapi_UsedTrackGetByID.userTrackLogs?.map(
                          (item) => (
                            <tr
                              key={item.regDate}
                              className="text-xs text-center text-gray-700 border-b"
                            >
                              <td className="py-2">
                                <Moment
                                  format="lll "
                                  local
                                  locale={i18n.language}
                                >
                                  {item.regDate}
                                </Moment>
                              </td>
                              <td
                                className={`${
                                  item.deductTxID
                                    ? `text-red-600 `
                                    : `text-cyan-800`
                                } font-semibold`}
                              >
                                {t(`${item.itemName}`)}
                              </td>
                              <td
                                className={`${
                                  item.deductTxID
                                    ? `text-red-600 `
                                    : `text-cyan-800`
                                } font-semibold`}
                              >
                                {item.deductTxID ? `-` : ``}
                                <NumberFormat
                                  value={item.usedAmount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td className="">
                                <NumberFormat
                                  value={item.totalBalance}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                  {tranLog?.eapi_UsedTrackGetByID.Pagination?.totalPages !==
                    0 && (
                    <Link
                      to="/my-profile/payment"
                      className="w-11/12 py-2 my-3 text-sm text-center text-gray-600 capitalize transition duration-300 ease-in-out border border-gray-300 rounded-lg hover:bg-gray-500 hover:text-white"
                    >
                      {t("common.load_more")}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* End */}
        </div>
      </section>
      {/* */}
      <Transition appear show={isOpen}>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="min-h-screen px-4 text-center bg-opacity-50 bg-coolGray-700">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle h-96" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-11/12 max-w-sm p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  {t("add_email.pop_title")}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t("add_email.pop_desc")}
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium capitalize border border-transparent rounded-md text-coolGray-900 bg-coolGray-200 hover:bg-coolGray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-coolGray-500"
                    onClick={closeModal}
                  >
                    {t("common.cancel")}
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-red-900 capitalize bg-red-200 border border-transparent rounded-md hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                  >
                    {t("common.delete")}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
