import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {MailOpenIcon, PlusCircleIcon} from "@heroicons/react/solid";
import {FormErrors} from "../../../../components/general/form-errors";
import {ButtonCommon} from "../../../../components/general/btn-common";
import React, {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {changePasswordMutation, changePasswordMutationVariables} from "../../../../__generated__/changePasswordMutation";
import {useHistory} from "react-router-dom";
import {Dialog, Transition} from "@headlessui/react";
import { useTranslation } from "react-i18next";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePasswordMutation($input: UpdateProfilePasswordInput!) {
    eapi_UpdateProfilePassword(input: $input) {
      ResultCode {
        Code
        Description
        Location
      }
    }
  }
`;

interface IChgPasswordProps {
  curPassword: string;
  chgPassword: string;
  confirmPassword: string;
}

export const ChangePassword = () => {
  const { t } = useTranslation();
  let [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const closeModal = () => {
    setIsOpen(false);
    history.push("/my-profile/detail");
  };
  const errorModal = () => {
    setIsOpen(false);
  };
  const validChgPassword = Yup.object().shape({
    curPassword: Yup.string()
      .min(8, `${t("sign_in.error.err_password_01")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+=~_-])(?=.*[0-9]).{8,16}$/,
        t("sign_in.error.err_password_02")
      )
      .required(`${t("sign_in.error.err_password_03")}`),
    chgPassword: Yup.string()
      .min(8, `${t("sign_in.error.err_password_01")}`)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+=~_-])(?=.*[0-9])(?!(?=.*[ışğüşöçİĞÜŞÖÇ])).{8,16}$/,
        `${t("sign_in.error.err_password_02")}`
      )
      .required(`${t("sign_in.error.err_password_03")}`),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("chgPassword"), null],
        `${t("sign_in.error.err_password_04")}`
      )
      .required(`${t("sign_in.error.err_password_05")}`)
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<IChgPasswordProps>({
    mode: "onChange",
    resolver: yupResolver(validChgPassword)
  });

  const onCompleted = (data: changePasswordMutation) => {
    const {
      eapi_UpdateProfilePassword: { ResultCode }
    } = data;
    if (ResultCode?.Code === "10000") {
      setIsOpen(true);
    } else {
      setIsOpen(true);
    }
  };
  const [changePasswordMutation, { loading, data: chgPwRes }] = useMutation<
    changePasswordMutation,
    changePasswordMutationVariables
  >(CHANGE_PASSWORD_MUTATION, {
    onCompleted
  });

  const onSubmit: SubmitHandler<IChgPasswordProps> = (data) => {
    //console.log(data);
    if (!loading) {
      changePasswordMutation({
        variables: {
          input: {
            curPassword: data.curPassword,
            chgPassword: data.chgPassword
          }
        }
      });
    }
  };
  return (
    <section className="w-full lg:w-9/12">
      <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
        {t("chg_password.title")}
      </h1>
      <hr className="border border-gray-500" />
      {/* */}
      <article className="flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center justify-center my-8">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
            <MailOpenIcon className="w-14 h-14 text-coolGray-500" />
          </div>
          <div className="absolute top-11 -right-2">
            <PlusCircleIcon className="w-10 h-10 text-warmGray-400" />
          </div>
        </div>
        <p className="mb-5 text-3xl text-purple-900 font-oswald">
          {t("chg_password.subtitle")}
        </p>
      </article>
      <div
        className={
          "w-full border border-gray-300 flex justify-center items-center flex-col py-10"
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* */}
          <label className="block mt-4">
            <span className="text-sm text-gray-700 font-oswald">
              {t("chg_password.cur_pass")}
            </span>
            <input
              aria-label="Enter your password"
              aria-required="true"
              type="password"
              placeholder={t("chg_password.cur_pass")}
              className={`block w-full outline-none inputStyle ${
                errors.curPassword ||
                chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
                  "91601"
                  ? `border-red-600`
                  : `border-coolGray-300`
              }`}
              {...register("curPassword")}
            />
          </label>
          {errors.curPassword && (
            <FormErrors errorMessage={errors.curPassword?.message} />
          )}
          {chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
            "91601" && (
            <FormErrors
              errorMessage={
                chgPwRes?.eapi_UpdateProfilePassword.ResultCode.Description
              }
            />
          )}
          {/* */}
          <label className="block mt-4">
            <span className="text-sm text-gray-700 font-oswald">
              {t("chg_password.new_pass")}
            </span>
            <input
              aria-label="Enter your password"
              aria-required="true"
              type="password"
              placeholder={t("chg_password.new_pass")}
              className={`block w-full outline-none inputStyle ${
                errors.chgPassword ? `border-red-600` : `border-coolGray-300`
              }`}
              {...register("chgPassword")}
            />
          </label>
          {errors.chgPassword && (
            <FormErrors errorMessage={errors.chgPassword?.message} />
          )}
          <label className="block mt-2">
            <span className="text-sm text-gray-700 font-oswald">
              {t("chg_password.con_pass")}
            </span>
            <input
              aria-label="Enter your password to confirm"
              aria-required="true"
              type="password"
              placeholder={t("chg_password.con_pass")}
              className={`block w-full outline-none inputStyle ${
                errors.confirmPassword
                  ? `border-red-600`
                  : `border-coolGray-300`
              }`}
              {...register("confirmPassword")}
            />
          </label>
          {errors.confirmPassword && (
            <FormErrors errorMessage={errors.confirmPassword?.message} />
          )}
          <ul className="my-2 ml-5 text-xs list-disc list-outside">
            <li>{t("join_form.password_ex0")}</li>
            <li>{t("join_form.password_ex1")}</li>
          </ul>
          {/* */}
          <div className="flex items-center justify-center w-full">
            <ButtonCommon
              clickEnable={isValid}
              loading={loading}
              actionText={t("chg_password.title")}
              size={`w-1/2 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100`}
            />
          </div>
        </form>
      </div>
      {/* */}
      {/* */}
      <Transition appear show={isOpen}>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="min-h-screen px-4 text-center bg-opacity-50 bg-coolGray-700">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle h-96" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-11/12 max-w-sm p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  {chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
                  "10000"
                    ? `${t("chg_password.error_exp01")}`
                    : "Error"}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
                    "10000"
                      ? `${t("chg_password.error_exp02")}`
                      : chgPwRes?.eapi_UpdateProfilePassword.ResultCode
                          ?.Description}
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-red-900 bg-red-200 border border-transparent rounded-md hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={
                      chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
                      "10000"
                        ? closeModal
                        : errorModal
                    }
                  >
                    {chgPwRes?.eapi_UpdateProfilePassword.ResultCode?.Code ===
                    "10000"
                      ? `${t("common.okay")}`
                      : chgPwRes?.eapi_UpdateProfilePassword.ResultCode
                          ?.Description}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </section>
  );
};
