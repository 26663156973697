import { gql, useQuery } from "@apollo/client";
import {
  myTransactionLogQuery,
  myTransactionLogQueryVariables,
} from "../__generated__/myTransactionLogQuery";

const TRANSACTION_LOG_QUERY = gql`
  query myTransactionLogQuery($input: AllUsedTrackInput!) {
    eapi_UsedTrackGetByID(input: $input) {
      userTrackLogs {
        email
        countryName
        city
        trackID
        deductTxID
        itemName
        usedAmount
        totalBalance
        regDate
        accessType
      }
      Pagination {
        totalPages
        totalResults
      }
      ResultCode {
        Code
        Description
      }
    }
  }
`;

export const useMyTranLog = (
  page: number,
  perPage: number,
  startDate?: string | null,
  endDate?: string | null
) => {
  return useQuery<myTransactionLogQuery, myTransactionLogQueryVariables>(
    TRANSACTION_LOG_QUERY,
    {
      variables: {
        input: { startDate, endDate, page, perPage }
      }
    }
  );
};
