import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import tranEn from './files/en.json';
import tranTr from './files/tr.json';

export const languages = ['en', 'tr'] as const;

export type Languages = typeof languages[number]; // 'en' | 'tr'

const resources = {
    en: { translation: tranEn },
    tr: { translation: tranTr },
}

const lsGetItem: any = window.localStorage.getItem('language')

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: JSON.parse(lsGetItem)?.e?.lang || 'tr',
        fallbackLng: 'tr',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['path', 'navigator']
        },
    })

export default i18next;
