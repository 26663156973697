import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../../apollo";

export const NavMainMenus = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [isConCode, setIsConCode] = useState("");

  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    setIsConCode(conCode);
  }, []);

  const { t, i18n } = useTranslation();
  const urlList = [
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/en/support/home"
    },
    {
      login:
        "https://genius-orc.myfreshworks.com/login/auth/1632015805179?client_id=78368727158800390&redirect_uri=https://goe.freshdesk.com/freshid/customer_authorize_callback",
      logout: "https://goe.freshdesk.com/tr/support/home"
    }
  ];

  return (
    <ul className="grid grid-flow-col ml-4 text-center capitalize divide-x divide-coolGray-800 text-coolGray-50 auto-cols-max font-opensans">
      <li className="relative px-10 list-inline-text group hover:text-rose-700">
        <NavLink activeClassName="text-rose-700" to="/shop" className="">
          {t("gnb.coin_shop")}
        </NavLink>
      </li>
      <li className="px-10 list-inline-text hover:text-rose-700">
        <NavLink activeClassName="text-rose-700" to="/game/ts2/news">
          {t("common.news")}
        </NavLink>
      </li>
      <li className="px-10 list-inline-text hover:text-rose-700">
        <a
          href={
            i18n.language === "tr"
              ? `https://goe.freshdesk.com/tr/support/discussions`
              : `https://goe.freshdesk.com/en/support/discussions`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("common.forum")}
        </a>
      </li>
      <li className="px-10 list-inline-text hover:text-rose-700">
        {isLoggedIn ? (
          i18n.language === "tr" ? (
            <a
              href={urlList[1].login}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("common.support")}
            </a>
          ) : (
            <a
              href={urlList[0].login}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("common.support")}
            </a>
          )
        ) : i18n.language === "tr" ? (
          <a href={urlList[1].logout} target="_blank" rel="noopener noreferrer">
            {t("common.support")}
          </a>
        ) : (
          <a href={urlList[0].logout} target="_blank" rel="noopener noreferrer">
            {t("common.support")}
          </a>
        )}
      </li>
    </ul>
  );
};
