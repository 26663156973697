import {MailOpenIcon, PlusCircleIcon} from "@heroicons/react/solid";
import {FormErrors} from "../../../../components/general/form-errors";
import {PolicyPrivacy} from "../../../../components/general/policy-privacy";
import {ButtonCommon} from "../../../../components/general/btn-common";
import React from "react";
import * as Yup from "yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {gql, useApolloClient, useMutation} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {useMyProfile} from "../../../../hooks/use-my-profile";
import {
    updateProfileRecoverEmailMutation,
    updateProfileRecoverEmailMutationVariables
} from "../../../../__generated__/updateProfileRecoverEmailMutation";
import { useTranslation } from "react-i18next";

const UPDATE_PROFILE_RECOVER_EMAIL_MUTATION = gql`
  mutation updateProfileRecoverEmailMutation(
    $input: UpdateProfileRecoverEmailInput!
  ) {
    eapi_UpdateProfileRecoverEmail(input: $input) {
      ResultCode {
        Code
        Description
      }
    }
  }
`;

interface ISecondEmailFromProps {
  recoverEmail: string;
  policyPrivacy: boolean | null;
}

export const AddEmail = () => {
  const { t } = useTranslation();
  const { data: userProfile } = useMyProfile();
  const client = useApolloClient();
  const history = useHistory();

  const validateSecondEmail = Yup.object().shape({
    recoverEmail: Yup.string()
      .required(`${t("sign_in.error.err_email_01")}`)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        `${t("sign_in.error.err_email_03")}`
      )
      .email(`${t("sign_in.error.err_email_02")}`),
    policyPrivacy: Yup.boolean()
      .oneOf([true], `${t("sign_in.error.err_checkbox_01")}`)
      .required(`${t("sign_in.error.err_checkbox_02")}`)
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ISecondEmailFromProps>({
    mode: "onChange",
    resolver: yupResolver(validateSecondEmail)
  });

  const onCompleted = (data: updateProfileRecoverEmailMutation) => {
    //console.log("data call: ",data);
    const resultData = data.eapi_UpdateProfileRecoverEmail?.ResultCode;
    if (resultData?.Code === "10000" && userProfile?.eapi_MyProfile.email) {
      //redirect
      const { recoverEmail } = getValues();
      client.writeFragment({
        id: `UserInfoEntity:${userProfile.eapi_MyProfile.email}`,
        fragment: gql`
          fragment SecondEmailVerify on UserInfoEntity {
            recoverEmail
            isVerifySecondEmail
          }
        `,
        data: {
          recoverEmail,
          isVerifySecondEmail: false
        }
      });
      history.push("/my-profile/detail");
    }
  };

  const [
    updateProfileRecoverEmailMutation,
    { data: updateProfileRecoverEmailMutationResult, loading }
  ] = useMutation<
    updateProfileRecoverEmailMutation,
    updateProfileRecoverEmailMutationVariables
  >(UPDATE_PROFILE_RECOVER_EMAIL_MUTATION, {
    onCompleted
  });

  const onSubmit: SubmitHandler<ISecondEmailFromProps> = (data) => {
    //console.log(data)
    if (!loading) {
      const { recoverEmail } = getValues();
      updateProfileRecoverEmailMutation({
        variables: {
          input: { recoverEmail }
        }
      });
    }
  };

  return (
    <section className="w-full lg:w-9/12">
      <h1 className="pt-14 pb-10 text-4xl text-center capitalize font-oswald lg:pt-24">
        {t("add_email.title")}
      </h1>
      <hr className="border border-gray-500" />
      {/* */}
      <article className="flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center justify-center my-8">
          <div className="flex items-center justify-center w-20 h-20 rounded-full bg-coolGray-300">
            <MailOpenIcon className="w-14 h-14 text-coolGray-500" />
          </div>
          <div className="absolute top-11 -right-2">
            <PlusCircleIcon className="w-10 h-10 text-warmGray-400" />
          </div>
        </div>
        <p className="mb-5 text-3xl text-purple-900 font-oswald">
          {t("add_email.subtitle")}
        </p>
      </article>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={
          "w-full border border-gray-300 flex justify-center items-center flex-col py-10"
        }
      >
        {/* */}
        <div className="w-8/12 my-3">
          <label className="block">
            <span className="text-sm text-gray-700 font-oswald">
              {t("common.email")}
            </span>
            <input
              type="email"
              placeholder={t("common.email")}
              className={`block w-full outline-none inputStyle ${
                errors.recoverEmail ? `border-red-600` : `border-coolGray-300`
              }`}
              {...register("recoverEmail")}
            />
          </label>
          <ul className="mt-2 ml-5 text-xs list-disc list-outside">
            <li>{t("join_form.email_ex")}</li>
          </ul>
          {errors.recoverEmail && (
            <FormErrors errorMessage={errors.recoverEmail?.message} />
          )}
          <div className="mt-8 mb-2 bg-white border border-coolGray-300">
            <label className="inline-flex items-center py-4 pl-4">
              <input
                className={`form-checkbox w-6 h-6 ${
                  errors.policyPrivacy
                    ? `border-red-600`
                    : `border-coolGray-300`
                } bg-coolGray-100 text-purple-900
                         hover:border-purple-900 hover:bg-purple-200 focus:ring-purple-800`}
                type="checkbox"
                {...register("policyPrivacy")}
              />
              <span className="ml-2 text-sm">
                <span className="capitalize">({t("common.required")})</span>{" "}
                {t("add_email.context_01")}
              </span>
            </label>
            <hr className="border-coolGray-300" />
            <div className="">
              <div className="w-full h-32 overflow-auto">
                <p className="p-4 text-sm text-red-600">
                  ※ {t("add_email.desc")}
                </p>
                <div className="pl-4">
                  <PolicyPrivacy />
                </div>
              </div>
            </div>
          </div>
          {errors.policyPrivacy?.message && (
            <FormErrors errorMessage={errors.policyPrivacy?.message} />
          )}
        </div>

        {/* */}
        <div className="flex items-center justify-center w-8/12">
          <ButtonCommon
            clickEnable={isValid}
            loading={loading}
            actionText={t("add_email.email_veri")}
            size={`w-1/2 text-lg ml-2 hover:bg-purple-800 hover:text-coolGray-100 capitalize`}
          />
        </div>
        {updateProfileRecoverEmailMutationResult?.eapi_UpdateProfileRecoverEmail
          .ResultCode?.Code && (
          <FormErrors
            errorMessage={
              updateProfileRecoverEmailMutationResult
                ?.eapi_UpdateProfileRecoverEmail.ResultCode?.Description
            }
          />
        )}
      </form>
      {/* */}
    </section>
  );
};
